import React, {useState, useContext} from 'react';
import NavMenu from "../lib/components/structure/navigation/NavMenu";
import { SketchPicker } from 'react-color'
import '../lib/css/CartsContainer.css';
import assets from '../lib/components/shared/AssetLists'
import Cart from "../lib/components/cart/Cart";
import AuthContext from "../lib/components/shared/auth-context";
import OrdersCart from "../lib/components/cart/OrdersCart";
import {useParams} from "react-router";
import jwtToken from "jsonwebtoken";
import CustomerOrdersCart from "../lib/components/cart/CustomerOrdersCart";
import BackDrop from "../lib/components/structure/navigation/BackDrop";
import axios from "axios";
import AdminSideDrawer from "../lib/components/structure/navigation/AdminSideDrawer";
import SettingsPage from "../lib/components/structure/SettingsPage";
import WarningModal from "../lib/components/shared/WarningModal";
import TermsModal from "./../lib/components/shared/TermsModal";
import { loadStripe } from "@stripe/stripe-js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHdd } from "@fortawesome/free-solid-svg-icons";
import AboutPage from "../lib/components/shared/AboutPage";
import SupportPage from "../lib/components/structure/SupportPage";
import { init, track } from 'fbq'


const pixel = '631332306996217'

const CartsContainer = (props) => {
    init(pixel)
    track('PageView')
    const auth = useContext(AuthContext)
    let  { username } = useParams();
    const [ currentName, setCurrentName ] = useState('')
    const [ currentPhone, setCurrentPhone ] = useState('')
    const [ currentType, setCurrentType ] = useState('')
    const [ currentTitle, setCurrentTitle ] = useState('sales-ticket')
    const [ currentBody, setCurrentBody ] = useState('')
    const [ messageSuccess, setMessageSuccess ] = useState(false)
    const [ messageSuccessCode, setMessageSuccessCode ] = useState(null)
    const [ showAdminMode, setShowAdminMode ] = useState(false);
    const [ showOrdersMode, setShowOrdersMode ] = useState(false);
    const [ loadedCategories, setLoadedCategories ] = useState(null);
    const [ loadedOrders, setLoadedOrders ] = useState(null);
    const [ categoriesLoaded, setCategoriesLoaded ] = useState(false);
    const [ ordersLoaded, setOrdersLoaded ] = useState(false);
    const [ hoursLoaded, setHoursLoaded ] = useState(false);
    const [ settingsLoaded, setSettingsLoaded ] = useState(false);
    const [ loadedProducts, setLoadedProducts ] = useState(null);
    const [ productsLoaded, setProductsLoaded ] = useState(false);
    const [ basketValue , setBasketValue ] = useState(0.00);
    const [ basketItems, setBasketItems ] = useState([]);
    const [ customerMode, setCustomerMode ] = useState(true)
    const [ gotMode, setGotMode ] =useState(false)
    const [ allocatedShopOpen, setAllocatedShopOpen] = useState(false)
    const [ gotDayOpen, setGotDayOpen ] = useState(false)
    const [ useName, setUseName ] = useState(username)
    const [ loading, setLoading ] = useState(false)
    const [ showSettings, setShowSettings ] = useState(false);
    const [ showBasket, setShowBasket ] = useState(false);
    const [ displayCategory, setDisplayCategory ] = useState(0)
    const [ showNavDrawer, setShowNavDrawer ] = useState(false)
    const [ showOpeningHours, setShowOpeningHours ] = useState(false)
    const [ showAuth, setShowAuth ] = useState(false);
    const [ showGoLive, setShowGoLive ] = useState(false);
    const [ editMode, setEditMode ] = useState(false)
    const [ deleteMode, setDeleteMode ] = useState(false)
    const [ showNew, setShowNew ] = useState(false);
    const [ showApproved, setShowApproved ] = useState(true);
    const [ showReady, setShowReady ] = useState(false);
    const [ showComplete, setShowComplete] = useState(false);
    const [ showArchived, setShowArchived ] = useState(false);
    const [ showCancelled, setShowCancelled] = useState(false);
    const [ showIncomplete, setShowIncomplete ] = useState(false);
    const [ orderIsDelivery, setOrderIsDelivery ] = useState(true);
    const [ formDataGot, setFormDataGot ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(false);
    const [ contactForm, setContactForm ] = useState(false)
    const [ showInvoiceSettings, setShowInvoiceSettings ] = useState(true);
    const [ showEmailSettings, setShowEmailSettings ] = useState(false);
    const [ showOrderSettings, setShowOrderSettings ] = useState(false);
    const [ showShopSettings, setShowShopSettings ] = useState(false);
    const [ allowConfirm, setAllowConfirm ] = useState(false);
    const [ formDataInitial, setFormDataInitial ] = useState(false);
    const [ location, setLocation ] = useState(null)
    const [ showConfirmItems, setShowConfirmItems ] = useState(false);
    const [ customerOrdersMode, setCustomerOrdersMode ] = useState(false)
    const [ haveClicked, setHaveClicked ] = useState(false)
    // eslint-disable-next-line
    const [ showAbout, setShowAbout ] = useState(username === 'support')
    // eslint-disable-next-line
    const [ showSupport, setShowSupport ] = useState(auth.username === 'support')
    const [ showTerms, setShowTerms ] = useState(false)
    const [ showUserPayment, setShowUserPayment] = useState(false)
    const [ showCompleteBasket, setShowCompleteBasket ] = useState(false)
    const [ navbarMode, setNavbarMode ] = useState('edit')
    // eslint-disable-next-line
    const [ videoLink, setVideoLink ] = useState("https://player.vimeo.com/video/511771701")
    // eslint-disable-next-line
    const [ valueColor, setValueColor ] = useState('#f2f2f2')
    // eslint-disable-next-line
    const [ shopBackground, setShopBackground ] = useState('#fbfbfb')
    // eslint-disable-next-line
    const [ cartNavColor, setCartNavColor ] = useState('#f2f2f2')
    // eslint-disable-next-line
    const [ navbarColor, setNavbarColor ] = useState('#282a36')

    const [ showWarning, setShowWarning ] = useState(false)
    const [ privacyMode, setPrivacyMode ] = useState(false)
    const [ addressIsSet, setAddressIsSet] = useState(false)
    const [ userExists, setUserExists] = useState(false);
    const [ emailValid, setEmailValid] = useState(false);
    const [ currentEmail, setCurrentEmail] = useState('');
    const [ currentUsername, setCurrentUsername ] = useState('');
    const [ usernameSet, setUsernameSet ] = useState(false)
    const [ usernameError, setUsernameError ] = useState(null)
    const [ usernameSuccess, setUsernameSuccess ] = useState(null)
    const [ resetCode, setResetCode ] = useState('')
    const [ invalidCode, setInvalidCode ] = useState(false)
    const [ showResetPassword, setShowResetPassword ] = useState(false)
    const [ successAlert, setSuccessAlert ] = useState(false)
    const [ editUsername, setEditUsername ] = useState(false)
    const [ editEmail, setEditEmail ] = useState(false)
    const [ editColorMode, setEditColorMode ] = useState('primary')
    const [ showEditColors, setShowEditColors ] = useState(false)
    const [ showSupportTickets, setShowSupportTickets] = useState(false)
    let [ displayMode, setDisplayMode ] = useState('new')
    let [ ticketType, setTicketType ] = useState('support')
    let [ showMessage, setShowMessage ] = useState(false)
    let [ ticketId, setTicketId ] = useState('')
    let [ replyMode, setReplyMode ] = useState(false)
    const [ showOpenNewTicket, setShowOpenNewTicket ] = useState(false)
    const handleSwitchTicketType = (event) => {
        let type = event.target.value
        setTicketType(type)
    }
    const handleReplyMode = () => {
        setReplyMode(prevState => !prevState)
    }
    const handleShowMessage = () => {
        setShowMessage(prevState => !prevState)
    }
    const handleShowNew = async () => {
        await getUsersSupportContent()
        setDisplayMode('new')
    }
    const handleShowCustomerReplies = async () => {
        await getUsersSupportContent()
        setDisplayMode('answered')
    }
    const handleShowAwaitingReply = async () => {
        await getUsersSupportContent()
        setDisplayMode('awaiting')
    }
    const handleShowClosed = async () => {
        await getUsersSupportContent()
        setDisplayMode('closed')
    }
    const supportButtonColor = (type) => {
        switch(type){
            case 'new':
                return displayMode === 'new' ? 'dark-blue' : 'grey';
            case 'answered':
                return displayMode === 'answered' ? 'dark-blue' : 'grey';
            case 'awaiting':
                return displayMode === 'awaiting' ? 'dark-blue' : 'grey';
            case 'closed':
                return displayMode === 'closed' ? 'dark-blue' : 'grey';
            default:
                return ''

        }
    }
    const handleShowSupportTickets = async () => {
        setLoading(true)
        await getUsersSupportContent()
        setLoading(false)
        setShowSupportTickets(true)
        setShowInvoiceSettings(false);
        setShowEmailSettings(false);
        setShowOrderSettings(false);
        setShowShopSettings(false);
        setShowNavDrawer(false)
        setShowOpeningHours(false)
    }
    const openTicket = async () => {
        let email, name, phone, type, title, body
        if(auth.isLoggedIn){
            email = auth.userEmail
            name = auth.appSettings.invoicing.invoice.firstname +
                ' ' + auth.appSettings.invoicing.invoice.lastname
            type = 'support-ticket'
            phone = auth.appSettings.invoicing.invoice.phoneNumber
            title = currentTitle
            body = currentBody
        } else {
            email = currentEmail
            name = currentName
            phone = currentPhone
            type = currentType
            title = currentTitle
            body = currentBody
        }
        try {
            let token;
            try{
                token = await jwtToken.sign(
                    {
                        email, name, phone, type, title, body
                    },
                    process.env.REACT_APP_JWT_KEY,
                    {
                        expiresIn: '1h'
                    }
                );
            }catch(err){console.log(err)}
            await axios.post(`${process.env.REACT_APP_SITE_URL}/api/ticket`, {
            }, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(async response => {
                    await auth.setUserTickets(response.data.tickets)
                    await auth.setUserMessages(response.data.messages)
                    setShowOpenNewTicket(false)
                    if(auth.isLoggedIn){
                        setMessageSuccess(false)
                    } else {
                        setMessageSuccess(true)
                    }
                    setMessageSuccessCode(response.data.ticketId)
                })
                .catch(error => {

                });
        } catch (error) {
            // setIsLoading(false);
            console.log('ERROR: ' + error)
        }
    }
    const collectInputData = async (event) => {
        let type = event.target.name,
            value = event.target.value
        switch(type){
            case 'email':
                await setCurrentEmail(value)
                break
            case 'name':
                await setCurrentName(value)
                break
            case 'phone':
                await setCurrentPhone(value)
                break
            case 'type':
                await setCurrentType(value)
                break
            case 'title':
                await setCurrentTitle(value)
                break
            case 'body':
                await setCurrentBody(value)
                break
            default:
                break
        }
    }
    const patchColors = async () => {
        let token;
        let siteColours = {
            priceButtonColor: auth.priceButtonColor,
            controlButtonColor: auth.controlButtonColor,
            navButtonColor: auth.navButtonColor
        }
        try{
            token = await jwtToken.sign(
                {
                    username: auth.username,
                    userToken: auth.userToken,
                    siteColours
                },
                process.env.REACT_APP_JWT_KEY,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/colours`, {
        }, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(async response => {
                let incomingToken = response.data.token
                let decodedToken = await jwtToken.decode(incomingToken, {complete: true});
                let appSettings = decodedToken.payload.appSettings;
                await jwtToken.verify(incomingToken,
                    `${process.env.REACT_APP_JWT_KEY}`,
                    async function(err, decoded) {
                        if(decoded){
                            const userData = await JSON.parse(localStorage.getItem('userData'))
                            const { categoryId, userEmail, cartId, stripeSettings,
                                cartOrder, cartInvoices, tickets, messages } = userData
                            await localStorage.setItem('userData', JSON.stringify({
                                username: auth.username, token, cartId, categoryId,
                                appSettings, stripeSettings, cartOrder, cartInvoices,
                                userEmail, tickets, messages
                            }))
                            await auth.setAppSettings(appSettings)
                            console.log(appSettings)
                        }else{console.log('Didn\'t decode')}
                    });
                setErrorMessage(null)
            })
            .catch(error => {

            });
    }
    const handleShowContactForm = () => {
        setContactForm(prevState => !prevState)
    }
    const setNewColor = newColor => {
        switch(editColorMode){
            case 'primary':
                auth.setNavButtonColor(newColor.hex)
                break
            case 'secondary':
                auth.setControlButtonColor(newColor.hex)
                break
            case 'basket':
                auth.setPriceButtonColor(newColor.hex)
                break
            default:

                break
        }
    }
    const colorToSetType = async event => {
        let editing = event.target.value
        await patchColors()
        setEditColorMode(editing)
    }
    const handleShowEditColors = async () => {
        await patchColors()
        setShowEditColors(prevState => !prevState)
    }
    const handleEditAccount = async () => {
        setUsernameSuccess(null)
        setUsernameError(null)
        if((editUsername && !editEmail) ||(!editUsername && editEmail)){
            // This is where we send off the patch request to the back end
            try {
                let decodedToken = await jwtToken.decode(auth.userToken, {complete: true});
                let password = decodedToken.payload.password;
                let email = decodedToken.payload.email;
                let usersEmail
                if(!currentEmail){
                    usersEmail = email
                } else {
                    usersEmail = currentEmail
                }
                let usersUsername
                if(!currentUsername){
                    usersUsername = auth.username
                } else {
                    usersUsername = currentUsername
                }
                let token;
                try{
                    token = await jwtToken.sign(
                        {
                            currentUsername: auth.username,
                            newEmail: usersEmail,
                            password,
                            newUsername: usersUsername
                        },
                        process.env.REACT_APP_JWT_KEY,
                        {
                            expiresIn: '1h'
                        }
                    );
                }catch(err){console.log(err)}
                await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/user/details`, {
                }, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(async response => {
                        let incomingToken = response.data.token
                        let decodedToken = await jwtToken.decode(incomingToken, {complete: true});
                        let username = decodedToken.payload.username;
                        let appSettings = decodedToken.payload.appSettings;
                        let cartId = decodedToken.payload.cartId;
                        let stripeSettings = decodedToken.payload.stripeSettings;
                        let cartOrder = decodedToken.payload.cartOrder;
                        let cartInvoices = decodedToken.payload.cartInvoices;
                        let token = decodedToken.payload.userToken;
                        await jwtToken.verify(incomingToken,
                            `${process.env.REACT_APP_JWT_KEY}`,
                            async function(err, decoded) {
                                if(decoded){
                                    const userData = await JSON.parse(localStorage.getItem('userData'))
                                    const { categoryId, userEmail, tickets, messages } = userData
                                    await localStorage.setItem('userData', JSON.stringify({
                                        username, token, cartId, categoryId, appSettings,
                                        stripeSettings, cartOrder, cartInvoices, userEmail, tickets, messages
                                    }))
                                    if(!editEmail){
                                        window.location.replace(`${process.env.REACT_APP_FRONTEND}/${username}`)
                                    }
                                }else{console.log('Didn\'t decode')}
                            });
                        setErrorMessage(null)
                    })
                    .catch(error => {

                    });
            } catch (error) {
                // setIsLoading(false);
                console.log('ERROR: ' + error)
            }
            // On response we set the incoming data to the browser memory
            // and then log the user out (thus automatically logging them
            // in under the new credentials... in theory).
            setEditUsername(false)
            setEditEmail(false)
        } else {
            setEditUsername(prevState => !prevState)
            setEditEmail(prevState => !prevState)
        }

    }
    const handleSetAddress = async () => {
        let {lat, lng} = auth.appSettings.invoicing.invoice.location
        let isTrue = lat + lng
        if (isTrue !== 0) {
            await handleShowInvoiceSettings()
        } else {
            setAddressIsSet(prevState => !prevState)
        }
    }
    const privacyModeHandler = () => {
        setPrivacyMode(prevState => !prevState)
    }
    const handleShowPrivacy = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
        setPrivacyMode(true)
        setShowTerms(true)
    }
    let date = new Date();
    let year = date.getFullYear()
    const handleShowTerms = async () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
        if(showTerms){
            setShowTerms(prevState => !prevState)
        } else {
            setPrivacyMode(false)
            setShowTerms(prevState => !prevState)
        }
    }
    // const handleShowSupport = () => {
    //     setShowSupport(prevState => !prevState)
    // }
    const handleShowAbout = async () => {
        window.location.href = 'https://blog.c4rt.uk';
        // window.scrollTo({top: 0})
        // await setShowAbout(prevState => !prevState)

    }
    const handleShowWarning = () => {
            setShowWarning(prevState => !prevState)
    }
    const refreshForCustomer = async (logout) => {
        await getUsersSupportContent()
        if(logout){
            setGotMode(false)
            setCategoriesLoaded(true)
            setProductsLoaded(true)
            setShowOrdersMode(false)
            auth.setOrderCompleted(false)
            setShowSettings(false)
            setCustomerOrdersMode(false)
            await reloadCart()
        } else {
            setGotMode(false)
            setCategoriesLoaded(true)
            setProductsLoaded(true)
            setShowOrdersMode(false)
            auth.setOrderCompleted(false)
            setShowSettings(false)
            setCustomerOrdersMode(prevState => !prevState)
            await reloadCart()
        }
    }
    const customerOrdersModeHandler = async (props) => {
        let { logout } = props
        await refreshForCustomer(logout)
    }
    const handleShowInvoiceSettings = () => {
        setShowSupportTickets(false)
        setShowInvoiceSettings(true);
        setShowEmailSettings(false);
        setShowOrderSettings(false);
        setShowShopSettings(false);
        setShowNavDrawer(false)
        setShowOpeningHours(false)
    }
    const handleShowEmailSettings = () => {
        setShowSupportTickets(false)
        setShowInvoiceSettings(false);
        setShowEmailSettings(true);
        setShowOrderSettings(false);
        setShowShopSettings(false);
        setShowNavDrawer(false)
        setShowOpeningHours(false)
    }
    const handleShowOrderSettings = () => {
        setShowInvoiceSettings(false);
        setShowSupportTickets(false)
        setShowEmailSettings(false);
        setShowOrderSettings(true);
        setShowShopSettings(false);
        setShowNavDrawer(false)
        setShowOpeningHours(false)
    }
    const handleShowOpeningHours = () => {
        setShowInvoiceSettings(false);
        setShowSupportTickets(false)
        setShowEmailSettings(false);
        setShowOrderSettings(false);
        setShowShopSettings(true);
        setShowNavDrawer(false)
        setShowOpeningHours(true)
    }
    const handleShowShopSettings = () => {
        setShowInvoiceSettings(false);
        setShowSupportTickets(false)
        setShowEmailSettings(false);
        setShowOrderSettings(false);
        setShowShopSettings(true);
        setShowNavDrawer(false)
        setShowOpeningHours(false)
    }
    if(!gotMode){
        if(!auth.isLoggedIn){
            setUseName(username)
            setCustomerMode(true)
            setGotMode(true)
        } else {
            setCustomerMode(false)
            setGotMode(true)
        }
    }
    const showFormValid = () => {
        if (!formDataGot && auth.isLoggedIn) {
            if (auth.appSettings.shop.allowInt) {
                if (auth.customer.addressOne.length > 0 &&
                    auth.customer.addressOne !== 'Address (Line 1)' &&
                    auth.customer.addressTwo.length > 0 &&
                    auth.customer.addressTwo !== 'Address Line 2' &&
                    auth.customer.postcode.length > 4 &&
                    auth.customer.postcode !== 'Postcode' &&
                    auth.customer.country !== 'Please select') {
                    setFormDataGot(true)
                    setAllowConfirm(true)
                } else {
                    setFormDataGot(true);
                    setAllowConfirm(false)
                }
            } else {
                if (auth.customer.addressOne.length > 0 &&
                    auth.customer.addressTwo.length > 0 &&
                    auth.customer.postcode.length > 4 &&
                    auth.customer.country === auth.appSettings.invoicing.invoice.country) {
                    setFormDataGot(true)
                    setAllowConfirm(true)
                } else {
                    if (!formDataGot) {
                        setAllowConfirm(false)
                    }
                    if (auth.customer.addressOne === 'Address (Line 1)' ||
                        auth.customer.addressTwo === 'Address Line 2' ||
                        auth.customer.postcode === 'Postcode' ||
                        auth.customer.country === 'Please select') {
                        setFormDataGot(true)
                        setAllowConfirm(false)
                    } else {
                        setAllowConfirm(true)
                    }
                }

            }
        }
    }
    if(!formDataInitial && auth.customerIsLoggedIn && !!auth.customer){
        setFormDataGot(false);
        showFormValid();
        setFormDataInitial(true);
    }
    const getInputData = event => {
        let {addressOne, addressTwo, postcode, country, address} = auth.customer
        // let newAddress =
        let userData;
        let userInput = event.target.value,
            actionType = event.target.name
        // Switch statement for action types
        switch(actionType) {
            case 'firstname':
                userData = {
                    firstname: userInput,
                    lastname: auth.customer.lastname,
                    email: auth.customer.email,
                    addressOne: auth.customer.addressOne,
                    addressTwo: auth.customer.addressTwo,
                    postcode: auth.customer.postcode,
                    address: {
                        value: addressOne + ', ' + addressTwo + ', ' + postcode + ', ' + country,
                        status: address.status,
                        location : {
                            lat: address.location.lat,
                            lng: address.location.lng
                        }
                    },
                    country: auth.customer.country,
                    instructions: auth.customer.instructions,
                    phone: auth.customer.phone,
                }
                break;
            case 'lastname':
                userData = {
                    firstname: auth.customer.firstname,
                    lastname: userInput,
                    email: auth.customer.email,
                    addressOne: auth.customer.addressOne,
                    addressTwo: auth.customer.addressTwo,
                    postcode: auth.customer.postcode,
                    address: {
                        value: addressOne + ', ' + addressTwo + ', ' + postcode + ', ' + country,
                        status: address.status,
                        location : {
                            lat: address.location.lat,
                            lng: address.location.lng
                        }
                    },
                    country: auth.customer.country,
                    instructions: auth.customer.instructions,
                    phone: auth.customer.phone,
                }
                break;
            case 'email':
                userData = {
                    firstname: auth.customer.firstname,
                    lastname: auth.customer.lastname,
                    email: userInput,
                    addressOne: auth.customer.addressOne,
                    addressTwo: auth.customer.addressTwo,
                    postcode: auth.customer.postcode,
                    address: {
                        value: addressOne + ', ' + addressTwo + ', ' + postcode + ', ' + country,
                        status: address.status,
                        location : {
                            lat: address.location.lat,
                            lng: address.location.lng
                        }
                    },
                    country: auth.customer.country,
                    instructions: auth.customer.instructions,
                    phone: auth.customer.phone,
                }
                break;
            case 'address-one':
                userData = {
                    firstname: auth.customer.firstname,
                    lastname: auth.customer.lastname,
                    email: auth.customer.email,
                    addressOne: userInput,
                    addressTwo: auth.customer.addressTwo,
                    postcode: auth.customer.postcode,
                    country: auth.customer.country,
                    address: {
                        value: userInput + ', ' + addressTwo + ', ' + postcode + ', ' + country,
                        status: address.status,
                        location : {
                            lat: null,
                            lng: null
                        }
                    },
                    instructions: auth.customer.instructions,
                    phone: auth.customer.phone,
                }
                break;
            case 'address-two':
                userData = {
                    firstname: auth.customer.firstname,
                    lastname: auth.customer.lastname,
                    email: auth.customer.email,
                    addressOne: auth.customer.addressOne,
                    addressTwo: userInput,
                    address: {
                        value: addressOne + ', ' + userInput + ', ' + postcode + ', ' + country,
                        status: address.status,
                        location : {
                            lat: null,
                            lng: null
                        }
                    },
                    postcode: auth.customer.postcode,
                    country: auth.customer.country,
                    instructions: auth.customer.instructions,
                    phone: auth.customer.phone,
                }
                break;
            case 'postcode':
                userData = {
                    firstname: auth.customer.firstname,
                    lastname: auth.customer.lastname,
                    email: auth.customer.email,
                    addressOne: auth.customer.addressOne,
                    addressTwo: auth.customer.addressTwo,
                    postcode: userInput,
                    address: {
                        value: addressOne + ', ' + addressTwo + ', ' + userInput + ', ' + country,
                        status: address.status,
                        location : {
                            lat: null,
                            lng: null
                        }
                    },
                    country: auth.customer.country,
                    instructions: auth.customer.instructions,
                    phone: auth.customer.phone,
                }

                break;
            case 'phone':
                userData = {
                    firstname: auth.customer.firstname,
                    lastname: auth.customer.lastname,
                    email: auth.customer.email,
                    addressOne: auth.customer.addressOne,
                    addressTwo: auth.customer.addressTwo,
                    postcode: auth.customer.postcode,
                    address: {
                        value: addressOne + ', ' + addressTwo + ', ' + postcode + ', ' + country,
                        status: address.status,
                        location : {
                            lat: address.location.lat,
                            lng: address.location.lng
                        }
                    },
                    country: auth.customer.country,
                    instructions: auth.customer.instructions,
                    phone: userInput,
                }
                break;
            case 'instructions':
                userData = {
                    firstname: auth.customer.firstname,
                    lastname: auth.customer.lastname,
                    email: auth.customer.email,
                    addressOne: auth.customer.addressOne,
                    addressTwo: auth.customer.addressTwo,
                    postcode: auth.customer.postcode,
                    address: {
                        value: addressOne + ', ' + addressTwo + ', ' + postcode + ', ' + country,
                        status: address.status,
                        location : {
                            lat: address.location.lat,
                            lng: address.location.lng
                        }
                    },
                    country: auth.customer.country,
                    instructions: userInput,
                    phone: auth.customer.phone
                }
                break;
            case 'country':
                userData = {
                    firstname: auth.customer.firstname,
                    lastname: auth.customer.lastname,
                    email: auth.customer.email,
                    addressOne: auth.customer.addressOne,
                    addressTwo: auth.customer.addressTwo,
                    postcode: auth.customer.postcode,
                    address: {
                        value: addressOne + ', ' + addressTwo + ', ' + postcode + ', ' + userInput,
                        status: address.status,
                        location : {
                            lat: null,
                            lng: null
                        }
                    },
                    country: userInput,
                    instructions: auth.customer.instructions,
                    phone: auth.customer.phone
                }
                break;
            default:
            // code block
        }
        setErrorMessage(false)
        setFormDataGot(false);
        auth.setCustomer(userData);
        showFormValid()
    }
    const calculateDeliveryCharges = () => {
        let { showCharges, chargesType } = auth.appSettings.shop
        let { perUnit, freeOver, showFree, standardFee,
            minFee, maxFee} = auth.appSettings.shop.chargeDelivery
        let rawCharges = auth.finalDistance*perUnit,
            processedCharge = 0;
        if(showCharges){
            if(chargesType === 'Standard'){
                processedCharge = standardFee
            }
            if(chargesType === 'Advanced'){
                if(rawCharges < minFee){
                    processedCharge =  minFee;
                }
                if(rawCharges > maxFee){
                    processedCharge = maxFee;
                }
                if (rawCharges < maxFee && rawCharges > minFee){
                    processedCharge = rawCharges;
                }
            }
            if (basketValue > freeOver && showFree){
                processedCharge = 0
            }
        } else {
            processedCharge = 0
        }
        return processedCharge
    }
    const deliveryOptionsHandler = () => {
        setShowConfirmItems(true);
    }
    const getDistance = async (lat, lng) => {
        let lng1 = auth.appSettings.invoicing.invoice.location.lng,
            lat1 = auth.appSettings.invoicing.invoice.location.lat;
        // console.log(lng1, lng, lat1, lat)

        const R = 6371e3; // metres
        const latitude1 = lat1 * Math.PI/180; // φ, λ in radians
        const latitude2 = lat * Math.PI/180;
        const differenceLat = (lat-lat1) * Math.PI/180;
        const differenceLng = (lng-lng1) * Math.PI/180;
        const a = Math.sin(differenceLat/2) * Math.sin(differenceLat/2) +
            Math.cos(latitude1) * Math.cos(latitude2) *
            Math.sin(differenceLng/2) * Math.sin(differenceLng/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const distance = R * c; // in metres
        let totalDistance
        if(auth.appSettings.shop.distUnits === 'Miles'){
            totalDistance = (distance/1000)*0.62137119223733
        } else {
            totalDistance = (distance/1000)
        }
        await auth.setFinalDistance(totalDistance);
        calculateDeliveryCharges(totalDistance)
    }
    const updateCustomer = async () => {
        const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_KEY;
        const C4RT_API_KEY = process.env.REACT_APP_JWT_KEY;
        let data;
        let checkAddress = auth.customer.addressOne + ', ' + auth.customer.addressTwo + ', ' +
            auth.customer.postcode + ', ' + auth.customer.country;
        const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=
                        ${encodeURIComponent(auth.customer.address.value)}&key=${GOOGLE_API_KEY}`
        );
        data = response.data;
        if (!data || data.status === 'ZERO_RESULTS') {
            setErrorMessage(true)
        } else {
            let token;
            await setLocation({
                lat: data.results[0].geometry.location.lat,
                lng: data.results[0].geometry.location.lng
            })
            if(!username){
                username = auth.appSettings.username
            }
            // console.log(username)
            try{
                token = await jwtToken.sign(
                    {
                        username,
                        userEmail: auth.customer.email,
                        userFirstname: auth.customer.firstname,
                        userLastname: auth.customer.lastname,
                        userAddressOne: auth.customer.addressOne,
                        userAddressTwo: auth.customer.addressTwo,
                        userAddress: {
                            status: true,
                            value: checkAddress,
                            location: {
                                lat: data.results[0].geometry.location.lat,
                                lng: data.results[0].geometry.location.lng
                            }
                        },
                        userPostcode: auth.customer.postcode,
                        userInstructions: auth.customer.instructions,
                        userPhone: auth.customer.phone,
                        userCountry: auth.customer.country,
                        token: auth.customerToken,
                        cartId: auth.cartId
                    },
                    C4RT_API_KEY,
                    {expiresIn: '1h'}
                );
            }catch(err){console.log(err)}
            await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/customers`,
                {}, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(async response => {
                    const { firstname, lastname, addressOne, addressTwo,
                            postcode, address, email, phone, country, instructions} = response.data.customer,
                        { token, publicKey } = response.data
                    const { value, status, location } = address
                    const { lat, lng } = location
                    await auth.setCustomer({
                        firstname, lastname, addressOne, addressTwo, postcode,
                        address: {value, status, location: { lat, lng }}, email, phone, country, instructions
                    })
                    await auth.setPublicKey(publicKey)
                    auth.customerToken = token;
                    await getDistance(lat, lng)
                })
                .catch(error => {
                    console.log(error);
                });
            auth.setDetailsConfirmed(true);
            deliveryOptionsHandler();
        }
    }
    const handleCloseSettings = () => {
        setSettingsLoaded(false)
        setShowSettings(false);
    }
    const handleDeliveryType = () => {
        setOrderIsDelivery(prevState => !prevState)
    }

    // eslint-disable-next-line
    let cartsId, storeUsername;
    if(!!auth.userToken){
        storeUsername = auth.username
    } else {
        storeUsername = username
    }
    const getCartId = async () => {
        // eslint-disable-next-line
        let token, cartsId;
        try{
            token = await jwtToken.sign(
                {
                    username: storeUsername
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {expiresIn: '1h'}
            );
        }catch(err){console.log(err)}
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/api/store`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            const responseData = await response.json();
            await jwtToken.verify(responseData.token,
                `${process.env.REACT_APP_JWT_KEY}`,
                async function(err, decoded) {
                    if(decoded){
                        let decodedToken = await jwtToken.decode(responseData.token, {complete: true});
                        let cart = decodedToken.payload.cart;
                        let settings = decodedToken.payload.settings;
                        let products = decodedToken.payload.products;
                        let categories = decodedToken.payload.categories;
                        let featured = decodedToken.payload.hasFeatured;
                        if (!response.ok) {
                            return new Error(responseData)
                        } else {
                            // eslint-disable-next-line
                            cartsId = cart._id;
                            if(featured){
                                await auth.setHasFeatured(featured)
                            }
                            await auth.setAppSettings(settings);
                            setLoadedProducts(products)
                            setLoadedCategories(categories)
                            setCategoriesLoaded(true);
                            setProductsLoaded(true);
                            setSettingsLoaded(true)
                            auth.setGotCartId(true)
                            let { shop } = settings
                            let { colours } = shop
                            auth.setPriceButtonColor(colours.priceButtonColor)
                            auth.setNavButtonColor(colours.navButtonColor)
                            auth.setControlButtonColor(colours.controlButtonColor)
                            const userData = JSON.parse(localStorage.getItem('userData'))
                            if(userData){
                                let { token, cartId, categoryId, stripeSettings, cartOrder,
                                    cartInvoices, userEmail, tickets, messages} = userData
                                await localStorage.setItem('userData', JSON.stringify({
                                    username: storeUsername, token, cartId, categoryId,
                                    appSettings: settings, stripeSettings, cartOrder,
                                    cartInvoices, userEmail, tickets, messages
                                }))
                            }
                        }
                    }else{
                        console.log('Didn\'t decode')
                    }
                });
        } catch (error) {
            console.log('ERROR: ' + error)
        }
    }
    if(customerMode && !auth.gotCartId && useName){
        if(!productsLoaded || (!categoriesLoaded && !auth.cartId)){
            getCartId({
                username: storeUsername, auth, setSettingsLoaded,
                setProductsLoaded, setLoadedProducts,
                setLoadedCategories, setCategoriesLoaded}).then()
        }
    }
    const handleCategoryUpdate = category => {
        let newCategories = loadedCategories;
        for(let i=0 ; i<loadedCategories.length ; i++){
            if(loadedCategories[i].id === category.id){
                delete newCategories[i];
                newCategories[i] = category;
                setLoadedCategories(newCategories);
                i = loadedCategories.length;
            }
        }
        reloadCart().then();
    }
    const getCategories = async (categories) => {
        setLoadedCategories(categories)
        // setIsLoading(true);
    }
    const handleNewProduct = product => {
        let newProduct = loadedProducts;
        newProduct.push(product)
        setLoadedProducts(newProduct);
        reloadCart().then();
    }
    const handleNewCategory = category => {
        let newCategory = loadedCategories;
        newCategory.push(category)
        setLoadedCategories(newCategory);
        reloadCart().then();
    }
    const getCustomerOrders = async () => {
        let token;
        try{
            token = await jwtToken.sign(
                {
                    customerToken: auth.customerToken,
                    cartId: auth.cartId
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/api/customers/orders`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                const responseData = await response.json();
                await auth.setCustomersOrders(responseData.orders)
                // console.log(responseData.orders)
                // setIsLoading(false);
            } catch (error) {
                // setIsLoading(false);
                console.log('ERROR: ' + error)
            }
    }
    const handleCompletingPayment = () => {
        setLoading(true)
    }
    const handleShowBasket = () => {
        if(!!auth.customerToken){
            if(auth.customer.address.location.lng + auth.customer.address.location.lat === 0){
                setShowCompleteBasket(true);
                auth.setDetailsConfirmed(true);
            } else {
                setShowCompleteBasket(true);
            }
        } else {
            setShowCompleteBasket(false);
        }
        setShowBasket(true)
    };
    const handleCloseBasket = () => {
        setShowBasket(false)
    };
    const handleNewOrderSuccess = async (orders) => {
        setLoading(false)
        await handleCloseBasket();
        await auth.setOrderId(null)
        await auth.setOrderNumber(null)
        await auth.setCreatedPromise(null)
        setBasketValue(0)
        setBasketItems([])
        setProductsLoaded(false)
        if(auth.isLoggedIn){
            setOrdersLoaded(false)
            await getOrders()
        }
        getCustomerOrders().then();
        await auth.setOrderCompleted(true)
        await reloadCart()
        setCustomerOrdersMode(true)
        await localStorage.setItem('customerData', JSON.stringify({
            customer: auth.customer, token: auth.customerToken, publicKey: null,
            orderId: null, orderNumber: null,
            orders: orders
        }))
        // setTimeout(() => {
        //     setShowUserPayment(false)
        //     setShowConfirmItems(false)
        // }, 1000)
    }
    const buildBasketFromOrder = async (order) => {
        let currentBasket = [],
            basketCurrentValue = 0;
        for(let i=0 ; i<order.items.length ; i++){
            let basketItem = {
                price: order.items[i].price,
                productId: order.items[i].productId,
                optionId: order.items[i].optionId,
                productName: order.items[i].productName,
                quantities: order.items[i].quantities,
                image: order.items[i].image,
                value: order.items[i].value,
                taxRate: order.items[i].taxRate,
                index: order.items[i].index
            }
            for(let j=0 ; j<loadedProducts.length ; j++){
                if(order.items[i].productId === loadedProducts[j]._id){
                    if(order.items[i].index === undefined){
                        basketCurrentValue += order.items[i].price*order.items[i].value
                        currentBasket.push(basketItem);
                    } else if(order.items[i].index >= 0) {
                        for(let k=0 ; k<loadedProducts[j].priceOptions.length ; k++){
                            if(loadedProducts[j].priceOptions[k]._id === order.items[i].optionId){
                                basketCurrentValue += order.items[i].price*order.items[i].value
                                currentBasket.push(basketItem);
                            }
                        }
                    }
                }
            }
        }
        if(order.type === 'Delivery'){
           await setOrderIsDelivery(true)
        } else if (order.type === 'Collection') {
            await setOrderIsDelivery(false)
        }
        setBasketValue(basketCurrentValue)
        setBasketItems(currentBasket)
        setProductsLoaded(false)
        setShowOrdersMode(false)
        auth.setOrderCompleted(false)
        setShowSettings(false)
        setCustomerOrdersMode(false)
        setCategoriesLoaded(true)
        setProductsLoaded(true)
        if(!order.paymentComplete){
            await auth.setCartId(order.cartId)
            await auth.setOrderId(order._id)
            await auth.setOrderNumber(order.number)
        } else {
            await auth.setOrderId(null)
            await auth.setOrderNumber(null)
        }

        // await auth.setCreatedPromise(null)
    }
    // Basket item controllers
    const addToBasketValue = (data) => {
        // console.log(data)
        let changeMade = false
        let taxRate;
        if(auth.appSettings.shop.showTax){
            if(auth.appSettings.shop.setGlobal){
                taxRate = auth.appSettings.shop.globalRate;
            }else {
                let testTaxRate = data.taxRate;
                if(testTaxRate === 'Standard Rate'){
                    taxRate = 20;
                }if(testTaxRate === 'Reduced Rate'){
                    taxRate = 5;
                }if(testTaxRate === 'Zero Rate'){
                    taxRate = 0;
                }
            }
        }
        let newPrice = basketValue + data.price;
        setBasketValue(newPrice);
        let currentBasket = basketItems;
        let basketRowPrice;
        for (let i = 0; i < loadedProducts.length; i++) {
            if (loadedProducts[i]._id === data.productId) {
                if(data.index >= 0){
                    for(let j=0 ; j<loadedProducts[i].priceOptions.length ; j++){
                        if(loadedProducts[i].priceOptions[j]._id === data.optionId){
                            basketRowPrice = loadedProducts[i].priceOptions[j].price;
                        }
                    }
                } else if(data.index === undefined) {
                    basketRowPrice = loadedProducts[i].price;
                }
            }
        }
        for (let i = 0; i < currentBasket.length; i++) {
            // if (currentBasket[i].productId === undefined && currentBasket[i].productId === data.productId) {
            //     currentBasket[i].value += 1
            //     changeMade = true
            // }
            if (currentBasket[i].productId === data.productId &&
                data.optionId === currentBasket[i].optionId &&
                currentBasket[i].optionId === data.optionId) {
                currentBasket[i].value += 1
                currentBasket[i].index = data.index
                currentBasket[i].optionId = data.optionId
                changeMade = true
            }
        }
        if(!changeMade){
            let basketItem
                basketItem = {
                    productId: data.productId,
                    price: basketRowPrice,
                    index: data.index,
                    optionId: data.optionId,
                    value: 1,
                    quantities: data.quantities,
                    productName: data.productName,
                    image: data.image,
                    taxRate: taxRate
                };
            currentBasket.push(basketItem);
            setBasketItems(currentBasket);
        } else{
            setBasketItems(currentBasket);
        }
    }
    const takeFromBasketValue = (data) => {
        let newPrice = basketValue - data.price;
        if(newPrice < 0){
            newPrice = 0.00;
        }
        let currentBasket = basketItems;
        for (let i = 0; i < currentBasket.length; i++) {
            if (currentBasket[i].optionId === null &&
                currentBasket[i].productId === data.productId && data.optionId === null ) {
                console.log('Still does')
                if (currentBasket[i].value > 1) {
                    currentBasket[i].value -= 1;
                    setBasketValue(newPrice);
                } else {
                    currentBasket.splice(i, 1);
                    setBasketValue(newPrice);
                }
            } else if (data.optionId !== data.productId && currentBasket[i].optionId === data.optionId) {
                for(let j=0 ; j<loadedProducts.length ; j++){
                    if(loadedProducts[j]._id === data.productId){
                        for(let k=0; k<loadedProducts[j].priceOptions.length ; k++){
                            if(loadedProducts[j].priceOptions[k]._id === data.optionId){
                                if (currentBasket[i].value > 1) {
                                    currentBasket[i].value -= 1;
                                    setBasketValue(newPrice);
                                } else {
                                    currentBasket.splice(i, 1);
                                    setBasketValue(newPrice);
                                }
                            }
                        }
                    }
                }
            }
        }

        //=> [1, 2, 3, 4, 6, 7, 8, 9, 0]
        setBasketItems(currentBasket)
        // console.log(basketItems);


    }
    const reloadCart = async () => {
        let token;
        try {
            token = await jwtToken.sign(
                {
                    cartId: auth.cartId
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        } catch (err) {
            console.log(err)
        }
        if (auth.isLoggedIn) {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/api/reload-cart`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                const responseData = await response.json();
                let incomingToken = responseData.token
                await jwtToken.verify(incomingToken,
                    `${process.env.REACT_APP_JWT_KEY}`,
                    async function (err, decoded) {
                        if (decoded) {
                            let decodedToken = await jwtToken.decode(incomingToken, {complete: true});
                            let categories = decodedToken.payload.categories;
                            let products = decodedToken.payload.products;
                            let featured = decodedToken.payload.hasFeatured;
                            if (!response.ok) {
                                return new Error(responseData)
                            }
                            if(featured){
                                await auth.setHasFeatured(featured)
                            }
                            setLoadedCategories(categories)
                            setCategoriesLoaded(true);
                            setLoadedProducts(products)
                            setProductsLoaded(true);
                        } else {
                            console.log('Didn\'t decode')
                        }
                    });
            } catch (error) {
                // setIsLoading(false);
                console.log('ERRROR: ' + error)
            }
        }
    }
    if((!categoriesLoaded || !productsLoaded) || !loadedCategories){ // Call function only if categories aren't loaded
        reloadCart().then();
    }
    const getOrders = async () => {
        let token;
        try {
            token = await jwtToken.sign(
                {
                    cartId: auth.cartId,
                    userToken: auth.userToken
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        } catch (err) {
            console.log(err)
        }
        if (auth.isLoggedIn) {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/api/orders`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                const responseData = await response.json();
                setLoadedOrders(responseData.orders)
                setOrdersLoaded(true);
                // setIsLoading(false);
                if (!response.ok) {
                    return new Error(responseData)
                }
            } catch (error) {
                // setIsLoading(false);
                console.log('ERRROR: ' + error)
            }
        }
    }
    if(!ordersLoaded){ // Call function only if products aren't loaded
        getOrders().then();
    }
    const isShopOpen = () => {
        let dayOpen = false, today;
        if (!gotDayOpen) {
            let wholeDate = new Date();
            today = wholeDate.getDay() - 1
            if (today === -1) {
                today = 6
            }
            if (today === 0) {
                dayOpen = auth.appSettings.shop.openingDays.monday
            }
            if (today === 1) {
                dayOpen = auth.appSettings.shop.openingDays.tuesday
            }
            if (today === 2) {
                dayOpen = auth.appSettings.shop.openingDays.wednesday
            }
            if (today === 3) {
                dayOpen = auth.appSettings.shop.openingDays.thursday
            }
            if (today === 4) {
                dayOpen = auth.appSettings.shop.openingDays.friday
            }
            if (today === 5) {
                dayOpen = auth.appSettings.shop.openingDays.saturday
            }
            if (today === 6) {
                dayOpen = auth.appSettings.shop.openingDays.sunday
            }
            setGotDayOpen(true)
        }
        if (dayOpen === false) {
            try {
                auth.setShopDayOpen(false)
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                auth.setShopDayOpen(true)
            } catch (error) {
                console.log(error)
            }
        }
        if (dayOpen) {
            // Return 1 if open
            return handleDayOpeningTimes(today)
        } else {
            try {
                setHoursLoaded(true)
            } catch (error) {
            }
            return 0
        }
    }
    const handleDayOpeningTimes = async (today) => {
        // Return 1 if the store is open for the specific time.
        // Return 0 if the store is closed for the specific time.
        let storeOpen = false
        // Get current UTC
        let utcDate = new Date().toISOString().split('T')[1]
        let utcHours = Number(utcDate.split(':')[0])
        let utcMinutes = Number(utcDate.split(':')[1])
        let firstSplit = auth.appSettings.shop.locale.split(')')[0]
        let secondSplit = firstSplit.split('GMT')[1]
        let mins = Number(secondSplit.split(':')[1])
        let hoursRaw, hrs
        let type = secondSplit[0]
        // Calculate UTC offset & // Get shops local time
        if (type === '+') {
            hoursRaw = secondSplit.split(':')[0]
            hrs = Number(hoursRaw.split('+')[1])
            hrs = hrs + utcHours
            if (hrs > 23) {
                hrs -= 24
            }
            mins = mins + utcMinutes
            if (mins > 59) {
                mins -= 60
                hrs += 1
            }
        } else if (type === '-') {
            hoursRaw = secondSplit.split(':')[0]
            hrs = Number(hoursRaw.split('-')[1])
            hrs = utcHours - hrs
            if (hrs < 0) {
                hrs += 24
            }
            mins = utcMinutes - mins
            if (mins < 0) {
                mins += 60
                hrs -= 1
            }
        } else {
            hoursRaw = secondSplit.split(':')[0]
            hrs = Number(hoursRaw.split('±')[1])
            hrs = utcHours
            mins = utcMinutes
        }
        // Compare local time to opening times
        let todaysHours = auth.appSettings.shop.openingHours[today]
        if (todaysHours) {
            for (let i = 0; i < todaysHours.length; i++) {
                if (todaysHours[i].fromHr <= hrs && hrs < Number(todaysHours[i].toHr)) {
                    storeOpen = true
                    i = todaysHours.length
                    await setHoursLoaded(true)
                } else if (hrs === Number(todaysHours[i].toHr)) {
                    if (mins < Number(todaysHours[i].toMin)) {
                        await setHoursLoaded(true)
                        storeOpen = true
                        i = todaysHours.length
                    }
                }
            }
            if (!storeOpen) {
                // console.log('store closed')
                return 0
            } else {
                // console.log('store open')
                return 1
            }
        }
    }
    const getSettings = async () => {
        // setIsLoading(true);
        let responseData;
        if (auth.isLoggedIn) {
            let token;
            try {
                token = await jwtToken.sign(
                    {
                        username: auth.username
                    },
                    `${process.env.REACT_APP_JWT_KEY}`,
                    {
                        expiresIn: '1h'
                    }
                );
            } catch (err) {
                console.log(err)
            }
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/api/settings`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                responseData = await response.json();
                await auth.setAppSettings(responseData.settings);
                let cartOrder = responseData.cartOrder;
                let cartInvoices = responseData.cartOrder;
                await auth.setCartOrder(responseData.cartOrder);
                await auth.setCartInvoices(responseData.cartInvoices);
                let {numberOfUsers, numberOfMonthly, numberOfAnnual} = responseData.cartsStats
                if (!numberOfUsers) {
                    numberOfUsers = 1
                }
                let fakeNum = numberOfUsers + 21
                let returnedStats = {
                    numberOfUsers: fakeNum, numberOfMonthly, numberOfAnnual
                }
                await auth.setCartStats(returnedStats)
                const userData = JSON.parse(localStorage.getItem('userData'))
                if (userData) {
                    const { username, token, cartId, categoryId,
                        stripeSettings, userEmail, tickets, messages } = userData
                    localStorage.setItem('userData', JSON.stringify({
                        username, token, cartId, categoryId, appSettings: responseData.settings,
                        stripeSettings, cartOrder, cartInvoices, userEmail, tickets, messages
                    }))
                }
                await setSettingsLoaded(true)
                // setIsLoading(false);
                if (!response.ok) {
                    return new Error(responseData)
                }
            } catch (error) {
                // setIsLoading(false);
                console.log('ERRROR: ' + error)
            }
        }
    }
    const openingHours = async () => {
        let shopIsOpen;
        try {
            shopIsOpen = await isShopOpen(auth.appSettings)
        } catch (error) {
        }
        if (shopIsOpen === 0) {
            try {
            } catch (error) {
            }
            try {
                await auth.setShopClosed(true)
            } catch (error) {
            }
            setHoursLoaded(true)
        }
        if (shopIsOpen === 1) {
            try {
                await auth.setShopClosed(false)
            } catch (error) {
            }
            setHoursLoaded(true)
        }
    }
    if(hoursLoaded && !allocatedShopOpen){
        setAllocatedShopOpen(true)
        // console.log(auth.shopClosed)
    }
    if(settingsLoaded && !hoursLoaded){
        openingHours().then()
    }
    if(!settingsLoaded){ // Call function only if products aren't loaded
        getSettings().then();
    }
    // These need replacing with useEffect hook.
    const reloadCategories = () => {
        setCategoriesLoaded(false);
    };
    const reloadProducts = () => {
        setProductsLoaded(false);
    };
    // Resets basket and switches between admin and customer view
    const switchModeHandler = () => {
        setShowAdminMode(prevMode => !prevMode);
        setShowSettings(false)
        setBasketItems([]);
        setBasketValue(0.00);
        setShowOrdersMode(false);
    };
    const switchEditHandler = () => {
        setShowAdminMode(false);
        setBasketItems([]);
        setBasketValue(0.00);
        setShowOrdersMode(false);
    };
    const ordersModeHandler = async () => {
        setNavbarMode('orders')
        setBasketValue(0)
        setBasketItems([])
        window.scrollTo({top: 0, behavior: 'smooth'})
            // await setShowNavDrawer(false)
        setShowSettings(false)
        setBasketValue(0)
        setShowAdminMode(false);
        setShowOrdersMode(true);
    }
    const emptyBasket = () => {
            setBasketItems([])
        setBasketValue(0)
    }
    const newProduct = async (categoryId) => {
        let token;
        try {
            token = await jwtToken.sign(
                {
                    categoryId: categoryId,
                    username: auth.username,
                    cartId: auth.cartId,
                    userToken: auth.userToken
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        } catch (err) {
            console.log(err)
        }
        await axios.post(`${process.env.REACT_APP_SITE_URL}/api/products/new`, {}, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(async res => {
                // props.reloadProducts();
                handleNewProduct(res.data.createdProduct);
                await setShowNavDrawer(false)
            })
            .catch(error => {
                console.log(error);
            })
    }
    const deleteCategory = async (categoryId) => {
        let token;
        try{
            token = await jwtToken.sign(
                {
                    categoryId: categoryId,
                    token: auth.userToken
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        await axios.delete(`${process.env.REACT_APP_SITE_URL}/api/categories`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(async (res) => {
                if(displayCategory === 0){
                    setDisplayCategory(0)
                } else {
                    setDisplayCategory(displayCategory - 1)
                }
                await reloadCart()
            })
            .catch(error => {
                console.log(error);
            });
    }
    const createCategory = async () => {
        let token;
        try {
            token = await jwtToken.sign(
                {
                    cartId: auth.cartId,
                    categoryName: 'New Category',
                    username: auth.username,
                    image: '../img/upload.png',
                    token: auth.userToken
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        } catch (err) {
            console.log(err)
        }
        await axios.post(`${process.env.REACT_APP_SITE_URL}/api/categories/new`, {}, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(async res => {
                let incomingToken = res.data.token
                await jwtToken.verify(incomingToken,
                    `${process.env.REACT_APP_JWT_KEY}`,
                    async function (err, decoded) {
                        if (decoded) {
                            await reloadCart();
                        } else {
                            console.log('Didn\'t decode')
                        }
                    });
            })
            .catch(error => {
                console.log(error);
            })
    }
    const updateCategoryName = async ({categoryId, newName}) => {
        let categoryName = newName.toUpperCase()
        let token;
        try {
            token = await jwtToken.sign(
                {
                    categoryId,
                    token: auth.userToken,
                    categoryName
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        } catch (err) {
            console.log(err)
        }
        await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/categories`, {}, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                // console.log(response.data.category.categoryName);
                handleCategoryUpdate(response.data.category);
                setDeleteMode(false)
                setEditMode(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const switchDrawerHandler = async () => {
        // await setShowNavDrawer(false)
        switchModeHandler();
    }
    const handleShowAuth = () => {
        setTimeout(() => {
            setShowBasket(false)
            setShowAuth(true)
            // setShowAbout(false)
            }, 20);

    };
    const handleShowGoLive = async () => {
        await createSubscription()
        setShowGoLive(true)
    };
    const handleShowSettings = async () => {
        setNavbarMode('account')
        window.scrollTo({top: 0, behavior: 'smooth'})
        // await setShowNavDrawer(false)
        setBasketValue(0)
        setBasketItems([])
        setShowOrdersMode(false)
        setShowAdminMode(false)
        setShowSettings(true)
    };
    const editStoreHandler = async () => {
        setDisplayCategory(0)
        setNavbarMode('edit')
        window.scrollTo({top: 0, behavior: 'smooth'})
        setShowSettings(false)
        switchEditHandler()
        // editStoreHandler();
        // await setShowNavDrawer(false)
    }
    const handleSwitchDrawer = async () => {
        await setShowNavDrawer(prevState => !prevState)
    }
    let currentOrders = null;
    if(loadedOrders){
        currentOrders = loadedOrders.length;
    }
    const handleNew = async () => {
        setShowNew(true);
        setShowApproved(false);
        setShowReady(false);
        setShowComplete(false);
        setShowArchived(false);
        setShowCancelled(false);
        setShowIncomplete(false);
        await handleSwitchDrawer()
    }
    const handleApproved = async () => {
        setShowNew(false);
        setShowApproved(true);
        setShowReady(false);
        setShowComplete(false);
        setShowArchived(false);
        setShowCancelled(false);
        setShowIncomplete(false);
        await handleSwitchDrawer()
    }
    const handleReady = async () => {
        setShowNew(false);
        setShowApproved(false);
        setShowReady(true);
        setShowComplete(false);
        setShowArchived(false);
        setShowCancelled(false);
        setShowIncomplete(false);
        await handleSwitchDrawer()
    }
    const handleComplete = async () => {
        setShowNew(false);
        setShowApproved(false);
        setShowReady(false);
        setShowComplete(true);
        setShowArchived(false);
        setShowCancelled(false);
        setShowIncomplete(false);
        await handleSwitchDrawer()
    }
    const handleArchived = async () => {
            setShowNew(false);
        setShowApproved(false);
        setShowReady(false);
        setShowComplete(false);
        setShowArchived(true);
        setShowCancelled(false);
        setShowIncomplete(false);
        await handleSwitchDrawer()
    }
    const handleCancelled = async () => {
            setShowNew(false);
        setShowApproved(false);
        setShowReady(false);
        setShowComplete(false);
        setShowArchived(false);
        setShowCancelled(true);
        setShowIncomplete(false);
        await handleSwitchDrawer()
    }
    const menuItemColor = (type) => {
        if(type === 'shop'){
            if(showShopSettings && !showOpeningHours){
                return 'dark'
            } else {
                return 'outline-dark'
            }
        }
        if(type === 'invoicing'){
            if(showInvoiceSettings){
                return 'dark'
            } else {
                return 'outline-dark'
            }
        }
        if(type === 'email'){
            if(showEmailSettings){
                return 'dark'
            } else {
                return 'outline-dark'
            }
        }
        if(type === 'payments'){
            if(showOrderSettings){
                return 'dark'
            } else {
                return 'outline-dark'
            }
        } if(type === 'opening-hours'){
            if(showOpeningHours){
                return 'dark'
            } else {
                return 'outline-dark'
            }
        }
    }
    const displayVideo = () => {
        // hide-nav-toggle
        return <div className={'embed-container'}>
            <iframe src={videoLink} width="640" height="360" frameBorder="0"
                    allow="autoplay; fullscreen" allowFullScreen title={'promo-video'} />
        </div>
    }
    const createSubscription = async () => {
        let responseData
        let token;
        try{
            token = await jwtToken.sign(
                {
                    username,
                    userToken: auth.userToken
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        try{
            const response = await fetch(
                `${process.env.REACT_APP_SITE_URL}/api/subscribe`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
            responseData = await response.json();
            let { cartOrder } = responseData
            let { userStripeId } = cartOrder
            let orderId = cartOrder._id
            await auth.setUserStripeId(userStripeId)
            await auth.setOrderId(orderId)
            let createdPromise = null;
            try{
                createdPromise = await loadStripe(`${process.env.REACT_APP_PUBLIC_KEY}`)
                await auth.setSubscriptionPromise(createdPromise)
            }catch(error){
                console.log(error)
            }
        }catch(error){}
    }
    const buildAndCheckEmail = async event => {
        setEditUsername(false)
        event.preventDefault();
        let userInput = event.target.value.toLowerCase();
        setCurrentEmail(userInput);
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userInput)) {
            setEmailValid(true);
            let token, email = userInput;
            try{
                token = await jwtToken.sign(
                    {
                        email
                    },
                    process.env.REACT_APP_JWT_KEY,
                    {
                        expiresIn: '1h'
                    }
                );
            }catch(err){console.log(err)}
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/api/users/check`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                const responseData = await response.json();
                let decodedToken = await jwtToken.decode(responseData.token, {complete: true});
                await jwtToken.verify(responseData.token,
                    `${process.env.REACT_APP_JWT_KEY}`,
                    async function(err, decoded) {
                        if(decoded){
                            // setIsLoading(false);
                            let customer = decodedToken.payload.customer;
                            if (!response.ok) {
                                return new Error(responseData)
                            }
                            if (customer === 1 || customer === 2) {
                                setUserExists(true);
                                setUsernameError('Sorry!! It looks like this email is taken!');
                            } else {
                                setUserExists(false);
                                setUsernameError(null);
                            }
                        }else{
                            console.log('Didn\'t decode')
                        }
                    });

            } catch (error) {
                // setIsLoading(false);
                console.log('ERRROR: ' + error)
            }
        } else {
            setEmailValid(false);
        }
    }
    const buildAndCheckUsername = async event => {
        event.preventDefault();
        setEditEmail(false)
        let username = event.target.value,
            currentLength = username.length,
            // username = username.replace(/\s+/g, '').toLowerCase();
            cleanUsername = username.replace(/\s+/g, '').toLowerCase();
        username = username.replace(/[^a-z0-9-]/gi,'' );
        if (username.length >= 4 && username.length <= 15){
            setUsernameError(null)
        }
        if(currentLength > username.length){
            setUsernameError('Sorry only numbers, letters and dashes allowed')
        }
        if (username.length <= 3) {
            setUsernameError('Usernames need to be at least 4 characters long')
        }
        if (username.length === 0) {
            setUsernameError('Start typing to select a username')
        }
        if (username.length > 15) {
            setUsernameError('Username can only be up to 15 characters long')
        }
        if (username.length <= 15) {
            setCurrentUsername(username);
            setUsernameSet(true);
        }
        try {
            let token, username = cleanUsername;
            try{
                token = await jwtToken.sign(
                    {
                        username
                    },
                    process.env.REACT_APP_JWT_KEY,
                    {
                        expiresIn: '1h'
                    }
                );
            }catch(err){console.log(err)}
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/api/users`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            const responseData = await response.json();
            let decodedToken = await jwtToken.decode(responseData.token, {complete: true});
            await jwtToken.verify(responseData.token,
                `${process.env.REACT_APP_JWT_KEY}`,
                async function(err, decoded) {
                    if(decoded){
                        let customer = decodedToken.payload.customer;
                        if (!response.ok) {
                            return new Error(responseData)
                        }
                        if (customer === 1) {
                            setUserExists(true);
                            setUsernameError('Sorry!! It looks like @' + username + ' is taken!');
                        } else {
                            setUsernameSuccess('Sweet!! Looks like @' + username + ' is available!');
                            setUserExists(false);
                        }
                    }else{
                        console.log('Didn\'t decode')
                    }
                });
        } catch (error) {
            // setIsLoading(false);
            console.log('ERROR: ' + error)
        }
    }
    const handleSetResetState = () => {
        setResetCode('')
        setInvalidCode(false)
        setShowResetPassword(false)
        setSuccessAlert(false)
    }
    const getUsersSupportContent = async () => {
        let token
        try{
            token = await jwtToken.sign(
                {
                    email: auth.userEmail,
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        try{
            const response = await fetch(
                `${process.env.REACT_APP_SITE_URL}/api/tickets`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
            let responseData = await response.json();
            let { tickets, messages } = responseData
            await auth.setUserTickets(tickets)
            await auth.setUserMessages(messages)
        }catch(error){}
    }
    // eslint-disable-next-line
    const sendUserToBlog =() => {
        window.location.href = "https://blog.c4rt.uk";
    }
    const sendUserToDocs =() => {
        window.open("https://blog.c4rt.uk/wp-content/uploads/2021/03/User-Guide.pdf", "blank");
    }

    return (
        <>
            {showAdminMode &&
            <div id={'floater'} hidden={!showEditColors} style={editColorMode === 'primary' ?
                {backgroundColor: auth.navButtonColor}
                : editColorMode === 'secondary' ?
                    {backgroundColor: auth.controlButtonColor}
                    : editColorMode === 'basket' &&
                    {backgroundColor: auth.priceButtonColor}
            }
            >
                <div style={{height:'20px'}}>
                    <span style={{width:'auto'}}>
                        <select onChange={colorToSetType} id={'color-selector'}>
                        <option value={'primary'}>
                            Primary
                        </option>
                        <option value={'secondary'}>
                            Secondary
                        </option>
                        <option value={'basket'}>
                            Basket
                        </option>
                    </select>
                    </span>
                    <span onClick={handleShowEditColors}
                          style={{ width:'30px', textAlign:'right', cursor:'pointer'}}>
                        <p><strong>SAVE <FontAwesomeIcon icon={faHdd}/></strong></p>
                    </span>
                </div>
                <hr />
                {<SketchPicker onChange={setNewColor}
                               color={editColorMode === 'primary' ?
                                   auth.navButtonColor
                                   : editColorMode === 'secondary' ?
                                       auth.controlButtonColor
                                       : editColorMode === 'basket' &&
                                       auth.priceButtonColor
                               }
                />}
            </div>
            }
            {showNavDrawer &&
            <BackDrop
                onClick={handleSwitchDrawer}/>
            }
            {loadedCategories &&
            <AdminSideDrawer show={showNavDrawer}
                             currentOrders={currentOrders}
                             showOrdersMode={showOrdersMode}
                             showAdminMode={showAdminMode}
                             customerMode={customerMode}
                             editStoreHandler={editStoreHandler}
                             handleShowSettings={handleShowSettings}
                             switchDrawerHandler={switchDrawerHandler}
                             handleShowAuth={handleShowAuth}
                             handleShowGoLive={handleShowGoLive}
                             basketValue={basketValue}
                             basketItems={basketItems}
                             ordersModeHandler={ordersModeHandler}
                             reloadCategories={reloadCategories}
                             reloadProducts={reloadProducts}
                             handleCategoryUpdate={handleCategoryUpdate}
                             reloadCart={reloadCart}
                             loadedCategories={loadedCategories}
                             setDisplayCategory={setDisplayCategory}
                             displayCategory={displayCategory}
                             haveClicked={haveClicked}
                             setHaveClicked={setHaveClicked}
                             setLoadedCategories={setLoadedCategories}
                             getSettings={getSettings}
                             setShowNavDrawer={setShowNavDrawer}
                             newProduct={newProduct}
                             createCategory={createCategory}
                             updateCategoryName={updateCategoryName}
                             deleteCategory={deleteCategory}
                             editMode={editMode}
                             setEditMode={setEditMode}
                             deleteMode={deleteMode}
                             setDeleteMode={setDeleteMode}
                             handleNew={handleNew}
                             handleApproved={handleApproved}
                             handleReady={handleReady}
                             handleComplete={handleComplete}
                             handleArchived={handleArchived}
                             handleCancelled={handleCancelled}
                             showNew={showNew}
                             showApproved={showApproved}
                             showReady={showReady}
                             showComplete={showComplete}
                             showArchived={showArchived}
                             showCancelled={showCancelled}
                             showIncomplete={showIncomplete}
                             showSettings={showSettings}
                             handleShowShopSettings={handleShowShopSettings}
                             menuItemColor={menuItemColor}
                             handleShowInvoiceSettings={handleShowInvoiceSettings}
                             handleShowEmailSettings={handleShowEmailSettings}
                             handleShowOrderSettings={handleShowOrderSettings}
                             handleShowOpeningHours={handleShowOpeningHours}
                             handleShowSupportTickets={handleShowSupportTickets}
                             showInvoiceSettings={showInvoiceSettings}
                             showEmailSettings={showEmailSettings}
                             showOrderSettings={showOrderSettings}
                             showShopSettings={showShopSettings}
                             showNavDrawer={showNavDrawer}
                             showOpeningHours={showOpeningHours}
                             controlButtonColor={auth.controlButtonColor}
                             navButtonColor={auth.navButtonColor}
                             priceButtonColor={auth.priceButtonColor}
                             emptyBasket={emptyBasket}
            />
            }
            <NavMenu
                key={'nav-'+auth.controlButtonColor+'-'+auth.navButtonColor+'-'+auth.priceButtonColor}
                priceButtonColor={auth.priceButtonColor}
                controlButtonColor={auth.controlButtonColor}
                navButtonColor={auth.navButtonColor}
                navbarColor={navbarColor}
                createSubscription={createSubscription}
                emptyBasket={emptyBasket}
                showNavDrawer={showNavDrawer}
                setShowNavDrawer={setShowNavDrawer}
                reloadCategories={reloadCategories}
                reloadProducts={reloadProducts}
                showAdminMode={showAdminMode}
                switchEditHandler={switchEditHandler}
                basketValue={basketValue}
                setBasketValue={setBasketValue}
                basketItems={basketItems}
                setBasketItems={setBasketItems}
                handleNewCategory={handleNewCategory}
                reloadCart={reloadCart}
                loadedOrders={loadedOrders}
                ordersModeHandler={ordersModeHandler}
                showOrdersMode={showOrdersMode}
                setShowAdminMode={setShowAdminMode}
                setShowOrdersMode={setShowOrdersMode}
                switchModeHandler={switchModeHandler}
                setOrdersLoaded={setOrdersLoaded}
                getSettings={getSettings}
                setSettingsLoaded={setSettingsLoaded}
                settingsLoaded={settingsLoaded}
                handleNewOrderSuccess={handleNewOrderSuccess}
                handleCompletingPayment={handleCompletingPayment}
                loading={loading}
                setLoading={setLoading}
                handleShowBasket={handleShowBasket}
                handleCloseBasket={handleCloseBasket}
                showSettings={showSettings}
                setShowSettings={setShowSettings}
                showBasket={showBasket}
                setShowBasket={setShowBasket}
                editStoreHandler={editStoreHandler}
                handleShowSettings={handleShowSettings}
                switchDrawerHandler={switchDrawerHandler}
                handleShowAuth={handleShowAuth}
                handleShowGoLive={handleShowGoLive}
                showAuth={showAuth}
                setShowAuth={setShowAuth}
                showGoLive={showGoLive}
                setShowGoLive={setShowGoLive}
                handleSwitchDrawer={handleSwitchDrawer}
                currentOrders={currentOrders}
                handleDeliveryType={handleDeliveryType}
                orderIsDelivery={orderIsDelivery}
                handleCloseSettings={handleCloseSettings}
                getInputData={getInputData}
                formDataGot={formDataGot}
                setFormDataGot={setFormDataGot}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                allowConfirm={allowConfirm}
                setAllowConfirm={setAllowConfirm}
                formDataInitial={formDataInitial}
                setFormDataInitial={setFormDataInitial}
                updateCustomer={updateCustomer}
                location={location}
                showConfirmItems={showConfirmItems}
                setShowConfirmItems={setShowConfirmItems}
                getDistance={getDistance}
                deliveryOptionsHandler={deliveryOptionsHandler}
                calculateDeliveryCharges={calculateDeliveryCharges}
                countries={assets.countries}
                customerOrdersModeHandler={customerOrdersModeHandler}
                customerOrdersMode={customerOrdersMode}
                handleShowAbout={handleShowAbout}
                addToBasketValue={addToBasketValue}
                takeFromBasketValue={takeFromBasketValue}
                setShowUserPayment={setShowUserPayment}
                showUserPayment={showUserPayment}
                showCompleteBasket={showCompleteBasket}
                setShowCompleteBasket={setShowCompleteBasket}
                buildAndCheckUsername={buildAndCheckUsername}
                buildAndCheckEmail={buildAndCheckEmail}
                usernameSuccess={usernameSuccess}
                setUsernameSuccess={setUsernameSuccess}
                usernameError={usernameError}
                setUsernameError={setUsernameError}
                usernameSet={usernameSet}
                setUsernameSet={setUsernameSet}
                currentUsername={currentUsername}
                setCurrentUsername={setCurrentUsername}
                currentEmail={currentEmail}
                setCurrentEmail={setCurrentEmail}
                emailValid={emailValid}
                setEmailValid={setEmailValid}
                userExists={userExists}
                setUserExists={setUserExists}
                resetCode={resetCode}
                setResetCode={setResetCode}
                invalidCode={invalidCode}
                setInvalidCode={setInvalidCode}
                showResetPassword={showResetPassword}
                setShowResetPassword={setShowResetPassword}
                successAlert={successAlert}
                setSuccessAlert={setSuccessAlert}
                handleSetResetState={handleSetResetState}
                navbarMode={navbarMode}
                handleShowEditColors={handleShowEditColors}
                showEditColors={showEditColors}
                handleShowContactForm={handleShowContactForm}
                contactForm={contactForm}
                showAbout={showAbout}
                currentName={currentName}
                setCurrentName={setCurrentName}
                currentPhone={currentPhone}
                setCurrentPhone={setCurrentPhone}
                currentType={currentType}
                setCurrentType={setCurrentType}
                currentTitle={currentTitle}
                setCurrentTitle={setCurrentTitle}
                currentBody={currentBody}
                setCurrentBody={setCurrentBody}
                messageSuccess={messageSuccess}
                setMessageSuccess={setMessageSuccess}
                messageSuccessCode={messageSuccessCode}
                setMessageSuccessCode={setMessageSuccessCode}
                collectInputData={collectInputData}
                openTicket={openTicket}
            />
            {/*{username === 'c4rt' &&*/}
            {/*displayVideo()*/}
            {/*}*/}
            {showAbout &&
            <AboutPage displayVideo={displayVideo}
                       key={'about-page'}
                       shopBackground={shopBackground}
                       handleShowAuth={handleShowAuth}
                       controlButtonColor={auth.controlButtonColor}
                       navButtonColor={auth.navButtonColor}
                       priceButtonColor={auth.priceButtonColor}
                       handleShowContactForm={handleShowContactForm}
                       handleShowAbout={handleShowAbout}
            />
            }
            {showSupport &&
                <SupportPage key={'support-page'}
                             shopBackground={shopBackground}
                             cartNavColor={cartNavColor}
                             displayMode={displayMode}
                             setDisplayMode={setDisplayMode}
                             ticketType={ticketType}
                             setTicketType={setTicketType}
                             showMessage={showMessage}
                             setShowMessage={setShowMessage}
                             ticketId={ticketId}
                             setTicketId={setTicketId}
                             replyMode={replyMode}
                             setReplyMode={setReplyMode}
                             controlButtonColor={auth.controlButtonColor}
                             navButtonColor={auth.navButtonColor}
                             priceButtonColor={auth.navButtonColor}
                             handleSwitchTicketType={handleSwitchTicketType}
                             handleReplyMode={handleReplyMode}
                             handleShowMessage={handleShowMessage}
                             handleShowNew={handleShowNew}
                             handleShowCustomerReplies={handleShowCustomerReplies}
                             handleShowAwaitingReply={handleShowAwaitingReply}
                             handleShowClosed={handleShowClosed}
                             supportButtonColor={supportButtonColor}
                             getUsersSupportContent={getUsersSupportContent}
                />
            }
            <div className={'hide-nav-toggle'} style={{height:'20px'}} />
            {!showAbout && !showSupport &&
            <div className="container" style={{
                backgroundColor: shopBackground,paddingTop:'18px', minHeight: '700px'
            }}>
                {showSettings &&
                <SettingsPage setSettingsLoaded={setSettingsLoaded} getSettings={getSettings}
                              showSettings={showSettings} handleCloseSettings={handleCloseSettings}
                              settingsLoaded={settingsLoaded} reloadCart={reloadCart}
                              showInvoiceSettings={showInvoiceSettings}
                              setShowInvoiceSettings={setShowInvoiceSettings}
                              setShowSupportTickets={setShowSupportTickets}
                              showSupportTickets={showSupportTickets}
                              showEmailSettings={showEmailSettings}
                              setShowEmailSettings={setShowEmailSettings}
                              showOrderSettings={showOrderSettings}
                              showOpeningHours={showOpeningHours}
                              setShowOpeningHours={setShowOpeningHours}
                              setShowOrderSettings={setShowOrderSettings}
                              showShopSettings={showShopSettings}
                              setShowShopSettings={setShowShopSettings}
                              handleShowInvoiceSettings={handleShowInvoiceSettings}
                              handleShowEmailSettings={handleShowEmailSettings}
                              handleShowOrderSettings={handleShowOrderSettings}
                              handleShowOpeningHours={handleShowOpeningHours}
                              handleShowShopSettings={handleShowShopSettings}
                              handleShowSupportTickets={handleShowSupportTickets}
                              menuItemColor={menuItemColor}
                              handleSetAddress={handleSetAddress}
                              addressIsSet={addressIsSet}
                              countries={assets.countries}
                              timeZones={assets.timeZones}
                              handleShowGoLive={handleShowGoLive}
                              basketItems={basketItems}
                              showAdminMode={showAdminMode}
                              buildAndCheckUsername={buildAndCheckUsername}
                              buildAndCheckEmail={buildAndCheckEmail}
                              usernameSuccess={usernameSuccess}
                              setUsernameSuccess={setUsernameSuccess}
                              usernameError={usernameError}
                              setUsernameError={setUsernameError}
                              usernameSet={usernameSet}
                              setUsernameSet={setUsernameSet}
                              currentUsername={currentUsername}
                              setCurrentUsername={setCurrentUsername}
                              currentEmail={currentEmail}
                              setCurrentEmail={setCurrentEmail}
                              emailValid={emailValid}
                              setEmailValid={setEmailValid}
                              userExists={userExists}
                              setUserExists={setUserExists}
                              resetCode={resetCode}
                              setResetCode={setResetCode}
                              invalidCode={invalidCode}
                              setInvalidCode={setInvalidCode}
                              showResetPassword={showResetPassword}
                              setShowResetPassword={setShowResetPassword}
                              successAlert={successAlert}
                              setSuccessAlert={setSuccessAlert}
                              handleSetResetState={handleSetResetState}
                              navbarColor={navbarColor}
                              cartNavColor={cartNavColor}
                              editUsername={editUsername}
                              setEditUsername={setEditUsername}
                              editEmail={editEmail}
                              setEditEmail={setEditEmail}
                              handleEditAccount={handleEditAccount}
                              controlButtonColor={auth.controlButtonColor}
                              navButtonColor={auth.navButtonColor}
                              priceButtonColor={auth.priceButtonColor}
                              showAbout={showAbout}
                              collectInputData={collectInputData}
                              messageSuccess={messageSuccess}
                              messageSuccessCode={messageSuccessCode}
                              openTicket={openTicket}
                              showMessage={showMessage}
                              handleShowMessage={handleShowMessage}
                              ticketId={ticketId}
                              handleReplyMode={handleReplyMode}
                              replyMode={replyMode}
                              setReplyMode={setReplyMode}
                              setTicketId={setTicketId}
                              getUsersSupportContent={getUsersSupportContent}
                              showOpenNewTicket={showOpenNewTicket}
                              setShowOpenNewTicket={setShowOpenNewTicket}
                              loading={loading}
                />
                }

                {allocatedShopOpen && auth.shopClosed && !auth.isLoggedIn && auth.appSettings.shop.closeShop &&
                <div className="alert alert-danger" style={{textAlign: 'center', marginTop: '60px'}}>
                    <strong>CLOSED!</strong> Sorry we only take orders during business opening hours. We apologise
                    for any inconvenience.
                </div>
                }
                {username === 'about' &&
                <div className="alert alert-danger" style={{textAlign: 'center', marginTop: '60px'}}>
                    This is only a demo store. Create one for yourself right now -
                    free to join with 0% transactions! <span
                    style={{cursor: 'pointer', fontWeight: 'bold'}}
                    onClick={handleShowAuth}>Get Started Now 🚀</span>.
                </div>
                }
                <div className="row">
                    {categoriesLoaded && productsLoaded && !showOrdersMode &&
                    !auth.orderCompleted && !showSettings && !customerOrdersMode &&
                    loadedCategories.map((category, index) => {
                        return <Cart
                            shopBackground={shopBackground}
                            controlButtonColor={auth.controlButtonColor}
                            navButtonColor={auth.navButtonColor}
                            priceButtonColor={auth.priceButtonColor}
                            key={index + '-' + category.id + '-navbar'}
                            categoryId={category.id}
                            index={index}
                            showAdminMode={showAdminMode}
                            categories={loadedCategories}
                            cartId={category.cartId}
                            value={category.value}
                            products={loadedProducts}
                            reloadProducts={reloadProducts}
                            reloadCategories={reloadCategories}
                            basketItems={basketItems}
                            addToBasketValue={addToBasketValue}
                            getCategories={getCategories}
                            takeFromBasketValue={takeFromBasketValue}
                            handleNewProduct={handleNewProduct}
                            handleCategoryUpdate={handleCategoryUpdate}
                            reloadCart={reloadCart}
                            showOrdersMode={showOrdersMode}
                            loadedOrders={loadedOrders}
                            loadedProducts={loadedProducts}
                            loadedCategories={loadedCategories}
                            categoriesLoaded={categoriesLoaded}
                            productsLoaded={productsLoaded}
                            getSettings={getSettings}
                            displayCategory={displayCategory}
                            setDisplayCategory={setDisplayCategory}
                            categoryIndex={index}
                            setLoadedCategories={setLoadedCategories}
                            setLoadedProducts={setLoadedProducts}
                            newProduct={newProduct}
                            createCategory={createCategory}
                            deleteCategory={deleteCategory}
                            updateCategoryName={updateCategoryName}
                            editMode={editMode}
                            setEditMode={setEditMode}
                            valueColor={valueColor}
                            cartNavColor={cartNavColor}
                            hasFeatured={auth.hasFeatured}
                            haveClicked={haveClicked}
                            setHaveClicked={setHaveClicked}
                        />
                    })}
                    {ordersLoaded && showOrdersMode && !showSettings && !customerOrdersMode &&
                    <OrdersCart
                        loadedOrders={loadedOrders}
                        showOrdersMode={showOrdersMode}
                        getOrders={getOrders}
                        handleNew={handleNew}
                        handleApproved={handleApproved}
                        handleReady={handleReady}
                        handleComplete={handleComplete}
                        handleArchived={handleArchived}
                        handleCancelled={handleCancelled}
                        showNew={showNew}
                        showApproved={showApproved}
                        showReady={showReady}
                        showComplete={showComplete}
                        showArchived={showArchived}
                        showCancelled={showCancelled}
                        showIncomplete={showIncomplete}
                        cartNavColor={cartNavColor}
                        controlButtonColor={auth.controlButtonColor}
                        navButtonColor={auth.navButtonColor}
                        priceButtonColor={auth.priceButtonColor}
                    />
                    }
                    {customerOrdersMode &&
                    <div className={'container'} style={{paddingTop:'0'}}>
                        <div className={'row'}>
                            <CustomerOrdersCart
                                loadedOrders={loadedOrders}
                                showOrdersMode={showOrdersMode}
                                getOrders={getOrders}
                                errorMessage={errorMessage}
                                countries={assets.countries}
                                getInputData={getInputData}
                                updateCustomer={updateCustomer}
                                buildBasketFromOrder={buildBasketFromOrder}
                                controlButtonColor={auth.controlButtonColor}
                                navButtonColor={auth.navButtonColor}
                                priceButtonColor={auth.priceButtonColor}
                            />
                        </div>
                    </div>
                    }
                </div>
            </div>
            }

            <WarningModal showWarning={showWarning}
                          handleShowWarning={handleShowWarning}
            />
            {/*<AboutModal showAbout={showAbout}*/}
            {/*            handleShowAbout={handleShowAbout}*/}
            {/*            displayVideo={displayVideo}*/}
            {/*            handleShowAuth={handleShowAuth}*/}
            {/*            controlButtonColor={auth.controlButtonColor}*/}
            {/*            navButtonColor={auth.navButtonColor}*/}
            {/*            priceButtonColor={auth.priceButtonColor}*/}
            {/*/>*/}
            <TermsModal handleShowTerms={handleShowTerms}
                        showTerms={showTerms}
                        privacyMode={privacyMode}
                        privacyModeHandler={privacyModeHandler}
            />
            <footer style={{marginTop: '0'}} className="py-5 bg-dark">
                <div className="container">
                    <p className="m-0 text-center text-white">
                        C4RT LTD &copy; {year}<br />
                        <span style={{color: 'grey'}}>
                            86-90 Paul Street, EC2A 4NE,<br />London, England, United Kingdom
                        </span><br />
                        <span style={{fontSize: '0.8em', color: 'grey'}}>
                            <span style={{cursor: 'pointer'}}
                                  onClick={handleShowTerms}
                            >Terms & Conditions
                            </span> | <span style={{cursor: 'pointer'}}
                                            onClick={handleShowPrivacy}
                        >
                            Privacy Policy
                        </span> {!auth.isLoggedIn &&'|'} {!auth.isLoggedIn && <span style={{cursor: 'pointer'}}
                                            onClick={handleShowContactForm}
                        >
                            Support
                        </span>} | <span style={{cursor: 'pointer'}}
                                            onClick={sendUserToDocs}
                        >
                            Documentation
                        </span>
                        </span>
                    </p>
                </div>
            </footer>
        </>
    );
}


export default CartsContainer;
