import React, {useContext, useState} from 'react';
import jwtToken from 'jsonwebtoken'
import Button from "../shared/Button";
import WelcomeEmail from "./email-settings/WelcomeEmail";
import NewOrderEmail from "./email-settings/NewOrderEmail";
import OrderApprovedEmail from "./email-settings/OrderApprovedEmail";
import OrderReadyEmail from "./email-settings/OrderReadyEmail";
import OrderCompletedEmail from "./email-settings/OrderCompletedEmail";
import axios from "axios";
import AuthContext from "../shared/auth-context";
import {
    faCertificate,
    faHandshake,
    faHandshakeSlash,
    faTruckLoading,
    faUserPlus
} from "@fortawesome/free-solid-svg-icons";
// import {faCogs} from "@fortawesome/free-solid-svg-icons";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const EmailSettings = () => {
    const auth = useContext(AuthContext)
    const [ welcomeMessageBody, setWelcomeMessageBody ] = useState(auth.appSettings.email.welcomeEmail.body)
    const [ welcomeMessageTitle, setWelcomeMessageTitle ] = useState(auth.appSettings.email.welcomeEmail.title)
    const [ newOrderMessageBody, setNewOrderMessageBody ] = useState(auth.appSettings.email.newOrder.body)
    const [ newOrderMessageTitle, setNewOrderMessageTitle ] = useState(auth.appSettings.email.newOrder.title)
    const [ newOrderCollection, setNewOrderCollection ] = useState(auth.appSettings.email.newOrder.collection)
    const [ newOrderDelivery, setNewOrderDelivery ] = useState(auth.appSettings.email.newOrder.delivery)
    const [ approvedMessageBody, setApprovedMessageBody ] = useState(auth.appSettings.email.approvedOrder.body)
    const [ approvedMessageTitle, setApprovedMessageTitle ] = useState(auth.appSettings.email.approvedOrder.title)
    const [ approvedDeliveryMessage, setApprovedDeliveryMessage ] = useState(auth.appSettings.email.approvedOrder.delivery || '')
    const [ approvedCollectionMessage, setApprovedCollectionMessage ] = useState(auth.appSettings.email.approvedOrder.collection || '')
    const [ completedMessageBody, setCompletedMessageBody ] = useState(auth.appSettings.email.orderComplete.body)
    const [ completedMessageTitle, setCompletedMessageTitle ] = useState(auth.appSettings.email.orderComplete.title)
    const [ completedDeliveryMessage, setCompletedDeliveryMessage ] = useState(auth.appSettings.email.orderComplete.delivery || '')
    const [ completedCollectionMessage, setCompletedCollectionMessage ] = useState(auth.appSettings.email.orderComplete.collection || '')
    const [ readyMessageBody, setReadyMessageBody ] = useState(auth.appSettings.email.orderCollect.body)
    const [ readyMessageTitle, setReadyMessageTitle ] = useState(auth.appSettings.email.orderCollect.title)
    const [ readyDeliveryMessage, setReadyDeliveryMessage ] = useState(auth.appSettings.email.orderCollect.delivery || '')
    const [ readyCollectionMessage, setReadyCollectionMessage ] = useState(auth.appSettings.email.orderCollect.collection || '')
    const [ showWelcome, setShowWelcome ] = useState(true);
    const [ showNewOrder, setShowNewOrder ] = useState(false);
    const [ showApproved, setShowApproved ] = useState(false);
    const [ showReady, setShowReady ] = useState(false);
    const [ showCompleted, setShowCompleted ] = useState(false);

    const [ showInfo, setShowInfo ] = useState(false)

    const handleShowInfo = () => {
        setShowInfo(prevState => !prevState)
    }

    const data = {
        welcomeMessageBody, setWelcomeMessageBody,
        welcomeMessageTitle, setWelcomeMessageTitle,
        newOrderMessageBody, setNewOrderMessageBody,
        newOrderMessageTitle, setNewOrderMessageTitle,
        approvedMessageBody, setApprovedMessageBody,
        approvedMessageTitle, setApprovedMessageTitle,
        completedMessageBody, setCompletedMessageBody,
        completedMessageTitle, setCompletedMessageTitle,
        readyMessageBody, setReadyMessageBody,
        readyMessageTitle, setReadyMessageTitle,
        approvedCollectionMessage, setApprovedCollectionMessage,
        approvedDeliveryMessage, setApprovedDeliveryMessage,
        completedDeliveryMessage, setCompletedDeliveryMessage,
        completedCollectionMessage, setCompletedCollectionMessage,
        readyDeliveryMessage, setReadyDeliveryMessage,
        readyCollectionMessage, setReadyCollectionMessage,
        newOrderCollection, setNewOrderCollection,
        newOrderDelivery, setNewOrderDelivery
    }

    const handleSetData = event => {
        event.preventDefault()
        let type = event.target.name
        let userInput = event.target.value;
        if(type==='welcome-email-title'){
            setWelcomeMessageTitle(userInput)
        }
        if(type==='welcome-email-message'){
            setWelcomeMessageBody(userInput)
        }
        if(type==='new-order-email-title'){
            setNewOrderMessageTitle(userInput)
        }
        if(type==='new-order-email-message'){
            setNewOrderMessageBody(userInput)
        }
        if(type==='new-order-collection'){
            setNewOrderCollection(userInput)
        }
        if(type==='new-order-delivery'){
            setNewOrderDelivery(userInput)
        }
        if(type==='order-approved-email-title'){
            setApprovedMessageTitle(userInput)
        }
        if(type==='order-approved-email-message'){
            setApprovedMessageBody(userInput)
        }
        if(type==='order-approved-collection'){
            setApprovedCollectionMessage(userInput)
        }
        if(type==='order-approved-delivery'){
            setApprovedDeliveryMessage(userInput)
        }
        if(type==='order-complete-email-title'){
            setCompletedMessageTitle(userInput)
        }
        if(type==='order-complete-email-message'){
            setCompletedMessageBody(userInput)
        }
        if(type==='order-complete-collection'){
            setCompletedCollectionMessage(userInput)
        }
        if(type==='order-complete-delivery'){
            setCompletedDeliveryMessage(userInput)
        }
        if(type==='order-ready-email-title'){
            setReadyMessageTitle(userInput)
        }
        if(type==='order-ready-email-message'){
            setReadyMessageBody(userInput)
        }
        if(type==='order-ready-collection'){
            setReadyCollectionMessage(userInput)
        }
        if(type==='order-ready-delivery'){
            setReadyDeliveryMessage(userInput)
        }
    }

    const updateMessage = async () => {
        let submitData = {
            username: auth.username,
            shop:auth.appSettings.shop,
            email: {
                welcomeEmail: {
                    title: welcomeMessageTitle,
                    body: welcomeMessageBody
                },
                newOrder: {
                    title: newOrderMessageTitle,
                    body: newOrderMessageBody,
                    collection: newOrderCollection,
                    delivery: newOrderDelivery
                },
                approvedOrder: {
                    title: approvedMessageTitle,
                    body: approvedMessageBody,
                    collection: approvedCollectionMessage,
                    delivery: approvedDeliveryMessage
                },
                orderCollect: {
                    title: readyMessageTitle,
                    body: readyMessageBody,
                    collection: readyCollectionMessage,
                    delivery: readyDeliveryMessage
                },
                orderComplete: {
                    title: completedMessageTitle,
                    body: completedMessageBody,
                    collection: completedCollectionMessage,
                    delivery: completedDeliveryMessage
                },
                // settings: auth.appSettings.email.settings
            },
            invoicing: auth.appSettings.invoicing,
            settingsValid: auth.appSettings.settingsValid,
            userToken: auth.userToken
        }
        try{
            await auth.setAppSettings(submitData)
            const userData = JSON.parse(localStorage.getItem('userData'))
            let { username, token, cartId, categoryId, stripeSettings, cartOrder,
                cartInvoices, userEmail, tickets, messages} = userData
            await localStorage.setItem('userData', JSON.stringify({
                username, token, cartId, categoryId, appSettings: submitData,
                stripeSettings, cartOrder, cartInvoices, userEmail, tickets, messages
            }))
        }catch(error){

        }
        let token;
        try{
            token = await jwtToken.sign(
                {
                    username: auth.username,
                    submitData
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {expiresIn: '1h'}
            );
        }catch(err){console.log(err)}
        await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/settings`, {}, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(async (res) => {
                await auth.setAppSettings(res.data.settings)
                const userData = JSON.parse(localStorage.getItem('userData'))
                let { username, token, cartId, categoryId, stripeSettings, cartOrder,
                    cartInvoices, userEmail, tickets, messages} = userData
                await localStorage.setItem('userData', JSON.stringify({
                    username, token, cartId, categoryId, appSettings: res.data.settings,
                    stripeSettings, cartOrder, cartInvoices, userEmail, tickets, messages
                }))
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleShowWelcome = () => {
        setShowWelcome(true);
        setShowNewOrder(false);
        setShowApproved(false);
        setShowReady(false);
        setShowCompleted(false);
        setShowInfo(false)
    }
    const handleShowNewOrder = () => {
        setShowWelcome(false);
        setShowNewOrder(true);
        setShowApproved(false);
        setShowReady(false);
        setShowCompleted(false);
        setShowInfo(false)
    }
    const handleShowApproved = () => {
        setShowWelcome(false);
        setShowNewOrder(false);
        setShowApproved(true);
        setShowReady(false);
        setShowCompleted(false);
        setShowInfo(false)
    }
    const handleShowReady = () => {
        setShowWelcome(false);
        setShowNewOrder(false);
        setShowApproved(false);
        setShowReady(true);
        setShowCompleted(false);
        setShowInfo(false)
    }
    const handleShowCompleted = () => {
        setShowWelcome(false);
        setShowNewOrder(false);
        setShowApproved(false);
        setShowReady(false);
        setShowCompleted(true);
        setShowInfo(false)
    }

    const buttonColor = (type) => {
        switch(type){
            case 'welcome':
                if(showWelcome){
                    return 'dark-blue'
                } else {
                    return 'grey'
                }
            case 'new-order':
                if(showNewOrder){
                    return 'dark-blue'
                } else {
                    return 'grey'
                }
            case 'approved':
                if(showApproved){
                    return 'dark-blue'
                } else {
                    return 'grey'
                }
            case 'ready':
                if(showReady){
                    return 'dark-blue'
                } else {
                    return 'grey'
                }
            case 'completed':
                if(showCompleted){
                    return 'dark-blue'
                } else {
                    return 'grey'
                }
            default:
                return ''

        }
    }


    return (
        <>
            <div className="container">
                <div className="row" style={{marginTop: '0'}}>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                        <div style={{width:'100%', display:'block', paddingTop:'5px'}}>
                            {/*<EmailSettingsNav />*/}
                            <Button onClick={handleShowWelcome}
                                    className={'email-settings ' +
                                    buttonColor('welcome')}
                                    style={{fontSize:'1.1em'}}
                                    text={'WELCOME EMAIL'}
                                    sidebarButton={true}
                                    icon={faUserPlus}/>
                            <Button onClick={handleShowNewOrder}
                                    className={'email-settings ' +
                                    buttonColor('new-order')}
                                    style={{fontSize:'1.1em'}}
                                    text={'NEW ORDER'}
                                    sidebarButton={true}
                                    icon={faHandshakeSlash}/>
                            <Button onClick={handleShowApproved}
                                    className={'email-settings ' +
                                    buttonColor('approved')}
                                    style={{fontSize:'1.1em'}}
                                    text={'ORDER APPROVED'}
                                    sidebarButton={true}
                                    icon={faHandshake}/>
                            <Button onClick={handleShowReady}
                                    className={'email-settings ' +
                                    buttonColor('ready')}
                                    style={{fontSize:'1.1em'}}
                                    text={'ORDER READY'}
                                    sidebarButton={true}
                                    icon={faTruckLoading}/>
                            <Button onClick={handleShowCompleted}
                                    className={'email-settings ' +
                                    buttonColor('completed')}
                                    style={{fontSize:'1.1em'}}
                                    text={'ORDER COMPLETE'}
                                    sidebarButton={true}
                                    icon={faCertificate}/>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                        <div className="card-body">
                            {showWelcome &&
                            <div className={'row'} style={{marginTop:'10px'}}>
                                <h5>Welcome Email</h5>
                            <WelcomeEmail
                                handleSetData={handleSetData}
                                data={data}
                                updateMessage={updateMessage}
                                showWelcome={showWelcome}
                                showInfo={showInfo}
                                handleShowInfo={handleShowInfo}
                            /></div>}
                            {showNewOrder &&
                            <div className={'row'} style={{marginTop:'10px'}}>
                                <h5>New Order Email</h5>
                                <NewOrderEmail
                                    handleSetData={handleSetData}
                                    data={data}
                                    updateMessage={updateMessage}
                                    showNewOrder={showNewOrder}
                                    showInfo={showInfo}
                                    handleShowInfo={handleShowInfo}
                                    newOrderMessageDelivery={newOrderDelivery}
                                    newOrderMessageCollection={newOrderCollection}
                                />
                            </div>
                            }
                            {showApproved &&
                            <div className={'row'} style={{marginTop:'10px'}}>
                                <h5>Order Approved Email</h5>
                                <OrderApprovedEmail
                                    showInfo={showInfo}
                                    handleShowInfo={handleShowInfo}
                                    handleSetData={handleSetData}
                                    data={data}
                                    updateMessage={updateMessage}
                                    showApproved={showApproved}
                                />
                            </div>
                            }
                            {showReady &&
                            <div className={'row'} style={{marginTop:'10px'}}>
                                <h5>Order Ready Email</h5>
                                <OrderReadyEmail
                                    showInfo={showInfo}
                                    handleShowInfo={handleShowInfo}
                                    handleSetData={handleSetData}
                                    data={data}
                                    updateMessage={updateMessage}
                                    showReady={showReady}
                                />
                            </div>
                            }
                            {showCompleted &&
                            <div className={'row'} style={{marginTop:'10px'}}>
                                <h5>Order Complete Email</h5>
                                <OrderCompletedEmail
                                    showInfo={showInfo}
                                    handleShowInfo={handleShowInfo}
                                    handleSetData={handleSetData}
                                    data={data}
                                    updateMessage={updateMessage}
                                    showCompleted={showCompleted}
                                />
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailSettings;
