import React, {useContext, useRef} from 'react';
import '../../../css/NavMenu.css'
import Button from "../../shared/Button";
import Modal from "react-bootstrap/Modal";
import Authentication from "../Authentication";
import AuthContext from "../../shared/auth-context";
import UserBasket from "../UserBasket";
import {useParams} from "react-router";
import'../../../css/PurpleNavButton.css'
import {
    faCaretDown, faComments, faSignOutAlt, faStore
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GoLiveModal from "../GoLiveModal";
import ContactModal from "../../shared/ContactModal";
import MainNavMenuItems from "../MainNavMenuItems";


const NavMenu = props => {
    const auth = useContext(AuthContext);
    let  { username } = useParams();
    const { showCompleteBasket, setShowCompleteBasket, buildAndCheckUsername,
        buildAndCheckEmail, usernameSuccess, setUsernameSuccess, usernameError,
        setUsernameError, usernameSet, setUsernameSet, currentUsername,
        setCurrentUsername, currentEmail, setCurrentEmail, emailValid,
        setEmailValid, userExists, setUserExists, setLoading, loading,
        reloadProducts, reloadCategories, showAuth, resetCode, setResetCode,
        invalidCode, setInvalidCode, showResetPassword, setShowResetPassword,
        successAlert, setSuccessAlert, handleSetResetState,controlButtonColor,
        navButtonColor, priceButtonColor, handleShowContactForm, contactForm,
        showAdminMode, basketValue, handleShowBasket, navbarMode, showEditColors,
        basketItems, handleShowEditColors, editStoreHandler, handleShowSettings,
        ordersModeHandler, switchModeHandler, showGoLive, customerOrdersMode,
        customerOrdersModeHandler, handleShowAuth, handleShowAbout, showAbout,
        currentName, setCurrentName, currentPhone, setCurrentPhone, currentType,
        setCurrentType, currentTitle, setCurrentTitle, currentBody, setCurrentBody,
        messageSuccess, setMessageSuccess, messageSuccessCode, setMessageSuccessCode,
        collectInputData, openTicket
    } = props
    const handleEmptyBasket = () => {
        props.emptyBasket()
    }
    let ref = React.createRef()
    let basketRef = useRef(ref);
    let orders
    if(!auth.customersOrders){
        const userData = JSON.parse(localStorage.getItem('customerData'))
        if(userData){
            orders = userData.orders

        }
    } else {
        orders = auth.customersOrders
    }
    const logUserOut = async () => {
        await auth.logout()
        if (!!auth.customerToken) {
            await auth.customerLogout()
        }
    }

    const signCustomerOut = async () => {
        await props.customerOrdersModeHandler({logout: true})
        auth.customerLogout()
    }
    const handleCloseAuth = () => props.setShowAuth(false);
    const handleCloseGoLive = () => props.setShowGoLive(false);

    return (
        <>
            <nav id={'nav-'+controlButtonColor+'-'+navButtonColor+'-'+priceButtonColor}
                 style={{backgroundColor: props.navbarColor}}
                 className="navbar navbar-expand-lg navbar-dark fixed-top">
                <div className="container " style={{paddingTop: '0',
                    width: username=== 'about' && showAbout ? '960px' : '100%'}}>
                    <button className="navbar-toggler"
                            onClick={username === 'support' ? handleShowAbout : props.handleSwitchDrawer}
                            style={{float: 'right'}}
                            type="button" data-toggle="collapse">
                    <span style={{lineHeight:'30px'}} className="dark-blue-text">
                        {username === 'support' ?
                            <><span style={{marginRight: '7px'}}>
                            HOME
                        </span> <FontAwesomeIcon className={'nav-logout'} icon={faStore}/></>
                            :
                            <><span style={{marginRight: '7px'}}>
                            MENU
                            </span> <FontAwesomeIcon className={'nav-logout'} icon={faCaretDown}/></>
                        }
                    </span>
                    </button>
                    <h1 className="navbar-brand collapse navbar-collapse"
                        style={{
                            fontSize: '2.2em',
                            lineHeight: '35px',
                            paddingTop: '0',
                            paddingBottom: '0',
                            marginBottom: '0',
                            marginTop: '0',
                            color: 'whitesmoke'
                        }}>
                        {!!username && (username === 'about' || username === 'support') ?
                            <span><img src="https://c4rt-photos.s3.amazonaws.com/c6387eb0-7083-11eb-80e6-96b685c9be97.jpg"
                                       style={{marginRight:'10px', marginTop: '-10px'}} alt="logo" />C4RT.UK {username === 'support' && '| SUPPORT'}</span>  :
                            !!username && username ?  username :
                            (!!currentUsername &&  currentUsername) ||  auth.username
                        }
                    </h1>
                    <div className="" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            {auth.isLoggedIn && auth.username === 'support' ?
                                <li className="nav-item nav-controls ">
                                    <Button onClick={logUserOut}
                                            className={'red nav-user-button'}
                                            text={'SIGN OUT'}
                                            key={'logout-store'}
                                            keyUsed={'logout'}
                                            keyMode={'-store'}
                                            float={'right'}
                                            controlButtonColor={controlButtonColor}
                                            navButtonColor={navButtonColor}
                                            priceButtonColor={priceButtonColor}
                                            active={true}
                                            icon={faSignOutAlt}/>
                                    <Button onClick={props.customerOrdersModeHandler}
                                            className={'red nav-user-button'}
                                            text={'SUPPORT TICKETS'}
                                            key={'account ' + controlButtonColor+controlButtonColor}
                                            keyUsed={'account'}
                                            float={'right'}
                                            keyMode={controlButtonColor+controlButtonColor}
                                            controlButtonColor={controlButtonColor}
                                            navButtonColor={navButtonColor}
                                            priceButtonColor={priceButtonColor}
                                            active={true}
                                            icon={faComments}/>
                                </li>
                                :
                                <MainNavMenuItems key={'nav-menu-items'}
                                                  showAdminMode={showAdminMode}
                                                  basketValue={basketValue}
                                                  handleShowBasket={handleShowBasket}
                                                  navbarMode={navbarMode}
                                                  showEditColors={showEditColors}
                                                  basketItems={basketItems}
                                                  handleShowEditColors={handleShowEditColors}
                                                  editStoreHandler={editStoreHandler}
                                                  handleShowSettings={handleShowSettings}
                                                  ordersModeHandler={ordersModeHandler}
                                                  switchModeHandler={switchModeHandler}
                                                  showGoLive={showGoLive}
                                                  customerOrdersMode={customerOrdersMode}
                                                  customerOrdersModeHandler={customerOrdersModeHandler}
                                                  handleShowAuth={handleShowAuth}
                                                  navButtonColor={navButtonColor}
                                                  priceButtonColor={priceButtonColor}
                                                  controlButtonColor={controlButtonColor}
                                                  username={username}
                                                  orders={orders}
                                                  signCustomerOut={signCustomerOut}
                                                  handleEmptyBasket={handleEmptyBasket}
                                                  handleShowContactForm={handleShowContactForm}
                                                  handleShowAbout={handleShowAbout}
                                                  showAbout={showAbout}
                                                  contactForm={contactForm}
                                />
                            }
                        </ul>
                    </div>
                </div>
            </nav>
            <Modal show={showAuth} onHide={handleCloseAuth}>
                <Authentication
                    handleClose={handleCloseAuth}
                    reloadCategories={reloadCategories}
                    reloadProducts={reloadProducts}
                    loading={loading}
                    setLoading={setLoading}
                    buildAndCheckUsername={buildAndCheckUsername}
                    buildAndCheckEmail={buildAndCheckEmail}
                    usernameSuccess={usernameSuccess}
                    setUsernameSuccess={setUsernameSuccess}
                    usernameError={usernameError}
                    setUsernameError={setUsernameError}
                    usernameSet={usernameSet}
                    setUsernameSet={setUsernameSet}
                    currentUsername={currentUsername}
                    setCurrentUsername={setCurrentUsername}
                    currentEmail={currentEmail}
                    setCurrentEmail={setCurrentEmail}
                    emailValid={emailValid}
                    setEmailValid={setEmailValid}
                    userExists={userExists}
                    setUserExists={setUserExists}
                    resetCode={resetCode}
                    setResetCode={setResetCode}
                    invalidCode={invalidCode}
                    setInvalidCode={setInvalidCode}
                    showResetPassword={showResetPassword}
                    setShowResetPassword={setShowResetPassword}
                    successAlert={successAlert}
                    setSuccessAlert={setSuccessAlert}
                    handleSetResetState={handleSetResetState}
                    controlButtonColor={controlButtonColor}
                    navButtonColor={navButtonColor}
                    priceButtonColor={priceButtonColor}
                />
            </Modal>
            <Modal show={props.showBasket} ref={basketRef} onHide={props.handleCloseBasket}>
                <UserBasket
                    addToBasketValue={props.addToBasketValue}
                    takeFromBasketValue={props.takeFromBasketValue}
                    basketItems={props.basketItems}
                    basketValue={props.basketValue}
                    setBasketValue={props.setBasketValue}
                    setBasketItems={props.setBasketItems}
                    setShowBasket={props.setShowBasket}
                    handleCloseBasket={props.handleCloseBasket}
                    setShowCompleteBasket={setShowCompleteBasket}
                    showCompleteBasket={showCompleteBasket}
                    setOrdersLoaded={props.setOrdersLoaded}
                    handleNewOrderSuccess={props.handleNewOrderSuccess}
                    handleShowGoLive={props.handleShowGoLive}
                    handleCompletingPayment={props.handleCompletingPayment}
                    loading={props.loading}
                    setLoading={props.setLoading}
                    handleDeliveryType={props.handleDeliveryType}
                    orderIsDelivery={props.orderIsDelivery}
                    getInputData={props.getInputData}
                    formDataGot={props.formDataGot}
                    setFormDataGot={props.setFormDataGot}
                    errorMessage={props.errorMessage}
                    setErrorMessage={props.setErrorMessage}
                    allowConfirm={props.allowConfirm}
                    setAllowConfirm={props.setAllowConfirm}
                    formDataInitial={props.formDataInitial}
                    setFormDataInitial={props.setFormDataInitial}
                    updateCustomer={props.updateCustomer}
                    location={props.location}
                    showConfirmItems={props.showConfirmItems}
                    setShowConfirmItems={props.setShowConfirmItems}
                    getDistance={props.getDistance}
                    deliveryOptionsHandler={props.deliveryOptionsHandler}
                    calculateDeliveryCharges={props.calculateDeliveryCharges}
                    countries={props.countries}
                    handleShowAuth={props.handleShowAuth}
                    buttonColor={props.buttonColor}
                    devMode={props.devMode}
                    setDevMode={props.setDevMode}
                    setShowUserPayment={props.setShowUserPayment}
                    controlButtonColor={controlButtonColor}
                    navButtonColor={navButtonColor}
                    priceButtonColor={priceButtonColor}
                />
            </Modal>
            <GoLiveModal createSubscription={props.createSubscription}
                         showGoLive={props.showGoLive}
                         setShowGoLive={props.setShowGoLive}
                         handleCloseGoLive={handleCloseGoLive}
                         getSettings={props.getSettings}
                         controlButtonColor={controlButtonColor}
                         navButtonColor={navButtonColor}
                         priceButtonColor={priceButtonColor}
            />
            <ContactModal key={'contact-modal'}
                          contactForm={contactForm}
                          controlButtonColor={controlButtonColor}
                          navButtonColor={navButtonColor}
                          priceButtonColor={priceButtonColor}
                          handleShowContactForm={handleShowContactForm}
                          currentName={currentName}
                          setCurrentName={setCurrentName}
                          currentPhone={currentPhone}
                          setCurrentPhone={setCurrentPhone}
                          currentType={currentType}
                          setCurrentType={setCurrentType}
                          currentTitle={currentTitle}
                          setCurrentTitle={setCurrentTitle}
                          currentBody={currentBody}
                          setCurrentBody={setCurrentBody}
                          messageSuccess={messageSuccess}
                          setMessageSuccess={setMessageSuccess}
                          messageSuccessCode={messageSuccessCode}
                          setMessageSuccessCode={setMessageSuccessCode}
                          currentEmail={currentEmail}
                          setCurrentEmail={setCurrentEmail}
                          collectInputData={collectInputData}
                          openTicket={openTicket}
            />
        </>
    )
}

export default NavMenu
