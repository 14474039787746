import React from 'react';
import Button from "../../../shared/Button";

const CategoryButton = props => {
    let {setDisplayCategory, categoryName, category,categoryIndex,
        navButtonColor, controlButtonColor, displayCategory, priceButtonColor,
        setHaveClicked
    } =props
    const handleDisplayCategory = async () => {
        setHaveClicked(true)
        await setDisplayCategory(categoryIndex)
    }

    return (
        <span id={categoryIndex + '-' +  category.categoryId+'-'+controlButtonColor+'-'+priceButtonColor}>
            <Button onClick={handleDisplayCategory}
                    className={'admin-nav-toggle user-button'}
                    text={categoryName}
                    key={'change-' + categoryIndex + displayCategory + priceButtonColor + navButtonColor}
                    usedKey={'change-' + categoryIndex}
                    keyMode={displayCategory + priceButtonColor + navButtonColor}
                    controlButtonColor={controlButtonColor}
                    navButtonColor={navButtonColor}
                    priceButtonColor={priceButtonColor}
                    solid={true}
                    categoryButton={true}
                    active={categoryIndex === displayCategory}
                    icon={null}/>
        </span>

    );
}

export default CategoryButton;
