import React, {useState, useContext} from 'react';
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "../shared/Button";
import '../../css/UserBasket.css'
import AuthContext from "../shared/auth-context";
import jwtToken from 'jsonwebtoken'
import {
    BuildAndCheckPassword, ConfirmPassword, ConfirmPasswords
} from "../shared/build-check-password";
import {Alert} from "react-bootstrap";
import TermsConditions from "../../../pages/TermsConditions";
import PrivacyPolicy from "../../../pages/cart/PrivacyPolicy";

const apiKey = process.env.REACT_APP_JWT_KEY;


const axios = require('axios').default;


const Authentication = props => {
    const auth = useContext(AuthContext);
    let {
        buildAndCheckUsername, buildAndCheckEmail, usernameSuccess,
        usernameError, usernameSet, currentUsername, currentEmail,
        setCurrentEmail, emailValid, userExists, handleSetResetState,
        resetCode, setResetCode, invalidCode, setInvalidCode, showResetPassword,
        setShowResetPassword, successAlert, setSuccessAlert, controlButtonColor,
        navButtonColor, priceButtonColor
    } = props


    const [containsNumber, setContainsNumber] = useState(false);
    const [containsUppercase, setContainsUppercase] = useState(false);
    const [containsSpecial, setContainsSpecial] = useState(false);
    const [longEnough, setLongEnough] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [passwordsLengthMatch, setPasswordsLengthMatch] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [resetUsername, setResetUsername] = useState('')
    const [showSetUsername, setShowSetUsername] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
    const [showPasswordResetFields, setShowPasswordResetFields] = useState(false)
    const [termsAgreed, setTermsAgreed] = useState(false)
    const [showTerms, setShowTerms] = useState(false)
    const [showPrivacyMode, setShowPrivacyMode] = useState(false)


    const handleShowPrivacy = () => {
        if (showTerms && showPrivacyMode) {
            setShowTerms(false)
            setShowPrivacyMode(false)
        } else if (!showTerms && showPrivacyMode) {
            setShowTerms(true)
        } else if (!showTerms && !showPrivacyMode) {
            setShowPrivacyMode(true)
            setShowTerms(true)
        } else if (showTerms && !showPrivacyMode) {
            setShowPrivacyMode(true)
            setShowTerms(true)
        }
    }
    const handleShowTerms = () => {
        if (showPrivacyMode) {
            setShowPrivacyMode(false)
            if (!showTerms) {
                setShowTerms(true)
            }
        } else {
            setShowTerms(prevState => !prevState)
        }

    }
    const handleAgreeTerms = () => {
        setTermsAgreed(prevState => !prevState)
        if (!termsAgreed) {
            setShowTerms(false)
        }
    }
    const resetCodeHandler = async (event) => {
        let value = event.target.value
        await setResetCode(value)
    }
    const checkCodeHandler = async () => {
        if (resetCode.length === 6) {
            // Send off the request here to check the code.
            try {
                let token;
                try {
                    token = await jwtToken.sign(
                        {
                            email: currentEmail,
                            resetCode
                        },
                        process.env.REACT_APP_JWT_KEY,
                        {
                            expiresIn: '1h'
                        }
                    );
                } catch (err) {
                    console.log(err)
                }
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/api/password`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                const responseData = await response.json();
                if (responseData.message === 'Success') {
                    setSuccessAlert(true)
                    setShowPasswordResetFields(true)
                } else {
                    setInvalidCode(true)
                    setCurrentEmail('')
                }
            } catch (error) {
                // setIsLoading(false);
                console.log('ERROR: ' + error)
            }
        }
    }
    const resetPasswordHandler = async () => {
        // Send off request to backend to email customer
        try {
            let token;
            try {
                token = await jwtToken.sign(
                    {
                        email: currentEmail
                    },
                    process.env.REACT_APP_JWT_KEY,
                    {
                        expiresIn: '1h'
                    }
                );
            } catch (err) {
                console.log(err)
            }
            await axios.post(`${process.env.REACT_APP_SITE_URL}/api/password`, {}, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => {
                    console.log(response.data)
                    if (response.data.message === 'Success') {
                        setResetUsername(response.data.username)
                        setShowResetPassword(true)
                    }
                })
                .catch(error => {

                });
        } catch (error) {
            // setIsLoading(false);
            console.log('ERROR: ' + error)
        }
    }
    const buildCheckPassword = async event => {
        event.preventDefault();
        let userInput = event.target.value;
        setCurrentPassword(userInput)
        await BuildAndCheckPassword(userInput, setCurrentPassword, setLongEnough,
            setContainsNumber, setContainsUppercase,
            setContainsSpecial);
    }
    const selectUsernameHandler = () => {
        setShowSetUsername(prevState => !prevState);
    }
    const confirmPassword = async () => {
        await setErrorMessage(null);
        await ConfirmPassword(containsNumber, longEnough, containsUppercase, containsSpecial,
            setShowConfirmPassword)
    }
    const confirmPasswords = event => {
        event.preventDefault();
        setSuccessAlert(false)
        ConfirmPasswords(event, currentPassword, setPasswordsLengthMatch, setPasswordsMatch)
    }
    let categoryId;
    const authSubmitHandler = async event => {
        await props.setLoading(true)
        event.preventDefault()
        if (userExists) {
            let token;
            try {
                token = await jwtToken.sign(
                    {
                        email: currentEmail,
                        password: currentPassword
                    },
                    apiKey,
                    {expiresIn: '1h'}
                );
            } catch (err) {
                console.log(err)
            }
            let error = false;
            await axios.post(`${process.env.REACT_APP_SITE_URL}/api/users/login`, {}, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(async res => {
                    if (res.data.message) {
                        setErrorMessage(res.data.message)
                        error = true
                    } else {
                        let incomingToken = res.data.token
                        let decodedToken = await jwtToken.decode(incomingToken, {complete: true});
                        let username = decodedToken.payload.username;
                        let appSettings = decodedToken.payload.appSettings;
                        let cartId = decodedToken.payload.cartId;
                        let stripeSettings = decodedToken.payload.stripeSettings;
                        let cartOrder = decodedToken.payload.cartOrder;
                        let cartInvoices = decodedToken.payload.cartInvoices;
                        let userEmail = decodedToken.payload.userEmail;
                        let token = decodedToken.payload.userToken;
                        let tickets = decodedToken.payload.tickets;
                        let messages = decodedToken.payload.messages;
                        await jwtToken.verify(incomingToken,
                            `${process.env.REACT_APP_JWT_KEY}`,
                            async function (err, decoded) {
                                if (decoded) {
                                    if (!username) {
                                    } else if (!error) {
                                        auth.login(username, token, cartId, categoryId, appSettings,
                                            stripeSettings, cartOrder, cartInvoices, userEmail, tickets, messages);
                                        props.handleClose();
                                    }
                                } else {
                                    console.log('Didn\'t decode')
                                }
                            });
                        setErrorMessage(null)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        } else {
            let token;
            try {
                token = await jwtToken.sign(
                    {
                        email: currentEmail,
                        password: currentPassword,
                        username: currentUsername
                    },
                    `${process.env.REACT_APP_JWT_KEY}`,
                    {
                        expiresIn: '1h'
                    }
                );
            } catch (err) {
                console.log(err)
            }
            await axios.post(`${process.env.REACT_APP_SITE_URL}/api/new-user`, {}, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(async res => {
                    let incomingToken = res.data.token
                    await jwtToken.verify(incomingToken,
                        `${process.env.REACT_APP_JWT_KEY}`,
                        async function (err, decoded) {
                            if (decoded) {
                                let decodedToken = await jwtToken.decode(incomingToken, {complete: true});
                                let username = decodedToken.payload.username;
                                let appSettings = decodedToken.payload.appSettings;
                                let cartId = decodedToken.payload.cartId;
                                let stripeSettings = decodedToken.payload.stripeSettings;
                                let cartOrder = decodedToken.payload.cartOrder;
                                let cartInvoices = decodedToken.payload.cartInvoices;
                                let token = decodedToken.payload.userToken;
                                let userEmail = decodedToken.payload.userEmail;
                                let tickets = decodedToken.payload.tickets;
                                let messages = decodedToken.payload.messages;
                                auth.login(username, token, cartId, categoryId, appSettings,
                                    stripeSettings, cartOrder, cartInvoices, userEmail, tickets, messages);
                                props.handleClose();
                                props.reloadProducts();
                                props.reloadCategories();
                            } else {
                                console.log('Didn\'t decode')
                            }
                        });
                })
                .catch(error => {
                    console.log(error);
                })
        }
        await props.setLoading(false)

    };
    const updatePasswordHandler = async () => {
        try {
            let token;
            try {
                token = await jwtToken.sign(
                    {
                        email: currentEmail,
                        password: currentPassword,
                        resetCode
                    },
                    process.env.REACT_APP_JWT_KEY,
                    {
                        expiresIn: '1h'
                    }
                );
            } catch (err) {
                console.log(err)
            }
            await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/password`, {}, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(async response => {
                    let incomingToken = response.data.token
                    let decodedToken = await jwtToken.decode(incomingToken, {complete: true});
                    let username = decodedToken.payload.username;
                    let appSettings = decodedToken.payload.appSettings;
                    let cartId = decodedToken.payload.cartId;
                    let stripeSettings = decodedToken.payload.stripeSettings;
                    let token = decodedToken.payload.userToken;
                    await jwtToken.verify(incomingToken,
                        `${process.env.REACT_APP_JWT_KEY}`,
                        async function (err, decoded) {
                            if (decoded) {
                                if (!username) {
                                } else if (!err) {
                                    const userData = JSON.parse(localStorage.getItem('userData'))
                                    let {
                                        username,
                                        categoryId,
                                        cartOrder,
                                        cartInvoices,
                                        userEmail,
                                        tickets,
                                        messages
                                    } = userData
                                    auth.login(username, token, cartId, categoryId, appSettings, stripeSettings,
                                        cartOrder, cartInvoices, userEmail, tickets, messages);
                                    props.handleClose();
                                }
                            } else {
                                console.log('Didn\'t decode')
                            }
                        });
                    setErrorMessage(null)
                })
                .catch(error => {

                });
        } catch (error) {
            // setIsLoading(false);
            console.log('ERROR: ' + error)
        }
    }
    const printErrors = () => {
        return <div>
            {passwordsMatch &&
            <Alert key={'idx3'} variant={'success'} style={{
                textAlign: 'center',
                fontSize: '0.8em', paddingTop: '7px', paddingBottom: '7px'
            }}>
                Everything looks good. Click below to continue.
            </Alert>}
            <div className={'user-warning'}>
                {!containsUppercase && currentPassword.length > 0 &&
                <Alert key={'idx4'} variant={'danger'}>
                    Has at least one uppercase letter
                </Alert>
                }
                {passwordsLengthMatch && !passwordsMatch &&
                <Alert key={'idx4'} variant={'danger'}>
                    Sorry those passwords aren't the same!
                </Alert>
                }
                {!containsSpecial && currentPassword.length > 0 &&
                <Alert key={'idx5'} variant={'danger'}>
                    Has one special character (#, @, ", $ etc)
                </Alert>
                }
                {!containsNumber && currentPassword.length > 0 &&
                <Alert key={'idx6'} variant={'danger'}>
                    Has at least one number (1,2,3,4 etc)
                </Alert>
                }
                {!longEnough && currentPassword.length > 0 &&
                <Alert key={'idx7'} variant={'danger'}>
                    Is over 8 characters in length
                </Alert>
                }
            </div>
        </div>
    }
    const getDangerColor = () => {
        if (!termsAgreed) {
            return 'red'
        } else {
            return 'blue'
        }
    }

    return (
        <>
            {!showSetUsername ?
                <>
                    <ModalHeader closeButton>

                        <ModalTitle>Register / Login</ModalTitle>

                    </ModalHeader>
                    <ModalBody>
                        {/*<img alt={'c4rt.uk'} src={'https://c4rt-photos.s3.amazonaws.com/d61ce680-42ee-11eb-8d08-4c4e9fa1ab3d.jpg'}*/}
                        {/*     style={{width: '42%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '28px'}}*/}
                        {/*/>*/}
                        <div className='embed-container'>
                            <iframe src='https://player.vimeo.com/video/489467113?autoplay=1&loop=1&autopause=0' frameBorder='0' webkitAllowFullScreen
                                    mozallowfullscreen allowFullScreen allow="autoplay; fullscreen" title={'demo-video'}/>
                        </div>
                        <h6 style={{marginTop: '20px'}}>Please enter in your email address to continue...</h6>
                        {!emailValid ?
                            ''
                            // <p><strong>Please enter in your email address to continue...</strong></p>
                            : userExists && !showPasswordResetFields ?
                                <p><strong>
                                    Welcome back, please enter your password below...
                                </strong></p>
                                : userExists && showPasswordResetFields ?
                                    <p><strong>
                                        Please enter your new password below...
                                    </strong></p>
                                    : usernameSet ?
                                        <p><strong>
                                            We've not met before, please choose a password...
                                        </strong></p>
                                        :
                                        <p><strong>
                                            We've not met before, please choose a password...
                                        </strong></p>
                        }
                        <form autoComplete={'off'}>
                            <input type="email"
                                   style={{marginBottom: '10px'}}
                                   className="customer-email form-control"
                                   placeholder="Email address"
                                   id="customer-email"
                                   value={currentEmail}
                                   name='customer-email'
                                   onInput={handleSetResetState}
                                   onChange={buildAndCheckEmail}
                                   disabled={showResetPassword}
                            />
                        </form>
                        {(usernameSet || (!userExists && !showPasswordResetFields)) &&
                        <p><em>You can use a new email address to register, or one that you've registered to login.</em>
                        </p>
                        }
                        {showResetPassword && !invalidCode && !showPasswordResetFields &&
                        <div>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       style={{marginBottom: '10px'}}
                                       className="customer-email form-control"
                                       placeholder="6-digit security code"
                                       id="security-code"
                                       value={resetCode}
                                       name='security-code'
                                       onInput={resetCodeHandler}
                                       onKeyUp={checkCodeHandler}

                                />
                            </form>
                            <Alert key={'idx'} variant={'success'} style={{
                                fontSize: '0.8em', textAlign: 'center',
                                paddingTop: '7px', paddingBottom: '7px'
                            }}>
                                We just emailed <strong>@{resetUsername}</strong> with a security code;
                                it may take a minute or two to get there.
                            </Alert>
                        </div>
                        }
                        {showPasswordResetFields &&
                        <div>
                            <input type="password"
                                   className="customer-password form-control"
                                   placeholder="Enter Password"
                                   id="customer-password"
                                   name='customer-password'
                                   value={currentPassword}
                                   style={{marginBottom: '10px'}}
                                   onChange={buildCheckPassword}
                                   onKeyUp={confirmPassword}
                            />
                            <input type="password"
                                   className="confirm-password form-control"
                                   placeholder="Confirm Password"
                                   id="confirm-password"
                                   style={{marginBottom: '14px'}}
                                   name='confirm-password'
                                   onKeyUp={confirmPasswords}
                            />
                            {printErrors()}
                            {successAlert && !currentPassword &&
                            <Alert key={'id2x'} style={{fontSize: '0.9em', textAlign: 'center'}} variant={'success'}>
                                <strong>Success</strong> please enter in your new password above.
                            </Alert>
                            }
                        </div>
                        }
                        {invalidCode &&
                        <Alert key={'id2x'} style={{fontSize: '0.9em', textAlign: 'center'}} variant={'danger'}>
                            Sorry but that wasn't the same code that we sent you; you'll need to try that again!
                        </Alert>
                        }
                        {userExists && emailValid && !showResetPassword ?
                            <>
                                <input type="password"
                                       style={{marginBottom: '10px'}}
                                       className="customer-email form-control"
                                       placeholder="Password"
                                       id="customer-password"
                                       value={currentPassword}
                                       name='customer-password'
                                       onChange={buildCheckPassword}
                                       onKeyUp={confirmPassword}
                                />
                                <p style={{
                                    textAlign: 'right', fontSize: '0.9em',
                                    textDecoration: 'underline', cursor: 'pointer'
                                }} onClick={resetPasswordHandler}>Reset password!</p>
                                {errorMessage &&
                                <Alert key={'id2x6'} style={{fontSize: '0.9em', textAlign: 'center'}}
                                       variant={'danger'}>
                                    {errorMessage}
                                </Alert>
                                }
                            </>
                            : !userExists && emailValid &&
                            <>
                                <p style={{
                                    marginTop: '30px',
                                    fontWeight: 'bold'
                                }}>
                                    Hi, we've not met. Let's get your account setup real quick.
                                </p>
                                <p>Type in a safe password that you can use to login to your account.</p>
                                <input type="password"
                                       className="customer-password form-control"
                                       placeholder="Enter Password"
                                       id="customer-password"
                                       name='customer-password'
                                       value={currentPassword}
                                       style={{marginBottom: '10px'}}
                                       onChange={buildCheckPassword}
                                       onKeyUp={confirmPassword}
                                />
                                {showConfirmPassword &&
                                <input type="password"
                                       className="confirm-password form-control"
                                       placeholder="Confirm Password"
                                       id="confirm-password"
                                       style={{marginBottom: '14px'}}
                                       name='confirm-password'
                                       onKeyUp={confirmPasswords}
                                />
                                }
                                {printErrors()}
                            </>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {userExists && !showResetPassword ?
                            <Button onClick={authSubmitHandler}
                                    className={'user-button'}
                                    text={'LOGIN'}
                                    key={'login-' + auth.username}
                                    usedKey={'login-'}
                                    keyMode={auth.username}
                                    controlButtonColor={controlButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    navButtonColor={navButtonColor}
                                    solid={true}
                                    categoryButton={true}
                                    sidebarButton={true}
                                    active={true}
                                    icon={null}/>
                            : showResetPassword ?
                                <Button onClick={updatePasswordHandler}
                                        className={'user-button'}
                                        text={'RESET PASSWORD'}
                                        key={'reset-' + auth.username}
                                        usedKey={'reset-'}
                                        keyMode={auth.username}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        solid={true}
                                        categoryButton={true}
                                        sidebarButton={true}
                                        active={true}
                                        disabled={!passwordsMatch}
                                        icon={null}/>
                                : !userExists ?
                                    <Button onClick={selectUsernameHandler}
                                            className={'user-button'}
                                            text={'REGISTER'}
                                            key={'reset-' + passwordsMatch}
                                            usedKey={'register-'}
                                            keyMode={passwordsMatch}
                                            controlButtonColor={controlButtonColor}
                                            navButtonColor={navButtonColor}
                                            priceButtonColor={priceButtonColor}
                                            solid={true}
                                            categoryButton={true}
                                            sidebarButton={true}
                                            active={true}
                                            disabled={!passwordsMatch}
                                            icon={null}/>
                                    :
                                    <Button onClick={authSubmitHandler}
                                            className={'user-button'}
                                            text={'CHECK EMAIL'}
                                            key={'reset-' + auth.username}
                                            usedKey={'register-'}
                                            keyMode={auth.username}
                                            controlButtonColor={controlButtonColor}
                                            navButtonColor={navButtonColor}
                                            priceButtonColor={priceButtonColor}
                                            solid={true}
                                            categoryButton={true}
                                            active={true}
                                            disabled={true}
                                            sidebarButton={true}
                                            icon={null}/>
                        }

                    </ModalFooter>
                </> :
                <>
                    <ModalHeader closeButton>

                        <ModalTitle>Complete Registration</ModalTitle>

                    </ModalHeader>
                    <ModalBody>
                        <img alt={'c4rt.uk'}
                             src={'https://c4rt-photos.s3.amazonaws.com/d61ce680-42ee-11eb-8d08-4c4e9fa1ab3d.jpg'}
                             style={{
                                 width: '90px', height: 'auto', display: 'block', float: 'right',
                                 marginBottom: '28px', marginLeft: '30px'
                             }}
                        />
                        <p><strong>Select a good username for your c4rt</strong></p>
                        <p>
                            This is how you tell people
                            about your c4rt so make it easy to remember.
                        </p>
                        <form autoComplete={'off'}>
                            <input type="text"
                                   className="customer-email form-control"
                                   placeholder="Username (how people find you)"
                                   id="username-signup"
                                   value={currentUsername}
                                   name='username-signup'
                                   onChange={buildAndCheckUsername}
                                   style={{marginBottom: '14px'}}
                            />
                        </form>
                        {showTerms &&
                        <div style={{
                            maxHeight: '200px', border: 'solid 1px #e2e2e2', borderRadius: '5px 5px 5px 5px',
                            marginTop: '20px', marginBottom: '20px', padding: '25px'
                        }}
                             className={'terms-scroll'}
                        >
                            {!showPrivacyMode ?
                                <TermsConditions/>
                                :
                                <PrivacyPolicy/>
                            }

                        </div>
                        }
                        <p style={{
                            textAlign: 'right', fontSize: '0.9em'
                        }}><input type="checkbox"
                                  id="terms-agreed"
                                  value={termsAgreed}
                                  name='username-signup'
                                  onChange={handleAgreeTerms}
                                  style={{
                                      height: '15px', width: '15px', paddingTop: ' 7px',
                                      cursor: 'pointer', marginLeft: '20px', float: 'right',
                                      marginRight: '20px', marginTop: '3px', marginBottom: '30px'
                                  }}
                        />I agree to the c4rt.uk <span style={{
                            cursor: 'pointer', textDecoration: 'underline', color: getDangerColor()
                        }}
                                                       onClick={handleShowTerms}>
                            Terms & Conditions
                        </span> and <span style={{
                            cursor: 'pointer', textDecoration: 'underline', color: getDangerColor()
                        }}
                                          onClick={handleShowPrivacy}>
                            Privacy Policy
                        </span></p>
                        {usernameError &&
                        <Alert key={'id2x1'} variant={'danger'}
                               style={{
                                   textAlign: 'center',
                                   fontSize: '0.8em', paddingTop: '7px', paddingBottom: '7px'
                               }}
                        >
                            {usernameError}
                        </Alert>
                        }
                        {usernameSuccess && !usernameError &&
                        <Alert key={'id2x2'} variant={'success'}
                               style={{
                                   textAlign: 'center',
                                   fontSize: '0.8em', paddingTop: '7px', paddingBottom: '7px'
                               }}
                        >
                            {usernameSuccess}
                        </Alert>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={authSubmitHandler}
                                className={'user-button'}
                                text={'SELECT USERNAME'}
                                key={'reset-' + currentUsername.length + termsAgreed}
                                usedKey={'register-'}
                                keyMode={currentUsername.length + termsAgreed}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                solid={true}
                                sidebarButton={true}
                                categoryButton={true}
                                active={true}
                                disabled={userExists || !termsAgreed || currentUsername.length < 4}
                                icon={null}/>
                    </ModalFooter>
                </>

            }


        </>

    )
}
;

export default Authentication;
