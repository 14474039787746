import React, {useContext} from "react";

import CheckoutForm from './CheckoutForm'
import { Elements } from "@stripe/react-stripe-js";
import AuthContext from "../shared/auth-context";

const UserPay = props => {
    const auth = useContext(AuthContext)
    const { publicKey } = props
    // eslint-disable-next-line
    const paymentSettingsValidHandler =() => {
        if(auth.stripeSettings.devMode){
            if(publicKey === 'Test public key' || 'Live public key'){
                return false
            } if(publicKey.split('_')[1] === 'test' &&
                publicKey.split('_')[0] === 'pk'
            ){
                return true
            } else {
                return false
            }
        } else {
            if(publicKey.split('')[1] === 'live' &&
                publicKey.split('')[0] === 'pk'
            ){
                return false
            }
        }
    }
    return (auth.createdPromise &&
            <Elements stripe={auth.createdPromise}>
                <CheckoutForm
                    publicKey={props.publicKey}
                    orderId={props.orderId}
                    handleNewOrderSuccess={props.handleNewOrderSuccess}
                    handleCloseBasket={props.handleCloseBasket}
                    errorMessageHandler={props.errorMessageHandler}
                    setLoading={props.setLoading}
                />
            </Elements>
    );
};

export default UserPay;
