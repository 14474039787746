import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusSquare} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../shared/auth-context";
import ImageOptionUpload from "../../shared/ImageOptionUpload";

const AdminPricingTable = props => {
    const auth = useContext(AuthContext)
    const { updateProduct, amount, index, price, quantities,
        productId, productOptionsHandlerRemove, priceOptions } = props

    const setData = async (event) => {
        let type = event.target.name,
            value = event.target.value;
        let newOptions = priceOptions;
        for(let i=0 ; i<priceOptions.length ; i++){
            if(props.row.id === priceOptions[i]._id){
                if(type === 'price'){
                    newOptions[index].price = value
                    newOptions[index].amount = amount
                    newOptions[index].quantities = quantities
                } if(type==='amount'){
                    newOptions[index].price = price
                    newOptions[index].amount = value
                    newOptions[index].quantities = quantities
                } if(type === 'quantities'){
                    newOptions[index].price = price
                    newOptions[index].amount = amount
                    newOptions[index].quantities = value
                }
            }
        }
        newOptions[index].productId = productId
        await props.setPriceOptions(newOptions)
    }
    const updatePricingProduct = async () => {
        await updateProduct()
        await props.reloadCart()
    }

    const removePricingOptionsHandler = async () => {
        await productOptionsHandlerRemove(index, productId)
    }
    return (
                <tr id={'options-' + productId + '-' + index}
                    style={{  paddingRight: '14px',
                        width: '100%'}}
                >
                    <td style={{width:'50px', paddingLeft: '14px'}}>
                        <ImageOptionUpload productName={props.productName}
                                           quantities={props.quantities}
                                           catId={props.categoryId}
                                           amount={props.amount}
                                           image={props.image}
                                           status={props.status}
                                           price={price}
                                           row={props.row}
                                           index={props.index}
                                           product={props.product}
                                           productId={props.row.productId}
                                           changeImage={props.changeImage}
                                           reloadCart={props.reloadCart}
                                           setPriceOptions={props.setPriceOptions}

                        />
                    </td>
                    <td style={{width: '105px'}}>
                        <span style={{marginLeft: '14px'}}>
                        {auth.appSettings.shop.currencySymbol}
                        </span>
                        <input type="text"
                               className="product-info-inputs admin-input"
                               style={{width: '50px', border: 'none'}}
                               placeholder={price}
                               id={index + '-' + productId + '-priceId'}
                               name='price'
                               onInput={setData}
                               onBlur={updatePricingProduct}
                        />
                    </td>
                    <td>
                        / <input type="text"
                                 className="product-info-inputs admin-input"
                                 style={{width: '50px', border: 'none'}}
                                 placeholder={quantities}
                                 id={index + '-' +  + productId + '-quantitiesId'}
                                 name='quantities'
                                 onInput={setData}
                                 onBlur={updatePricingProduct}
                    />
                    </td>
                    <td>
                        {auth.appSettings.shop.manageStockLevels ?
                        <>
                            <span style={{fontSize: '0.7em', marginLeft: '6px'}} >Qty: </span> <input type="text"
                                     className="product-info-inputs admin-input"
                                     style={{width: '40px', border: 'none'}}
                                     placeholder={amount}
                                     id={index + '-' + productId + '-amountId'}
                                     name='amount'
                                     onInput={setData}
                                     onBlur={updatePricingProduct}
                                     disabled={true}
                        />
                        </> : <div style={{width: '60px'}} />
                        }
                    </td>
                    <td style={{  paddingRight: '14px'}}>
                            <FontAwesomeIcon onClick={removePricingOptionsHandler}
                                             style={{cursor: 'pointer', marginRight: '5px', color: '#f8c2ce'}}
                                             icon={faMinusSquare}/>
                    </td>
                </tr>
        );

}

export default AdminPricingTable;
