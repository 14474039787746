import React, {useContext} from 'react';
import Button from "../../shared/Button";
import '../../../css/Button.css'
import {
    faBullhorn,
    faClock,
    faCogs,
    faComment,
    faCommentDots,
    faRocket,
    faSignOutAlt,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../shared/auth-context";

const SettingsNav = props => {
    const auth = useContext(AuthContext)
    let {
        handleShowShopSettings, handleShowEmailSettings, handleShowSupportTickets,
        handleShowInvoiceSettings, handleShowOpeningHours, showEmailSettings,
        showInvoiceSettings, showShopSettings, showOpeningHours,
        controlButtonColor, navButtonColor, handleShowGoLive, priceButtonColor,
        showSupportTickets, loading
    } = props
    const logUserOut = async () => {
        await auth.logout()
        if (!!auth.customerToken) {
            await auth.customerLogout()
        }
    }
    const buttonColor = (type) => {
        switch(type){
            case 'my-account':
                return showInvoiceSettings ? 'dark-blue' : 'grey'
            case 'support':
                return showSupportTickets ? 'dark-blue' : 'grey'
            case 'settings':
                return showShopSettings && !showOpeningHours ? 'dark-blue' : 'grey'
            case 'hours':
                return showOpeningHours && showShopSettings ? 'dark-blue' : 'grey'
            case 'logout':
                return 'purple'
            case 'live':
                return 'pink'
            case 'marketing':
                return showEmailSettings ? 'dark-blue' : 'grey'
            default:
                return ''

        }
    }

    return (

        <nav className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12
                category-navigation navbar-light admin-nav-toggle" style={{
            marginTop:'0', backgroundColor: props.cartNavColor}}>
            <ul style={{paddingBottom: '0'}} className="navbar-nav ml-auto">
                <li className="nav-item nav-controls ">
                    <Button onClick={handleShowInvoiceSettings}
                            className={'admin-nav-toggle nav-button ' +
                            buttonColor('my-account')}
                            style={{fontSize:'1.1em', marginLeft:'0'}}
                            text={'ACCOUNT'}
                            icon={faUser}/>
                    <Button onClick={handleShowEmailSettings}
                            className={'admin-nav-toggle nav-button ' +
                            buttonColor('marketing')}
                            style={{fontSize:'1.1em'}}
                            text={'MARKETING'}
                            icon={faBullhorn}/>
                    <Button onClick={handleShowShopSettings}
                            className={'admin-nav-toggle nav-button ' +
                            buttonColor('settings')}
                            style={{fontSize:'1.1em', marginLeft:'0'}}
                            text={'SETTINGS'}
                            icon={faCogs}/>
                    {auth.appSettings.shop.closeShop &&
                    <Button onClick={handleShowOpeningHours}
                            className={'admin-nav-toggle nav-button ' +
                            buttonColor('hours')}
                            style={{fontSize:'1.1em'}}
                            text={'HOURS'}
                            icon={faClock}/>
                    }
                    <Button onClick={handleShowSupportTickets}
                            className={'admin-nav-toggle nav-button ' +
                            buttonColor('support')}
                            style={{fontSize:'1.1em'}}
                            key={'store-support'}
                            keyUsed={'store'}
                            keyMode={'-support'}
                            text={'SUPPORT'}
                            icon={!loading ? faComment : faCommentDots}/>
                    {auth.isLoggedIn && !props.showAdminMode &&
                    <Button onClick={logUserOut}
                            className={'admin-nav-toggle user-button'}
                            key={'logout-store'}
                            keyUsed={'logout'}
                            keyMode={'-store'}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            text={'LOGOUT'}
                            solid={true}
                            active={false}
                            categoryButton={true}
                            float={'right'}
                            icon={faSignOutAlt}/>
                    }
                    {auth.isLoggedIn && !props.showAdminMode && !auth.appSettings.shop.storeIsLive &&
                    <Button onClick={handleShowGoLive}
                            className={'admin-nav-toggle user-button'}
                            key={'launch-store-go'}
                            keyUsed={'launch'}
                            keyMode={'-store-go'}
                            controlButtonColor={controlButtonColor} // switched colours
                            navButtonColor={navButtonColor} // switched colours
                            text={'LAUNCH'}
                            solid={true}
                            active={true}
                            categoryButton={true}
                            float={'right'}
                            icon={faRocket}/>
                    }
                </li>
            </ul>
        </nav>

        // <nav className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12
        //         category-navigation navbar-light admin-nav-toggle" style={{
        //     backgroundColor: props.cartNavColor
        // }}>
        //     <ul style={{paddingBottom: '10px', width:'100%'}} className="navbar-nav ml-auto">
        //         <li className="nav-item nav-controls ">
        //                 <Button onClick={props.handleShowGoLive}
        //                         className={'admin-nav-toggle red user-button'}
        //                         text={''}
        //                         float={'right'}
        //                         style={{float:'right'}}
        //                         icon={}/>
        //         </li>
        //     </ul>
        // </nav>


    );
}

export default SettingsNav;
