import {createContext} from 'react';

const AuthContext = createContext({
    appSettings: '', cartOrder: '', setCartOrder: '', cartInvoices: '', setCartInvoices: '',
    customer: '', orderJustCompleted:'', setOrderJustCompleted:'', userEmail:'', setUserEmail: '',
    setCustomer: '', devMode: '', setDevMode: '', cartStats: '', setCartStats: '',
    setAppSettings: '', customersOrders: '', setCustomersOrders: '', hasFeatured: '', setHasFeatured:'',
    isLoggedIn: '', orderComplete: '', setOrderComplete: '',
    username: '', stripeSettings: '', setStripeSettings: '', setUserName: '',
    isLoading: '', setIsLoading: '', userToken: '', setUserToken: '', setIsLoggedIn:'',
    shopClosed: '', setShopClosed: '', userTickets:'', setUserTickets: '', userMessages:'', setUserMessages: '',
    categoryId: '', gotCartId: '', setGotCartId: '', shopDayOpen: '', setShopDayOpen: '',
    navButtonColor: '', setNavButtonColor: '', priceButtonColor: '', setPriceButtonColor: '',
    controlButtonColor: '', setControlButtonColor: '',
    receivedToken:'', customerToken:'',  formIsValid:'',  detailsConfirmed:'',  firstnameValid:'',
    lastnameValid:'',  address1Valid:'',  address2Valid:'',  countryValid:'',  postcodeValid:'',

    customerId:'',  customerFirstname:'',  customerLastname:'',  customerCountry:'',
    customerAddressOne:'',  customerAddressTwo:'',  customerPostcode:'',
    customerPhone:'',  customerInstructions:'',  customerIsLoggedIn:'',
    customerEmail:'',  setCustomerEmail:'',  setCustomerAddressOne:'',
    setCustomerAddressTwo:'',  setCustomerFirstname:'',  setCustomerLastname:'',
    setCustomerPostcode:'',  setCustomerInstructions:'',  setCustomerPhone:'',
    setFormIsValid:'',  setDetailsConfirmed:'',  setFirstnameValid:'',
    setLastnameValid:'',  setAddress1Valid:'',  setPostcodeValid:'',  setCustomerCountry:'',
    finalDistance: '', setFinalDistance: '', totalCharges: '', setTotalCharges: '',
    deliveryCharges: '', setDeliveryCharges: '', cartsId: '', setCartsId: '',
    orderNumber: '', setOrderNumber: '', orderId: '', setOrderId: '', publicKey: '', setPublicKey: '',
    createdPromise: '', setCreatedPromise: '', setCartId: '', cartId: '', subscriptionPromise: '', setSubscriptionPromise: '',
    userStripeId: '', setUserStripeId: '',
    login: () => {},
    logout: () => {},
    customerLogin: () => {},
    customerLogout: () => {}
});

export default AuthContext;
