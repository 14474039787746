import React, {useContext} from 'react';
import Button from "../../../shared/Button";
import '../../../../css/Button.css'
import {faBullhorn, faClock, faCogs, faUser} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../../shared/auth-context";

const SettingsSidedrawerNav = props => {
    let { showInvoiceSettings, showEmailSettings, handleShowInvoiceSettings,
        handleShowEmailSettings, handleShowShopSettings, showShopSettings,
        handleShowOpeningHours, showOpeningHours, controlButtonColor, priceButtonColor,
        navButtonColor } = props
    const auth = useContext(AuthContext)
    const buttonColor = (type) => {
        switch (type) {
            case 'my-account':
                return showInvoiceSettings ? 'dark-blue' : 'grey'
            case 'marketing':
                return showEmailSettings ? 'dark-blue' : 'grey'
            case 'settings':
                return showShopSettings ? 'dark-blue' : 'grey'
            case 'hours':
                return showOpeningHours ? 'dark-blue' : 'grey'
            case 'logout':
                return controlButtonColor
            case 'live':
                return navButtonColor
            default:
                return ''

        }
    }

        return (
            <>
                <div style={{display: 'block', textAlign: 'center'}}>
                    <Button onClick={handleShowInvoiceSettings}
                            className={'sidebar-button ' +
                            buttonColor('my-account')}
                            key={'my-account-sidebar'}
                            usedKey={'my-account'}
                            keyMode={'-sidebar'}
                            style={{fontSize: '1.1em', marginLeft: '0'}}
                            sidebarButton={true}
                            solid={false}
                            text={'ACCOUNT'}
                            icon={faUser}/>
                    <Button onClick={handleShowEmailSettings}
                            className={'sidebar-button ' +
                            buttonColor('marketing')}
                            key={'marketing-sidebar'}
                            usedKey={'marketing'}
                            keyMode={'-sidebar'}
                            style={{fontSize: '1.1em', marginLeft: '0'}}
                            sidebarButton={true}
                            solid={false}
                            text={'MARKETING'}
                            icon={faBullhorn}/>
                    <Button onClick={handleShowShopSettings}
                            className={'sidebar-button ' +
                            buttonColor('settings')}
                            key={'settings-sidebar'}
                            usedKey={'settings'}
                            keyMode={'-sidebar'}
                            style={{fontSize: '1.1em', marginLeft: '0'}}
                            sidebarButton={true}
                            solid={false}
                            text={'SETTINGS'}
                            icon={faCogs}/>
                    {auth.appSettings.shop.closeShop &&
                    <Button onClick={handleShowOpeningHours}
                            className={'sidebar-button ' +
                            buttonColor('hours')}
                            key={'hours-sidebar'}
                            usedKey={'hours'}
                            keyMode={'-sidebar'}
                            style={{fontSize: '1.1em', marginLeft: '0'}}
                            sidebarButton={true}
                            solid={false}
                            text={'HOURS'}
                            icon={faClock}/>
                    }
                    <Button onClick={auth.logout}
                            className={'sidebar-button'}
                            text={'LOGOUT'}
                            key={'customer-logout'}
                            keyUsed={'customer-'}
                            keyMode={'logout'}
                            sidebarButton={true}
                            solid={false}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            active={false}
                            icon={faUser}/>
                </div>
            </>
        );
}

export default SettingsSidedrawerNav;
