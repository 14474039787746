import React, {useContext} from 'react';

import '../../../css/AdminProduct.css';
import AdminProductItem from "./AdminProductItem";
import AuthContext from "../../shared/auth-context";


const AdminProduct = props => {
    const auth = useContext(AuthContext)
    let {priceOptions, shopBackground} = props
    return (
        <>
            {props.categoryId === props.catId && auth.appSettings &&
                    <AdminProductItem
                        shopBackground={shopBackground}
                        productId={props.productId}
                        productName={props.productName}
                        price={props.price}
                        quantities={props.quantities}
                        amount={props.amount}
                        image={props.image}
                        categories={props.categories}
                        categoryId={props.categoryId}
                        status={props.status}
                        handleNewProduct={props.handleNewProduct}
                        reloadCart={props.reloadCart}
                        products={props.products}
                        taxRate={props.taxRate}
                        displayCategory={props.displayCategory}
                        setDisplayCategory={props.setDisplayCategory}
                        categoryIndex={props.categoryIndex}
                        product={props.product}
                        priceOptions={priceOptions}
                        getSettings={props.getSettings}
                    />
            }
        </>
    );
}

export default AdminProduct;
