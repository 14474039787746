import React, {useContext } from 'react';
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Modal from "react-bootstrap/Modal";
import Button from "../shared/Button";
import AuthContext from "./auth-context";
import '../../css/ContactModal.css'
import SupportTicketBody from "./SupportTicketBody";
const ContactModal = props => {
    const auth = useContext(AuthContext)
    const {
            contactForm, controlButtonColor, navButtonColor,
            priceButtonColor, handleShowContactForm, collectInputData,
            openTicket, messageSuccess, messageSuccessCode,
        } = props

    return (
        <>
            <Modal id={'contact-modal'} show={contactForm} onHide={handleShowContactForm}>
                <ModalHeader closeButton>
                    <ModalTitle>CONTACT C4RT LTD</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className={'container'} style={{marginTop:'0', paddingTop:'0'}}>
                        <div className={'row'} style={{marginTop:'0', paddingTop:'0'}}>
                            <SupportTicketBody key={'support-ticket-body'}
                                               messageSuccess={messageSuccess}
                                               collectInputData={collectInputData}
                                               messageSuccessCode={messageSuccessCode}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleShowContactForm}
                            className={'user-button'}
                            text={'CLOSE'}
                            key={'close-' + auth.username}
                            usedKey={'close-'}
                            keyMode={auth.username}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            solid={true}
                            float={'left'}
                            categoryButton={true}
                            sidebarButton={true}
                            active={false}
                            icon={null}/>
                    {!messageSuccess &&
                    <Button onClick={openTicket}
                            className={'user-button'}
                            text={'SEND MESSAGE'}
                            key={'close-' + auth.username}
                            usedKey={'close-'}
                            keyMode={auth.username}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            solid={true}
                            categoryButton={true}
                            sidebarButton={true}
                            active={true}
                            icon={null}/>
                    }
                </ModalFooter>
            </Modal>
        </>
    );

}

export default ContactModal;
