import React from 'react';
import EmailSettingsKey from "./EmailSettingsKey";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faAngleUp} from "@fortawesome/free-solid-svg-icons";

const WelcomeEmail = props => {

    return (
        <div>
            <p>
                This email is the first email that is sent to a new customer and
                is essentially just to thank them for creating an account.
            </p>
            <input type="text"
                   className="form-control"
                   placeholder="Welcome to our Store"
                   id="welcome-email-title"
                   name='welcome-email-title'
                   value={props.data.welcomeMessageTitle}
                   onInput={props.handleSetData}
                   onBlur={props.updateMessage}
                   aria-label={'Welcome email title'}
            />
            <p style={{cursor: 'pointer', float: 'right', paddingTop: '14px', fontSize: '0.8em',
                width: '100%', display: 'block', marginBottom: '0', paddingBottom: '0'}}
               onClick={props.handleShowInfo}>
                <em>{!props.showInfo ?
                    <p>
                        <FontAwesomeIcon icon={faAddressCard} /> See available personalisation settings
                    </p> :
                    <p>
                        <FontAwesomeIcon icon={faAddressCard} /> Hide settings <FontAwesomeIcon icon={faAngleUp} />
                    </p>}
                </em>
            </p>
            {props.showInfo &&
            <EmailSettingsKey showWelcome={props.showWelcome}/>
            }
            <textarea
                style={{minHeight: '300px', marginTop: '10px'}}
                className="form-control body-area"
                placeholder="Welcome email message body"
                id="welcome-email-message"
                name='welcome-email-message'
                value={props.data.welcomeMessageBody}
                onInput={props.handleSetData}
                onBlur={props.updateMessage}
                aria-label={'Welcome email message body'}
            />

        </div>
    );

}

export default WelcomeEmail;
