import React, {useContext} from 'react';
import AuthContext from "../../shared/auth-context";


const EmailSettingsKey = props => {
    let auth = useContext(AuthContext)
    return (
        <div className="container">
            <div className="row" style={{height: '30px', marginTop: '10px'}}>
                <div className="col">
                    <p>[first-name]</p>
                </div>
                <div className="col-8">
                    <p>Print the customers first name</p>
                </div>
            </div>
            <hr />
            {!props.showWelcome &&
            <>
                <div className="row" style={{height: '30px', marginTop: '0'}}>
                    <div className="col">
                        <p>[order-number]</p>
                    </div>
                    <div className="col-8">
                        <p>Print the order number</p>
                    </div>
                </div>
                <div className="row" style={{height: '30px', marginTop: '0'}}>
                    <div className="col">
                        <p>[order-items]</p>
                    </div>
                    <div className="col-8">
                        <p>Print a table of items</p>
                    </div>
                </div>
                <hr />
            </>
            }
            <div className="row" style={{height: '30px', marginTop: '0'}}>
                <div className="col">
                    <p>[store-link]</p>
                </div>
                <div className="col-8">
                    <p>Prints "@{auth.username}" <strong>as a link</strong> to your cart</p>
                </div>
            </div>
            {!props.showWelcome &&
            <>
                <hr/>
                <div className="row" style={{marginTop: '0'}}>
                    <div className="col">
                        <p>[fulfilment-option]</p>
                    </div>
                    <div className="col-8">
                        <p>Set dynamic text based on whether your customer is
                            collecting or expecting a delivery.</p>
                    </div>
                </div>
            </>
            }
        </div>
    );
}

export default EmailSettingsKey;
