import React from 'react';
import EmailSettingsKey from "./EmailSettingsKey";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp, faCogs} from "@fortawesome/free-solid-svg-icons";

const OrderCompletedEmail = props => {

        return (
            <div>
                <p>
                    When you complete an order in the order manager (the order was collected
                    or delivered) the customer will be sent this email.
                </p>
                <input type="text"
                       className="form-control"
                       placeholder="Order complete email title"
                       id="order-complete-email-title"
                       name='order-complete-email-title'
                       value={props.data.completedMessageTitle}
                       onInput={props.handleSetData}
                       onBlur={props.updateMessage}
                       aria-label={'Order complete email title'}
                />
                <p style={{cursor: 'pointer', float: 'right', paddingTop: '14px', fontSize: '0.8em',
                    width: '100%', display: 'block', marginBottom: '0', paddingBottom: '0'}}
                   onClick={props.handleShowInfo}>
                    <em>{!props.showInfo ?
                        <p>
                            <FontAwesomeIcon icon={faCogs} /> See available personalisation settings
                        </p> :
                        <p>
                            <FontAwesomeIcon icon={faCogs} /> Hide settings <FontAwesomeIcon icon={faAngleUp} />
                        </p>}
                    </em>
                </p>
                {props.showInfo &&
                <>
                    <EmailSettingsKey showCompleted={props.showCompleted}/>
                    <div className="container">
                        <div className="row" style={{marginTop: '0px'}}>
                            <div className="col" style={{paddingTop: '10px'}}>
                                <p><strong>Collection text:</strong></p>
                            </div>
                            <div className="col-8">
                            <textarea className="form-control"
                                      placeholder="Collection instructions"
                                      id="order-complete-collection"
                                      name='order-complete-collection'
                                      value={props.data.completedCollectionMessage}
                                      onInput={props.handleSetData}
                                      onBlur={props.updateMessage}
                                      aria-label={'Order complete collection instructions'}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col" style={{paddingTop: '0'}}>
                                <p><strong>Delivery text:</strong></p>
                            </div>
                            <div className="col-8">
                            <textarea className="form-control"
                                      placeholder="Delivery instructions"
                                      id="order-complete-delivery"
                                      name='order-complete-delivery'
                                      value={props.data.completedDeliveryMessage}
                                      onInput={props.handleSetData}
                                      onBlur={props.updateMessage}
                                      aria-label={'Order complete delivery instructions'}
                            />
                            </div>
                        </div>
                    </div>
                </>
                }
                <textarea style={{minHeight: '300px'}}
                          className="form-control"
                          placeholder="Order complete email message body"
                          id="order-complete-email-message"
                          name='order-complete-email-message'
                          value={props.data.completedMessageBody}
                          onInput={props.handleSetData}
                          onBlur={props.updateMessage}
                          aria-label={'Order complete email message body'}
                />
            </div>
        );

}

export default OrderCompletedEmail;
