import React, {useContext, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import AuthContext from "./auth-context";
import jwtToken from "jsonwebtoken";
import axios from "axios";
import Button from "./Button";
import {
    faComment,
    faCommentDots,
    faCommentSlash,
} from "@fortawesome/free-solid-svg-icons";
import SupportModalBody from "./SupportModalBody";

const SupportModal = (props) => {
    const auth = useContext(AuthContext)
    const [ replyBody, setReplyBody ] = useState(null)
    // eslint-disable-next-line
    const [ agentName, setAgentName ] = useState('Vil Pietersen')
    let { showMessage, handleShowMessage, ticketId, replyMode,
        handleReplyMode, setReplyMode, key, keyMode, controlButtonColor,
        navButtonColor
    } = props

    const buildReplyBody = async event => {
        let reply = event.target.value
        await setReplyBody(reply)
    }

    const getTicketInfo = () => {
        for(let i=0 ; i<auth.userTickets.length ; i++){
            if(ticketId === auth.userTickets[i]._id){
                return auth.userTickets[i].title
            }
        }
    }
    const submitReply = async () => {
        let { invoice } = auth.appSettings.invoicing
        let {firstname, lastname } = invoice
        let name = auth.username === 'support' ? agentName
            :
            firstname + ' ' + lastname
        if(!!replyBody){
            try {
                let token;
                try{
                    token = await jwtToken.sign(
                        {
                            userEmail: auth.userEmail, ticketId, name, body: replyBody
                        },
                        process.env.REACT_APP_JWT_KEY,
                        {
                            expiresIn: '1h'
                        }
                    );
                }catch(err){console.log(err)}
                await axios.post(`${process.env.REACT_APP_SITE_URL}/api/ticket/reply`, {
                }, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(async response => {
                        await auth.setUserTickets(response.data.tickets)
                        await auth.setUserMessages(response.data.messages)
                        console.log(response.data.tickets)
                        console.log(response.data.messages)
                        setReplyMode(false)
                        setReplyBody(null)
                        // if(auth.username === 'support'){
                        //     setShowMessage(false)
                        // }
                    })
                    .catch(error => {

                    });
            } catch (error) {
                // setIsLoading(false);
                console.log('ERROR: ' + error)
            }
        }
    }

    const closeTicket = async () => {
        let { invoice } = auth.appSettings.invoicing
        let {firstname, lastname } = invoice
        let name = auth.username === 'support' ? agentName
            :
            firstname + ' ' + lastname
        try {
            let token;
            try{
                token = await jwtToken.sign(
                    {
                        userEmail: auth.userEmail, ticketId, name, body: replyBody
                    },
                    process.env.REACT_APP_JWT_KEY,
                    {
                        expiresIn: '1h'
                    }
                );
            }catch(err){console.log(err)}
            await axios.post(`${process.env.REACT_APP_SITE_URL}/api/ticket/toggle`, {
            }, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(async response => {
                    let {findMessages, findTickets, message} = response.data
                    if(message === 'success'){
                        await auth.setUserMessages(findMessages)
                        await auth.setUserTickets(findTickets)
                        handleShowMessage()
                    }
                })
                .catch(error => {

                });
        } catch (error) {
            // setIsLoading(false);
            console.log('ERROR: ' + error)
        }

    }

    const getMessages = () => {
        let messages = []
        for(let i=0 ; i< auth.userMessages.length ; i++) {
            for (let j = 0; j < auth.userMessages[i].length; j++) {
                if (auth.userMessages[i][j].ticketId === ticketId) {
                    messages.push(auth.userMessages[i][j])
                }
            }
        }
        if(messages.length === 0){
            for(let i=0 ; i< auth.userMessages.length ; i++){
                if(auth.userMessages[i].ticketId === ticketId){
                    messages.push(auth.userMessages[i])
                }
            }
        }
        return messages
    }
        return (
            <Modal id={key + keyMode}
                   show={showMessage}
                   onHide={handleShowMessage}>
                <ModalHeader closeButton>
                    <ModalTitle>{getTicketInfo()}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <h5>Ticket ID: #{ticketId}</h5>
                    <hr />
                    {getMessages().map((message, index) => {
                        return (
                            <SupportModalBody key={'support-modal-body'}
                                              message={message}
                                              index={index}
                            />
                        )
                    }
                    )}
                    { replyMode &&
                        <textarea onInput={buildReplyBody}
                                  className={'form-control'}>

                        </textarea>
                    }
                </ModalBody>
                <ModalFooter>
                    <div style={{width:'100%'}}>
                        <Button onClick={closeTicket}
                                className={'admin-nav-toggle ticket-button'}
                                style={{fontSize:'1.1em', marginLeft:'0'}}
                                solid={true}
                                float={'left'}
                                key={'close-ticket' + ticketId}
                                keyUsed={'close-ticket'}
                                keyMode={ticketId}
                                categoryButton={true}
                                disabled={false}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={navButtonColor}
                                active={false}
                                text={'CLOSE TICKET'}
                                icon={faCommentSlash}/>
                        <Button onClick={replyMode && !replyBody ? handleReplyMode : !replyMode ? handleReplyMode : submitReply}
                                className={'admin-nav-toggle ticket-send-button'}
                                style={{fontSize:'1.1em', marginLeft:'0'}}
                                solid={true}
                                float={'right'}
                                categoryButton={true}
                                key={'send-reply-button' + ticketId}
                                keyUsed={'send-reply-button'}
                                keyMode={ticketId}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={navButtonColor}
                                active={false}
                                text={replyMode && !replyBody ? 'CANCEL' : !replyMode ? 'REPLY' : 'SEND'}
                                icon={replyMode && !replyBody ? faCommentDots : !replyMode ? faComment : faComment}/>
                    </div>
                </ModalFooter>
            </Modal>
        );

}

export default SupportModal;
