import React from 'react';
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";
import TermsConditions from "../../../pages/TermsConditions";
import PrivacyPolicy from "../../../pages/cart/PrivacyPolicy";

const renderModal = props => {
    const {onClick, headingText, switchView, privacyMode} = props
    return <>
        {/*eslint-disable-next-line*/}
        <a name={'top'} href={'#'}> </a>
        <ModalHeader closeButton>
            <ModalTitle>{headingText}</ModalTitle>
        </ModalHeader>
        <ModalBody>
            {privacyMode ?
                <PrivacyPolicy />
                :
                <TermsConditions/>
            }
        </ModalBody>
        <ModalFooter>
            <Button variant="primary"
                    onClick={switchView}
                    href={'#top'}
            >
                {privacyMode ? 'View Terms & Conditions' : 'View Privacy Policy' }
            </Button>
            <Button variant="danger"
                    onClick={onClick}>
                CLOSE
            </Button>
        </ModalFooter>
    </>
}


const TermsModal = props => {
    const {showTerms, handleShowTerms, privacyMode, privacyModeHandler} = props
        return (
            <div>
                <Modal size="lg" show={showTerms} onHide={handleShowTerms}>
                    {privacyMode ?
                    renderModal({
                        onClick: handleShowTerms,
                        headingText: 'C4RT LTD Privacy Policy',
                        switchView: privacyModeHandler,
                        privacyMode
                                 })
                                 :
                        renderModal({
                            onClick: handleShowTerms,
                            headingText: 'C4RT LTD Terms & Conditions',
                            switchView: privacyModeHandler,
                            privacyMode
                        })
                    }

                </Modal>
            </div>
        );
}

export default TermsModal;
