import React, {useContext, useRef} from 'react';
import AuthContext from "./auth-context";
import axios from "axios";

const ImageOptionUpload = (props) => {
    const auth = useContext(AuthContext)
    const productId = props.productId
    const filePickerRef = useRef()

    const pickImageHandler = () => {
        filePickerRef.current.click();
    }

    const pickedHandler = async event => {
        event.preventDefault();
        // eslint-disable-next-line
        let pickedFile;
        let fileIsValid;
        if (event.target.files && event.target.files.length === 1){
            pickedFile = event.target.files[0]
            fileIsValid = true
        } else {
            fileIsValid = false
        }
        const data = new FormData()
        if (fileIsValid) {
            data.append('option-image', pickedFile)
            data.append('productId', props.product._id)
            data.append('rowId', props.row.id)
            data.append('amount', props.amount)
            data.append('quantities', props.quantities)
            data.append('price', props.price)
            data.append('productName', props.product.productName)
            try {
                await axios.post(`${process.env.REACT_APP_SITE_URL}/api/upload/option-img-upload`,
                    data,
                    {
                        method: 'POST',
                        headers: {
                            'accept': 'application/json',
                            'Accept-Language': 'en-US,en;q=0.8',
                            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                            'Authorization': 'Bearer ' + auth.userToken
                        }
                    })
                    .then(async res => { // then print response status
                        let newOptions = res.data.product.priceOptions
                        await props.setPriceOptions(newOptions)
                        props.reloadCart().then();
                    })
            } catch (errors) {
                console.log(errors)
            }
        }
    }


    return (
        <>
            <div className={`from-control`} id={'options-' + props.productId + '-' + props.index}>
                <form encType="multipart/form-data" method="post">
                    <input id={productId + '-' + props.index}
                           ref={filePickerRef}
                           style={{display: 'none'}}
                           type={'file'}
                           className="form-control-file"
                           name="option-image"
                           accept={'.jpg, .png, .jpeg'}
                           onChange={pickedHandler}
                    />
                </form>
            </div>
            <div className={`Image admin-image`} style={{cursor: 'pointer'}}>
                <img alt={props.image} style={{width: '50px'}}
                     className="admin-product-image"
                     src={props.row.image} onClick={pickImageHandler}/>
            </div>
        </>
    )

}

export default ImageOptionUpload;
