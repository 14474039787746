import React from 'react';

const CustomerInvoiceRow = props => {
    const invoiceItem = props.item;
    let setTax;
    if (invoiceItem.taxRate === 'Standard Rate' ||
        invoiceItem.taxRate === 20 || invoiceItem.taxRate === '20') {
        setTax = 20;
    } else if (invoiceItem.taxRate === 'Reduced Rate' ||
        invoiceItem.taxRate === 5 || invoiceItem.taxRate === '5') {
        setTax = 5;
    } else if (invoiceItem.taxRate === 'Zero Rate' || invoiceItem.taxRate === 0) {
        setTax = 0;
    }

    return (
        <>
            <tr style={{height: '25px', borderBottom: '1px solid #f1f1f1'}}>
                <td className="col-7 border-0">
                    <img src={props.item.image} alt={''}
                         style={{
                             marginRight: '7px', width: '35px',
                             borderRadius: '5px'
                         }}
                    />
                    <span style={{fontSize: '0.85em'}}><strong>{invoiceItem.productName}</strong></span>
                </td>
                <td className="col-2 text-center border-0">
                    {Number(props.order.totalTax) > 0 && setTax}{Number(props.order.totalTax) > 0 && '%'}</td>
                <td className="col-1 text-center border-0">
                    {props.viewInvoice &&
                        <span >{invoiceItem.value}</span>
                    }
                </td>
                <td className="col-2 text-right border-0">
                    {props.viewInvoice ?
                        <span style={{marginLeft: '40px'}}>
                            {(invoiceItem.value * invoiceItem.price).toFixed(2)}
                        </span>
                        :
                        invoiceItem.value
                    }
                </td>
            </tr>
        </>
    );
}

export default CustomerInvoiceRow;
