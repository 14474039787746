import React, { useContext, useState } from "react";
import axios from "axios";
import AuthContext from "../../shared/auth-context";
import jwtToken from 'jsonwebtoken'

import '../../../css/AdminProduct.css'
import ImageUpload from "../../shared/ImageUpload";
import {faPlusSquare, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AdminPricingTable from "./AdminPricingTable";
import WarningModal from "../../shared/WarningModal";
const siteUrl = `${process.env.REACT_APP_SITE_URL}`

const AdminProductItem = props => {
    const auth = useContext(AuthContext);
    const [ nameValue, setNameValue ] = useState('');
    const [ priceValue, setPriceValue ] = useState(props.price);
    const [ quantitiesValue, setQuantitiesValue ] = useState(props.quantities);
    const [ taxRateValue, setTaxRateValue ] = useState(props.taxRate);
    const [ statusValue, setStatusValue ] = useState(props.status);
    const [ amountValue, setAmountValue ] = useState(props.amount);
    const [ priceOptions, setPriceOptions ] = useState(props.priceOptions)
    const [ warning, setWarning ] = useState(false)
    const [ message, setMessage ] = useState('')

    const handleShowWarningModal = () => {
        setMessage(
            'Are you sure you want to do that? You will no longer have access to this product if you delete it!'
        )
        setWarning(prevState => !prevState)
    }
    const handleDeleteProduct = async () => {
        await deleteProduct()
    }

    const allocateName = event => {
        let userInput = event.target.value
        setNameValue(userInput);
    }
    const allocatePrice = event => {
        let userInput = event.target.value;
        setPriceValue(userInput);
    }
    const allocateQuantities = event => {
        let userInput = event.target.value;
        setQuantitiesValue(userInput);
    }
    const allocateTaxRate = event => {
        let userInput = event.target.value;
        setTaxRateValue(userInput);
    }
    const allocateStatus = event => {
        let userInput = event.target.value;
        setStatusValue(userInput);
    }
    const allocateAmount = event => {
        let userInput = event.target.value;
        setAmountValue(userInput);
    }
    // eslint-disable-next-line
    let submitData;
    const changeImage = event => {
        // eslint-disable-next-line
        submitData = {
            productName: {
                value: props.productName,
                isValid: true
            },
            image: {
                value: siteUrl + 'uploads/images/' + props.productId + '.jpg',
                isValid: true
            },
            price: {
                value: props.price,
                isValid: true
            },
            catId: {
                value: props.categoryId,
                isValid: true
            },
            cartId: {
                value: auth.cartId,
                isValid: true
            },
            quantities: {
                value: event.target.value,
                isValid: true
            },
            status: {
                value: props.status,
                isValid: true
            },
            priceOptions,
            amount: {
                value: props.amount,
                isValid: true
            },
            username: {
                value: auth.username,
                isValid: true
            }
        }
        updateProduct().then(res => {

        })
    }
    const productOptionsHandlerAdd = async () => {
        let newOptions = priceOptions
        await newOptions.push({
            price: 0,
            amount: 0,
            quantities: 'Items',
            productId: props.product._id,
            image: props.image,
        })
        let productsPriceOptions = []
        for(let i=0 ; i<newOptions.length ; i++){
            if(props.productId === props.product._id){
                productsPriceOptions.push(newOptions[i])
            }
        }
        await setPriceOptions(productsPriceOptions)
        await updateProduct()
    }
    const productOptionsHandlerRemove = async (index) => {
        let optionsCopy = priceOptions
        await optionsCopy.splice(index, 1);
        await setPriceOptions(optionsCopy)
        await updateProduct()
    }


    const updateProduct = async (index) => {
        let token, prodName;
        if(nameValue === ''){
            prodName = 'Name Product';
        } else {
            prodName = nameValue
        }
        try{
            token = await jwtToken.sign(
                {
                    productName: {
                        value: prodName,
                        isValid: true
                    },
                    image: {
                        value: props.image,
                        isValid: true
                    },
                    price: {
                        value: priceValue,
                        isValid: true
                    },
                    catId: {
                        value: props.categoryId,
                        isValid: true
                    },
                    cartId: {
                        value: auth.cartId,
                        isValid: true
                    },
                    quantities: {
                        value: quantitiesValue,
                        isValid: true
                    },
                    taxRate: {
                        value: taxRateValue,
                        isValid: true
                    },
                    status: {
                        value: statusValue,
                        isValid: true
                    },
                    amount: {
                        value: amountValue,
                        isValid: true
                    },
                    username: {
                        value: auth.username,
                        isValid: true
                    },
                    productId: props.productId,
                    userAuth: auth.userToken,
                    priceOptions
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/products`, {
        }, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(async response => {
                let newOptions = response.data.product.priceOptions
                await setPriceOptions(newOptions)
                await auth.setHasFeatured(response.data.hasFeatured)
                props.reloadCart().then();
                props.getSettings().then();
                setNameValue('')
            })
            .catch(error => {

            });
    }
    const deleteProduct = async () => {
        let token;
        try{
            token = await jwtToken.sign(
                {
                    productId: props.productId,
                    token: auth.userToken
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        await axios.delete(`${process.env.REACT_APP_SITE_URL}/api/products`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                props.reloadCart().then();
                props.getSettings().then();
                setWarning(false)
            })
            .catch(error => {
                console.log(error);
            });
    }

    let price
    if(props.price === undefined){
        price = 0.00;
    }else{
        price = Number(props.price);
    }

    if(props.categoryIndex === props.displayCategory){
        return <div id={'product-' + props.index + '-' + props.productId + props.product.amount}
                    className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-sx-12">
            <div className="card" id={props.productId}>
                <div className="admin-grid-container">
                    <ImageUpload
                        key={props.productId + '-imageId'}
                        productName={props.productName}
                        quantities={props.quantities}
                        catId={props.categoryId}
                        amount={props.amount}
                        image={props.image}
                        status={props.status}
                        price={price}
                        productId={props.productId}
                        changeImage={changeImage}
                        reloadCart={props.reloadCart}
                        priceOptions={props.product.priceOptions}
                    />
                    <div className="Name">
                        <form>
                            <input
                                type="text"
                                className="product-name-input admin-input"
                                id={props.productId + props.productName + '-nameId'}
                                placeholder={props.productName}
                                value={nameValue}
                                onBlur={updateProduct}
                                onChange={allocateName}
                            />
                        </form>
                    </div>
                    <div className="Price">
                        <table style={{width: '100%'}}>
                            <tbody>
                            <tr id={props.productId} style={{width: '100%'}}>
                                <td>
                                    {auth.appSettings.shop.currencySymbol}
                                    <input type="text"
                                           className="product-info-inputs admin-input"
                                           style={{width: '50px', border: 'none'}}
                                           placeholder={price}
                                           id={props.productId + '-priceId'}
                                           name='price'
                                           onBlur={updateProduct}
                                           onChange={allocatePrice}
                                    />
                                </td>
                                <td>
                                    / <input type="text"
                                             className="product-info-inputs admin-input"
                                             style={{width: '50px', border: 'none'}}
                                             placeholder={props.quantities}
                                             id={props.productId + '-quantitiesId'}
                                             name='quantities'
                                             onBlur={updateProduct}
                                             onChange={allocateQuantities}
                                />
                                </td>
                                <td>
                                    {auth.appSettings.shop.manageStockLevels ?
                                        <><span style={{fontSize: '0.7em', marginLeft: '0'}} >
                                        Qty:
                                    </span> <input type="text"
                                                   className="product-info-inputs admin-input"
                                                   style={{width: '40px', border: 'none'}}
                                                   placeholder={props.amount}
                                                   id={props.productId + '-amountId'}
                                                   name='amount'
                                                   onBlur={updateProduct}
                                                   value={amountValue}
                                                   onChange={allocateAmount}
                                                   disabled={true}
                                        />
                                        </> : <div style={{width: '73px'}}/>
                                    }
                                </td>
                                <td>
                                    <FontAwesomeIcon onClick={productOptionsHandlerAdd}
                                                     style={{cursor: 'pointer', marginRight: '10px',
                                                         color: '#b7f5a4'}}
                                                     icon={faPlusSquare}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {priceOptions.length > 0 && <hr />}
                {priceOptions.length > 0 &&
                <table style={{marginBottom: '30px'}}>
                    <tbody>
                    {priceOptions.map((row, index) =>
                        <AdminPricingTable key={'options-' + row.productId + '-' + index}
                                           price={row.price}
                                           updateProduct={updateProduct}
                                           allocatePrice={allocatePrice}
                                           quantities={row.quantities}
                                           allocateQuantities={allocateQuantities}
                                           amount={row.amount}
                                           productId={row.productId}
                                           amountValue={amountValue}
                                           index={index}
                                           row={row}
                                           product={props.product}
                                           image={row.image}
                                           setPriceOptions={setPriceOptions}
                                           allocateAmount={allocateAmount}
                                           priceOptions={props.product.priceOptions}
                                           productOptionsHandlerRemove={productOptionsHandlerRemove}
                                           productName={props.productName}
                                           catId={props.categoryId}
                                           status={props.status}
                                           changeImage={changeImage}
                                           reloadCart={props.reloadCart}
                                           getSettings={props.getSettings}
                        />)
                    }
                    </tbody>
                </table>
                }

                <div className="card-footer" style={{
                    paddingLeft: '0', paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px',
                    marginLeft: '0', marginRight: '0', marginTop: '0', marginBottom: '0'
                }}>

                    <div className="status-grid-container" >
                        {auth.appSettings.shop.showTax && !auth.appSettings.shop.setGlobal &&
                        <select
                            id="tax-rate"
                            name="tax-rate"
                            aria-label="Choose tax rate"
                            onBlur={updateProduct}
                            onChange={allocateTaxRate}
                            value={taxRateValue}
                            className={"form-control"}
                            style={{
                                marginTop: '0', marginBottom: '0',
                                marginLeft: '0', marginRight: '0'
                            }}
                        >
                            <option value="Standard Rate">Standard Rate</option>
                            <option value="Reduced Rate">Reduced Rate</option>
                            <option value="Zero Rate">Zero Rate</option>
                        </select>
                        }
                        {auth.appSettings.shop.showTax && auth.appSettings.shop.setGlobal &&
                        <select
                            id="tax-rate"
                            name="tax-rate"
                            aria-label="Choose tax rate"
                            disabled={true}
                            className={"form-control"}
                            style={{
                                marginTop: '0', marginBottom: '0',
                                marginLeft: '0', marginRight: '0'
                            }}
                        >
                            <option value="Standard Rate">{auth.appSettings.shop.globalRate}</option>
                        </select>
                        }
                        {!auth.appSettings.shop.showTax &&
                        <p style={{marginTop: '10px'}}>No tax charges</p>
                        }
                        <div className="Index">


                        </div>

                        <div className="Status">
                            <select
                                id="status"
                                name="status"
                                aria-label="Select category"
                                onBlur={updateProduct}
                                onChange={allocateStatus}
                                value={statusValue}
                                className={"form-control"}
                                style={{marginTop: '0', marginBottom: '0'}}
                            >
                                <option value="Live">Live</option>
                                <option value="Featured">Featured</option>
                                <option value="De-active">De-active</option>
                            </select>
                        </div>
                        <div className="Delete">
                            <p onClick={handleShowWarningModal}
                               style={{marginTop: '7px', marginBottom: '0', float: 'right',
                                   cursor:'pointer', color: '#ea3c53'
                               }}><FontAwesomeIcon icon={faTrashAlt} /></p>
                        </div>
                    </div>
                </div>
            </div>
            <WarningModal message={message}
                          warning={warning}
                          handleShowWarningModal={handleShowWarningModal}
                          handleDelete={handleDeleteProduct}
            />
        </div>

    } else {
        return ''
    }
}

export default AdminProductItem
