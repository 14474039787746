import React, {useContext, useState} from 'react';
import Button from "../../shared/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../shared/auth-context";

const SideDrawerButton = (props) => {
    const auth = useContext(AuthContext)
    const [categoryName, setCategoryName] = useState(props.category.categoryName.toUpperCase())
    let {controlButtonColor, navButtonColor, priceButtonColor, setHaveClicked} = props
    const displayIcon = () => {
        if (!props.editMode && props.deleteMode && props.displayCategory === props.index &&
            props.loadedCategories.length > 1) {
            return <FontAwesomeIcon className={'nav-logout'} icon={faTrashAlt}/>
        } else {
            return <FontAwesomeIcon className={'nav-logout'} icon={faPencilAlt}/>
        }
    }
    const editButtonHandler = async (event) => {
        event.preventDefault();
        if (!props.deleteMode) {
            await props.setDeleteMode(true)
            await props.setDisplayCategory(props.index)
            document.getElementById('edit-' + props.category._id).focus()
        }
        if (props.deleteMode) {
            if (props.displayCategory === props.index && props.loadedCategories.length > 1) {
                await props.deleteCategory(props.loadedCategories[props.index]._id)
                await props.setDeleteMode(false)
            } else {
                await props.setDisplayCategory(props.index)
            }

        }

    }
    const displayCategoryHandler = async () => {
        await setHaveClicked(true)
        window.scrollTo({top: 0, behavior: 'smooth'})
        await props.setDisplayCategory(props.index)
        if (!props.deleteMode) {
            await props.setShowNavDrawer(false)
        }
    }
    const displayFeaturedCategoryHandler = async () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
        await props.setDisplayCategory(999)
        await props.setShowNavDrawer(false)
    }
    const setCategoryData = (event) => {
        let newName = event.target.value
        setCategoryName(newName)
    }

    const handleUpdateCategory = async () => {
        await props.updateCategoryName({newName: categoryName, categoryId: props.category._id})
    }

    return (
        <>
            <div id={props.category._id + '-' + props.index + '-button'}
                 style={{display: 'block', width: '100%'}}>
                {!props.showAdminMode && !props.showOrdersMode && auth.isLoggedIn ?
                    <Button onClick={editButtonHandler}
                            className={'category-edit-button'}
                            text={displayIcon()}
                            key={'edit-' + props.category._id + '-name'}
                            keyUsed={'edit-'}
                            keyMode={props.category._id + '-name'}
                            float={'right'}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            active={false}
                            sidebarButton={true}
                            icon={null}/>
                    :
                    props.showAdminMode && !props.showOrdersMode && auth.isLoggedIn &&
                    (props.index === 0 && auth.hasFeatured) &&
                    <Button onClick={displayFeaturedCategoryHandler}
                            className={'sidebar-category'}
                            key={'sidebar-featured-category-nav'}
                            keyUsed={'sidebar-featured-'}
                            keyMode={'category-nav'}
                            text={'FEATURED'}
                            sidebarButton={true}
                            categoryButton={true}
                            solid={true}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            active={props.displayCategory === 999}
                            icon={null}/>
                }
                <div style={{marginRight: '20px'}}/>
                {props.editMode ?
                    <Button onClick={displayCategoryHandler}
                            className={'sidebar-category'}
                            key={'show-category-name'}
                            keyUsed={'show-'}
                            keyMode={'category-name'}
                            text={props.category.categoryName}
                            float={'right'}
                            sidebarButton={true}
                            categoryButton={true}
                            solid={true}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            active={props.displayCategory === props.index}
                            icon={null}/>
                    : auth.isLoggedIn ?
                        <Button onClick={displayCategoryHandler}
                                className={'sidebar-category'}
                                key={'edit-category-item-name'}
                                keyUsed={'edit-'}
                                keyMode={'category-item-name'}
                                text={!props.editMode && props.displayCategory === props.index ?
                                    <input type="text"
                                           id={'edit-' + props.category._id}
                                           className="category-menu-inputs"
                                           placeholder={props.category.categoryName.toUpperCase()}
                                           name={props.category._id}
                                           onChange={setCategoryData}
                                           onBlur={handleUpdateCategory}
                                           value={categoryName}
                                    />
                                    :
                                    props.category.categoryName}
                                sidebarButton={true}
                                categoryButton={true}
                                solid={true}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                active={props.displayCategory === props.index}
                                icon={null}/>
                        :
                        auth.appSettings.shop.storeIsLive && !auth.isLoggedIn &&
                        <>
                            {props.index === 0 && auth.hasFeatured &&
                            <Button onClick={displayFeaturedCategoryHandler}
                                    className={'sidebar-category'}
                                    key={'sidebar-featured-category-nav'}
                                    keyUsed={'sidebar-featured-'}
                                    keyMode={'category-nav'}
                                    text={'FEATURED'}
                                    sidebarButton={true}
                                    categoryButton={true}
                                    solid={true}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    active={props.displayCategory === 999}
                                    icon={null}/>
                            }

                            <Button onClick={displayCategoryHandler}
                                    className={'sidebar-category'}
                                    key={'sidebar-category-nav'}
                                    keyUsed={'sidebar-'}
                                    keyMode={'category-nav'}
                                    text={props.category.categoryName}
                                    sidebarButton={true}
                                    categoryButton={true}
                                    solid={true}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    active={props.displayCategory === props.index}
                                    icon={null}/>
                        </>
                }
            </div>
        </>
    );

}

export default SideDrawerButton;
