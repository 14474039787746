import React, {useContext} from 'react';
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../shared/auth-context";
import UserInvoiceItem from "./UserInvoiceItem";

const UserInvoicesModal = (props) => {
    const auth = useContext(AuthContext)
    let {showInvoice, handleShowInvoice} = props
        return (
            <>
                <Modal show={showInvoice} onHide={handleShowInvoice}>
                    <ModalHeader closeButton>
                        <ModalTitle>@{auth.username} Invoices</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        {auth.cartInvoices.map((invoice, index) => {
                            return <UserInvoiceItem
                                key={invoice._id}
                                invoice={invoice}
                                index={index}
                            />
                        })}
                    </ModalBody>

                    <ModalFooter>
                        <div style={{width: '100%'}}>
                            <Button style={{float: 'right', marginLeft: '7px'}}
                                    variant="danger" onClick={handleShowInvoice}>
                                CLOSE <FontAwesomeIcon style={{marginLeft: '7px'}} icon={faTimesCircle}/>
                            </Button>
                            <Button style={{float: 'right', marginLeft: '7px'}}
                                    onClick={window.print}>
                                PRINT <FontAwesomeIcon style={{marginLeft: '7px'}} icon={faTimesCircle}/>
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );

}

export default UserInvoicesModal;
