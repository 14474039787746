import React from 'react';

const PrivacyPolicy = props => {

        return (
            <>
                <div style={{fontSize: '0.7em'}}>
                    <h4>Privacy Policy</h4>
                    <p>We are committed to safeguarding the privacy of our website visitors; this policy sets out how we will treat your personal information.</p>
                    <p>Our website uses cookies. We will ask you to consent to our use of cookies in accordance with the terms of this policy when you first visit our website. By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.</p>
                    <h5>(1)      What information do we collect?</h5>
                    <p>We may collect, store and use the following kinds of personal information:</p>
                    <p>(a)    information about your computer and about your visits to and use of this website including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation.</p>
                    <p>(b)    information relating to any transactions carried out between you and us on or in relation to this website, including information relating to any purchases you make of our goods or services.</p>
                    <p>(c)     information that you provide to us for the purpose of registering with us.</p>
                    <p>(d)    information that you provide to us for the purpose of subscribing to our website services, email notifications and/or newsletters.</p>
                    <p>(e)    any other information that you choose to send to us.</p>
                    <p>Before you disclose to us the personal information of another person, you must obtain that person’s consent to both the disclosure and the processing of that personal information in accordance with the terms of this privacy policy.</p>
                    <h5>(2)     Using your personal information</h5>
                    <p>Personal information submitted to us via this website will be used for the purposes specified in this privacy policy or in relevant parts of the website.</p>
                    <p>We may use your personal information to:</p>
                    <p>(a)      administer the website</p>
                    <p>(b)      improve your browsing experience by personalising the website</p>
                    <p>(c)      enable your use of the services available on the website</p>
                    <p>(d)      send you goods purchased via the website, and supply to you services purchased via the website</p>
                    <p>(e)      send statements and invoices to you, and collect payments from you</p>
                    <p>(f)      send you general (non-marketing) commercial communications</p>
                    <p>(g)      send you email notifications which you have specifically requested</p>
                    <p>(h)      send you our newsletter and other marketing communications relating to our business or the businesses of carefully-selected third parties which we think may be of interest to you, by post or, where you have specifically agreed to this, by email or similar technology (and you can inform us at any time if you no longer require marketing communications).</p>
                    <p>(i)     deal with inquiries and complaints made by or about you relating to the website</p>
                    <p>(j)    keep the website secure and prevent fraud</p>
                    <p>(k)     verify compliance with the terms and conditions governing the use of the website (including monitoring private messages sent through our website private messaging service).</p>
                    <p>Where you submit personal information for publication on our website, we will publish and otherwise use that information in accordance with the license you grant to us.</p>
                    <p>We will not, without your express consent, provide your personal information to any third parties for the purpose of direct marketing.</p>
                    <p>All our website financial transactions are handled through our payment services provider, PayPal. You can review the PayPal privacy policy on the PayPal website. We will share information with PayPal only to the extent necessary for the purposes of processing payments you make via our website, refunding such payments and dealing with complaints and queries relating to such payments and refunds.</p>
                    <h5>(3)      Disclosures</h5>
                    <p>We may disclose your personal information to any of our employees, officers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this privacy policy.</p>
                    <p>We may disclose your personal information to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes set out in this privacy policy.</p>
                    <p>In addition, we may disclose your personal information:</p>
                    <p>(a)      to the extent that we are required to do so by law;</p>
                    <p>(b)      in connection with any ongoing or prospective legal proceedings;</p>
                    <p>(c)      in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk);</p>
                    <p>(d)    to the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating) selling.</p>
                    <p>(e)    to any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information.</p>
                    <p>Except as provided in this privacy policy, we will not provide your information to third parties.</p>
                    <h5>(4)      Security of your personal information</h5>
                    <p>We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.</p>
                    <p>We will store all the personal information you provide on our secure password and firewall-protected servers.</p>
                    <p>All electronic transactions entered into via the website will be protected by encryption technology.</p>
                    <p>You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</p>
                    <p>You are responsible for keeping your password and other login details confidential. We will not ask you for your password except when you log in to the website.</p>
                    <h5>(5)      Policy amendments</h5>
                    <p>We may update this privacy policy from time to time by posting a new version on our website. You should check this page occasionally to ensure you are happy with any changes.</p>
                    <p>We may also notify you of changes to our privacy policy by email.</p>
                    <h5>(6)      Your rights</h5>
                    <p>You may instruct us to provide you with any personal information we hold about you. Provision of such information will be subject to:</p>
                    <p>(a)      the payment of a fee (currently fixed at GBP 25); and</p>
                    <p>(b)      the supply of appropriate evidence of your identity (for this purpose, we will usually accept a photocopy of your passport certified by a solicitor or bank plus an original copy of a utility bill showing your current address).</p>
                    <p>We may withhold such personal information to the extent permitted by law.</p>
                    <p>You may instruct us not to process your personal information for marketing purposes, by sending an email to us. In practice, you will usually either expressly agree in advance to our use of your personal information for marketing purposes, or we will provide you with an opportunity to opt out of the use of your personal information for marketing purposes.</p>
                    <h5>(7)      Third party websites</h5>
                    <p>The website contains links to other websites. We are not responsible for the privacy policies or practices of third party websites.</p>
                    <h5>(8)      Updating information</h5>
                    <p>Please let us know if the personal information which we hold about you needs to be corrected or updated.</p>
                    <h5>(9)      Contact</h5>
                    <p>If you have any questions about this privacy policy or our treatment of your personal information, please contact us using our website.</p>
                </div>
            </>
        );

}

export default PrivacyPolicy;
