import React, {useContext, useState} from 'react';
// import Button from "react-bootstrap/Button";
import '../../css/PaymentSettings.css'
import AuthContext from "../shared/auth-context";
import axios from "axios";
import jwtToken from "jsonwebtoken";
import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey} from "@fortawesome/free-solid-svg-icons";

const C4RT_API_KEY = process.env.REACT_APP_JWT_KEY;


const PaymentSettings = props => {
    const auth = useContext(AuthContext)
    const [devPublic, setDevPublic] = useState(auth.stripeSettings.devPublic)
    const [devSecret, setDevSecret] = useState(auth.stripeSettings.devSecret)
    const [livePublic, setLivePublic] = useState(auth.stripeSettings.livePublic)
    const [liveSecret, setLiveSecret] = useState(auth.stripeSettings.liveSecret)
    const [stripeEmail, setStripeEmail] = useState(auth.stripeSettings.stripeEmail)
    const [errorMessage, setErrorMessage] = useState(null)


    const paymentStateHandler = async event => {
        let type = event.target.name
        let newMode
        try {
            if (type === 'development-mode') {
                newMode = true
                await auth.setDevMode(true)
            } else if (type === 'live-mode') {
                newMode = false
                await auth.setDevMode(false)
            } else {
                newMode = !auth.devMode
                await auth.setDevMode(prevState => !prevState)
            }
        } catch (error) {
        }
        let token;
        try {
            token = await jwtToken.sign(
                {
                    userToken: auth.userToken, devMode: newMode,
                    username: auth.username
                },
                C4RT_API_KEY,
                {expiresIn: '1h'}
            );
        } catch (err) {
            console.log(err)
        }
        await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/keys/mode`, {}, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(async res => {
            let stripeSettings = res.data.savedSettings
            try {
                await auth.setStripeSettings(stripeSettings)
                await auth.setDevMode(stripeSettings.devMode)
                const userData = JSON.parse(localStorage.getItem('userData'))
                if (!newMode && stripeSettings.devMode) {
                    setErrorMessage(
                        `Not allowed live`
                    )
                } else {
                    setErrorMessage(null)
                }
                if (userData) {
                    const {
                        username, token, cartId, categoryId, appSettings, stripeSettings,
                        cartOrder, cartInvoices, userEmail, tickets, messages
                    } = userData
                    localStorage.setItem('userData', JSON.stringify({
                        username, token, cartId, categoryId, appSettings,
                        stripeSettings: stripeSettings, cartOrder, cartInvoices, userEmail, tickets, messages
                    }))
                }
            } catch (error) {
            }
        })
    }
    const isKeyTypeValid = async (props) => {
        let {type, userInput} = props
        let valid, message
        let keyType = userInput.split('_')[0]
        let keyMode = userInput.split('_')[1]
        if (type === 'dev-public-key') {
            if (keyType === 'pk') {
                return {valid: true}
            } else {
                message = 'There seems to be a problem with that key sorry!'
                valid = false
            }
        } else if (type === 'dev-secret-key') {
            if (keyType === 'sk') {
                return {valid: true}
            } else {
                message = 'There seems to be a problem with that key sorry!'
                valid = false
            }
        } else if (type === 'live-public-key') {
            if (keyMode === 'live' && keyType === 'pk') {
                return {valid: true}
            } else if (keyMode === 'test' && keyType === 'pk') {
                message = 'You\'re not allowed to use test keys in your live store sorry!'
                valid = false
            } else {
                message = 'You\'re not allowed to use test keys in your live store sorry!'
                valid = false
            }
        } else if (type === 'live-secret-key') {
            if (keyMode === 'live' && keyType === 'sk') {
                return {valid: true}
            } else if (keyMode === 'test' && keyType === 'sk') {
                message = 'You\'re not allowed to use test keys in your live store sorry'
                valid = false
            } else {
                message = 'That is not a valid key you have just entered sorry!'
                valid = false
            }
        }
        return {valid, message}
    }
    const getInput = async event => {
        let userInput = event.target.value,
            type = event.target.name;
        let res;
        res = await isKeyTypeValid({type: type, userInput})
        if (res.valid) {
            if (type === 'dev-public-key') {
                await setDevPublic(userInput)
            } else if (type === 'dev-secret-key') {
                await setDevSecret(userInput)
            } else if (type === 'live-public-key') {
                await setLivePublic(userInput)
            } else if (type === 'live-secret-key') {
                await setLiveSecret(userInput)
            }
            setErrorMessage(null)
        } else {
            if (type === 'dev-public-key') {
                await setDevPublic(userInput)
            } else if (type === 'dev-secret-key') {
                await setDevSecret(userInput)
            } else if (type === 'live-public-key') {
                await setLivePublic(userInput)
            } else if (type === 'live-secret-key') {
                await setLiveSecret(userInput)
            }
            setErrorMessage(res.message)
        }
    }
    const clearField = async event => {
        let type = event.target.name;
        if (type === 'dev-public-key') {
            await setDevPublic('')
        } else if (type === 'dev-secret-key') {
            await setDevSecret('')
        } else if (type === 'live-public-key') {
            await setLivePublic('')
        } else if (type === 'live-secret-key') {
            await setLiveSecret('')
        }
    }
    const updateKeys = async () => {
        if (!errorMessage) {
            let token;
            try {
                token = await jwtToken.sign(
                    {
                        userToken: auth.userToken,
                        devPublic, devSecret, livePublic,
                        liveSecret, stripeEmail, devMode: auth.devMode,
                        username: auth.username
                    },
                    `${process.env.REACT_APP_JWT_KEY}`,
                    {
                        expiresIn: '1h'
                    }
                );
            } catch (err) {
                console.log(err)
            }
            await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/keys`, {}, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(async res => {
                    try {
                        await auth.setStripeSettings(res.data.stripeSettings)
                        await setDevPublic(res.data.stripeSettings.devPublic)
                        await setDevSecret(res.data.stripeSettings.devSecret)
                        await setLivePublic(res.data.stripeSettings.livePublic)
                        await setLiveSecret(res.data.stripeSettings.liveSecret)
                        await setStripeEmail(res.data.stripeSettings.stripeEmail)
                        await auth.setDevMode(res.data.stripeSettings.devMode)
                        setErrorMessage(null)
                    } catch (error) {
                    }
                    const userData = JSON.parse(localStorage.getItem('userData'))
                    let { cartOrder, cartInvoices, userEmail, tickets, messages} = userData
                    localStorage.setItem('userData', JSON.stringify({
                        username: auth.username, token: auth.userToken, cartId: auth.cartId,
                        categoryId: auth.categoryId, appSettings: auth.appSettings,
                        stripeSettings: {
                            devPublic, devSecret, livePublic, liveSecret, stripeEmail, devMode: auth.devMode
                        }, cartOrder, cartInvoices, userEmail, tickets, messages
                    }))
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
    const returnErrorMessage = () => {
        return errorMessage !== 'Not allowed live' ?
            <Alert key={'error-alert'} variant={'danger'}
                   style={{marginTop: '30px', textAlign: 'center'}}>
                {errorMessage}
            </Alert>
            :
            <Alert key={'error-alert'} variant={'danger'}
                   style={{fontSize: '0.9em', marginTop: '30px', textAlign: 'center'}}>
                @{auth.username} needs to be active before 'LIVE' mode is allowed,
                please <span onClick={props.handleShowGoLive}
                             style={{
                                 fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer'
                             }}>launch @{auth.username}</span> now to proceed!
            </Alert>
    }
    const seeKeysStyle = () => {
        return {
            color: 'transparent',
            padding: '4px',
            textShadow: '0 0 5px rgba(0,0,0,0.7)',
            width: '100%',
            marginTop:'0'
        }
    }

    return (
        <>
            <div className="container" style={{marginBottom: '20px', marginTop: '0'}}>
                <label className="switch">
                    <input type="checkbox"
                           style={{
                               float: 'right', marginBottom: '0',
                               width: '20px', marginTop: '0'
                           }}
                           checked={!auth.devMode}
                           className="form-control"
                           placeholder="Cart mode toggle"
                           id="cart-mode-toggle"
                           name='cart-mode-toggle'
                           onClick={paymentStateHandler}
                    />
                    <span className="slider round"> </span>
                </label><span style={{float: 'right', fontSize:'0.8em', marginRight: '10px', marginTop: '10px'}}>
                            <em>{auth.devMode === true ? 'TEST/DEV mode' :
                                'LIVE mode'}</em>
                        </span>
                <h5 style={{marginBottom: '0', marginTop:'0'}}><FontAwesomeIcon icon={faKey}/> Stripe Payments</h5>
                <hr/>
                <div className="row" style={{marginTop: '0', width: '100%'}}>
                    <div className="card-body">
                        {auth.stripeSettings.devMode ?
                            <>

                                <div style={{marginTop: '0', marginBottom: '10px'}}>
                                    <span style={{fontSize:'0.7em', marginBottom:'0', lineHeight:'0.7em'}}>TEST Public Keys:</span>
                                    <form autoComplete={'off'}>
                                        <input type="text"
                                               className="input-control"
                                               style={seeKeysStyle()}
                                               placeholder="Not entered a key"
                                               id="dev-public-key"
                                               name='dev-public-key'
                                               onFocus={clearField}
                                               onChange={getInput}
                                               onBlur={updateKeys}
                                               value={devPublic}
                                        />
                                    </form>
                                </div>
                                <div style={{marginTop: '0', marginBottom: '10px'}}>
                                    <span style={{fontSize:'0.7em', marginBottom:'0', lineHeight:'0.7em'}}>TEST Secret Keys:</span>
                                    <form autoComplete={'off'}>
                                        <input type="text"
                                               className="input-control"
                                               style={seeKeysStyle()}
                                               placeholder="Not entered a dev key"
                                               id="dev-secret-key"
                                               name='dev-secret-key'
                                               onFocus={clearField}
                                               onChange={getInput}
                                               onBlur={updateKeys}
                                               value={devSecret}
                                        />
                                    </form>
                                </div>

                            </>
                            : !auth.stripeSettings.devMode &&
                            <>

                                <div style={{marginTop: '0', marginBottom: '10px'}}>
                                    <span style={{fontSize:'0.7em', marginBottom:'0', lineHeight:'0.7em'}}>LIVE Public Keys:</span>
                                    <form autoComplete={'off'}>
                                        <input type="text"
                                               className="input-control"
                                               style={seeKeysStyle()}
                                               placeholder="Not entered a live key"
                                               id="live-public-key"
                                               name='live-public-key'
                                               onFocus={clearField}
                                               onChange={getInput}
                                               onBlur={updateKeys}
                                               value={livePublic}
                                        />
                                    </form>
                                </div>


                                <div style={{marginTop: '0', marginBottom: '10px'}}>
                                    <span style={{fontSize:'0.7em', marginBottom:'0', lineHeight:'0.7em'}}>LIVE Secret Keys:</span>
                                    <form autoComplete={'off'}>
                                        <input type="text"
                                               className="input-control"
                                               style={seeKeysStyle()}
                                               placeholder="Not entered a live key"
                                               id="live-secret-key"
                                               name='live-secret-key'
                                               onFocus={clearField}
                                               onChange={getInput}
                                               onBlur={updateKeys}
                                               value={liveSecret}
                                        />
                                    </form>
                                </div>

                            </>
                        }
                    </div>
                </div>
            </div>
            {errorMessage &&
            returnErrorMessage()
            }
            {/*<hr  style={{marginTop: '60px'}}/>*/}
            {/*<p style={{marginTop: '20px', fontSize: '0.8em', textAlign: 'center', width: '90%',*/}
            {/*marginRight: 'auto',marginLeft: 'auto'}}>*/}
            {/*    <strong>How we deal with your data:</strong> All of the payment keys entered into our site*/}
            {/*    are sent via a securely encrypted connection to our server which encrypts the keys before*/}
            {/*    saving them to the database. The only way it is mechanically possible to view these keys*/}
            {/*    in a readable form (how they are seen above) is on this page because we have used the*/}
            {/*    password you used to log into this session to authorise the decryption of the keys so that*/}
            {/*    you can view them. No one at C4RT LTD (or anyone who may gain access to our database - God*/}
            {/*    forbid) has access to view these keys, even if they were to look directly in the database,*/}
            {/*    as they cannot be viewed without first being authorised with an account password and then*/}
            {/*    being decrypted with a secret key (which is only kept in one secret location that only one*/}
            {/*    person has access to). Users passwords again have been encrypted; so no one can copy an*/}
            {/*    encrypted password and fake a login to another persons account). Basically extreme measures*/}
            {/*    are in place to ensure everyone's data is proper secure. <em>#NoMessingAbout</em>*/}
            {/*</p>*/}
        </>
    );
}

export default PaymentSettings;
