import React, {useState, useCallback, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import CartsContainer from "./pages/CartsContainer";
import AuthContext from './lib/components/shared/auth-context'


const App = () => {
    const [ isLoggedIn, setIsLoggedIn ] = useState(false)
    const [ userName, setUserName] = useState(false);
    const [ userCategory, setUserCategory] = useState(false);
    const [ appSettings, setAppSettings] = useState(null);
    const [ userToken, setUserToken ] = useState(null);
    const [ customersOrders, setCustomersOrders ] = useState(null);
    const [ customerToken, setCustomerToken ] = useState(null);
    const [ customerIsLoggedIn, setCustomerIsLoggedIn ] = useState(false)
    const [ customerFirstname, setCustomerFirstname ] = useState(false)
    const [ customerEmail, setCustomerEmail ] = useState(false)
    const [ customerAddressOne, setCustomerAddressOne ] = useState(false)
    const [ customerAddressTwo, setCustomerAddressTwo ] = useState(false)
    const [ customerPostcode, setCustomerPostcode ] = useState(false)
    const [ customerPhone, setCustomerPhone ] = useState(false)
    const [ customerInstructions, setCustomerInstructions ] = useState(false)
    const [ customerLastname, setCustomerLastname ] = useState(false)
    const [ customerId, setCustomerId ] = useState(null)
    const [ formIsValid, setFormIsValid ] = useState(false);
    const [ detailsConfirmed, setDetailsConfirmed ] = useState(false);
    const [ firstnameValid, setFirstnameValid] = useState(false);
    const [ lastnameValid, setLastnameValid] = useState(false);
    const [ address1Valid, setAddress1Valid] = useState(false);
    const [ address2Valid, setAddress2Valid] = useState(false);
    const [ countryValid, setCountryValid] = useState(false);
    const [ postcodeValid, setPostcodeValid] = useState(false);
    const [ customerCountry, setCustomerCountry ] = useState(null)
    const [ customer, setCustomer ] = useState(null)
    const [ finalDistance, setFinalDistance ] = useState(0)
    const [ totalCharges, setTotalCharges ] = useState(0)
    const [ deliveryCharges, setDeliveryCharges ] = useState(0)
    const [ cartsId, setCartsId ] = useState('')
    const [ gotCartId, setGotCartId ] =useState(false)
    const [ shopDayOpen, setShopDayOpen ] = useState(false)
    const [ shopClosed, setShopClosed ] = useState(false)
    const [ stripeSettings, setStripeSettings ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ orderCompleted, setOrderCompleted ] = useState(false)
    const [ orderNumber, setOrderNumber ] = useState(null);
    const [ orderId, setOrderId ] = useState(null);
    const [ publicKey, setPublicKey ] = useState(null);
    const [ createdPromise, setCreatedPromise ] = useState(null);
    const [ subscriptionPromise, setSubscriptionPromise ] = useState(null);
    const [ userStripeId, setUserStripeId ] = useState(null);
    const [ devMode, setDevMode ] = useState(true)
    const [ cartId, setCartId ] = useState(null)
    const [ cartOrder, setCartOrder ] = useState(null)
    const [ cartInvoices, setCartInvoices ] = useState(null)
    const [ cartStats, setCartStats ] = useState(null)
    const [ userEmail, setUserEmail ] = useState(null)
    const [ priceButtonColor, setPriceButtonColor ] = useState('#65e485')
    const [ controlButtonColor, setControlButtonColor ] = useState('#a692f8')
    const [ navButtonColor, setNavButtonColor ] = useState('#ff79c6')
    const [ userTickets, setUserTickets ] = useState([])
    const [ userMessages, setUserMessages ] = useState([])
    const [ hasFeatured, setHasFeatured ] = useState(false)

    const login = useCallback(async (username, token, cartId, categoryId, appSettings,
                               stripeSettings, cartOrder, cartInvoices, userEmail, tickets, messages) => {
        // setIsLoggedIn(false)
        setUserToken(token)
        setUserEmail(userEmail)
        setCartInvoices(cartInvoices)
        setCartOrder(cartOrder)
        setStripeSettings(stripeSettings)
        setUserName(username)
        setCartId(cartId)
        setUserCategory(categoryId)
        setAppSettings(appSettings)
        setUserTickets(tickets)
        setUserMessages(messages)
        setControlButtonColor(appSettings.shop.colours.controlButtonColor)
        setNavButtonColor(appSettings.shop.colours.navButtonColor)
        setPriceButtonColor(appSettings.shop.colours.priceButtonColor)
        await localStorage.setItem('userData', JSON.stringify({
            username, token, cartId, categoryId, appSettings,
            stripeSettings, cartOrder, cartInvoices, userEmail,
            tickets, messages
        }))
        setIsLoggedIn(true)
    }, [])

    const customerLogin = useCallback((customer, token, orders) => {
        setCustomer(customer)
        setCustomerToken(token)
        setCustomerId(customer._id)
        setCustomersOrders(orders)
        localStorage.setItem('customerData', JSON.stringify({
            customer, token, orders
        }))
        setCustomerIsLoggedIn(true);
    }, [])


    const customerLogout = useCallback(() => {
        setCustomer(null)
        setCustomersOrders(null)
        localStorage.removeItem('customerData')
        setCustomerToken( null)
        setCustomerIsLoggedIn(false);
        setCustomerId(null);
        setOrderId(null)
        setCreatedPromise(null)
        setCustomerFirstname(false);
    }, [])

    const logout = useCallback(() => {
        setUserEmail(null)
        setStripeSettings({});
        setAppSettings({})
        setUserToken(null)
        setIsLoggedIn(false);
        setUserName(null)
        setCartId(false)
        setUserCategory(null)
        setGotCartId(false)
        setOrderId(null)
        setCreatedPromise(null)
        setCartOrder(null)
        setCartInvoices(null)
        setUserTickets(null)
        setUserMessages(null)
        localStorage.removeItem('userData')
    }, [])

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        if(userData && userData.token && userData.username !== 'about'){
            login(userData.username, userData.token, userData.cartId, userData.categoryId,
                userData.appSettings, userData.stripeSettings, userData.cartOrder,
                userData.cartInvoices, userData.userEmail, userData.tickets, userData.messages).then()
        }
    }, [login])

    useEffect(() => {
        const customerData = JSON.parse(localStorage.getItem('customerData'))
        if(customerData && customerData.token){
            customerLogin(customerData.customer, customerData.token, customerData.orders)
        }
    }, [customerLogin])

    let redirectURL = '/' + userName;

  return (
          <AuthContext.Provider value={
              {
                  appSettings: appSettings, cartStats, setCartStats, userTickets, setUserTickets,
                  stripeSettings, setStripeSettings, setIsLoggedIn, userMessages, setUserMessages,
                  customer: customer, cartInvoices, setCartInvoices, hasFeatured, setHasFeatured,
                  setCustomer: setCustomer, setUserName, userEmail, setUserEmail,
                  setAppSettings: setAppSettings, controlButtonColor, setControlButtonColor,
                  isLoggedIn: isLoggedIn, devMode, setDevMode,
                  username: userName, customersOrders, setCustomersOrders,
                  cartId, navButtonColor, setNavButtonColor, priceButtonColor, setPriceButtonColor,
                  isLoading, setIsLoading, orderCompleted, setOrderCompleted,
                  cartsId, setCartsId, shopClosed, setShopClosed,
                  categoryId: userCategory, gotCartId, setGotCartId, shopDayOpen, setShopDayOpen,

                  login, logout, customerLogin, userToken, customerLogout,
                  customerToken, formIsValid, detailsConfirmed, firstnameValid,
                  lastnameValid, address1Valid, address2Valid, countryValid, postcodeValid,

                  customerId, customerFirstname, customerLastname, customerCountry,
                  customerAddressOne, customerAddressTwo, customerPostcode,
                  customerPhone, customerInstructions, customerIsLoggedIn,
                  customerEmail, setCustomerEmail, setCustomerAddressOne, cartOrder, setCartOrder,
                  setCustomerAddressTwo, setCustomerFirstname, setCustomerLastname,
                  setCustomerPostcode, setCustomerInstructions, setCustomerPhone,
                  setFormIsValid, setDetailsConfirmed, setFirstnameValid,
                  setLastnameValid, setAddress1Valid, setPostcodeValid, setCustomerCountry,
                  setAddress2Valid, setCountryValid, setCustomerId, finalDistance, setFinalDistance,
                  totalCharges, setTotalCharges, deliveryCharges, setDeliveryCharges,
                  orderNumber, setOrderNumber, orderId, setOrderId, publicKey, setPublicKey,
                  createdPromise, setCreatedPromise, subscriptionPromise, setSubscriptionPromise,
                  userStripeId, setUserStripeId, setCartId
              }
          }>
              <Router>
                  {isLoggedIn &&
                  <Switch key={'user'}>
                      <Route key={'user'} path={redirectURL} component={CartsContainer}/>
                      <Redirect to={redirectURL} />
                  </Switch>
                  }
                  {!isLoggedIn &&
                  <Switch key={'visitor'}>
                      <Route key={'visitor'} path="/:username" component={CartsContainer}/>
                      {/*<Route path='/' component={() => {*/}
                      {/*    window.location.href = 'https://blog.c4rt.uk';*/}
                      {/*    return null;*/}
                      {/*}}/>*/}
                      <Redirect to="/about"/>
                  </Switch>
                  }
              </Router>
          </AuthContext.Provider>
  );
}

export default App;
