import React, {useContext, useState} from 'react';
import jwtToken from "jsonwebtoken";
import axios from "axios";
import AuthContext from "../../../shared/auth-context";
import StockModalDynamicRow from "./StockModalDynamicRow";

const StockModalRow = props => {
    let { product, displayCategory, managementType, setLoadedProducts,
        setLoadedCategories } = props
    let auth = useContext(AuthContext)

    let [ newAmount, setNewAmount ] = useState('')
    const handleChangeAmount = (event) => {
        let value = event.target.value
        setNewAmount(value)
    }

    const updateProductAmount = async () => {
        let updateAmount = product.amount
        if(managementType === 'ADD'){
            updateAmount += Number(newAmount)
        }
        if(managementType === 'REMOVE'){
            updateAmount -= Number(newAmount)
            if(updateAmount < 0){
                updateAmount = 0
            }
        }
        if(managementType === 'ADJUST'){
            updateAmount = Number(newAmount)
        }
        let token;
        try{
            token = await jwtToken.sign(
                {
                    amount: updateAmount,
                    productId: product._id,
                    priceOptions: product.priceOptions,
                    userToken: auth.userToken
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/products/amount`, {
        }, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(async res => {
                setNewAmount('')
                auth.setHasFeatured(res.data.hasFeatured)
                setLoadedProducts(res.data.products)
                setLoadedCategories(res.data.categories)
                // Set loaded products and categories
            })
            .catch(error => {

            });
    }

        return (
            <>
                {(displayCategory === product.catId || displayCategory === 'ALL') &&
                <StockModalDynamicRow key={''}
                                      product={product}
                                      productName={product.productName}
                                      handleChangeAmount={handleChangeAmount}
                                      updateProductAmount={updateProductAmount}
                                      newAmount={newAmount}
                />}
                {
                    product.priceOptions.length > 0 &&
                        product.priceOptions.map((row, index) => {
                            return <StockModalDynamicRow key={''}
                                                         product={product}
                                                         row={row}
                                                         handleChangeAmount={handleChangeAmount}
                                                         updateProductAmount={updateProductAmount}
                                                         newAmount={newAmount}
                                                         productName={product.productName}
                                                         isPriceOption={row._id}
                            />
                        })
                }
            </>
        );

}

export default StockModalRow;
