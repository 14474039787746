import React from 'react';

const SupportModalBody = (props) => {
    let { message, index }= props
    let messageSentTime = message.sentOn
    let rawDate = messageSentTime.split('T')[0]
    let day = rawDate.split('-')[2]
    let month = rawDate.split('-')[1]
    let year = rawDate.split('-')[0]
    let rawTime = messageSentTime.split('T')[1]
    let hours = rawTime.split(':')[0]
    let minutes = rawTime.split(':')[1]

        return (
            <>
                {index !== 0 &&
                <hr />
                }
                <div className={'row'} style={{
                    paddingLeft:'15px', paddingRight:'15px', marginBottom:'0',
                    marginTop:'0'
                }}>
                    <div style={{
                        width:'100%', textAlign :message.name === 'Vil Pietersen' ? 'left' : 'right',
                        marginBottom:'5px', fontWeight:'bold'
                    }}>
                        {message.name} {message.name === 'Vil Pietersen' && '[Support]'}
                    </div>
                </div>
                <div style={{marginBottom:'0', padding:'10px',
                    border:'1px solid whitesmoke', backgroundColor:'whitesmoke', borderRadius:'7px 7px 7px 7px'
                }}>
                    {message.body}
                </div>
                <div className={'row'} style={{
                    paddingLeft:'15px', paddingRight:'15px', marginBottom:'0',
                    marginTop:'0'
                }}>
                    <div style={{width:'100%', float:'right', textAlign:'right',
                        marginBottom:'0', fontSize:'0.8em', marginTop:'0'}}>
                        {day}-{month}-{year} {hours}:{minutes}
                    </div>
                </div>
            </>
        );
}

export default SupportModalBody;
