import React, {useContext, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEye, faEyeSlash, faInfoCircle, faPlusSquare, faUserTimes
} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../shared/auth-context";
import axios from "axios";
import Button from "react-bootstrap/Button";
import ShiftItem from "./ShiftItem";
import ListCountries from "../shared/ListCountries";
import jwtToken from "jsonwebtoken";
import assets from '../shared/AssetLists'
import '../../css/ShopSettings.css'
import ShopSettingsRow from "./ShopSettingsRow";
import {Alert} from "react-bootstrap";
const minRowHeight = '70px'
// import {Alert} from "react-bootstrap";

const ShopSettings = props => {
    const auth = useContext(AuthContext);
    const { timeZones } = props
    const {shop, email, invoicing} = auth.appSettings;
    const {
        firstname, lastname, companyName, addressOne, addressTwo,
        accountEmail, phone, postcode, country, location
    } = invoicing.invoice
    const {bacs, isBranded, aboutCompany} = invoicing
    const {
        showTax, vatNumber, pricesBefore, setGlobal, globalRate,
        closeShop, minOrder, minValue, restrictDist, inputDist,
        distUnits, allowInt, showCharges, chargeDelivery, chargesType
    } = shop;
    const {minFee, maxFee, perUnit, standardFee, freeOver, showFree} = chargeDelivery
    const [showChargeTaxInfo, setShowChargeTaxInfo] = useState(false)
    const [showPricesTaxInfo, setShowPricesTaxInfo] = useState(false)
    const [showGlobalTaxInfo, setShowGlobalTaxInfo] = useState(false)
    const [showMinOrderInfo, setShowMinOrderInfo] = useState(false)
    const [showDistanceInfo, setShowDistanceInfo] = useState(false)
    const [showInternationalInfo, setShowInternationalInfo] = useState(false)
    const [showChargesInfo, setShowChargesInfo] = useState(false)
    const [showTimesInfo, setShowTimesInfo] = useState(false)
    // const [hideTimes, setHideTimes] = useState(false)
    const [minimumFee, setMinimumFee] = useState(minFee);
    const [maximumFee, setMaximumFee] = useState(maxFee);
    const [perUnitFee, setPerUnitFee] = useState(perUnit);
    const [standardFeeValue, setStandardFeeValue] = useState(standardFee);
    const [freeOverValue, setFreeOverValue] = useState(freeOver);
    const [vatValue, setVatValue] = useState(vatNumber)
    const [globalValue, setGlobalValue] = useState(globalRate)
    const [minInputValue, setMinInputValue] = useState(minValue)
    const [inputDistValue, setInputDistValue] = useState(inputDist)
    const [userCountry, setUserCountry] = useState(country)
    const [inputDistUnits, setInputDistUnits] = useState(distUnits)
    const [inputAllowInt, setInputAllowInt] = useState(allowInt)
    const [showTaxOptions, setShowTaxOptions] = useState(showTax)
    const [showPriceTaxOptions, setPriceShowTaxOptions] = useState(pricesBefore)
    const [showGlobalOptions, setShowGlobalOptions] = useState(setGlobal)
    const [showCloseShop, setShowCloseShop] = useState(closeShop)
    const [showMinOrderOptions, setShowMinOrderOptions] = useState(minOrder)
    const [showDistanceOptions, setShowDistanceOptions] = useState(restrictDist)
    const [showChargesOptions, setShowChargesOptions] = useState(showCharges)
    const [chargesTypeValue, setChargesTypeValue] = useState(chargesType)
    const [showFreeDelOptions, setShowFreeDelOptions] = useState(showFree)
    const [allowWeekday, setAllowWeekday] = useState(auth.appSettings.shop.openingDays)
    const [shiftTimes, setShiftTimes] = useState(auth.appSettings.shop.openingHours);
    const [currencySymbol, setCurrencySymbol] = useState(auth.appSettings.shop.currencySymbol)
    const [currency, setCurrency] = useState(auth.appSettings.shop.currency)
    const [locale, setLocale] = useState(auth.appSettings.shop.locale)
    const [showCancelAccount, setShowCancelAccount] = useState(false)
    const [allowCollections, setAllowCollections] = useState(auth.appSettings.shop.allowCollections || false)
    const [disableDeliveries, setDisableDeliveries] = useState(auth.appSettings.shop.disableDeliveries || false)
    const [manageStockLevels, setManageStockLevels] = useState(auth.appSettings.shop.manageStockLevels || false)
    // eslint-disable-next-line
    const [colours, setColours] = useState( auth.appSettings.shop.colours  ||  {
        priceButtonColor: '#65e485',
        controlButtonColor: '#a692f8',
        navButtonColor: '#ff79c6'
        })
    const [showCollectInfo, setShowCollectInfo] = useState(false)
    const [showDisableInfo, setShowDisableInfo] = useState(false)

    // const currencies = [
    //     'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT',
    //     'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BOV', 'BRL', 'BSD', 'BTN', 'BWP', 'BYN', 'BZD',
    //     'CAD', 'CDF', 'CHE', 'CHF', 'CHW', 'CLF', 'CLP', 'CNY', 'COP', 'COU', 'CRC', 'CUC', 'CUP',
    //     'CVE', 'CZK', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'EUR', 'FJD', 'FKP', 'GBP', 'GEL',
    //     'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS',
    //     'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW',
    //     'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MAD', 'MDL', 'MGA',
    //     'MKD', 'MMK', 'MNT', 'MOP', 'MRU', 'MUR', 'MVR', 'MWK', 'MXN', 'MXV', 'MYR', 'MZN', 'NAD',
    //     'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG',
    //     'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL',
    //     'SOS', 'SRD', 'SSP', 'STN', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY',
    //     'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'USD', 'USN', 'UYI', 'UYU', 'UYW', 'UZS', 'VES', 'VND',
    //     'VUV', 'WST', 'XAF', 'XAF', 'XAF', 'XAF', 'XAF', 'XAF', 'XAG', 'XAU', 'XBA', 'XBB', 'XBC',
    //     'XBD', 'XCD', 'XDR', 'XOF', 'XPD', 'XPF', 'XPT', 'XSU', 'XTS', 'XUA', 'XXX', 'YER', 'ZAR',
    //     'ZMW', 'ZWL'
    // ]

    const currencySymbols = [
        '£', '€', '$', 'ƒ', '¥', '₡', '₱', '¢', '₪', '¥', '₩', '₭', '₮', '₦', '₽', '₴', '฿', '؋', '﷼', '₼', '៛',
        '$b', 'B/.', 'Br', 'Bs', 'BZ$', 'CHF', 'C$', '₫', 'Ft', 'Gs', 'J$', 'Kč', 'KM', 'kn', 'kr', 'L',
        'lei', 'MT', 'NT$', 'P', 'Q', 'R', 'RD$', 'RM', 'Rp', '₨', 'R$', 'S', 'S/.', 'TT$', '$U', 'Z$', 'zł',
        'лв', 'ден', 'Дин.'
    ]

    const handleShowCollectInfo = () => {
        setShowCollectInfo(prevState => !prevState)
    }
    const handleChangeCurrencySymbol = async event => {
        let userInput = event.target.value
        try {
            await setCurrencySymbol(userInput)
        } catch (error) {
        }
    }
    const handleChangeCurrency = event => {
        let userInput = event.target.value
        setCurrency(userInput)
    }
    const handleChangeLocale = event => {
        let userInput = event.target.value
        try {
            setLocale(userInput)
        } catch (error) {
        }
    }
    const handleAllowCollections = () => {
        if (!allowCollections) {
            setAllowCollections(true)
        } else {
            setAllowCollections(false)
        }
        if (disableDeliveries) {
            setDisableDeliveries(false)
        }
    }
    const handleDisableDeliveries = () => {
        if (!disableDeliveries) {
            setDisableDeliveries(true)
            setShowChargesOptions(false)
        } else {
            setDisableDeliveries(false)
        }
    }
    const handleShowDisableInfo = () => {
        setShowDisableInfo(prevState => !prevState)
    }
    // eslint-disable-next-line
    const handleShowCancelAccount = () => {
        setShowCancelAccount(prevMode => !prevMode)
    }
    const handleCancelAccount = async () => {
        let submitData = {
            username: auth.username,
            shop: {
                showTax: showTaxOptions,
                vatNumber: vatValue,
                pricesBefore: showPriceTaxOptions,
                setGlobal: showGlobalOptions,
                globalRate: globalValue,
                closeShop: showCloseShop,
                minOrder: showMinOrderOptions,
                minValue: Number(minInputValue),
                restrictDist: showDistanceOptions,
                inputDist: inputDistValue,
                distUnits: inputDistUnits,
                allowInt: inputAllowInt,
                locale: locale,
                currencySymbol: currencySymbol,
                currency: currency,
                showCharges: showChargesOptions,
                chargesType: chargesTypeValue,
                storeIsLive: false,
                allowCollections,
                disableDeliveries,
                manageStockLevels,
                colours,
                chargeDelivery:
                    {
                        minFee: minimumFee,
                        maxFee: maximumFee,
                        perUnit: perUnitFee,
                        standardFee: standardFeeValue,
                        showFree: showFreeDelOptions,
                        freeOver: freeOverValue
                    },
                openingHours: shiftTimes,
                openingDays: allowWeekday
            },
            email: {
                welcomeEmail: {
                    title: email.welcomeEmail.title,
                    body: email.welcomeEmail.body
                },
                newOrder: {
                    title: email.newOrder.title,
                    body: email.newOrder.body
                },
                approvedOrder: {
                    title: email.approvedOrder.title,
                    body: email.approvedOrder.body
                },
                orderCollect: {
                    title: email.orderCollect.title,
                    body: email.orderCollect.body
                },
                orderDispatch: {
                    title: email.orderDispatch.title,
                    body: email.orderDispatch.body
                },
                orderComplete: {
                    title: email.orderComplete.title,
                    body: email.orderComplete.body
                }
            },
            invoicing: {
                logo: auth.appSettings.invoicing.logo,
                invoice: {
                    firstname: firstname,
                    lastname: lastname,
                    companyName: companyName,
                    addressOne: addressOne,
                    addressTwo: addressTwo,
                    accountEmail: accountEmail,
                    country: userCountry,
                    phone: phone,
                    postcode: postcode,
                    location
                },
                isBranded: isBranded,
                aboutCompany: aboutCompany,
                bacs: {
                    payeeName: bacs.payeeName,
                    bankName: bacs.bankName,
                    sortCode: bacs.sortCode,
                    accNumber: bacs.accNumber
                }
            },
            settingsValid: false,
            userToken: auth.userToken
        }
        doUpdate(submitData).then();
    }
    const doUpdate = async submitData => {
        try {
            await auth.setAppSettings(submitData)
            const userData = JSON.parse(localStorage.getItem('userData'))
            let { username, token, cartId, categoryId, stripeSettings, cartOrder,
                cartInvoices, userEmail, tickets, messages} = userData
            await localStorage.setItem('userData', JSON.stringify({
                username, token, cartId, categoryId, appSettings: submitData,
                stripeSettings, cartOrder, cartInvoices, userEmail, tickets, messages
            }))
        } catch (error) {

        }
        let token;
        try {
            token = await jwtToken.sign(
                {
                    username: auth.username,
                    submitData
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {expiresIn: '1h'}
            );
        } catch (err) {
            console.log(err)
        }
        await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/settings`, {}, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then((res) => {
                props.setSettingsLoaded(false);
                props.getSettings().then();
            })
            .catch(error => {
                console.log(error);
            });
    }
    const updateSettings = async () => {
        let submitData = {
            username: auth.username,
            shop: {
                showTax: showTaxOptions,
                vatNumber: vatValue,
                pricesBefore: showPriceTaxOptions,
                setGlobal: showGlobalOptions,
                globalRate: globalValue,
                closeShop: showCloseShop,
                minOrder: showMinOrderOptions,
                minValue: Number(minInputValue),
                restrictDist: showDistanceOptions,
                inputDist: inputDistValue,
                distUnits: inputDistUnits,
                allowInt: inputAllowInt,
                locale: locale,
                currencySymbol: currencySymbol,
                currency: currency,
                showCharges: showChargesOptions,
                chargesType: chargesTypeValue,
                storeIsLive: auth.appSettings.shop.storeIsLive,
                allowCollections,
                disableDeliveries,
                manageStockLevels,
                colours,
                chargeDelivery:
                    {
                        minFee: minimumFee,
                        maxFee: maximumFee,
                        perUnit: perUnitFee,
                        standardFee: standardFeeValue,
                        showFree: showFreeDelOptions,
                        freeOver: freeOverValue
                    },
                openingHours: shiftTimes,
                openingDays: allowWeekday
            },
            email: {
                welcomeEmail: {
                    title: email.welcomeEmail.title,
                    body: email.welcomeEmail.body
                },
                newOrder: {
                    title: email.newOrder.title,
                    body: email.newOrder.body,
                    collection: email.newOrder.collection,
                    delivery: email.newOrder.delivery
                },
                approvedOrder: {
                    title: email.approvedOrder.title,
                    body: email.approvedOrder.body,
                    collection: email.approvedOrder.collection,
                    delivery: email.approvedOrder.delivery
                },
                orderCollect: {
                    title: email.orderCollect.title,
                    body: email.orderCollect.body,
                    collection: email.orderCollect.collection,
                    delivery: email.orderCollect.delivery
                },
                orderComplete: {
                    title: email.orderComplete.title,
                    body: email.orderComplete.body,
                    collection: email.orderComplete.collection,
                    delivery: email.orderComplete.delivery
                }
            },
            invoicing: {
                logo: auth.appSettings.invoicing.logo,
                invoice: {
                    firstname: firstname,
                    lastname: lastname,
                    companyName: companyName,
                    addressOne: addressOne,
                    addressTwo: addressTwo,
                    accountEmail: accountEmail,
                    country: userCountry,
                    phone: phone,
                    postcode: postcode,
                    location
                },
                isBranded: isBranded,
                aboutCompany: aboutCompany,
                bacs: {
                    payeeName: bacs.payeeName,
                    bankName: bacs.bankName,
                    sortCode: bacs.sortCode,
                    accNumber: bacs.accNumber
                }
            },
            settingsValid: false,
            token: auth.userToken
        }
        doUpdate(submitData).then();
    }
    const handleShowTaxOptions = () => {
        setShowTaxOptions(prevMode => !prevMode);
    };
    // eslint-disable-next-line
    const handleManageStockLevels = () => {
        setManageStockLevels(prevMode => !prevMode)
    }
    const handleShowGlobalOptions = async () => {
        setShowGlobalOptions(prevMode => !prevMode);
        await updateSettings()
    };
    const handleShowMinOrderOptions = () => {
        setShowMinOrderOptions(prevMode => !prevMode);
    };
    const handleShowDistanceOptions = () => {
        setShowDistanceOptions(prevMode => !prevMode);
        if (inputAllowInt) {
            setInputAllowInt(false)
        }
    };
    const handleShowCloseShop = () => {
        setShowCloseShop(prevMode => !prevMode);
    };
    const handleShowIntSettings = async event => {
        await setInputAllowInt(prevMode => !prevMode);
        await setUserCountry(auth.appSettings.invoicing.invoice.country);
        // updateSettings().then();
    };
    const handlePriceShowTaxOptions = () => {
        setPriceShowTaxOptions(prevMode => !prevMode);
    };
    const handleShowChargesOptions = () => {
        setShowChargesOptions(prevMode => !prevMode);
    };
    const freeDeliveryHandler = () => {
        setShowFreeDelOptions(prevMode => !prevMode)
    }
    const handleShowChargeTaxInfo = () => {
        setShowChargeTaxInfo(prevMode => !prevMode);
        setShowPricesTaxInfo(false);
        setShowGlobalTaxInfo(false);
        setShowMinOrderInfo(false);
        setShowDistanceInfo(false);
        setShowInternationalInfo(false);
        setShowChargesInfo(false);
        setShowTimesInfo(false);
    };
    const handleShowPricesTaxInfo = () => {
        setShowChargeTaxInfo(false);
        setShowPricesTaxInfo(prevMode => !prevMode);
        setShowGlobalTaxInfo(false);
        setShowMinOrderInfo(false);
        setShowDistanceInfo(false);
        setShowInternationalInfo(false);
        setShowChargesInfo(false);
        setShowTimesInfo(false);
    };
    const handleShowGlobalTaxInfo = () => {
        setShowChargeTaxInfo(false);
        setShowPricesTaxInfo(false);
        setShowGlobalTaxInfo(prevMode => !prevMode);
        setShowMinOrderInfo(false);
        setShowDistanceInfo(false);
        setShowInternationalInfo(false);
        setShowChargesInfo(false);
        setShowTimesInfo(false);
    };
    const handleShowMinOrderInfo = () => {
        setShowMinOrderInfo(prevMode => !prevMode);
        setShowChargeTaxInfo(false);
        setShowPricesTaxInfo(false);
        setShowGlobalTaxInfo(false);
        setShowDistanceInfo(false);
        setShowInternationalInfo(false);
        setShowChargesInfo(false);
        setShowTimesInfo(false);
    };
    const handleShowDistanceInfo = () => {
        setShowChargeTaxInfo(false);
        setShowPricesTaxInfo(false);
        setShowGlobalTaxInfo(false);
        setShowMinOrderInfo(false);
        setShowDistanceInfo(prevMode => !prevMode);
        setShowInternationalInfo(false);
        setShowChargesInfo(false);
        setShowTimesInfo(false);
    };
    const handleShowInternationalInfo = () => {
        setShowChargeTaxInfo(false);
        setShowPricesTaxInfo(false);
        setShowGlobalTaxInfo(false);
        setShowMinOrderInfo(false);
        setShowDistanceInfo(false);
        setShowInternationalInfo(prevMode => !prevMode);
        setShowChargesInfo(false);
        setShowTimesInfo(false);
    };
    const handleShowChargesInfo = () => {
        setShowChargeTaxInfo(false);
        setShowPricesTaxInfo(false);
        setShowGlobalTaxInfo(false);
        setShowMinOrderInfo(false);
        setShowDistanceInfo(false);
        setShowInternationalInfo(false);
        setShowChargesInfo(prevMode => !prevMode);
        setShowTimesInfo(false);
    };
    const handleShowTimesInfo = () => {
        setShowChargeTaxInfo(false);
        setShowPricesTaxInfo(false);
        setShowGlobalTaxInfo(false);
        setShowMinOrderInfo(false);
        setShowDistanceInfo(false);
        setShowInternationalInfo(false);
        setShowChargesInfo(false);
        setShowTimesInfo(prevMode => !prevMode);
    };
    let mon, tue, wed, thur, fri, sat, sun;
    const handleShowWeekday = async event => {
        let weekday = event.target.name;
        switch (weekday) {
            case 'Monday':
                mon = !allowWeekday.monday
                tue = allowWeekday.tuesday;
                wed = allowWeekday.wednesday;
                thur = allowWeekday.thursday;
                fri = allowWeekday.friday;
                sat = allowWeekday.saturday;
                sun = allowWeekday.sunday;
                break;
            case 'Tuesday':
                mon = allowWeekday.monday
                tue = !allowWeekday.tuesday;
                wed = allowWeekday.wednesday;
                thur = allowWeekday.thursday;
                fri = allowWeekday.friday;
                sat = allowWeekday.saturday;
                sun = allowWeekday.sunday;
                break;
            case 'Wednesday':
                mon = allowWeekday.monday
                tue = allowWeekday.tuesday;
                wed = !allowWeekday.wednesday;
                thur = allowWeekday.thursday;
                fri = allowWeekday.friday;
                sat = allowWeekday.saturday;
                sun = allowWeekday.sunday;
                break;
            case 'Thursday':
                mon = allowWeekday.monday
                tue = allowWeekday.tuesday;
                wed = allowWeekday.wednesday;
                thur = !allowWeekday.thursday;
                fri = allowWeekday.friday;
                sat = allowWeekday.saturday;
                sun = allowWeekday.sunday;
                break;
            case 'Friday':
                mon = allowWeekday.monday
                tue = allowWeekday.tuesday;
                wed = allowWeekday.wednesday;
                thur = allowWeekday.thursday;
                fri = !allowWeekday.friday;
                sat = allowWeekday.saturday;
                sun = allowWeekday.sunday;
                break;
            case 'Saturday':
                mon = allowWeekday.monday
                tue = allowWeekday.tuesday;
                wed = allowWeekday.wednesday;
                thur = allowWeekday.thursday;
                fri = allowWeekday.friday;
                sat = !allowWeekday.saturday;
                sun = allowWeekday.sunday;
                break;
            case 'Sunday':
                mon = allowWeekday.monday
                tue = allowWeekday.tuesday;
                wed = allowWeekday.wednesday;
                thur = allowWeekday.thursday;
                fri = allowWeekday.friday;
                sat = allowWeekday.saturday;
                sun = !allowWeekday.sunday;
                break;
            default:
        }
        await setAllowWeekday({
            monday: mon, tuesday: tue, wednesday: wed, thursday: thur,
            friday: fri, saturday: sat, sunday: sun
        })
    }
    // const handleHideTimes = async () => {
    //     await setHideTimes(prevState => !prevState)
    //     await updateSettings().then(() => {
    //         props.getSettings().then()
    //     })
    // }
    const handleSetMinimumFee = event => {
        let userInput = event.target.value
        setMinimumFee(userInput)
    }
    const handleSetMaximumFee = event => {
        let userInput = event.target.value
        setMaximumFee(userInput)
    }
    const handleSetPerUnitFee = event => {
        let userInput = event.target.value
        if(userInput === ''){
            userInput = 0
        }
        setPerUnitFee(userInput)
    }
    const handleSetStandardFeeValue = event => {
        let userInput = event.target.value
        setStandardFeeValue(userInput)
    }
    const handleSetFreeOverValue = event => {
        let userInput = event.target.value
        setFreeOverValue(userInput)
    }
    const handleChangeVatNumber = event => {
        let inputValue = event.target.value;
        setVatValue(inputValue);
    }
    const handleChangeGlobalRate = event => {
        let inputValue = event.target.value;
        setGlobalValue(inputValue)
    }
    const handleChangeMinValue = event => {
        let inputValue = event.target.value;
        setMinInputValue(inputValue);
    }
    const handleChangeInputDist = event => {
        let inputValue = event.target.value;
        setInputDistValue(inputValue);
    }
    const handleChangeDistUnits = event => {
        let inputValue = event.target.value;
        setInputDistUnits(inputValue);
        if (inputAllowInt) {
            setInputAllowInt(true)
        }
    }
    const handleChargesTypeOptions = event => {
        let inputValue = event.target.value;
        setChargesTypeValue(inputValue);
    }
    const handleRemoveShift = async (weekday, index) => {
        let mondayShifts = shiftTimes[0],
            tuesShifts = shiftTimes[1],
            wedShifts = shiftTimes[2],
            thurShifts = shiftTimes[3],
            friShifts = shiftTimes[4],
            satShifts = shiftTimes[5],
            sunShifts = shiftTimes[6]
        if (weekday === 'Monday') {
            mondayShifts.splice(index, 1);
        }
        if (weekday === 'Tuesday') {
            tuesShifts.splice(index, 1);
        }
        if (weekday === 'Wednesday') {
            wedShifts.splice(index, 1);
        }
        if (weekday === 'Thursday') {
            thurShifts.splice(index, 1);
        }
        if (weekday === 'Friday') {
            friShifts.splice(index, 1);
        }
        if (weekday === 'Saturday') {
            satShifts.splice(index, 1);
        }
        if (weekday === 'Sunday') {
            sunShifts.splice(index, 1);
        }
        await setShiftTimes([
            mondayShifts, tuesShifts, wedShifts, thurShifts, friShifts, satShifts, sunShifts
        ])
        updateSettings().then();
    }
    const handleAddShift = async event => {
        event.preventDefault();
        let weekday = event.target.name;
        let mondayShifts = shiftTimes[0],
            tuesShifts = shiftTimes[1],
            wedShifts = shiftTimes[2],
            thurShifts = shiftTimes[3],
            friShifts = shiftTimes[4],
            satShifts = shiftTimes[5],
            sunShifts = shiftTimes[6]
        if (weekday === 'Monday') {
            await mondayShifts.push({fromHr: '11', fromMin: '15', toHr: '14', toMin: '00'})
        }
        if (weekday === 'Tuesday') {
            await tuesShifts.push({fromHr: '11', fromMin: '15', toHr: '14', toMin: '00'})
        }
        if (weekday === 'Wednesday') {
            await wedShifts.push({fromHr: '11', fromMin: '15', toHr: '14', toMin: '00'})
        }
        if (weekday === 'Thursday') {
            await thurShifts.push({fromHr: '11', fromMin: '15', toHr: '14', toMin: '00'})
        }
        if (weekday === 'Friday') {
            await friShifts.push({fromHr: '11', fromMin: '15', toHr: '14', toMin: '00'})
        }
        if (weekday === 'Saturday') {
            await satShifts.push({fromHr: '11', fromMin: '15', toHr: '14', toMin: '00'})
        }
        if (weekday === 'Sunday') {
            await sunShifts.push({fromHr: '11', fromMin: '15', toHr: '14', toMin: '00'})
        }
        await setShiftTimes([
            mondayShifts, tuesShifts, wedShifts, thurShifts, friShifts, satShifts, sunShifts
        ])
        updateSettings().then();
    }
    const handleChangeTimes = async event => {
        let currentShifts = shiftTimes;
        let day = event.target.name.split('&')[0];
        let type = event.target.name.split('&')[1];
        let index = event.target.name.split('&')[2];
        let hrMin = event.target.name.split('&')[3];
        let value = event.target.value;
        let weekday, newFromHrs, newToHrs, newFromMin, newToMin;
        if (day === 'Monday') {
            weekday = 0
        }
        if (day === 'Tuesday') {
            weekday = 1
        }
        if (day === 'Wednesday') {
            weekday = 2
        }
        if (day === 'Thursday') {
            weekday = 3
        }
        if (day === 'Friday') {
            weekday = 4
        }
        if (day === 'Saturday') {
            weekday = 5
        }
        if (day === 'Sunday') {
            weekday = 6
        }
        if (hrMin === 'hrs') {
            if (type === 'from') {
                newFromHrs = value
                newToHrs = currentShifts[weekday][index].toHr
                newFromMin = currentShifts[weekday][index].fromMin
                newToMin = currentShifts[weekday][index].toMin
            } else if (type === 'to') {
                newFromHrs = currentShifts[weekday][index].fromHr
                newToHrs = value
                newFromMin = currentShifts[weekday][index].fromMin
                newToMin = currentShifts[weekday][index].toMin
            }
        } else if (hrMin === 'min') {
            if (type === 'from') {
                newFromHrs = currentShifts[weekday][index].fromHr
                newToHrs = currentShifts[weekday][index].toHr
                newFromMin = value
                newToMin = currentShifts[weekday][index].toMin
            } else if (type === 'to') {
                newFromHrs = currentShifts[weekday][index].fromHr
                newToHrs = currentShifts[weekday][index].toHr
                newFromMin = currentShifts[weekday][index].fromMin
                newToMin = value
            }
        }
        currentShifts[weekday].splice([index], 1, {
            fromHr: newFromHrs,
            fromMin: newFromMin,
            toHr: newToHrs,
            toMin: newToMin
        })
        await setShiftTimes(currentShifts)
        // console.log({fromHr: newFromHrs, fromMin: newFromMin, toHr: newToHrs, toMin: newToMin})
    }
    const displayShiftItem = (props) => {
        let {fromHr, fromMin, toHr, toMin} = props.times,
            type = props.type, index = props.index, day = props.weekday;
        let from, to
        if (props.type === 'from') {
            from = fromHr
            to = fromMin;
        } else {
            from = toHr;
            to = toMin;
        }
        let hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
            '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
        let min = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
        let selectName = day + '&' + type + '&' + index + '&hrs';
        return (
            <>
                <select
                    name={selectName}
                    aria-label="Opening hour"
                    onInput={handleChangeTimes}
                    onChange={updateSettings}
                    value={from}
                    // className={"form-control"}
                    style={{
                        marginTop: '0', marginBottom: '0',
                        marginLeft: '0', marginRight: '0',
                        width: '45px'
                    }}
                >{hours.map(hour => <option value={hour}>{hour}</option>)
                }
                </select> <select
                aria-label="Closing time"
                onInput={handleChangeTimes}
                onChange={updateSettings}
                name={day + '&' + type + '&' + index + '&min'}
                value={to}
                // className={"form-control"}\
                style={{
                    marginTop: '0', marginBottom: '0',
                    marginLeft: '0', marginRight: '0',
                    width: '45px'
                }}
            >{min.map(time => <option value={time}>{time}</option>)
            }
            </select>
            </>
        )
    }
    const weekday = (weekday) => {
        let showThis, daysShifts;
        if (weekday === 'Monday') {
            showThis = allowWeekday.monday
            daysShifts = shiftTimes[0]
        }
        if (weekday === 'Tuesday') {
            showThis = allowWeekday.tuesday
            daysShifts = shiftTimes[1]
        }
        if (weekday === 'Wednesday') {
            showThis = allowWeekday.wednesday
            daysShifts = shiftTimes[2]
        }
        if (weekday === 'Thursday') {
            showThis = allowWeekday.thursday
            daysShifts = shiftTimes[3]
        }
        if (weekday === 'Friday') {
            showThis = allowWeekday.friday
            daysShifts = shiftTimes[4]
        }
        if (weekday === 'Saturday') {
            showThis = allowWeekday.saturday
            daysShifts = shiftTimes[5]
        }
        if (weekday === 'Sunday') {
            showThis = allowWeekday.sunday
            daysShifts = shiftTimes[6]
        }
        return (
            <div className={'row'} style={{marginTop:'10px'}}>
                <div style={{display: 'block', width: '100%'}}>
                    {weekday !== 'Monday' && <hr/>}
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-sx-12">
                    {showThis ?
                        <div>
                            <strong>{weekday}'s: </strong>
                        </div>
                        :
                        <div>
                            <strong style={{color: '#ea3c53', fontSize: '0.9em'}}><u>CLOSED!</u></strong>
                        </div>
                    }
                </div>
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-sx-12">
                    {/*// this is where the shifts for a specific day are mapped out */}
                    {showThis &&
                    daysShifts.map((times, index) =>
                        <ShiftItem key={'shift-item'} displayShiftItem={displayShiftItem}
                                   times={times} index={index} weekday={weekday}
                                   handleRemoveShift={handleRemoveShift}/>
                    )
                    }
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-sx-12">
                    <div>
                        {showThis ?
                            <Button onClick={handleAddShift} name={weekday} style={{
                                height: '25px', paddingTop: '0', backgroundColor: props.navButtonColor,
                                border: '0', color: 'white'
                            }}
                            className={'shadow-none'} >
                                <FontAwesomeIcon icon={faPlusSquare}/> SHIFT
                            </Button>
                            :
                            <strong>{weekday}: </strong>
                        }<span style={{float: 'right'}}>
                    {!showThis ?
                        <strong style={{color: '#ea3c53', fontSize: '0.9em', marginLeft: '-15px'}}><u>
                            <FontAwesomeIcon icon={faEyeSlash}/>
                        </u></strong>
                        :
                        <strong style={{color: '#2cc415', fontSize: '0.9em', marginLeft: '-15px'}}><u>
                            <FontAwesomeIcon icon={faEye}/>
                        </u></strong>
                    }
                </span><input type="checkbox"
                              style={{
                                  float: 'right', marginBottom: '0',
                                  width: '20px', marginTop: '-7px', marginRight: '23px'
                              }}
                              checked={showThis}
                              name={weekday}
                              onClick={handleShowWeekday}
                              className="form-control"
                              onInput={updateSettings}
                    />
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="container" style={{
            marginBottom: '0', overflow: 'hidden', paddingLeft: '0',
            paddingRight: '0', marginTop:'0'
        }}>
            {props.showOpeningHours ? <>
                {/*OPENING HOURS*/}
                    <div className="row" style={{marginTop: '0', paddingTop: '0'}}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12"
                             style={{marginTop: '0', paddingTop: '0', maxWidth: '900px',
                                 marginLeft:'auto', marginRight:'auto'
                             }}>
                            {showCloseShop &&
                            props.showOpeningHours &&
                            <>
                                {weekday('Monday')}
                                {weekday('Tuesday')}
                                {weekday('Wednesday')}
                                {weekday('Thursday')}
                                {weekday('Friday')}
                                {weekday('Saturday')}
                                {weekday('Sunday')}
                            </>
                            }
                        </div>
                    </div>
                </>
                :
                <div className="row" style={{marginTop: '0', paddingTop: '0'}}>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12"
                         style={{marginTop: '0', paddingTop: '0', maxWidth:'1000px',
                                 marginLeft: 'auto', marginRight: 'auto'}}>
                        <div className={'hide-nav-toggle'}>
                            <span style={{height:'40px', display:'block', width:'100%'}}/>
                        </div>
                        {auth.appSettings.invoicing.invoice.location.lat +
                        auth.appSettings.invoicing.invoice.location.lng === 0 &&
                        <div style={{marginTop: '0', fontSize: '0.9em', textAlign: 'center'}}>
                            <Alert key={'More settings available'} variant={'danger'}>
                                <strong>IMPORTANT:</strong> Please provide your address
                                in the <span style={{cursor:'pointer', textDecoration:'underline'}}
                                             onClick={props.handleShowInvoiceSettings}>
                                <strong>MY ACCOUNT</strong>
                            </span> area; we need it so that we can generate your invoices for you.
                            </Alert>
                        </div>
                        }
                        {/*<div style={{display: 'block', marginTop: '10px', paddingTop: '7px'}}>*/}
                        {/*    <p style={{height:'40px'}}><FontAwesomeIcon*/}
                        {/*        onClick={handleShowGlobalTaxInfo}*/}
                        {/*        style={{*/}
                        {/*            marginRight: '7px', float: 'left',*/}
                        {/*            marginTop: '4px'*/}
                        {/*        }} icon={faInfoCircle}/>*/}
                        {/*        <label className="switch">*/}
                        {/*            <input type="checkbox"*/}
                        {/*                   style={{*/}
                        {/*                       float: 'right', marginBottom: '0',*/}
                        {/*                       width: '20px', marginTop: '0'*/}
                        {/*                   }}*/}
                        {/*                   checked={manageStockLevels}*/}
                        {/*                   className="form-control"*/}
                        {/*                   placeholder="Manage Stock Levels"*/}
                        {/*                   id="manage-stock-levels"*/}
                        {/*                   name='manage-stock-levels'*/}
                        {/*                   onClick={handleManageStockLevels}*/}
                        {/*                   onInput={updateSettings}*/}
                        {/*            />*/}
                        {/*            <span className="slider round"> </span>*/}
                        {/*        </label>*/}
                        {/*        <span style={{paddingTop: '5px'}}>*/}
                        {/*            <strong>Manage Stock Levels?</strong></span>*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                        {/*<hr />*/}
                        {auth.appSettings.invoicing.invoice.country !== 'United Kingdom' &&
                        <>
                            <div style={{height: '40px', display: 'block', margin: '0', padding: '0'}}>
                                <p>
                                    <>
                                        <select id="max-distance-units"
                                                style={{
                                                    float: 'right', marginTop: '-7px',
                                                    width: '250px', height: '35px',
                                                    marginRight: '0', marginBottom: '0',
                                                    marginLeft: '0', paddingTop: '4px'
                                                }}
                                                name="status"
                                                className="form-control"
                                                aria-label="Set maximum distance"
                                                onInput={handleChangeLocale}
                                                value={locale}
                                                onBlur={updateSettings}
                                        >
                                            {timeZones.map(zone =>
                                                <ListCountries key={zone} zone={zone}/>)}
                                        </select>
                                        <span style={{float: 'left'}}>
                                        <strong>Locale</strong>
                                </span>

                                    </>
                                </p>
                            </div>
                            <hr/>
                            <div style={{marginTop: '0', paddingTop: '0'}}>
                                <div style={{
                                    height: '40px', display: 'block',
                                    margin: '0', padding: '0'
                                }}>
                                    <p>
                                        <>
                                            <select id="max-distance-units"
                                                    style={{
                                                        float: 'right', marginTop: '-7px',
                                                        width: '80px', height: '35px',
                                                        marginBottom: '0',
                                                        marginLeft: '7px', paddingTop: '4px'
                                                    }}
                                                    name="status"
                                                    className="form-control"
                                                    aria-label="Set maximum distance"
                                                    onInput={handleChangeCurrency}
                                                    value={currency}
                                                    onChange={updateSettings}
                                            >
                                                {assets.currencies.map(currency =>
                                                    <option value={currency}>
                                                        {currency}
                                                    </option>)}
                                            </select>
                                            <select id="max-distance-units"
                                                    style={{
                                                        float: 'right', marginTop: '-7px',
                                                        width: '55px', height: '35px',
                                                        marginRight: '0', marginBottom: '0',
                                                        marginLeft: '7px', paddingTop: '4px',
                                                        paddingLeft: '3px', paddingRight: '3px',
                                                        fontSize: '0.9em'
                                                    }}
                                                    name="status"
                                                    className="form-control"
                                                    aria-label="Set maximum distance"
                                                    onInput={handleChangeCurrencySymbol}
                                                    value={currencySymbol}
                                                    onChange={updateSettings}
                                            >
                                                {currencySymbols.map(symbol =>
                                                    <option value={symbol}>
                                                        {symbol}
                                                    </option>)}
                                            </select>
                                            <span style={{float: 'left'}}>
                                        <strong>Currency</strong>
                                    </span>

                                        </>
                                    </p>
                                </div>
                            </div>
                            <hr/>
                        </>
                        }
                        <ShopSettingsRow infoOnClick={handleShowChargeTaxInfo}
                                         minRowHeight={minRowHeight}
                                         checkboxChecked={showTaxOptions}
                                         checkboxOnChange={handleShowTaxOptions}
                                         checkboxOnBlur={updateSettings}
                                         inputOnBlur={updateSettings}
                                         inputOnChange={handleChangeVatNumber}
                                         inputValue={vatValue}
                                         showInfoOptions={showChargeTaxInfo}
                                         settingsTitle={'Charge taxes?'}
                                         checkboxId="vat-number-checkbox"
                                         checkboxName='vat-number-checkbox'
                                         checkboxLabel={'Charge tax?'}
                                         inputLabel={'Company VAT number'}
                                         inputPlaceholder={'VAT Number'}
                                         inputId={'vat-number'}
                                         inputName={'vat-number'}
                                         settingsText={'Charge tax? (VAT number)'}
                                         infoText={'Choose to show a breakdown of tax on your invoices and set globally ' +
                                         'or on a product by product basis according to current tax codes.'}
                    />
                        {showTaxOptions &&
                        <>
                            <ShopSettingsRow infoOnClick={handleShowPricesTaxInfo}
                                             minRowHeight={minRowHeight}
                                             checkboxChecked={pricesBefore}
                                             checkboxOnChange={handlePriceShowTaxOptions}
                                             checkboxOnInput={updateSettings}
                                             checkboxLabel={'Show prices before?'}
                                             checkboxId="prices-before-tax"
                                             checkboxName='prices-before-tax'
                                             simpleRow={true}
                                             showInfoOptions={showPricesTaxInfo}
                                             infoText={'Your store will include tax in the prices by default and give a' +
                                             'breakdown of charges at checkout and in the invoice. You can change' +
                                             'this to display "before tax" prices in your cart.'}
                            />
                        </>
                        }
                        {showTaxOptions &&
                        <ShopSettingsRow infoOnClick={handleShowGlobalTaxInfo}
                                         minRowHeight={minRowHeight}
                                         checkboxChecked={showGlobalOptions}
                                         checkboxOnChange={handleShowGlobalOptions}
                                         checkboxOnBlur={updateSettings}
                                         inputOnBlur={updateSettings}
                                         inputOnChange={handleChangeGlobalRate}
                                         inputValue={globalValue}
                                         showInfoOptions={showGlobalTaxInfo}
                                         settingsTitle={'Set global tax rate?'}
                                         settingsText={'Set global tax rate?'}
                                         input={true}
                                         inputType={'tax-rate'}
                                         infoText={'Tax can be calculated on a product by product basis (set in the ' +
                                             'cart editor) however if you wish to se a global rate for all ' +
                                             'your products (to save time) then use this option.'}
                        />
                        }
                            <ShopSettingsRow infoOnClick={handleShowMinOrderInfo}
                                             minRowHeight={minRowHeight}
                                             checkboxChecked={showMinOrderOptions}
                                             checkboxOnChange={handleShowMinOrderOptions}
                                             checkboxOnBlur={updateSettings}
                                             inputOnBlur={updateSettings}
                                             inputOnChange={handleChangeMinValue}
                                             inputValue={minInputValue}
                                             inputPlaceholder={'Minimum value in (' + currencySymbol + ')' }
                                             inputId={'minimum-value'}
                                             inputName={'minimum-value'}
                                             showInfoOptions={showMinOrderInfo}
                                             settingsTitle={'Minimum order amount?'}
                                             settingsText={'Minimum order:'}
                                             infoText={'If you would like to set a minimum amount for an order then ' +
                                             'use this setting. This will apply to all your orders.'}
                            />


                        {auth.appSettings.invoicing.invoice.location.lat +
                        auth.appSettings.invoicing.invoice.location.lng !== 0 &&
                        <>
                            <div style={{paddingTop: '0', minHeight: '70px' , marginTop: '0'}}>
                                <p><FontAwesomeIcon
                                    onClick={handleShowDistanceInfo}
                                    style={showDistanceOptions ?
                                        {marginLeft: '7px', float: 'right', marginTop: '30px'}
                                        :
                                    {marginLeft: '7px', float: 'right', marginTop: '10px'}} icon={faInfoCircle}/>
                                    <label className="switch" style={showDistanceOptions ? {marginTop: '20px'} : {marginTop: '0'}}>
                                        <input type="checkbox"
                                               style={{
                                                   float: 'left', marginBottom: '0',
                                                   width: '20px', marginTop: '0'
                                               }}
                                               checked={showDistanceOptions}
                                               className="form-control"
                                               id="distance-options"
                                               name='distance-options'
                                               onChange={handleShowDistanceOptions}
                                               onInput={updateSettings}
                                        />
                                        <span className="slider round"> </span>
                                    </label>
                                    {showDistanceOptions &&
                                    <>
                                        <div>
                                            <span style={{
                                                fontSize: '0.9em', marginBottom: '0',
                                                marginTop: '0', float: 'left', fontWeight:'bold'
                                            }}>
                                                Restrict distance {!showDistanceOptions && 'for orders?'}
                                            </span><br/>
                                            <input type="text"
                                                   style={{
                                                       float: 'left', marginTop: '0',
                                                       width: '55px', height: '40px',
                                                       marginRight: '10px', marginBottom: '0'
                                                   }}
                                                   className="form-control"
                                                   placeholder={'20'}
                                                   id="restrict-distance"
                                                   name='restrict-distance'
                                                   onChange={handleChangeInputDist}
                                                   value={inputDistValue}
                                                   onBlur={updateSettings}
                                            />
                                            <select id="max-distance-units"
                                                    style={{float: 'left', marginTop: '0', paddingLeft:'0',
                                                        maxWidth: '100px', height: '40px', marginRight: '10px',
                                                        marginBottom: '0'
                                                    }}
                                                    name="status"
                                                    className="form-control"
                                                    aria-label="Set maximum distance"
                                                    onChange={handleChangeDistUnits}
                                                    value={inputDistUnits}
                                                    onBlur={updateSettings}
                                            >
                                                {distUnits === 'Miles' ?
                                                    <>
                                                        <option value={'Miles'}>
                                                            Miles
                                                        </option>
                                                        <option value={'Km'}>
                                                            Km
                                                        </option>
                                                    </>
                                                    :
                                                    <>
                                                        <option value={'Km'}>
                                                            Km
                                                        </option>
                                                        <option value={'Miles'}>
                                                            Miles
                                                        </option>
                                                    </>
                                                }
                                            </select>

                                        </div>
                                    </>}
                                    {!showDistanceOptions &&
                                        <>
                                            <span style={{paddingTop: '5px'}}>
                                                <strong>Restrict distance {!showDistanceOptions && 'for orders?'}</strong>
                                            </span>

                                        </>

                                    }
                                </p>
                            </div>
                            <hr />
                        </>
                        }
                        {showDistanceInfo &&
                        <div>
                            <p>
                                You can restrict those who live outside of your catchment area from
                                registering and ordering on your site using this setting.
                            </p>
                        </div>}
                        {!showDistanceOptions &&
                        <>
                            <ShopSettingsRow infoOnClick={handleShowInternationalInfo}
                                             minRowHeight={minRowHeight}
                                             checkboxChecked={allowInt}
                                             checkboxOnChange={handleShowIntSettings}
                                             checkboxOnInput={updateSettings}
                                             checkboxLabel={'Allow international?'}
                                             checkboxId="allow-international"
                                             checkboxName='allow-international'
                                             simpleRow={true}
                                             showInfoOptions={showInternationalInfo}
                                             infoText={'The cart is setup to only take orders in the same ' +
                                             'country as the shop however this can be changed using the ' +
                                             'above settings.'}
                            />
                        </>
                        }
                        <ShopSettingsRow infoOnClick={handleShowCollectInfo}
                                         minRowHeight={minRowHeight}
                                         checkboxChecked={allowCollections}
                                         checkboxOnChange={handleAllowCollections}
                                         checkboxOnInput={updateSettings}
                                         checkboxLabel={'Allow customers to collect?'}
                                         checkboxId="allow-collections"
                                         checkboxName='allow-collections'
                                         simpleRow={true}
                                         showInfoOptions={showCollectInfo}
                                         infoText={'Allow your customers to collect orders from your store'}
                        />
                        {allowCollections &&
                        <>
                            <ShopSettingsRow infoOnClick={handleShowDisableInfo}
                                             minRowHeight={minRowHeight}
                                             checkboxChecked={disableDeliveries}
                                             checkboxOnChange={handleDisableDeliveries}
                                             checkboxOnInput={updateSettings}
                                             checkboxLabel={'Disable deliveries?'}
                                             checkboxId="disable-deliveries"
                                             checkboxName='disable-deliveries'
                                             simpleRow={true}
                                             showInfoOptions={showDisableInfo}
                                             infoText={'You can set it so that your customers can ' +
                                             'only collect from your store by selecting' +
                                             'this option. Deliveries are set to true as standard.'}
                            />
                        </>
                        }


                        {!disableDeliveries &&
                        <>
                            <ShopSettingsRow infoOnClick={handleShowChargesInfo}
                                             minRowHeight={minRowHeight}
                                             checkboxChecked={showChargesOptions}
                                             checkboxOnChange={handleShowChargesOptions}
                                             checkboxOnBlur={updateSettings}
                                             inputOnBlur={updateSettings}
                                             inputOnChange={handleChargesTypeOptions}
                                             inputValue={chargesTypeValue}
                                             showInfoOptions={showChargesInfo}
                                             settingsTitle={'Charge for deliveries?'}
                                             settingsText={'Type of delivery charges:'}
                                             input={true}
                                             inputType={'delivery-charges'}
                                             infoText={'Tax can be calculated on a product by product basis (set in the ' +
                                             'cart editor) however if you wish to se a global rate for all ' +
                                             'your products (to save time) then use this option.'}
                            />
                            {showChargesInfo &&
                            <p>
                                You can add delivery charges to orders and will depend on the type
                                of order your customer is placing (click and collect or home delivery). <br/>
                                <strong>Standard:</strong> You can opt to charge a flat rate for
                                all deliveries. <br/>
                                <strong>Advanced:</strong> You can set minimum, maximum and per
                                mile/km charges.
                            </p>
                            }
                        </>
                        }
                        {showChargesOptions && chargesTypeValue === 'Standard' &&
                        <>
                            <ShopSettingsRow infoOnClick={handleShowChargesInfo}
                                             minRowHeight={minRowHeight}
                                             rowOptionItem={true}
                                             settingsTitle={'Standard charge per order'}
                                             inputValue={standardFeeValue}
                                             inputOnBlur={updateSettings}
                                             inputOnChange={handleSetStandardFeeValue}
                                             currencySymbol={auth.appSettings.shop.currencySymbol}
                            />
                        </>
                        }
                        {showChargesOptions && chargesTypeValue === 'Advanced' &&
                        <>
                            <ShopSettingsRow infoOnClick={null}
                                             minRowHeight={minRowHeight}
                                             rowOptionItem={true}
                                             settingsTitle={'Minimum charge per order'}
                                             inputValue={minimumFee}
                                             inputOnBlur={updateSettings}
                                             inputOnChange={handleSetMinimumFee}
                                             currencySymbol={auth.appSettings.shop.currencySymbol}
                            />
                        </>
                        }
                        {showChargesOptions && chargesTypeValue === 'Advanced' &&
                        <>
                            <ShopSettingsRow infoOnClick={null}
                                             minRowHeight={minRowHeight}
                                             rowOptionItem={true}
                                             settingsTitle={'Maximum charge per order'}
                                             inputValue={maximumFee}
                                             inputOnBlur={updateSettings}
                                             inputOnChange={handleSetMaximumFee}
                                             currencySymbol={auth.appSettings.shop.currencySymbol}
                            />
                        </>}
                        {showChargesOptions && chargesTypeValue === 'Advanced' &&
                        <>
                            <ShopSettingsRow infoOnClick={null}
                                             minRowHeight={minRowHeight}
                                             rowOptionItem={true}
                                             settingsTitle={'Distance charges per unit'}
                                             inputValue={perUnitFee}
                                             inputOnBlur={updateSettings}
                                             inputOnChange={handleSetPerUnitFee}
                                             currencySymbol={auth.appSettings.shop.currencySymbol}
                            />
                        </>
                        }
                        {showChargesOptions &&
                        <>
                            <ShopSettingsRow infoOnClick={handleShowChargesInfo}
                                             minRowHeight={minRowHeight}
                                             checkboxChecked={showFreeDelOptions}
                                             checkboxOnChange={freeDeliveryHandler}
                                             checkboxOnBlur={updateSettings}
                                             inputOnBlur={updateSettings}
                                             inputOnChange={handleSetFreeOverValue}
                                             inputValue={freeOverValue}
                                             inputPlaceholder={'Free over (' + currencySymbol + ')' }
                                             inputId={'free-over'}
                                             inputName={'free-over'}
                                             showInfoOptions={false}
                                             settingsTitle={'Free for orders over value?'}
                                             settingsText={'Free for orders over:'}
                                             infoText={null}
                            />
                            </>
                        }

                            <ShopSettingsRow infoOnClick={handleShowTimesInfo}
                                             minRowHeight={minRowHeight}
                                             checkboxChecked={showCloseShop}
                                             checkboxOnChange={handleShowCloseShop}
                                             checkboxOnInput={updateSettings}
                                             checkboxLabel={'Switch cart OFF out of hours?'}
                                             checkboxId="switch-cart-off"
                                             checkboxName='switch-cart-off'
                                             simpleRow={true}
                                             showInfoOptions={showTimesInfo}
                                             infoText={'If you only want to take orders when you\'re open ' +
                                             'then select this option and allocate which days you are open. ' +
                                             'You can also add shifts if you are open more than once by ' +
                                             'clicking the "Add Shift" button.'}
                            />
                        {showCancelAccount && auth.appSettings.shop.storeIsLive &&
                        <div>
                            <hr/>
                            <Button variant="danger" style={{float: 'right'}} onClick={handleCancelAccount}>
                                <FontAwesomeIcon className={'nav-logout'} icon={faUserTimes}/>
                                <span style={{marginRight: '17px'}}>CANCEL SUBSCRIPTION</span>
                            </Button><p>
                            If you would like to cancel your payment (but still have access to your store
                            should you want to activate it again) then please confirm.
                        </p>

                        </div>
                        }
                    </div>
                </div>
            }

        </div>
    );
}

export default ShopSettings;
