import React, {useContext, useState} from 'react';
import AmountDisplay from "../AmountDisplay";
import AuthContext from "../../shared/auth-context";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCaretSquareDown, faCaretSquareUp } from "@fortawesome/free-solid-svg-icons";
import PriceOptions from "../PriceOptions";

const ProductItem = props => {
    const auth = useContext(AuthContext)
    let { priceButtonColor, product} = props
    const [ showOptions, setShowOptions ] = useState(true)
    let globalValue;
    if(props.basketItems.length > 0){
        for(let i=0 ; i<props.basketItems.length ; i++){
            if(props.basketItems[i].productId === props.productId &&
                props.basketItems[i].index === undefined){
                globalValue = props.basketItems[i].value;
            }
        }
    }
    if(!globalValue){
        globalValue = 0;
    }
    const [ currentValue, setCurrentValue ] = useState(globalValue);

    const showProductOptionsHandler = () => {
        setShowOptions(prevState => !prevState)
    }
    const addItem = event => {
        event.preventDefault();
            let newValue = currentValue + 1;
            setCurrentValue(newValue);
            props.addToBasketValue({
                price: props.price,
                productId: props.productId,
                optionId: null,
                productName: props.productName,
                quantities: props.product.quantities,
                image: props.image,
                taxRate: props.taxRate,
                index: undefined
            });
    }
    const removeItem = async event => {
        event.preventDefault();
        if(props.basketItems.length > 0){
            await props.takeFromBasketValue({
                index: props.product.index,
                price: props.price,
                optionId: null,
                productId: props.productId,
                productName: props.productName
            });
        }
    }

        return (
            product.status !== 'De-active' &&
            <div style={{marginBottom: '10px'}}
                 id={'product-' + props.index + '-' +  props.productId}
                 className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                <div className="card-body">
                    <div className="card" style={{padding: '10px', backgroundColor: "transparent"}}>
                        <table style={{width: '100%'}}>
                            <tbody >
                            <tr>
                                <td colSpan="2" rowSpan="2">
                                    <img alt={props.productName} className=" admin-nav-toggle product-image"
                                         src={props.image}/>
                                    <img alt={props.productName} className="hide-nav-toggle product-image"
                                         src={props.image} style={{width: '70px', height: 'auto'}}/>
                                </td>
                                <td colSpan="6"><h5>{props.productName}</h5></td>
                            </tr>
                            <tr>
                                <td colSpan="3">
                                    <p style={{
                                        marginBottom: '4px', lineHeight: '1.2em',
                                        fontSize: '0.7em'
                                    }}>
                                    <span style={{fontSize: '1.6em'}}>
                                        <strong>{auth.appSettings.shop.currencySymbol}{props.price.toFixed(2)}</strong>
                                    </span><br />
                                        /{props.quantities}{props.product.priceOptions.length > 0 &&
                                    <><br />
                                        <span onClick={showProductOptionsHandler} style={{
                                            marginTop: '3px',
                                            lineHeight: '2.2em',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            backgroundColor: props.priceButtonColor,
                                            color: '#fff',
                                            paddingLeft: '4px', paddingRight: '4px',
                                            borderRadius: '5px 5px 5px 5px'
                                        }}> {!showOptions ? <> More <FontAwesomeIcon style={{marginTop: '0'}}
                                                                                     icon={faCaretSquareDown}/></>
                                            :
                                            <> Hide <FontAwesomeIcon style={{marginTop: '0'}}
                                                                     icon={faCaretSquareUp}/></>
                                        }</span>
                                    </>}
                                    </p>
                                </td>
                                <td style={{width: '40px'}}>
                                    <Button className="product-buttons product-controls shadow-none"
                                            style={{
                                                backgroundColor: props.priceButtonColor,
                                                color: 'white'
                                            }}>
                                        <span style={{padding: '0', fontSize: '3.4em', margin: '0', lineHeight: '35px'}}
                                              onClick={removeItem}>
                                            -
                                        </span>
                                    </Button>
                                </td>
                                <td style={{width: '40px'}}>
                                    <AmountDisplay currentValue={globalValue}
                                                   valueColor={props.valueColor}
                                    />
                                </td>
                                <td style={{width: '40px'}}>
                                    <Button className="product-buttons product-controls shadow-none"
                                            style={{backgroundColor: props.priceButtonColor,
                                                color: 'white'}}>
                                     <span style={{padding: '0', fontSize: '3.4em', margin: '0', lineHeight: '35px'}}
                                           onClick={addItem}>
                                         +
                                     </span>
                                    </Button>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        {showOptions && props.product.priceOptions.length > 0 &&
                        <>
                            <hr />
                            <table style={{width: '100%'}}>
                                <tbody>
                                {props.product.priceOptions.map((row, index) =>
                                        <PriceOptions key={'options-' + index + '-' + row.productId}
                                                      priceButtonColor={priceButtonColor}
                                                      row={row}
                                                      currentValue={currentValue}
                                                      index={index}
                                                      buttonColor={props.buttonColor}
                                                      valueColor={props.valueColor}
                                                      addToBasketValue={props.addToBasketValue}
                                                      productName={props.productName}
                                                      productId={props.product._id}
                                                      image={props.image}
                                                      taxRate={props.taxRate}
                                                      takeFromBasketValue={props.takeFromBasketValue}
                                                      basketItems={props.basketItems}
                                                      price={row.price}
                                                      loadedProducts={props.loadedProducts}
                                                      product={props.product}
                                        />
                                    // <div className={'pricing-container'} style={{marginTop: '0'}}>
                                    //     <div className={'Qty'}>
                                    //         {row.quantities}
                                    //     </div>
                                    //     <div className={'Price'} style={{fontWeight: 'bold'}}>
                                    //         {auth.appSettings.shop.currencySymbol}{row.price}
                                    //     </div>
                                    //     <div className={'Reduce'}>
                                    //         <Button className="product-buttons product-controls"
                                    //                 style={{backgroundColor: props.buttonColor}}>
                                    //             <span style={{padding: '0', fontSize: '3.4em', margin: '0', lineHeight: '35px'}}
                                    //                   onClick={removeItem}>
                                    //                 -
                                    //             </span>
                                    //         </Button>
                                    //     </div>
                                    //     <div className={'Display'}>
                                    //         <AmountDisplay currentValue={globalValue}
                                    //                        valueColor={props.valueColor}
                                    //         />
                                    //     </div>
                                    //     <div className={'Plus'}>
                                    //         <Button className="product-buttons product-controls"
                                    //                 style={{backgroundColor: props.buttonColor}}>
                                    //          <span style={{padding:'0', fontSize: '3.4em', margin:'0', lineHeight: '35px'}}
                                    //                onClick={addItem}>
                                    //              +
                                    //          </span>
                                    //         </Button>
                                    //     </div>
                                    // </div>
                                )}
                                </tbody>
                            </table>
                        </>

                        }
                    </div>
                </div>
            </div>



        );
}

export default ProductItem;


// <div className="product-container">
//     <div className="Image">
//         <img alt={props.productName} className="product-image"
//              src={props.image}/>
//     </div>
//     <div className="Title"><h4>{props.productName}</h4></div>
//     <div className="Price" style={{paddingTop:'10px'}}>
//         {props.product.priceOptions.length === 0 && <p> </p>}
//         <p style={{
//             marginBottom: '4px', lineHeight: '1.2em',
//             fontSize: '0.7em'
//         }}>
//                                     <span style={{fontSize: '1.6em'}}>
//                                         <strong>{auth.appSettings.shop.currencySymbol}{props.price.toFixed(2)}</strong><br/>
//                                     </span>
//             /{props.quantities}{props.product.priceOptions.length > 0 &&
//         <><br/>
//             <span onClick={showProductOptionsHandler} style={{
//                 marginTop: '3px', lineHeight: '2.2em', fontWeight: 'bold', cursor: 'pointer',
//                 backgroundColor: props.buttonColor, color: '#fff',
//                 padding: '4px', borderRadius: '5px 5px 5px 5px'
//             }}>More <FontAwesomeIcon style={{marginTop: '0'}}
//                                      icon={faCaretSquareDown}/></span>
//         </>}
//         </p>
//     </div>
//     <div className="Reduce" style={{paddingTop: '15px'}}>
//         <Button className="product-buttons product-controls"
//                 style={{backgroundColor: props.buttonColor}}>
//                                         <span style={{padding: '0', fontSize: '3.4em', margin: '0', lineHeight: '35px'}}
//                                               onClick={removeItem}>
//                                             -
//                                         </span>
//         </Button>
//     </div>
//     <div className="Display" style={{paddingTop: '15px'}}>
//         <AmountDisplay currentValue={globalValue}
//                        valueColor={props.valueColor}
//         />
//     </div>
//     <div className="Plus" style={{paddingTop: '15px'}}>
//         <Button className="product-buttons product-controls"
//                 style={{backgroundColor: props.buttonColor}}>
//                                      <span style={{padding:'0', fontSize: '3.4em', margin:'0', lineHeight: '35px'}}
//                                            onClick={addItem}>
//                                          +
//                                      </span>
//         </Button>
//     </div>
// </div>
