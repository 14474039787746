
export const BuildAndCheckPassword = async (userInput, setCurrentPassword, setLongEnough,
                                     setContainsNumber, setContainsUppercase,
                                     setContainsSpecial) => {
    setCurrentPassword(userInput)
    let hasNumber = 0, hasSpecial = 0, hasEnough = 0, hasUpper = 0;
    let testString = /^[A-Za-z0-9 ]+$/

    //Validate TextBox value against the Regex.
    let isValid = testString.test(userInput);
    if (!isValid) {
        hasSpecial = 1;
    }
    if (userInput.length > 7) {
        hasEnough = 1;
    } else {
        hasEnough = 0;
    }
    hasNumber = userInput.replace(/[^0-9]/g, "").length;
    hasUpper = userInput.replace(/[^A-Z]/g, "").length;

    if (hasEnough === 1) {
        setLongEnough(true);
    } else {
        setLongEnough(false);
    }
    if (hasNumber >= 1) {
        setContainsNumber(true);
    } else {
        setContainsNumber(false);
    }
    if (hasUpper >= 1) {
        setContainsUppercase(true);
    } else {
        setContainsUppercase(false);
    }
    if (hasSpecial > 0) {
        if (userInput.length > 0) {
            setContainsSpecial(true);
        }
    } else {
        setContainsSpecial(false);
    }
}

export const ConfirmPassword = (containsNumber, longEnough, containsUppercase, containsSpecial,
                                setShowConfirmPassword) => {
    if (containsNumber && longEnough && containsUppercase && containsSpecial) {
        setShowConfirmPassword(true);
    } else {
        setShowConfirmPassword(false);
    }
}
export const ConfirmPasswords = (event, currentPassword, setPasswordsLengthMatch, setPasswordsMatch) => {
    if (event.target.value.length === currentPassword.length) {
        setPasswordsLengthMatch(true);
    } else {
        setPasswordsLengthMatch(false);
    }
    if (event.target.value === currentPassword) {
        setPasswordsMatch(true);
    } else {
        setPasswordsMatch(false);
    }
}

// const confirmPassword = () => {
//     if (containsNumber && longEnough && containsUppercase && containsSpecial) {
//         setShowConfirmPassword(true);
//     } else {
//         setShowConfirmPassword(false);
//     }
// }
// const confirmPasswords = event => {
//     event.preventDefault();
//     if (event.target.value.length === currentPassword.length) {
//         setPasswordsLengthMatch(true);
//         console.log('lengths the same')
//     } else {
//         setPasswordsLengthMatch(false);
//     }
//     if (event.target.value === currentPassword) {
//         setPasswordsMatch(true);
//     } else {
//         setPasswordsMatch(false);
//     }
// }

// module.exports.buildAndCheckPassword = buildAndCheckPassword;
// module.exports.confirmPassword = confirmPassword;
// module.exports.confirmPasswords = confirmPasswords;
