import React, {useContext, useState} from 'react';
import '../../css/CheckoutForm.css'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import axios from "axios";
import AuthContext from "../shared/auth-context";
import jwtToken from 'jsonwebtoken';

const CheckoutForm = props => {
    const auth = useContext(AuthContext)
    const orderId = props.orderId;
    let stripe = useStripe();
    let elements = useElements();
    const CARD_OPTIONS = {
        iconStyle: 'solid',
        style: {
            base: {
                iconColor: '#c4f0ff',
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#fce883',
                },
                '::placeholder': {
                    color: '#87bbfd',
                },
            },
            invalid: {
                iconColor: '#ffc7ee',
                color: '#ffc7ee',
            },
        },
    };
    const [ message, setMessage ] = useState(null)

    const handleSubmit = async event => {
        event.preventDefault();
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                email: auth.customer.email
            }
        });
        let messageData = ''
        if (!error) {
            const {id} = paymentMethod;
                let token;
                try{
                    token = await jwtToken.sign(
                        {
                            id, orderId,
                            publicKey: props.publicKey,
                            email: auth.customer.email
                        },
                        `${process.env.REACT_APP_JWT_KEY}`,
                        {
                            expiresIn: '1h'
                        }
                    );
                }catch(err){console.log(err)}
                await axios.post(`${process.env.REACT_APP_SITE_URL}/api/payment`, {}, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }).then(async (res) => {
                    messageData = res.data.message
                    if(!messageData){
                        await auth.setCustomersOrders(res.data.customerOrders)
                        await props.handleNewOrderSuccess(res.data.customerOrders)

                    } else {
                        await setMessage(messageData)
                        await props.setLoading(false)
                    }
                    // This is where we want to send the approved email (if auto approve).

                }).catch(error => {
                    console.log(error)
                });

        }
    };



    return (
        <>
            <form
                onSubmit={handleSubmit}
                style={{maxWidth: "400px", margin: "0 auto"}}>
                <CardElement options={CARD_OPTIONS}/>
                {message &&
                <p>{message}</p>
                }
                <button type="submit" hidden={true} id={'stripe-pay-button'} disabled={!stripe}>
                    Pay
                </button>
            </form>
        </>
    );
};

export default CheckoutForm;
