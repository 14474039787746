import React from 'react';
import Button from "react-bootstrap/Button";


const BasketPlusButton = props => {
    let { priceButtonColor, getLighterColor } = props


    const addToBasket = async () => {
        let newValue = props.currentOptionValue + 1
        props.setCurrentOptionValue(newValue)
        await props.addToBasketValue({
            index: props.product.index,
            productId: props.productId,
            optionId: props.optionId,
            productName: props.productName,
            image: props.image,
            taxRate: props.taxRate,
            value: newValue,
            price: props.price
        });
    }

    return (
        <Button className={'shadow-none'} onClick={addToBasket} style={{
            backgroundColor: getLighterColor(priceButtonColor), border: '0', width: '30px', height: '16px',
            marginTop:'0', borderRadius: '5px 5px 5px 5px', paddingTop: '0',
            textAlign: 'center', lineHeight: '15px', cursor: 'pointer', color: '#fff'
        }}>
                                    <span style={{marginTop: '-15px'}}>
                                        +
                                    </span>
        </Button>
    );
}

export default BasketPlusButton;
