import React from 'react';
import EmailSettingsKey from "./EmailSettingsKey";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp, faCogs} from "@fortawesome/free-solid-svg-icons";

const NewOrderEmail = props => {

    return (
        <div>
            <p>
                Whenever a user creates an order they will be sent this email to confirm
                that you have received their order.
            </p>
            <input type="text"
                   className="form-control"
                   placeholder="Thanks for your order"
                   id="new-order-email-title"
                   name='new-order-email-title'
                   value={props.data.newOrderMessageTitle}
                   onInput={props.handleSetData}
                   onBlur={props.updateMessage}
                   aria-label={'Welcome email title'}
            />
            <p style={{cursor: 'pointer', float: 'right', paddingTop: '14px', fontSize: '0.8em',
                width: '100%', display: 'block', marginBottom: '0'}}
               onClick={props.handleShowInfo}>
                <em>{!props.showInfo ?
                    <p>
                        <FontAwesomeIcon icon={faCogs} /> See available personalisation settings
                    </p> :
                    <p>
                        <FontAwesomeIcon icon={faCogs} /> Hide settings <FontAwesomeIcon icon={faAngleUp} />
                    </p>}
                </em>
            </p>
            {props.showInfo &&
                <>
            <EmailSettingsKey showNewOrder={props.showNewOrder}/>
                    <div className="container">
                        <div className="row" style={{marginTop: '0px'}}>
                            <div className="col" style={{paddingTop: '10px'}}>
                                <p><strong>Collection text:</strong></p>
                            </div>
                            <div className="col-8">
                            <textarea className="form-control"
                                      placeholder="Collection instructions"
                                      id="new-order-collection"
                                      name='new-order-collection'
                                      value={props.data.newOrderCollection}
                                      onInput={props.handleSetData}
                                      onBlur={props.updateMessage}
                                      aria-label={'New order collection instructions'}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col" style={{paddingTop: '0'}}>
                                <p><strong>Delivery text:</strong></p>
                            </div>
                            <div className="col-8">
                            <textarea className="form-control"
                                      placeholder="Delivery instructions"
                                      id="new-order-delivery"
                                      name='new-order-delivery'
                                      value={props.data.newOrderDelivery}
                                      onInput={props.handleSetData}
                                      onBlur={props.updateMessage}
                                      aria-label={'New order delivery instructions'}
                            />
                            </div>
                        </div>
                    </div>
            </>
            }
            <textarea
                style={{minHeight: '300px'}}
                className="form-control"
                placeholder="New order email message body"
                id="new-order-email-message"
                name='new-order-email-message'
                value={props.data.newOrderMessageBody}
                onInput={props.handleSetData}
                onBlur={props.updateMessage}
                aria-label={'New order email message body'}
            />

        </div>
    );

}

export default NewOrderEmail;
