import React, {useRef, useContext} from 'react';
import axios from "axios";
import AuthContext from "./auth-context";

const ImageUpload = props => {
    const auth = useContext(AuthContext)
    const productId = props.productId
    const filePickerRef = useRef()

    const pickImageHandler = () => {
        filePickerRef.current.click();
    }

    const pickedHandler = async event => {
        event.preventDefault();
        // eslint-disable-next-line
        let pickedFile;
        let fileIsValid;
        if (event.target.files && event.target.files.length === 1){
            pickedFile = event.target.files[0]
            fileIsValid = true
        } else {
            fileIsValid = false
        }
        const data = new FormData()
        if (fileIsValid) {
            data.append('uploaded-image', pickedFile)
            data.append('productId', productId)
            data.append('productName', props.productName)
            data.append('status', props.status)
            data.append('quantities', props.quantities)
            data.append('catId', props.catId)
            data.append('amount', props.amount)
            data.append('price', props.price)
            try {
                await axios.post(`${process.env.REACT_APP_SITE_URL}/api/upload/profile-img-upload`,
                    data,
                    {
                        method: 'POST',
                        headers: {
                            'accept': 'application/json',
                            'Accept-Language': 'en-US,en;q=0.8',
                            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                            'Authorization': 'Bearer ' + auth.userToken
                        }
                    })
                    .then(res => { // then print response status
                        props.reloadCart();
                    })
            } catch (errors) {
                console.log(errors)
            }
        }
    }


    return (
        <>
            <div className={`from-control`}>
                <form encType="multipart/form-data" method="post">
                <input id={productId}
                       ref={filePickerRef}
                       style={{display: 'none'}}
                       type={'file'}
                       className="form-control-file"
                       name="uploaded-image"
                       accept={'.jpg, .png, .jpeg'}
                       onChange={pickedHandler}
                />
                </form>
            </div>
            <div className={`Image admin-image`} style={{cursor: 'pointer'}}>
                <img alt={props.image} className="admin-product-image"
                     src={props.image} onClick={pickImageHandler}/>
            </div>
        </>
    )
}

export default ImageUpload;
