import React, {useContext} from 'react';
import '../../../../css/AdminCartNav.css'
import '../../../../css/CartNav.css';
import CategoryButton from "./CategoryButton";
import Button from "../../../shared/Button";
import AuthContext from "../../../shared/auth-context";

const CartNav = props => {
    const auth = useContext(AuthContext)
    let {
        controlButtonColor, navButtonColor, priceButtonColor, setDisplayCategory,
        displayCategory, categoryId, index, setHaveClicked
    } = props
    const handleDisplayFeatured = async () => {
        await setDisplayCategory(999)
    }

    return (
        <>
            {(props.displayCategory === index || (displayCategory === 999 && index === 0)) &&
            <nav className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12 category-navigation
            navbar-light admin-nav-toggle" style={{backgroundColor: props.cartNavColor}}
                 id={index + '-' + categoryId + '-navbar'}
            >
                <ul style={{paddingBottom: '10px', fontSize: '1.2em'}}
                    className="navbar-nav ml-auto">
                    <li className="nav-item nav-controls "
                        id={index + '-' + categoryId + '-navbar-item'}
                    >
                        {auth.hasFeatured &&
                        <Button onClick={handleDisplayFeatured}
                                className={'admin-nav-toggle user-button'}
                                text={'FEATURED'}
                                key={'featured-products' + displayCategory}
                                usedKey={'featured-'}
                                keyMode={'products' + displayCategory}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                solid={true}
                                categoryButton={true}
                                active={displayCategory === 999 && index === 0}
                                icon={null}/>
                        }
                        {props.categories.map((category, index) => <CategoryButton
                                key={index + '-' + category.categoryId + '-' + navButtonColor + '-' + controlButtonColor + '-' + priceButtonColor}
                                catId={category.id}
                                categoryName={category.categoryName}
                                categoryId={props.categoryId}
                                categoryIndex={index}
                                category={category}
                                displayCategory={props.displayCategory}
                                setDisplayCategory={props.setDisplayCategory}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                setHaveClicked={setHaveClicked}
                            />
                        )}
                    </li>
                </ul>
            </nav>
            }
        </>
    )
}

export default CartNav;
