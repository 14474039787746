import React, {useContext } from 'react';
import '../../css/Cart.css';
import '../../css/SideDrawer.css'
import AdminProduct from "../product/admin/AdminProduct";
import CartNav from "../structure/navigation/user/CartNav";
import Product from "../product/user/Product";
import AdminCartNav from "../structure/navigation/admin/AdminCartNav";
import AuthContext from "../shared/auth-context";

const Cart = props => {
    const auth = useContext(AuthContext);
    let {showAdminMode, controlButtonColor, navButtonColor, loadedCategories,
        loadedProducts, priceButtonColor, shopBackground, setDisplayCategory,
        index, categoryId, categories, cartNavColor,
        haveClicked, setHaveClicked} = props;
    return (
        <>
            {!showAdminMode && auth.isLoggedIn ?
                <AdminCartNav
                    key={index + '-' + categoryId + '-navbar'}
                    categories={props.categories}
                    categoryId={props.categoryId}
                    showAdminMode={showAdminMode}
                    reloadProducts={props.reloadProducts}
                    reloadCategories={props.reloadCategories}
                    handleNewProduct={props.handleNewProduct}
                    categoryIndex={props.categoryIndex}
                    handleCategoryUpdate={props.handleCategoryUpdate}
                    reloadCart={props.reloadCart}
                    index={props.index}
                    displayCategory={props.displayCategory}
                    setDisplayCategory={props.setDisplayCategory}
                    setLoadedCategories={props.setLoadedCategories}
                    setLoadedProducts={props.setLoadedProducts}
                    getSettings={props.getSettings}
                    getCategories={props.getCategories}
                    newProduct={props.newProduct}
                    createCategory={props.createCategory}
                    deleteCategory={props.deleteCategory}
                    updateCategoryName={props.updateCategoryName}
                    editMode={props.editMode}
                    setEditMode={props.setEditMode}
                    cartNavColor={props.cartNavColor}
                    controlButtonColor={controlButtonColor}
                    navButtonColor={navButtonColor}
                    priceButtonColor={priceButtonColor}
                    loadedCategories={loadedCategories}
                    loadedProducts={loadedProducts}
                />
                : auth.appSettings.shop.storeIsLive ?
                <CartNav
                    key={index + '-' + categoryId + '-navbar-item'}
                    categories={categories}
                    categoryId={categoryId}
                    showAdminMode={showAdminMode}
                    index={index}
                    displayCategory={auth.hasFeatured && !haveClicked ? 999 : props.displayCategory}
                    setDisplayCategory={setDisplayCategory}
                    cartNavColor={cartNavColor}
                    priceButtonColor={priceButtonColor}
                    controlButtonColor={controlButtonColor}
                    navButtonColor={navButtonColor}
                    haveClicked={haveClicked}
                    setHaveClicked={setHaveClicked}
                />
                    : auth.isLoggedIn &&
                    <CartNav
                        key={index + '-' + categoryId + '-navbar'}
                        categories={categories}
                        categoryId={categoryId}
                        showAdminMode={showAdminMode}
                        index={index}
                        displayCategory={auth.hasFeatured && !haveClicked ? 999 : props.displayCategory}
                        setDisplayCategory={setDisplayCategory}
                        cartNavColor={cartNavColor}
                        controlButtonColor={controlButtonColor}
                        navButtonColor={navButtonColor}
                        priceButtonColor={priceButtonColor}
                        haveClicked={haveClicked}
                        setHaveClicked={setHaveClicked}
                    />
            }
            {!showAdminMode && auth.isLoggedIn ?
                props.products.map((product, index) => <AdminProduct
                    key={'product-' + index + '-' + product.productId + product.amount}
                    productName={product.productName}
                    image={product.image}
                    price={product.price}
                    quantities={product.quantities}
                    amount={product.amount}
                    productId={product._id}
                    catId={product.catId}
                    categoryId={props.categoryId}
                    taxRate={product.taxRate}
                    categories={props.categories}
                    products={props.products}
                    reloadProducts={props.reloadProducts}
                    status={product.status}
                    handleNewProduct={props.handleNewProduct}
                    reloadCart={props.reloadCart}
                    getSettings={props.getSettings}
                    categoryIndex={props.index}
                    displayCategory={props.displayCategory}
                    setDisplayCategory={props.setDisplayCategory}
                    product={product}
                    index={index}
                    priceOptions={product.priceOptions}
                    shopBackground={shopBackground}
                />): auth.appSettings.shop.storeIsLive ?
                props.products.map((product, index) => <Product
                    submitProduct={'yes'}
                    key={'product-' + index + '-' + product.productId}
                    productName={product.productName}
                    image={product.image}
                    price={product.price}
                    taxRate={product.taxRate}
                    quantities={product.quantities}
                    productId={product.id}
                    catId={product.catId}
                    categoryId={props.categoryId}
                    addToBasketValue={props.addToBasketValue}
                    takeFromBasketValue={props.takeFromBasketValue}
                    basketItems={props.basketItems}
                    categoryIndex={props.index}
                    displayCategory={auth.hasFeatured && !haveClicked ? 999 : props.displayCategory}
                    setDisplayCategory={props.setDisplayCategory}
                    buttonColor={props.buttonColor}
                    valueColor={props.valueColor}
                    product={product}
                    loadedProducts={props.loadedProducts}
                    priceOptions={product.priceOptions}
                    controlButtonColor={controlButtonColor}
                    navButtonColor={navButtonColor}
                    priceButtonColor={priceButtonColor}
                    shopBackground={shopBackground}
                />)
                : auth.isLoggedIn ?
                    props.products.map((product, index) => <Product
                        submitProduct={'yes'}
                        key={'product-' + index + '-' + product.productId}
                        productName={product.productName}
                        image={product.image}
                        price={product.price}
                        taxRate={product.taxRate}
                        quantities={product.quantities}
                        productId={product.id}
                        catId={product.catId}
                        categoryId={props.categoryId}
                        addToBasketValue={props.addToBasketValue}
                        takeFromBasketValue={props.takeFromBasketValue}
                        basketItems={props.basketItems}
                        categoryIndex={props.index}
                        displayCategory={props.displayCategory}
                        setDisplayCategory={props.setDisplayCategory}
                        buttonColor={props.buttonColor}
                        valueColor={props.valueColor}
                        product={product}
                        loadedProducts={props.loadedProducts}
                        controlButtonColor={controlButtonColor}
                        navButtonColor={navButtonColor}
                        priceButtonColor={priceButtonColor}
                        shopBackground={shopBackground}
                    />)
                        : !auth.appSettings.shop.storeIsLive && props.index === 0 &&
                        <>
                            <img alt={props.username}
                                 className={'admin-nav-toggle'}
                                 style={{width: '100%'}}
                                 src={'https://c4rt-photos.s3.eu-west-2.amazonaws.com/e52a4740-4dd8-11eb-824d-dc7efaf00af9.jpg'}/>
                            <img alt={props.username} className={'hide-nav-toggle'}
                                 style={{
                                     width: '100%', maxWidth: '400px', height: '100%', maxHeight: '177px',
                                     marginTop: '50px', marginLeft: 'auto', marginRight: 'auto'
                                 }}
                                 src={'https://c4rt-photos.s3.eu-west-2.amazonaws.com/e52a4740-4dd8-11eb-824d-dc7efaf00af9.jpg'}/>
                        </>
            }
        </>
    );
}

export default Cart;
