import React, {useContext, useState} from 'react';
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "../shared/Button";
import '../../css/UserBasket.css'
import AuthContext from "../shared/auth-context";
import UserBasketItem from "./UserBasketItem";
import {BuildAndCheckPassword, ConfirmPassword, ConfirmPasswords} from '../shared/build-check-password'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faPencilAlt, faRetweet} from "@fortawesome/free-solid-svg-icons";
import UserPay from "./UserPay";
import jwtToken  from 'jsonwebtoken';
import { loadStripe } from "@stripe/stripe-js";
import {useParams} from "react-router";
import SignupGridContainer from "./SignupGridContainer";
import {Alert} from "react-bootstrap";



const C4RT_API_KEY = process.env.REACT_APP_JWT_KEY;

const axios = require('axios').default;

const UserBasket = props => {
    const auth = useContext(AuthContext);
    let { username } = useParams();
    if(!username){
        username = auth.username
    }
    const { getInputData, formDataGot, setFormDataGot, errorMessage,
        setErrorMessage, setAllowConfirm, formDataInitial, setFormDataInitial,
        location, showConfirmItems, setShowConfirmItems, updateCustomer,
        calculateDeliveryCharges, controlButtonColor, navButtonColor, priceButtonColor } = props

    const [ showCheckEmail, setShowCheckEmail ] = useState(false);
    const [ showUserPayment, setShowUserPayment ] = useState(false);
    const [ customerExists, setCustomerExists ] = useState(false);
    const [ emailValid, setEmailValid ] = useState(false);
    const [ namesValid, setNamesValid ] = useState(false);
    const [ showDeliveryInfo, setShowDeliveryInfo ] = useState(false);
    const [ showPasswordResetFields, setShowPasswordResetFields ] = useState(false)

    // Password state variables
    const [ containsNumber, setContainsNumber ] = useState(false);
    const [ containsUppercase, setContainsUppercase ] = useState(false);
    const [ containsSpecial, setContainsSpecial ] = useState(false);
    const [ longEnough, setLongEnough ] = useState(false);
    const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);
    const [ passwordsMatch, setPasswordsMatch ] = useState(false);
    const [ passwordsLengthMatch, setPasswordsLengthMatch ] = useState(false);
    const [ currentPassword, setCurrentPassword ] = useState('');
    const [ customerIsAdmin, setCustomerIsAdmin ] = useState(false)
    const [ showResetPassword, setShowResetPassword ] = useState(false)
    // Manages form input state
    const [currentEmail, setCurrentEmail] = useState('');
    const [currentFirstname, setCurrentFirstname] = useState('');
    const [currentLastname, setCurrentLastname] = useState('');
    const [ resetCode, setResetCode ] = useState('')
    const [ successAlert, setSuccessAlert ] = useState(false)
    const [ invalidCode, setInvalidCode ] = useState(false)
    const [ haveCheckedKey, setHaveCheckedKey ] = useState(false)
    const [ keyIsValid, setKeyIsValid ] = useState(false)

    const resetCodeHandler = (event) => {
        let code = event.target.value
        setResetCode(code)
    }

    const validateSecurityCode = async () => {
        if(resetCode.length === 6){
            try {
                let token;
                try{
                    token = await jwtToken.sign(
                        {
                            email: currentEmail,
                            resetCode
                        },
                        process.env.REACT_APP_JWT_KEY,
                        {
                            expiresIn: '1h'
                        }
                    );
                }catch(err){console.log(err)}
                let apiLink
                if(customerIsAdmin && !auth.isLoggedIn){
                    apiLink = `${process.env.REACT_APP_SITE_URL}/api/password`
                } else {
                    apiLink = `${process.env.REACT_APP_SITE_URL}/api/customer/password`
                }
                const response = await fetch(apiLink, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                const responseData = await response.json();
                if(responseData.message === 'Success'){
                    setSuccessAlert(true)
                    setShowPasswordResetFields(true)
                } else {
                    setInvalidCode(true)
                    setCurrentEmail('')
                }
            } catch (error) {
                // setIsLoading(false);
                console.log('ERROR: ' + error)
            }
        }
    }

    let customerToken, customer, orders;

    const updatePasswordHandler = async () => {
        try {
            let token, categoryId;
            try{
                token = await jwtToken.sign(
                    {
                        email: currentEmail,
                        password: currentPassword,
                        resetCode
                    },
                    process.env.REACT_APP_JWT_KEY,
                    {
                        expiresIn: '1h'
                    }
                );
            }catch(err){console.log(err)}
            let apiLink
            if(customerIsAdmin && !auth.isLoggedIn){
                apiLink = `${process.env.REACT_APP_SITE_URL}/api/password`
            } else {
                apiLink = `${process.env.REACT_APP_SITE_URL}/api/customer/password`
            }
            await axios.patch(apiLink, {
            }, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(async res => {
                    if(!!res.data.message){
                        setErrorMessage(res.data.message)
                    }else{
                        if(customerIsAdmin && !auth.isLoggedIn){
                            let incomingToken = res.data.token
                            let decodedToken = await jwtToken.decode(incomingToken, {complete: true});
                            let username = decodedToken.payload.username;
                            let appSettings = decodedToken.payload.appSettings;
                            let cartId = decodedToken.payload.cartId;
                            let stripeSettings = decodedToken.payload.stripeSettings;
                            let token = decodedToken.payload.userToken;
                            await jwtToken.verify(incomingToken,
                                `${process.env.REACT_APP_JWT_KEY}`,
                                async function(err, decoded) {
                                    if(decoded){
                                        if(!username){}
                                        else if(!err) {
                                            const userData = JSON.parse(localStorage.getItem('userData'))
                                            let { cartOrder, cartInvoices, userEmail, tickets, messages} = userData
                                            auth.login(username, token, cartId, categoryId, appSettings,
                                                stripeSettings, cartOrder, cartInvoices, userEmail, tickets, messages);
                                            props.handleClose();
                                        }
                                    }else{console.log('Didn\'t decode')}
                                });
                            setErrorMessage(null)
                        }else{customer = res.data.customer;
                            customerToken = res.data.token;
                            orders = res.data.orders;
                            if(res.data.message){
                                setErrorMessage(res.data.message)
                            }
                            auth.customerLogin(customer, customerToken, orders);
                            if(props.basketItems.length === 0){
                                await props.setShowBasket(false)
                            }
                            checkEmail();
                        }
                    }

                })
                .catch(error => {

                });
        } catch (error) {
            // setIsLoading(false);
            console.log('ERROR: ' + error)
        }
    }
    const collectPasswords = () => {
        return <>
            <div style={{marginBottom: '20px'}}>
                <p>Type in a safe password that you can use to login to your account.</p>
                <input type="password"
                       className="customer-password form-control"
                       placeholder="Enter Password"
                       id="customer-password"
                       name='customer-password'
                       value={currentPassword}
                       onChange={buildCheckPassword}
                       onKeyUp={confirmPassword}
                />
                {showConfirmPassword &&
                <input type="password"
                       className="confirm-password form-control"
                       placeholder="Confirm Password"
                       id="confirm-password"
                       name='confirm-password'
                       onKeyUp={confirmPasswords}
                />
                }
            </div>
            {passwordsLengthMatch && !passwordsMatch &&
            <Alert key={'id2x7'} style={{fontSize: '0.9em', textAlign:'center'}} variant={'danger'}>
                Sorry those passwords aren't the same!
            </Alert>
            }
            {passwordsMatch && passwordsMatch &&
            <Alert key={'id2x8'} style={{fontSize: '0.8em', textAlign:'center'}} variant={'success'}>
                Everything is looking good. Click on the continue button below!
            </Alert>}
            <div className={'user-warning'}>
                {!containsUppercase && currentPassword.length > 0 &&
                <Alert key={'id2x9'} style={{fontSize: '0.9em', textAlign:'center'}} variant={'danger'}>
                    Password contains at least one uppercase letter
                </Alert>
                }
                {!containsSpecial && currentPassword.length > 0 &&
                <Alert key={'id2x10'} style={{fontSize: '0.9em', textAlign:'center'}} variant={'danger'}>
                    Password contains at least one special character (#, @, ", $ etc)
                </Alert>
                }
                {!containsNumber && currentPassword.length > 0 &&
                <Alert key={'id2x11'} style={{fontSize: '0.9em', textAlign:'center'}} variant={'danger'}>
                    Password contains at least number
                </Alert>
                }
                {!longEnough && currentPassword.length > 0 &&
                <Alert key={'id2x12'} style={{fontSize: '0.9em', textAlign:'center'}} variant={'danger'}>
                    Password is over 8 characters long
                </Alert>
                }
            </div>
        </>
    }
    const resetPasswordHandler = async () => {
        try {
            let token;
            try{
                token = await jwtToken.sign(
                    {
                        email: currentEmail
                    },
                    process.env.REACT_APP_JWT_KEY,
                    {
                        expiresIn: '1h'
                    }
                );
            }catch(err){console.log(err)}
            let apiLink
            if(customerIsAdmin && !auth.isLoggedIn){
                apiLink = `${process.env.REACT_APP_SITE_URL}/api/password`
            } else {
                apiLink = `${process.env.REACT_APP_SITE_URL}/api/customer/password`
            }
            await axios.post(apiLink, {
            }, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => {
                    if(response.data.message === 'Success'){
                        setShowResetPassword(true)
                    }
                })
                .catch(error => {

                });
        } catch (error) {
            // setIsLoading(false);
            console.log('ERROR: ' + error)
        }
    }
    const deliveryTypeHandler = async () => {
        await props.handleDeliveryType()
    }
    const switchShow = () => {
        props.setShowCompleteBasket(false);
        setShowCheckEmail(false);
        setShowConfirmItems(false);
        setPasswordsMatch(false);
    }
    const checkEmailHandler = async (data) => {
        setShowConfirmItems(false);
        setShowCheckEmail(true);
        auth.setDetailsConfirmed(false);
        if(auth.customerIsLoggedIn) {
            let setData = {
                firstname: auth.customer.firstname,
                lastname: auth.customer.lastname,
                email: auth.customer.email,
                addressOne: auth.customer.addressOne,
                addressTwo: auth.customer.addressTwo,
                postcode: auth.customer.postcode,
                address: auth.customer.address,
                country: auth.customer.country,
                instructions: auth.customer.instructions,
                phone: auth.customer.phone,
            }
            await auth.setCustomer(setData);
        }
    }
    const showFormValid = () => {
        if (!formDataGot) {
            if (auth.appSettings.shop.allowInt) {
                if (auth.customer.addressOne.length > 0 &&
                    auth.customer.addressOne !== 'Address (Line 1)' &&
                    auth.customer.addressTwo.length > 0 &&
                    auth.customer.addressTwo !== 'Address Line 2' &&
                    auth.customer.postcode.length > 4 &&
                    auth.customer.postcode !== 'Postcode' &&
                    auth.customer.country !== 'Please select') {
                    setFormDataGot(true)
                    setAllowConfirm(true)
                } else {
                    setFormDataGot(true);
                    setAllowConfirm(false)
                }
            } else {
                if (auth.customer.addressOne.length > 0 &&
                    auth.customer.addressTwo.length > 0 &&
                    auth.customer.postcode.length > 4 &&
                    auth.customer.country === auth.appSettings.invoicing.invoice.country) {
                    setFormDataGot(true)
                    setAllowConfirm(true)
                } else {
                    if (!formDataGot) {
                        setAllowConfirm(false)
                    }
                    if (auth.customer.addressOne === 'Address (Line 1)' ||
                        auth.customer.addressTwo === 'Address Line 2' ||
                        auth.customer.postcode === 'Postcode' ||
                        auth.customer.country === 'Please select') {
                        setFormDataGot(true)
                        setAllowConfirm(false)
                    } else {
                        setAllowConfirm(true)
                    }
                }

            }
        }
    }
    if(!formDataInitial && auth.customerIsLoggedIn && !!auth.customerToken){
        setFormDataGot(false);
        showFormValid();
        setFormDataInitial(true);
    }
    const checkMailHandler = async () => {
        setShowConfirmItems(false);
        setShowCheckEmail(true);
        auth.setDetailsConfirmed(false);
        if(auth.customerIsLoggedIn) {
            let setData = {
                firstname: auth.customer.firstname,
                lastname: auth.customer.lastname,
                email: auth.customer.email,
                addressOne: auth.customer.addressOne,
                addressTwo: auth.customer.addressTwo,
                postcode: auth.customer.postcode,
                address: auth.customer.address,
                country: auth.customer.country,
                instructions: auth.customer.instructions,
                phone: auth.customer.phone,
            }
            auth.setCustomer(setData);
        }
    }
    const checkEmail = event => {
        if(event){
            event.preventDefault();
        }
        if(!!auth.customerToken){
            if(auth.customer.address.location.lng + auth.customer.address.location.lat !== 0){
                props.setShowCompleteBasket(true);
                auth.setDetailsConfirmed(true);
            } else {
                props.setShowCompleteBasket(true);
            }
        } else {
            props.setShowCompleteBasket(true);
        }

    }
    const buildAndCheckEmail = async event => {
        event.preventDefault();
        let userInput = event.target.value;
        setCurrentEmail(userInput.toLowerCase());
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userInput)) {
            setEmailValid(true);
            userInput = userInput.toLowerCase();
            let token, email = userInput, thisName;
            if(!username){
                thisName = auth.username
            } else {
                thisName = username
            }
            try{
                token = await jwtToken.sign(
                    {
                        email, username: thisName
                    },
                    `${process.env.REACT_APP_JWT_KEY}`,
                    {
                        expiresIn: '1h'
                    }
                );
            }catch(err){console.log(err)}
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/api/customers`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                const responseData = await response.json();
                // setIsLoading(false);
                if (!response.ok) {
                    return new Error(responseData)
                }
                if (responseData.customer === 1) {
                    setCustomerExists(true);
                } else if (responseData.customer === 0) {
                    setCustomerExists(false);
                }
                if(!!responseData.isAdmin && !auth.isLoggedIn){
                    if(props.basketValue === 0){
                        setCustomerIsAdmin(true)
                        setCustomerExists(true);
                    } else {
                        setCustomerIsAdmin(false)
                    }
                } else {
                    setCustomerIsAdmin(false)
                }
            } catch (error) {
                // setIsLoading(false);
                console.log('ERRROR: ' + error)
            }
        } else {
            setEmailValid(false);
        }
    }
    const buildCheckPassword = async event => {
        event.preventDefault();
        let userInput = event.target.value;
        setCurrentPassword(userInput)
        await BuildAndCheckPassword(userInput, setCurrentPassword, setLongEnough,
            setContainsNumber, setContainsUppercase,
            setContainsSpecial);
    }
    const confirmPassword = () => {
        ConfirmPassword(containsNumber, longEnough, containsUppercase, containsSpecial,
            setShowConfirmPassword)
    }
    const confirmPasswords = event => {
        event.preventDefault();
        ConfirmPasswords(event, currentPassword, setPasswordsLengthMatch, setPasswordsMatch)
    }
    const getFirstname = event => {
        let userInput = event.target.value;
        if(auth.customerIsLoggedIn) {
            let userData = {
                firstname: userInput,
                lastname: auth.customer.lastname,
                email: auth.customer.email,
                addressOne: auth.customer.addressOne,
                addressTwo: auth.customer.addressTwo,
                postcode: auth.customer.postcode,
                country: auth.customer.country,
                instructions: auth.customer.instructions,
                phone: auth.customer.phone,
            }
            auth.setCustomer(userData)
        }
        setCurrentFirstname(userInput);
    }
    const getLastname = event => {
        let userInput = event.target.value;
        if(auth.customerIsLoggedIn) {
            let userData = {
                firstname: auth.customer.firstname,
                lastname: userInput,
                email: auth.customer.email,
                addressOne: auth.customer.addressOne,
                addressTwo: auth.customer.addressTwo,
                postcode: auth.customer.postcode,
                country: auth.customer.country,
                instructions: auth.customer.instructions,
                phone: auth.customer.phone,
            }
            auth.setCustomer(userData)
        }
        setCurrentLastname(userInput);
    }
    const validateNames = () => {
        if(currentFirstname.length > 0 && currentLastname.length > 0 ){
            setNamesValid(true)
        } else {setNamesValid(false)}
    }
    const basketValid = () => {
            return props.basketValue >= auth.appSettings.shop.minValue &&
                !auth.formIsValid;
    }
    const distanceValid = () => {
        if(auth.appSettings.shop.restrictDist && auth.appSettings.shop.minOrder){
            if(auth.finalDistance > auth.appSettings.shop.inputDist){
                return false
            } else if (auth.appSettings.shop.minValue > props.basketValue){
                return false
            } else {
                return true
            }
        } else if(auth.appSettings.shop.restrictDist){
            if(auth.finalDistance > auth.appSettings.shop.inputDist){
                return false
            } else {
                return true
            }
        } else if(auth.appSettings.shop.minOrder){
            if(auth.appSettings.shop.minValue > props.basketValue){
                return false
            } else {
                return true
            }
        }else {
            return true
        }

    }
    const submitFormForConfirmation = async event => {
        // event.preventDefault();
        // if(isCustomerLoginMode){
        let token;
        try{
            token = await jwtToken.sign(
                {
                    password: currentPassword,
                    email: currentEmail,
                    firstName: currentFirstname,
                    lastName: currentLastname,
                    username: auth.appSettings.username
                },
                C4RT_API_KEY,
                {expiresIn: '1h'}
            );
        }catch(err){console.log(err)}
        let customer = '';
        await axios.post(`${process.env.REACT_APP_SITE_URL}/api/customers/new`, {}, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(res => {
                // console.log(res.data)
                customer = res.data.customer;
                customerToken = res.data.token;
                orders = res.data.orders;
            })
            .catch(error => {
                console.log(error);
            })
        let data = {username: auth.appSettings.username, customer}
        await auth.customerLogin(customer, customerToken, orders);
        if(props.basketItems.length !== 0){
            await checkEmailHandler(data);
        } else {
            await props.setShowBasket(false)
        }
        // console.log(auth.customerLogin)
    }
    const customerLogin = async event => {
        event.preventDefault()
        let token;
        try{
            token = await jwtToken.sign(
                {
                    password: currentPassword,
                    email: currentEmail
                },
                C4RT_API_KEY,
                {expiresIn: '1h'}
            );
        }catch(err){console.log(err)}
        await axios.post(`${process.env.REACT_APP_SITE_URL}/api/customers/login`, {}, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(async res => {
                customer = res.data.customer;
                customerToken = res.data.token;
                orders = res.data.orders;
                if(res.data.message){
                    setErrorMessage(res.data.message)
                }
                let { lat, lng } = customer.address.location
                console.log(customer)
                if(lat + lng !== 0){
                    await props.setShowConfirmItems(true)
                    await auth.setDetailsConfirmed(true);
                }
            })
            .catch(error => {
                console.log(error);
            })
        await auth.customerLogin(customer, customerToken, orders);
        if(props.basketItems.length === 0){
            await props.setShowBasket(false)
        }
        checkEmail();
    }
    const userLogin = async () => {
        const apiKey = process.env.REACT_APP_JWT_KEY;
        let token, categoryId;
        try{
            token = await jwtToken.sign(
                {
                    email: currentEmail,
                    password: currentPassword
                },
                apiKey,
                {expiresIn: '1h'}
            );
        }catch(err){console.log(err)}
        let error = false;
        await axios.post(`${process.env.REACT_APP_SITE_URL}/api/users/login`, {}, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(async res => {
                if(res.data.message){
                    setErrorMessage(res.data.message)
                    error = true
                } else{
                    let incomingToken = res.data.token
                    let decodedToken = await jwtToken.decode(incomingToken, {complete: true});
                    let username = decodedToken.payload.username;
                    let appSettings = decodedToken.payload.appSettings;
                    let cartId = decodedToken.payload.cartId;
                    let stripeSettings = decodedToken.payload.stripeSettings;
                    let cartOrder = decodedToken.payload.cartOrder;
                    let cartInvoices = decodedToken.payload.cartInvoices;
                    let userEmail = decodedToken.payload.userEmail;
                    let token = decodedToken.payload.userToken;
                    let tickets = decodedToken.payload.tickets;
                    let messages = decodedToken.payload.messages;
                    await jwtToken.verify(incomingToken,
                        `${process.env.REACT_APP_JWT_KEY}`,
                        async function(err, decoded) {
                            if(decoded){
                                if(!username){}
                                else if(!error) {
                                    await auth.login(username, token, cartId,
                                        categoryId, appSettings, stripeSettings,
                                        cartOrder, cartInvoices, userEmail,
                                        tickets, messages
                                        );
                                }
                                // props.handleClose();
                            }else{console.log('Didn\'t decode')}
                        });
                    setErrorMessage(null)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
    const createNewOrder = async () => {
        props.setLoading(true)
        document.getElementById('stripe-pay-button').click()
    }
    const calculateSubtotal = () => {
        if(auth.appSettings.shop.pricesBefore){
            return props.basketValue;
        } else {
            return props.basketValue-calculateTotalTaxes();
        }
    }
    const calculateTotal = () => {
        if(auth.appSettings.shop.pricesBefore){
            if(props.orderIsDelivery && !auth.appSettings.shop.disableDeliveries){
                return props.basketValue+calculateTotalTaxes()+calculateDeliveryCharges();
            } else {
                return props.basketValue+calculateTotalTaxes();
            }
        } else {
            if(props.orderIsDelivery && !auth.appSettings.shop.disableDeliveries){
                return props.basketValue+calculateDeliveryCharges();
            } else {
                return props.basketValue;
            }
        }
    }
    const handleShowDeliveryChargeInfo = () => {
        setShowDeliveryInfo(prevState => !prevState);
    }
    const calculateStandardRate = () => {
        let totalStandardRate = 0;
        for(let i=0 ; i<props.basketItems.length ; i++){
            let { price, value } = props.basketItems[i];
            if(props.basketItems[i].taxRate === '20' || props.basketItems[i].taxRate === 20 ||
                props.basketItems[i].taxRate === 'Standard Rate'){
                let itemsTotal = (price*value*20*0.01)
                totalStandardRate = totalStandardRate+itemsTotal;
            }
        }
        return totalStandardRate.toFixed(2)
    }
    const calculateReducedRate = () => {
        let totalStandardRate = 0;
        for(let i=0 ; i<props.basketItems.length ; i++){
            let { price, value } = props.basketItems[i];
            if(props.basketItems[i].taxRate === 5 || props.basketItems[i].taxRate === '5' ||
                props.basketItems[i].taxRate === 'Reduced Rate'){
                let itemsTotal = (price*value*5*0.01)
                totalStandardRate = totalStandardRate+itemsTotal;
            }
        }
        return totalStandardRate.toFixed(2)
    }
    const calculateTotalTaxes = () => {
        let totalStandardRate = Number(calculateStandardRate())
        let totalReducedRate = Number(calculateReducedRate())

        return totalStandardRate + totalReducedRate
    }
    const showTaxCharges = width => {
        return(
            <>
                <div style={{width: `100%`, height: '25px', float: 'right'}}>
                    <p style={{float: 'right', marginBottom: '0'}}>
                    <span style={{width: '130px', fontSize: '0.7em'}}>
                        {auth.appSettings.shop.currencySymbol}{calculateDeliveryPercentages().tax.stdTot.toFixed(2)} @ 20%
                    </span>
                        <span style={{width: '100px'}}> {auth.appSettings.shop.currencySymbol}{calculateStandardRate()}</span>
                    </p>
                </div>
                <div style={{width: `${width}%`, height: '25px', float: 'right'}}>
                    <p style={{float: 'right', marginBottom: '0'}}>
                    <span style={{width: '130px', fontSize: '0.7em'}}>
                        {auth.appSettings.shop.currencySymbol}{calculateDeliveryPercentages().tax.redTot.toFixed(2)} @ 5%
                    </span>
                        <span style={{width: '100px'}}> {auth.appSettings.shop.currencySymbol}{calculateReducedRate()}</span>
                    </p>
                </div>
                <div style={{width: `${width}%`, height: '25px', float: 'right'}}>
                    <p style={{float: 'right', marginBottom: '0'}}>
                    <span style={{width: '130px', fontSize: '0.7em'}}>
                        {auth.appSettings.shop.currencySymbol}{calculateDeliveryPercentages().tax.zeroTot.toFixed(2)} @ 0%
                    </span>
                        <span style={{width: '100px'}}> {auth.appSettings.shop.currencySymbol}0.00</span>
                    </p>
                </div>
                <div style={{width: `${width}%`, height: '25px', float: 'right'}}>
                    <h6 style={{float: 'right', marginBottom: '0', marginTop: '3px', textAlign: 'right'}}>
                        Total Tax:
                        <span style={{width: '100px'}}> {auth.appSettings.shop.currencySymbol}{calculateTotalTaxes().toFixed(2)}</span>
                    </h6>
                </div>
            </>
        )
    }
    const showDeliveryCharges =() => {
        if(props.orderIsDelivery){
            let totalCalculatedCharges = calculateDeliveryCharges()
            const { standardTotals, reducedTotals } = calculateDeliveryPercentages().delivery;
            let finalStandard = (standardTotals / 100) * 0.2 * totalCalculatedCharges,
                finalReduced = (reducedTotals / 100) * 0.05 * totalCalculatedCharges,
                charges = totalCalculatedCharges;
            if(!finalStandard){
                finalStandard = 0
            }
            if(!finalReduced){
                finalReduced = 0
            }
            if(!charges){
                charges = 0
            }
            let newStandard = finalStandard.toFixed(2),
                newReduced = finalReduced.toFixed(2),
                newCharges = charges.toFixed(2);
            let totalAfter = Number(newStandard) + Number(newReduced) + Number(newCharges),
                totalCharges = Number(newStandard) + Number(newReduced)
            auth.setTotalCharges(totalCharges)
            return(
                <>
                    <div style={{width: '100%', height: '25px'}}>
                        <h6 style={{float: 'left', marginBottom: '0'}}>
                            <span style={{width: '100px'}}>
                                {auth.appSettings.shop.currencySymbol}{charges.toFixed(2)} </span>
                            <span style={{width: '130px', fontSize: '0.7em'}}>
                            Delivery Subtotal
                        </span>
                        </h6>
                    </div>
                    {auth.appSettings.shop.showCharges ?
                        <>
                            <div style={{width: '100%', height: '25px'}}>
                                <p style={{float: 'left',  marginTop: '0', marginBottom: '0'}}>
                                    <span style={{width: '100px'}}>
                                        {auth.appSettings.shop.currencySymbol}{finalStandard.toFixed(2)}</span>
                                    <span style={{width: '130px', fontSize: '0.7em'}}> 20% Charges
                        </span>
                                </p>
                            </div>
                            <div style={{width: '100%', height: '25px'}}>
                                <p style={{float: 'left', marginTop: '0', marginBottom: '0'}}>
                                    <span style={{width: '100px'}}>
                                        {auth.appSettings.shop.currencySymbol}{finalReduced.toFixed(2)}
                                    </span>
                                    <span style={{width: '130px', fontSize: '0.7em'}}> 5% Charges
                            </span>
                                </p>
                            </div>
                        </>: ''
                    }
                    <div style={{width: '100%', height: '25px'}}>
                        <h6 style={{float: 'left', marginBottom: '0'}}>
                            <span style={{width: '100px', marginTop: '4px'}}>
                                {auth.appSettings.shop.currencySymbol}{totalAfter.toFixed(2)} </span>
                            <span style={{width: '130px', fontSize: '0.7em'}}>
                            Total Delivery
                        </span>
                        </h6>
                    </div>
                </>
            )
        }
    }
    const showBasketCharges = () => {
        return (<>
                {// eslint-disable-next-line
                    calculateTotalTaxes() > 0 &&
                    calculateTotalTaxes() > 0 &&
            <>
                <hr/>
                    <div style={{width: '100%', height: '25px'}}>
                        <h5 style={{float: 'right', marginBottom: '0', marginTop: '15px'}}>
                            Subtotal:
                            <span style={{width: '100px'}}> {auth.appSettings.shop.currencySymbol}{calculateSubtotal().toFixed(2)}</span>
                        </h5>
                    </div>
                 <hr/>
            </>
            }
                <div className="signup-grid-container"
                     style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <div className="Left">
                        {auth.appSettings.shop.showCharges && props.orderIsDelivery &&
                        !auth.appSettings.shop.disableDeliveries &&
                        <h6>Delivery Charges <FontAwesomeIcon icon={faInfoCircle}
                                                                      onClick={handleShowDeliveryChargeInfo}
                                                                      onBlur={handleShowDeliveryChargeInfo}
                                                                      style={{
                            marginLeft: '7px', marginTop: '0', cursor: 'pointer', fontSize: '0.8em'
                        }}/>
                        </h6>
                        }
                        {showDeliveryInfo &&
                        <p>
                            <span style={{fontSize: '0.7em'}}>
                                {auth.appSettings.shop.chargesType === 'Advanced' ?
                                    <>
                                        <p>
                                            This shop has a minimum charge of
                                            <strong> {auth.appSettings.shop.currencySymbol}{auth.appSettings.shop.chargeDelivery.minFee} </strong>
                                            and charges
                                            <strong> {auth.appSettings.shop.currencySymbol}{auth.appSettings.shop.chargeDelivery.perUnit} </strong>
                                            per
                                            <strong> {auth.appSettings.shop.distUnits === 'Miles' ?
                                            <span>Mile</span>
                                                :
                                                <span>km</span>
                                            } </strong>
                                            with a maximum of
                                            <strong> {auth.appSettings.shop.currencySymbol}{auth.appSettings.shop.chargeDelivery.maxFee} </strong>
                                            per order.
                                        </p>
                                        <p>
                                            Your total delivery charges will be calculated and added to the order
                                            once you have provided us with an address.
                                        </p>
                                    </>
                                    :
                                    <p>
                                        Our store has a standard charge of
                                        <strong> {auth.appSettings.shop.currencySymbol}{auth.appSettings.shop.chargeDelivery.standardFee} </strong>
                                        on all deliveries.
                                    </p>
                                }
                                {auth.appSettings.shop.chargeDelivery.showFree &&
                                    <p>
                                        <strong>Free Deliveries: </strong>
                                        <span>
                                            <span>For orders over </span>
                                            <span style={{textDecoration: 'underline'}}>
                                                {auth.appSettings.shop.currencySymbol}{auth.appSettings.shop.chargeDelivery.freeOver}
                                            </span>
                                        </span>
                                    </p>
                                }
                            </span>
                        </p>
                        }
                        {auth.appSettings.shop.showCharges &&
                        !auth.appSettings.shop.disableDeliveries &&
                        showDeliveryCharges()
                        }
                    </div>
                    <div className="Right" style={{paddingLeft: '10px'}}>
                        {auth.appSettings.shop.showTax &&
                        <>
                            <h6 style={{float: 'right', marginBottom: '0'}}>
                                VAT Charges
                            </h6>
                            <br/>
                        </>
                        }
                        {auth.appSettings.shop.showTax &&
                        auth.appSettings.shop.showCharges ? showTaxCharges(100)
                            : auth.appSettings.shop.showTax &&
                            !auth.appSettings.shop.showCharges && showTaxCharges(100)
                        }
                    </div>
                </div>
                <hr/>
                {!basketValid() && auth.appSettings.shop.minOrder ?
                    <Alert style={{textAlign: 'center', padding:'10px'}}
                           key={'basket-limit-not-met'}
                           variant={'danger'}>
                        <p style={{margin:'0', fontSize: '0.8em'}}>
                            Sorry but the minimum amount for this basket is
                            <strong> {auth.appSettings.shop.currencySymbol}{Number(auth.appSettings.shop.minValue).toFixed(2)}</strong>, <br />
                            please add more items.</p>
                    </Alert>
                :
                    <h4 style={{float: 'right', marginTop: '10px', width: '100%', textAlign: 'right'}}>
                        <span>Total Price: </span>
                        {auth.appSettings.shop.currencySymbol}{(calculateTotal() + auth.totalCharges).toFixed(2)}</h4>
                }
            </>
        )
    }
    const getCartPublicKey = async () => {
        let responseData
        let token;
        if(!username){
            username = auth.appSettings.username
        }
        try{
            token = await jwtToken.sign(
                {
                    username,
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        try{
            const response = await fetch(
                `${process.env.REACT_APP_SITE_URL}/api/public-key`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
            responseData = await response.json();
        }catch(error){}
        let publicKey  = await responseData.publicKey
        let devMode  = responseData.devMode
        return {publicKey, devMode}
    }
    const handleUserPayment = async () => {
        props.setLoading(true)
        // If there is already an order in the pipeline then update order else create new one!
        if(auth.orderId && auth.orderNumber){
            // Need to be able to update orders here!
            const { standardTotals, reducedTotals } = calculateDeliveryPercentages().delivery;
            const finalStandard = (standardTotals/100)*0.2*calculateDeliveryCharges(),
                finalReduced = (reducedTotals/100)*0.05*calculateDeliveryCharges(),
                charges = calculateDeliveryCharges(),
                totalAfter = (charges + finalStandard + finalReduced).toFixed(2);
            let token, type;
            if(!props.orderIsDelivery){
                type = 'Collection'
            } else {
                type = 'Delivery'
            }
            try{
                token = await jwtToken.sign(
                    {
                        cartId: auth.cartId,
                        customer: {
                            customerId: auth.customerId,
                            firstname: auth.customer.firstname,
                            lastname: auth.customer.lastname,
                            addressOne: auth.customer.addressOne,
                            addressTwo: auth.customer.addressTwo,
                            phone: auth.customer.phone,
                            postcode: auth.customer.postcode,
                            country: auth.customer.country,
                            instructions: auth.customer.instructions,
                            location,
                        },
                        items: props.basketItems,
                        taxCharges:{
                            standardCharges: calculateStandardRate(),
                            reducedCharges: calculateReducedRate(),
                            totalTax: calculateTotalTaxes(),
                        },
                        deliveryCharges:{
                            subtotal: charges,
                            standardCharges: finalStandard,
                            reducedCharges: finalReduced,
                            totalDelivery: totalAfter
                        },
                        subTotal: (calculateSubtotal()).toFixed(2),
                        total: (calculateTotal() + finalStandard + finalReduced).toFixed(2) ,
                        type,
                        currency: auth.appSettings.shop.currencySymbol,
                        status: 0,
                        orderId: auth.orderId,
                        token: auth.customerToken
                    },
                    C4RT_API_KEY,
                    {expiresIn: '1h'}
                );
            }catch(err){console.log(err)}
            await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/order`, {}, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(async response => {
                    await auth.setCustomersOrders(response.data.orders)
                })
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            // if(showConfirmItems === true){
                let thisCartId = '';
                if (!auth.cartId){
                    let token
                    if(!username){
                        username = auth.appSettings.username
                    }
                    try{
                        token = await jwtToken.sign(
                            {
                                username
                            },
                            C4RT_API_KEY,
                            {expiresIn: '1h'}
                        );
                    }catch(err){console.log(err)}
                    try {
                        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/api/carts`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token

                            }
                        })
                        const responseData = await response.json();
                        await jwtToken.verify(responseData.token,
                            `${process.env.REACT_APP_JWT_KEY}`,
                            async function(err, decoded) {
                                if(decoded){
                                    let decodedToken = await jwtToken.decode(responseData.token, {complete: true});
                                    let cart = decodedToken.payload.cart;
                                    let publicKey = decodedToken.payload.publicKey;
                                    if (!response.ok) {
                                        return new Error(responseData)
                                    } else {
                                        thisCartId = cart._id;
                                        await auth.setCartId(cart._id);
                                        await auth.setPublicKey(publicKey)
                                        await auth.setGotCartId(true)
                                    }
                                }else{
                                    console.log('Didn\'t decode')
                                }
                            });
                    } catch (error) {
                        console.log('ERROR: ' + error)
                    }
                }
                const { standardTotals, reducedTotals } = calculateDeliveryPercentages().delivery;
                const finalStandard = (standardTotals/100)*0.2*calculateDeliveryCharges(),
                    finalReduced = (reducedTotals/100)*0.05*calculateDeliveryCharges(),
                    charges = calculateDeliveryCharges(),
                    totalAfter = (charges + finalStandard + finalReduced).toFixed(2);
                let token, type;
                if(!props.orderIsDelivery){
                    type = 'Collection'
                } else {
                    type = 'Delivery'
                }
                if(!thisCartId){
                    thisCartId = auth.cartId
                }
                try{
                    token = await jwtToken.sign(
                        {
                            cartId: thisCartId,
                            customerId: auth.customerId,
                            customer: {
                                firstname: auth.customer.firstname,
                                lastname: auth.customer.lastname,
                                addressOne: auth.customer.addressOne,
                                addressTwo: auth.customer.addressTwo,
                                phone: auth.customer.phone,
                                postcode: auth.customer.postcode,
                                country: auth.customer.country,
                                instructions: auth.customer.instructions,
                                location,
                            },
                            items: props.basketItems,
                            taxCharges:{
                                standardCharges: calculateStandardRate(),
                                reducedCharges: calculateReducedRate(),
                                totalTax: calculateTotalTaxes(),
                            },
                            deliveryCharges:{
                                subtotal: charges,
                                standardCharges: finalStandard,
                                reducedCharges: finalReduced,
                                totalDelivery: totalAfter
                            },
                            subTotal: (calculateSubtotal()).toFixed(2),
                            total: (calculateTotal() + finalStandard + finalReduced).toFixed(2) ,
                            type,
                            currency: auth.appSettings.shop.currencySymbol,
                            status: 0,
                            customerToken: auth.customerToken
                        },
                        C4RT_API_KEY,
                        {expiresIn: '1h'}
                    );
                }catch(err){console.log(err)}
                await axios.post(`${process.env.REACT_APP_SITE_URL}/api/orders/new`, {}, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(async res => {
                        await auth.setOrderId(res.data.order._id)
                        await auth.setOrderNumber(res.data.order.number)
                        await auth.setCartId(res.data.order.cartId)
                        await auth.setCustomersOrders(res.data.orders)
                    })
                    .catch(error => {
                        console.log(error);
                    })
            // }
        }
        let {devMode, publicKey} = await getCartPublicKey()
        try{
            await localStorage.setItem('customerData', JSON.stringify({
                customer: auth.customer, token: auth.customerToken, publicKey,
                orderId: auth.orderId, orderNumber: auth.orderNumber,
                orders: auth.customersOrders
            }))
            await auth.setDevMode(devMode)
        }catch(error){}
        await auth.setPublicKey(publicKey)
        let createdPromise = null;
        try{
            createdPromise = await loadStripe(publicKey)
            await auth.setCreatedPromise(createdPromise)
        }catch(error){
            console.log(error)
        }
        if(!!createdPromise){
            setShowUserPayment(prevState => !prevState)
            setShowConfirmItems(prevState => !prevState)
        }
        props.setLoading(false)

    }
    const calculateDeliveryPercentages = () => {
        let standardTotals =0, reducedTotals =0, zeroTotals =0;
        for(let i=0 ; i<props.basketItems.length ; i++){
            let { price, value } = props.basketItems[i];
            if(props.basketItems[i].taxRate === 5 || props.basketItems[i].taxRate === '5' ||
                props.basketItems[i].taxRate === 'Reduced Rate'){
                let reducedItemsTotal = (price*value)
                reducedTotals += Number(reducedItemsTotal);
            }
            if(props.basketItems[i].taxRate === 20 || props.basketItems[i].taxRate === '20' ||
                props.basketItems[i].taxRate === 'Standard Rate'){
                let standardItemsTotal = (price*value)
                standardTotals += Number(standardItemsTotal);
            }
            if(props.basketItems[i].taxRate === 0 || props.basketItems[i].taxRate === '0' ||
                props.basketItems[i].taxRate === 'Zero Rate'){
                let zeroItemsTotal = (price*value)
                zeroTotals += Number(zeroItemsTotal);
            }
        }
        let redTot = reducedTotals, stdTot = standardTotals, zeroTot = zeroTotals
        reducedTotals = (reducedTotals/props.basketValue)*100;
        standardTotals = (standardTotals/props.basketValue)*100;
        zeroTotals = (zeroTotals/props.basketValue)*100;
        return {
            delivery: {reducedTotals, standardTotals, zeroTotals},
            tax: {redTot, stdTot, zeroTot}
        }
    }
    const handleShowOffer = async () => {
        await props.handleShowGoLive()
        await props.handleCloseBasket()

    }

    const authBody = () => {
        return (
            <div className={'disable-dbl-tap-zoom'}>
                <div style={{width:'100%', textAlign:'center', marginBottom:'30px'}}>
                    <img src={auth.appSettings.invoicing.logo}
                         alt={auth.appSettings.invoicing.invoice.companyName || auth.username}
                         style={{
                             marginLeft:'auto', marginRight:'auto',
                             borderRadius:'10px 10px 10px 10px'}}
                    />
                    <p>
                        <em>
                            {auth.appSettings.invoicing.aboutCompany}
                        </em>
                    </p>
                </div>
                <p><strong>Please enter in your email address to continue...</strong></p>
                <form autoComplete={'off'}>
                    <input type="email"
                           className="customer-email form-control"
                           placeholder="Email address"
                           id="customer-email"
                           value={currentEmail}
                           name='customer-email'
                           onChange={buildAndCheckEmail}
                           disabled={showResetPassword}
                           style={{marginBottom: '25px'}}
                    />
                </form>
                {!auth.appSettings.shop.storeIsLive && emailValid && !auth.isLoggedIn &&
                !customerIsAdmin ?
                    <Alert key={'user-alerted-note'} variant={'danger'}
                           style={{marginTop: '30px', textAlign: 'center', fontSize: '0.9em'}}>
                        Only the store owner can create accounts at the moment!
                    </Alert>
                    : !auth.appSettings.shop.storeIsLive && emailValid && auth.isLoggedIn &&
                currentEmail !== auth.userEmail ?
                <>

                    <Alert key={'user-alerted-note'} variant={'danger'}
                           style={{marginTop: '30px', textAlign: 'center', fontSize: '0.9em'}}>
                        Only the store owner can create accounts at the moment!<br />
                        To create live accounts, please <span onClick={handleShowOffer}
                                                              style={{cursor: 'pointer', fontWeight: 'bold'}}>
                            launch @{auth.username}
                       </span> 🚀
                    </Alert>
                </>
                    :
                    customerExists && emailValid && !showResetPassword ?
                        <div>
                            <p style={{marginTop: '20px'}}>
                                Welcome back, please enter your password below to continue...
                            </p>
                            <input type="password"
                                   className="customer-email form-control"
                                   placeholder="Password"
                                   id="customer-password"
                                   value={currentPassword}
                                   name='customer-password'
                                   onChange={buildCheckPassword}
                                   onKeyUp={confirmPassword}
                            />
                            <p style={{textAlign: 'right', fontSize: '0.9em',
                                textDecoration: 'underline', cursor: 'pointer'
                            }} onClick={resetPasswordHandler}>Reset password!</p>
                            {((auth.isLoggedIn && currentEmail !== auth.userEmail) ||
                                (!auth.isLoggedIn && !customerIsAdmin)) &&
                                <Alert key={'id2x6'} style={{fontSize: '0.8em', textAlign:'center'}} variant={'danger'}>
                                    Only the store owner can log in when the store is in "DEV" mode
                                </Alert>
                            }
                            {errorMessage &&
                            <Alert key={'id2x6'} style={{fontSize: '0.9em', textAlign:'center'}} variant={'danger'}>
                                {errorMessage}
                            </Alert>
                            }
                        </div>
                        : !customerExists && emailValid &&
                        <>
                            <p style={{
                                marginTop: '30px',
                                fontWeight: 'bold'
                            }}>
                                Hi, we've not met. Let's get your account setup real quick.
                            </p>
                            <p style={{marginBottom: '0'}}>
                                Please enter your personal contact information.
                            </p>
                            <div className="signup-grid-container" style={{
                                marginBottom: '0',
                            }}>
                                <form autoComplete={'off'}>
                                    <div className="row" style={{padding: '0', margin:'0', width:'100%'}} >
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                                            <div className="Left">
                                                <input type="text"
                                                       className="form-control"
                                                       placeholder="First Name"
                                                       id="customer-firstname"
                                                       name='firstname'
                                                       onInput={getFirstname}
                                                       onChange={validateNames}
                                                       value={currentFirstname}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder="Last Name"
                                                   id="customer-lastname"
                                                   name='lastname'
                                                   onInput={getLastname}
                                                   onChange={validateNames}
                                                   value={currentLastname}
                                            />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12">
                                            {namesValid &&
                                            collectPasswords()
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </>
                }
                {showResetPassword && !successAlert &&
                <form autoComplete={'off'}>
                    <input type="text"
                           className="form-control"
                           placeholder="6 Digit Code"
                           id="security-code"
                           name='security-code'
                           onInput={resetCodeHandler}
                           onKeyUp={validateSecurityCode}
                           value={resetCode}
                    />
                </form>
                }
                {showPasswordResetFields && successAlert ?
                collectPasswords()
                    : invalidCode &&
                    <p>Not correct try again</p>
                }
            </div>
        )
    }
    const mapProducts = () => {
        return props.basketItems.map((product, index) => <UserBasketItem
            key={'print-' + product._id + '-' + index}
            addToBasketValue={props.addToBasketValue}
            navButtonColor={navButtonColor}
            priceButtonColor={priceButtonColor}
            controlButtonColor={controlButtonColor}
            optionId={product.optionId}
            index={index}
            product={product}
            basketValue={props.basketValue}
            setBasketValue={props.setBasketValue}
            basketItems={props.basketItems}
            setBasketItems={props.setBasketItems}
            handleCloseBasket={props.handleCloseBasket}
            setShowBasket={props.setShowBasket}
            taxRate={product.taxRate}
            takeFromBasketValue={props.takeFromBasketValue}
        />)
    }
    const checkCartKeyIsValid = async () => {
        let {devMode, publicKey} = await getCartPublicKey()
        let keyType = publicKey.split('_')[0]
        let keyMode = publicKey.split('_')[1]
        if(devMode){
            if(keyType === 'pk'){
                setKeyIsValid(true)
            } else {
                setKeyIsValid(false)
            }
        } else {
            if(keyType === 'pk' && keyMode === 'live'){
                setKeyIsValid(true)
            } else {
                setKeyIsValid(false)
            }
        }
        setHaveCheckedKey(true)
    }
    if(!haveCheckedKey){
        checkCartKeyIsValid().then()
    }

    return (
        <>
            <ModalHeader closeButton>

                {!props.showCompleteBasket && props.basketItems.length > 0 ?
                    <ModalTitle>Confirm Basket</ModalTitle>
                    : props.basketItems.length === 0 ?
                        <ModalTitle>{auth.appSettings.invoicing.invoice.companyName}</ModalTitle>
                        :
                        !showCheckEmail ?
                        <ModalTitle>{auth.appSettings.invoicing.invoice.companyName} | Access</ModalTitle>
                        : !showConfirmItems ?
                            <ModalTitle>Enter Delivery Contact Info</ModalTitle>
                            : !!auth.customerToken ?
                            <ModalTitle>Account Login</ModalTitle>
                                :
                                <ModalTitle>Final Order Confirmation</ModalTitle>
                }
            </ModalHeader>
            {auth.appSettings.invoicing.invoice.location.lat === 0 &&
            auth.appSettings.invoicing.invoice.location.lng === 0 &&
            props.basketItems.length !== 0
                ?
                <ModalBody>
                    <Alert key={'keys-not-set'} style={{fontSize: '0.9em', textAlign:'center'}}
                           variant={'danger'}>
                        The address is not set for this store so we cannot take
                            any orders at the moment!
                    </Alert>
                    {!auth.isLoggedIn &&
                    <p style={{fontSize: '0.8em'}}><em><strong>NOTE: </strong>
                        If you are the store owner, to continue, please empty the basket,
                        login to your account, visit <strong>settings</strong> > <strong>
                            my account</strong> area and set your address.</em></p>
                    }
                </ModalBody>
                :
            haveCheckedKey && !keyIsValid &&
            props.basketItems.length !== 0 ?
                <ModalBody>
                    <Alert key={'keys-not-set'} style={{fontSize: '0.9em', textAlign:'center'}}
                           variant={'danger'}>
                        Payment keys have not been configured correctly!
                    </Alert>
                    {!auth.isLoggedIn &&
                    <p style={{fontSize: '0.8em'}}><em><strong>NOTE: </strong>
                        If you are the store owner, to continue, please empty the basket,
                        login to your account, visit <strong>settings</strong> > <strong>
                            payment keys</strong> area and set your payment keys.</em></p>
                    }
                </ModalBody>
            :
                !props.showCompleteBasket && props.basketItems.length > 0 ?
                <ModalBody>
                        <>
                            <table style={{width: '100%'}}>
                                <tbody>
                                {mapProducts()}
                                </tbody>
                            </table>
                            {showBasketCharges()}
                        </>
                </ModalBody>
                : props.basketItems.length === 0 ?
                    <ModalBody>
                        {authBody()}
                    </ModalBody>
                    :
                !showCheckEmail && !auth.customerIsLoggedIn ?
                <ModalBody>
                    {authBody()}
                </ModalBody>
                    : !showConfirmItems && !auth.detailsConfirmed ?
                        <ModalBody>
                            <p style={{marginBottom: '0'}}>
                                Please enter in your information.
                            </p>
                            <SignupGridContainer errorMessage={errorMessage}
                                                 countries={props.countries}
                                                 getInputData={getInputData}
                                                 updateCustomer={updateCustomer}
                            />
                        </ModalBody>
                        : !showUserPayment ?
                        <ModalBody>
                            <Button onClick={checkMailHandler}
                                    className={'edit-button'}
                                    text={'EDIT'}
                                    key={'reset-address'}
                                    usedKey={'reset-'}
                                    keyMode={'address'}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    solid={true}
                                    float={'right'}
                                    categoryButton={true}
                                    active={false}
                                    icon={faPencilAlt}/>
                            <p className={"user-details"}>
                                <strong>Attn: </strong>{auth.customer.firstname} {auth.customer.lastname}
                            </p>
                            {auth.customer.phone &&
                            <p className={"user-details"}><strong> Phone: </strong> {auth.customer.phone}</p>
                            }
                            <p className={"user-details"}>
                                <strong>Email:</strong> {auth.customer.email}
                            </p>
                            <p className={"user-details"}>
                                <strong>Address:</strong> {auth.customer.addressOne}, {auth.customer.addressTwo}, {auth.customer.postcode}
                            </p>
                            <p className={"user-details"}>
                                <strong>Country:</strong> {auth.customer.country}
                            </p>
                            {auth.customer.instructions && auth.customer.instructions !== 'Add Instructions' &&
                            <p className={"user-details"}>
                                <strong>Delivery Instructions:</strong> {auth.customer.instructions}
                            </p>
                            }
                            <hr/>
                            {
                                <>
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                        {mapProducts()}
                                        </tbody>
                                    </table>
                                </>
                            }
                            {showBasketCharges()}
                        </ModalBody>
                            : auth.orderNumber && auth.orderId && auth.publicKey && auth.createdPromise &&
                            <ModalBody>
                                <UserPay
                                    orderNumber={auth.orderNumber}
                                    orderId={auth.orderId}
                                    publicKey={auth.publicKey}
                                    handleNewOrderSuccess={props.handleNewOrderSuccess}
                                    handleCloseBasket={props.handleCloseBasket}
                                    setErrorMessage={setErrorMessage}
                                    setLoading={props.setLoading}
                                    username={username}
                                />
                                {auth.devMode &&
                                <>
                                    <Alert key={'id2x6'}
                                           style={{fontSize: '0.9em', textAlign: 'center', marginTop: '30px'}}
                                           variant={'danger'}>
                                        <p style={{marginBottom: '0'}}><strong>@{username && username} is currently set to "test" or "dev"
                                            mode.*</strong></p>
                                    </Alert>
                                    <p style={{fontSize: '0.8em'}}><em>
                                        *Any emails sent out in this mode will
                                        be sent to the store owner. This is so the store owner can test their communications and
                                        emails etc. When you switch over to "Live" mode in your payment settings area this notice
                                        will disappear and the platform will send emails to the customer and not the c4rt owner.
                                    </em>
                                    </p>
                                </>
                                }
                            </ModalBody>

            }

            {!props.showCompleteBasket && props.basketItems.length > 0 ?
                <ModalFooter>

                    <div style={{width: '100%'}}>
                        {auth.appSettings.shop.allowCollections &&
                        !auth.appSettings.shop.disableDeliveries &&
                        <Button onClick={deliveryTypeHandler}
                                className={'user-button'}
                                text={props.orderIsDelivery ? 'COLLECT' : 'DELIVER'}
                                key={'delivery-' + props.orderIsDelivery}
                                usedKey={'delivery-'}
                                keyMode={props.orderIsDelivery}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                solid={true}
                                float={'left'}
                                disabled={!distanceValid()}
                                categoryButton={true}
                                sidebarButton={true}
                                active={false}
                                icon={faRetweet}/>
                        }
                        {auth.appSettings.shop.minOrder ?
                            <Button onClick={checkEmail}
                                    className={'user-button'}
                                    text={'CONFIRM'}
                                    key={'confirm-pre-order'}
                                    usedKey={'confirm-'}
                                    keyMode={'pre-order'}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    solid={true}
                                    float={'right'}
                                    categoryButton={true}
                                    sidebarButton={true}
                                    active={false}
                                    disabled={!basketValid()}
                                    icon={null}/>
                            : username === 'about' ?
                                <Button onClick={props.handleShowAuth}
                                        className={'user-button'}
                                        text={'BUILD YOUR OWN C4RT NOW'}
                                        key={'user-display-login'}
                                        usedKey={'user-'}
                                        keyMode={'display-login'}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        solid={true}
                                        float={'right'}
                                        categoryButton={true}
                                        sidebarButton={true}
                                        active={false}
                                        icon={null}/>
                                :<>
                                    <Button onClick={checkEmail}
                                            className={'user-button'}
                                            text={'CONFIRM BASKET'}
                                            key={'reset-address' + keyIsValid}
                                            usedKey={'reset-'}
                                            keyMode={'address' + keyIsValid}
                                            controlButtonColor={controlButtonColor}
                                            navButtonColor={navButtonColor}
                                            priceButtonColor={priceButtonColor}
                                            solid={true}
                                            float={'right'}
                                            disabled={
                                                !keyIsValid ||
                                                (auth.appSettings.invoicing.invoice.location.lat === 0 &&
                                                    auth.appSettings.invoicing.invoice.location.lng === 0)
                                            }
                                            categoryButton={true}
                                            sidebarButton={true}
                                            active={true}
                                            icon={null}/>
                                </>
                        }
                    </div>



                </ModalFooter>
                :
                props.basketItems.length === 0 ?
                    <ModalFooter>
                        {!customerExists ?
                            <Button onClick={submitFormForConfirmation}
                                    className={'user-button'}
                                    text={'CREATE ACCOUNT'}
                                    key={'customer-account-create-' + passwordsMatch}
                                    usedKey={'customer-account-create-'}
                                    keyMode={passwordsMatch}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    solid={true}
                                    float={'right'}
                                    disabled={!passwordsMatch}
                                    categoryButton={true}
                                    sidebarButton={true}
                                    active={true}
                                    icon={null}/>
                            : customerIsAdmin && !showResetPassword ?
                                <Button onClick={userLogin}
                                        className={'user-button'}
                                        text={'ACCOUNT LOGIN'}
                                        key={'customer-login-button' + currentPassword}
                                        usedKey={'customer-login-'}
                                        keyMode={'button' + currentPassword}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        solid={true}
                                        float={'right'}
                                        disabled={auth.isLoggedIn && currentEmail !== auth.userEmail}
                                        categoryButton={true}
                                        sidebarButton={true}
                                        active={true}
                                        icon={null}/>
                                : showResetPassword ?
                                    <Button onClick={updatePasswordHandler}
                                            className={'user-button'}
                                            text={'RESET PASSWORD'}
                                            key={'reset-user-password-button'}
                                            usedKey={'reset-user'}
                                            keyMode={'-password-button'}
                                            controlButtonColor={controlButtonColor}
                                            navButtonColor={navButtonColor}
                                            priceButtonColor={priceButtonColor}
                                            solid={true}
                                            float={'right'}
                                            disabled={auth.isLoggedIn && currentEmail !== auth.userEmail}
                                            categoryButton={true}
                                            sidebarButton={true}
                                            active={true}
                                            icon={null}/>
                                    :
                                    <Button onClick={customerLogin}
                                            className={'user-button'}
                                            text={'ACCOUNT LOGIN'}
                                            key={'customer-login-button'}
                                            usedKey={'customer-login-'}
                                            keyMode={'button'}
                                            controlButtonColor={controlButtonColor}
                                            navButtonColor={navButtonColor}
                                            priceButtonColor={priceButtonColor}
                                            solid={true}
                                            float={'right'}
                                            disabled={
                                                (auth.isLoggedIn && currentEmail !== auth.userEmail) ||
                                                (!auth.isLoggedIn && !customerIsAdmin)
                                            }
                                            categoryButton={true}
                                            sidebarButton={true}
                                            active={true}
                                            icon={null}/>

                        }
                    </ModalFooter>
                : !showCheckEmail && !auth.customerIsLoggedIn ?
                    <ModalFooter>
                        <Button onClick={submitFormForConfirmation}
                                className={'user-button'}
                                text={'EDIT BASKET ITEMS'}
                                key={'edit-basket-button'}
                                usedKey={'edit-'}
                                keyMode={'basket-button'}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                solid={true}
                                float={'left'}
                                categoryButton={true}
                                sidebarButton={true}
                                active={false}
                                icon={null}/>
                        {!customerExists ?
                            <Button onClick={submitFormForConfirmation}
                                    className={'user-button'}
                                    text={'CHECK EMAIL'}
                                    key={'customer-create-button'}
                                    usedKey={'customer-create-'}
                                    keyMode={'button'}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    solid={true}
                                    float={'right'}
                                    disabled={!passwordsMatch}
                                    categoryButton={true}
                                    sidebarButton={true}
                                    active={true}
                                    icon={null}/>
                            :
                            <Button onClick={customerLogin}
                                    className={'user-button'}
                                    text={'ACCOUNT LOGIN'}
                                    key={'customer-login-buttons'}
                                    usedKey={'customer-login-'}
                                    keyMode={'buttons'}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    solid={true}
                                    float={'right'}
                                    disabled={auth.isLoggedIn && currentEmail !== auth.userEmail}
                                    categoryButton={true}
                                    sidebarButton={true}
                                    active={true}
                                    icon={null}/>
                        }

                    </ModalFooter>
                    : !showConfirmItems && !auth.detailsConfirmed ?
                        <ModalFooter>
                            <Button onClick={switchShow}
                                    className={'user-button'}
                                    text={'EDIT BASKET ITEMS'}
                                    key={'switch-show-items'}
                                    usedKey={'switch-show'}
                                    keyMode={'-items'}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    solid={true}
                                    float={'right'}
                                    categoryButton={true}
                                    sidebarButton={true}
                                    active={false}
                                    icon={null}/>
                            {auth.customerIsLoggedIn &&
                            <Button onClick={updateCustomer}
                                    className={'user-button'}
                                    text={'CONFIRM DETAILS'}
                                    key={'customer-login-details'}
                                    usedKey={'customer-login-'}
                                    keyMode={'details'}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    solid={true}
                                    float={'right'}
                                    categoryButton={true}
                                    sidebarButton={true}
                                    active={true}
                                    icon={null}/>
                            }
                        </ModalFooter>
                        : !showUserPayment ?
                        <ModalFooter>
                            {auth.appSettings.shop.restrictDist &&
                            auth.finalDistance > auth.appSettings.shop.inputDist &&
                            <div style={{width: '60%'}}>
                                <p className={'insufficient-warning'}>
                                    Sorry but this store only takes orders from customers who live up to
                                    <strong> {Number(auth.appSettings.shop.inputDist).toFixed(0)} {' '}
                                    {auth.appSettings.shop.distUnits}</strong> away.</p>
                            </div>
                            }

                            {auth.appSettings.shop.allowCollections &&
                            !auth.appSettings.shop.disableDeliveries &&
                            <Button onClick={deliveryTypeHandler}
                                    className={'user-button'}
                                    text={props.orderIsDelivery ? 'COLLECT ORDER' : 'DELIVER ORDER'}
                                    key={'order-type-select'}
                                    usedKey={'order-type-'}
                                    keyMode={'select'}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    solid={true}
                                    float={'left'}
                                    disabled={!distanceValid()}
                                    categoryButton={false}
                                    sidebarButton={true}
                                    active={false}
                                    icon={faRetweet}/>
                            }
                            <Button onClick={handleUserPayment}
                                    className={'user-button'}
                                    text={'COMPLETE ORDER'}
                                    key={'order-type-' + props.orderIsDelivery}
                                    usedKey={'order-type-'}
                                    keyMode={props.orderIsDelivery}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    solid={true}
                                    float={'right'}
                                    disabled={!distanceValid() || props.loading}
                                    categoryButton={true}
                                    sidebarButton={true}
                                    active={true}
                                    icon={null}/>
                        </ModalFooter>
                            :
                            <ModalFooter>
                                {auth.appSettings.shop.restrictDist &&
                                auth.finalDistance > auth.appSettings.shop.inputDist &&
                                <div style={{width: '60%'}}>
                                    <p className={'insufficient-warning'}>
                                        Sorry but this store only takes orders from customers who live up to
                                        <strong> {Number(auth.appSettings.shop.inputDist).toFixed(0)} {' '}
                                            {auth.appSettings.shop.distUnits}</strong> away.</p>
                                </div>
                                }
                                <Button onClick={handleUserPayment}
                                        className={'user-button'}
                                        text={'EDIT ORDER'}
                                        key={'order-type-select-edit'}
                                        usedKey={'order-type-'}
                                        keyMode={'select-edit'}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        solid={true}
                                        float={'right'}
                                        sidebarButton={true}
                                        disabled={!distanceValid()}
                                        categoryButton={false}
                                        active={false}
                                        icon={null}/>
                                <Button onClick={createNewOrder}
                                        className={'user-button'}
                                        text={<strong>
                                            PAY {auth.appSettings.shop.currencySymbol}{(calculateTotal() +
                                            (calculateDeliveryPercentages().delivery.standardTotals/100)*0.2*calculateDeliveryCharges() +
                                            (calculateDeliveryPercentages().delivery.reducedTotals/100)*0.05*calculateDeliveryCharges()).toFixed(2) } NOW
                                        </strong>}
                                        key={'order-type-select-pay'}
                                        usedKey={'order-type-'}
                                        keyMode={'select-pay'}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        solid={true}
                                        float={'right'}
                                        disabled={!distanceValid() || props.loading}
                                        categoryButton={true}
                                        sidebarButton={true}
                                        active={true}
                                        icon={null}/>
                            </ModalFooter>
            }
        </>
    )
};

export default UserBasket;
