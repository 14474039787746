import React, {useContext, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../shared/Button";
import {
    faBan, faRedo, faShoppingBasket, faUser, faPrint, faFileAlt, faFileDownload, faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import '../../../css/OrdersCart.css';
import AuthContext from "../../shared/auth-context";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import CustomerTaxInvoice from "../../shared/CustomerTaxInvoice";
import jwtToken from 'jsonwebtoken'
import OrderRow from "./OrderRow";
let token;

const OrderItem = props => {
    const auth = useContext(AuthContext)
    const [ showCustomer, setShowCustomer ] = useState(false)
    const [ viewInvoice, setViewInvoice ] = useState(false)
    const { customerId, firstname, lastname,
        addressOne, addressTwo, postcode, phone, instructions } = props.customer
    const { total, type, controlButtonColor, navButtonColor, priceButtonColor } = props
    const { showNew, showApproved, showReady, showComplete, getIcon,
        showArchived, showCancelled } = props.isSet

    const handleViewInvoice = () => {
        setViewInvoice(prevState => !prevState)
    }
    const reInitiateOrder = async event => {
        event.preventDefault();
        let items = []
        for(let i=0 ; i<props.items.length ; i++){
            let newRow = {
                productId: props.items[i].productId,
                productName: props.items[i].productName,
                price: props.items[i].price,
                value: props.items[i].value,
                image: props.items[i].image,
                taxRate: props.items[i].taxRate,
                taxCharge: props.items[i].taxCharge
            }
            items.push(newRow)
        }
        try{
            token = await jwtToken.sign(
                {
                    customerId,
                    customer: { firstname, lastname,
                        addressOne, addressTwo, postcode, phone, instructions },
                    cartId:auth.cartId,
                    taxCharges: props.order.taxCharges,
                    deliveryCharges: props.order.deliveryCharges,
                    subTotal: props.order.subTotal,
                    items: items,
                    total: total,
                    type: type,
                    status: 0,
                    token: auth.userToken,
                    orderId: props.orderId
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        updateStatus(token).then();
    }
    const approveOrder = async event => {
        event.preventDefault();
        let items = []
        for(let i=0 ; i<props.items.length ; i++){
            let newRow = {
                productId: props.items[i].productId,
                productName: props.items[i].productName,
                price: props.items[i].price,
                value: props.items[i].value,
                image: props.items[i].image,
                taxRate: props.items[i].taxRate,
                taxCharge: props.items[i].taxCharge
            }
            items.push(newRow)
        }
        try{
            token = await jwtToken.sign(
                {
                    customerId,
                    customer: { firstname, lastname,
                        addressOne, addressTwo, postcode, phone, instructions },
                    cartId:auth.cartId,
                    taxCharges: props.order.taxCharges,
                    deliveryCharges: props.order.deliveryCharges,
                    subTotal: props.order.subTotal,
                    items: items,
                    total: total,
                    type: type,
                    status: 1,
                    token: auth.userToken,
                    orderId: props.orderId
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        updateStatus(token).then();
    }
    const orderReady = async event => {
        event.preventDefault();
        let items = []
        for(let i=0 ; i<props.items.length ; i++){
            let newRow = {
                productId: props.items[i].productId,
                productName: props.items[i].productName,
                price: props.items[i].price,
                value: props.items[i].value,
                image: props.items[i].image,
                taxRate: props.items[i].taxRate,
                taxCharge: props.items[i].taxCharge
            }
            items.push(newRow)
        }
        try{
            token = await jwtToken.sign(
                {
                    customerId,
                    customer: { firstname, lastname,
                        addressOne, addressTwo, postcode, phone, instructions },
                    cartId:auth.cartId,
                    taxCharges: props.order.taxCharges,
                    deliveryCharges: props.order.deliveryCharges,
                    subTotal: props.order.subTotal,
                    items: items,
                    total: total,
                    type: type,
                    status: 2,
                    token: auth.userToken,
                    orderId: props.orderId
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        updateStatus(token).then();
    }
    const orderCompleted = async event => {
        event.preventDefault();
        let items = []
        for(let i=0 ; i<props.items.length ; i++){
            let newRow = {
                productId: props.items[i].productId,
                productName: props.items[i].productName,
                price: props.items[i].price,
                value: props.items[i].value,
                image: props.items[i].image,
                taxRate: props.items[i].taxRate,
                taxCharge: props.items[i].taxCharge
            }
            items.push(newRow)
        }
        try{
            token = await jwtToken.sign(
                {
                    customerId,
                    customer: { firstname, lastname,
                        addressOne, addressTwo, postcode, phone, instructions },
                    cartId:auth.cartId,
                    taxCharges: props.order.taxCharges,
                    deliveryCharges: props.order.deliveryCharges,
                    subTotal: props.order.subTotal,
                    items: items,
                    total: total,
                    type: type,
                    status: 3,
                    token: auth.userToken,
                    orderId: props.orderId
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        updateStatus(token).then();
    }
    const archiveOrder = async event => {
        event.preventDefault();
        let items = []
        for(let i=0 ; i<props.items.length ; i++){
            let newRow = {
                productId: props.items[i].productId,
                productName: props.items[i].productName,
                price: props.items[i].price,
                value: props.items[i].value,
                image: props.items[i].image,
                taxRate: props.items[i].taxRate,
                taxCharge: props.items[i].taxCharge
            }
            items.push(newRow)
        }
        try{
            token = await jwtToken.sign(
                {
                    customerId,
                    customer: { firstname, lastname,
                        addressOne, addressTwo, postcode, phone, instructions },
                    cartId:auth.cartId,
                    taxCharges: props.order.taxCharges,
                    deliveryCharges: props.order.deliveryCharges,
                    subTotal: props.order.subTotal,
                    items: items,
                    total: total,
                    type: type,
                    status: 4,
                    token: auth.userToken,
                    orderId: props.orderId
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        updateStatus(token).then();
    }
    const cancelOrder = async event => {
        let items = []
        for(let i=0 ; i<props.items.length ; i++){
            let newRow = {
                productId: props.items[i].productId,
                productName: props.items[i].productName,
                price: props.items[i].price,
                value: props.items[i].value,
                image: props.items[i].image,
                taxRate: props.items[i].taxRate,
                taxCharge: props.items[i].taxCharge
            }
            items.push(newRow)
        }
        try{
            token = await jwtToken.sign(
                {
                    customerId,
                    customer: { firstname, lastname,
                        addressOne, addressTwo, postcode, phone, instructions },
                    cartId:auth.cartId,
                    taxCharges: props.order.taxCharges,
                    deliveryCharges: props.order.deliveryCharges,
                    subTotal: props.order.subTotal,
                    items: items,
                    total: total,
                    type: type,
                    status: 5,
                    token: auth.userToken,
                    orderId: props.orderId
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {
                    expiresIn: '1h'
                }
            );
        }catch(err){console.log(err)}
        updateStatus(token).then();
    }
    const updateStatus = async (token) => {
        await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/orders`, {}, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                props.getOrders();
                // emailUpdateCustomer(status).then();
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleCloseCustomer = async () => {
        try {
            await setShowCustomer(false);
        }catch(err){
            console.log(err)
        }
        setTimeout(function(){ setViewInvoice(false) }, 100);
    }
    const handleShowCustomer = () => {
        setShowCustomer(true);
    }

    const handleShowOrder = () => {
        setShowCustomer(false);
    }

    let [ basketIcon, fileIcon ] =
        [faShoppingBasket, faUser, faPrint, faFileDownload, faFileAlt]
    let [ cancelIcon ] = [ faBan, faRedo ]

    return (
        <>
            {showNew &&  props.status === 10 &&
            <OrderRow firstname={firstname} lastname={lastname} postcode={postcode} phone={phone}
                      orderNumber={props.order.number} currencySymbol={auth.appSettings.shop.currencySymbol}
                      total={total.toFixed(2)} approveOrder={approveOrder} order={props.order}
                      cancelIcon={cancelIcon} cancelOrder={cancelOrder} handleShowCustomer={handleShowCustomer}
                      fileIcon={fileIcon} handleShowOrder={handleShowOrder} basketIcon={basketIcon} status={10}
                      orderIcon={props.getIcon(1)} orderId={props.orderId} controlButtonColor={controlButtonColor}
                      navButtonColor={navButtonColor} priceButtonColor={priceButtonColor} getIcon={getIcon}
            />
            }
            {showNew &&  props.status === 0 &&
            <OrderRow firstname={firstname} lastname={lastname} postcode={postcode} phone={phone}
                      orderNumber={props.order.number} currencySymbol={auth.appSettings.shop.currencySymbol}
                      total={total.toFixed(2)} approveOrder={approveOrder} order={props.order}
                      cancelIcon={cancelIcon} cancelOrder={cancelOrder} handleShowCustomer={handleShowCustomer}
                      fileIcon={fileIcon} handleShowOrder={handleShowOrder} basketIcon={basketIcon} status={0}
                      orderIcon={props.getIcon(1)} orderId={props.orderId} controlButtonColor={controlButtonColor}
                      navButtonColor={navButtonColor} priceButtonColor={priceButtonColor} getIcon={getIcon}
            />
            }
            {showApproved && props.status === 1 &&
            <OrderRow firstname={firstname} lastname={lastname} postcode={postcode} phone={phone}
                      orderNumber={props.order.number} currencySymbol={auth.appSettings.shop.currencySymbol}
                      total={total.toFixed(2)} orderReady={orderReady} order={props.order}
                      cancelIcon={cancelIcon} cancelOrder={cancelOrder} handleShowCustomer={handleShowCustomer}
                      fileIcon={fileIcon} handleShowOrder={handleShowOrder} basketIcon={basketIcon} status={1}
                      orderIcon={props.getIcon(2)} orderId={props.orderId} controlButtonColor={controlButtonColor}
                      navButtonColor={navButtonColor} priceButtonColor={priceButtonColor} getIcon={getIcon}
            />
            }
            {showReady &&  props.status === 2 &&
            <OrderRow firstname={firstname} lastname={lastname} postcode={postcode} phone={phone}
                      orderNumber={props.order.number} currencySymbol={auth.appSettings.shop.currencySymbol}
                      total={total.toFixed(2)} orderCompleted={orderCompleted} order={props.order}
                      cancelIcon={cancelIcon} cancelOrder={cancelOrder} handleShowCustomer={handleShowCustomer}
                      fileIcon={fileIcon} handleShowOrder={handleShowOrder} basketIcon={basketIcon} status={2}
                      orderIcon={props.getIcon(3)} orderId={props.orderId} controlButtonColor={controlButtonColor}
                      navButtonColor={navButtonColor} priceButtonColor={priceButtonColor} getIcon={getIcon}

            />
            }
            {showComplete &&  props.status === 3 &&
            <OrderRow firstname={firstname} lastname={lastname} postcode={postcode} phone={phone}
                      orderNumber={props.order.number} currencySymbol={auth.appSettings.shop.currencySymbol}
                      total={total.toFixed(2)} archiveOrder={archiveOrder} order={props.order}
                      cancelIcon={cancelIcon} cancelOrder={cancelOrder} handleShowCustomer={handleShowCustomer}
                      fileIcon={fileIcon} handleShowOrder={handleShowOrder} basketIcon={basketIcon} status={3}
                      orderIcon={props.getIcon(4)} orderId={props.orderId} controlButtonColor={controlButtonColor}
                      navButtonColor={navButtonColor} priceButtonColor={priceButtonColor} getIcon={getIcon}
            />
            }
            {showArchived &&  props.status === 4 &&
            <OrderRow firstname={firstname} lastname={lastname} postcode={postcode} phone={phone}
                      orderNumber={props.order.number} currencySymbol={auth.appSettings.shop.currencySymbol}
                      total={total.toFixed(2)} orderCompleted={orderCompleted} order={props.order}
                      cancelIcon={cancelIcon} cancelOrder={cancelOrder} handleShowCustomer={handleShowCustomer}
                      fileIcon={fileIcon} handleShowOrder={handleShowOrder} basketIcon={basketIcon} status={4}
                      orderIcon={props.getIcon(5)} orderId={props.orderId} controlButtonColor={controlButtonColor}
                      navButtonColor={navButtonColor} priceButtonColor={priceButtonColor} getIcon={getIcon}

            />
            }
            {showCancelled &&  props.status === 5 &&
            <OrderRow firstname={firstname} lastname={lastname} postcode={postcode} phone={phone}
                      orderNumber={props.order.number} currencySymbol={auth.appSettings.shop.currencySymbol}
                      total={total.toFixed(2)} reInitiateOrder={reInitiateOrder} order={props.order}
                      cancelIcon={cancelIcon} cancelOrder={cancelOrder} handleShowCustomer={handleShowCustomer}
                      fileIcon={fileIcon} handleShowOrder={handleShowOrder} basketIcon={basketIcon} status={5}
                      orderIcon={null} orderId={props.orderId} controlButtonColor={controlButtonColor}
                      navButtonColor={navButtonColor} priceButtonColor={priceButtonColor} getIcon={getIcon}
            />
            }
            <Modal show={showCustomer} onHide={handleCloseCustomer}>
                <ModalBody>
                    <CustomerTaxInvoice
                        key={'order-' + props.orderId}
                        loadedOrders={props.loadedOrders}
                        order={props.order}
                        orderIndex={props.orderIndex}
                        viewInvoice={viewInvoice}
                        controlButtonColor={controlButtonColor}
                        navButtonColor={navButtonColor}
                        priceButtonColor={priceButtonColor}
                    />
                </ModalBody>
                <ModalFooter>
                    <div style={{width: '100%'}}>
                        {/*<p style={{*/}
                        {/*    paddingTop: '10px', width: '200px', marginLeft: 'auto',*/}
                        {/*    marginRight: 'auto', float: 'left', marginBottom: '2px'*/}
                        {/*}}>*/}
                        {/*    Powered by c4rt.uk 🚀</p>*/}
                        <Button onClick={handleCloseCustomer}
                                className={'user-button'}
                                text={'CLOSE'}
                                key={'items-invoice' + navButtonColor+controlButtonColor}
                                keyUsed={'close-invoice'}
                                float={'right'}
                                keyMode={navButtonColor+controlButtonColor}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                sidebarButton={true}
                                active={false}
                                solid={true}
                                icon={faTimesCircle}/>
                        <Button onClick={handleViewInvoice}
                                className={'user-button'}
                                text={viewInvoice ? 'VIEW ITEMS' : 'VIEW INVOICE'}
                                key={'items-invoice' + navButtonColor+controlButtonColor}
                                keyUsed={'items-invoice'}
                                float={'right'}
                                keyMode={navButtonColor+controlButtonColor}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                sidebarButton={true}
                                active={true}
                                solid={true}
                                fullIcon={viewInvoice ?
                                    <FontAwesomeIcon style={{marginLeft: '7px'}} icon={faShoppingBasket}/>
                                    :
                                    <FontAwesomeIcon style={{marginLeft: '7px'}} icon={faFileDownload}/>
                                }/>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default OrderItem;


// <div className="order-grid-container">
//     <div className="Icon Circle">
//         <FontAwesomeIcon icon={props.getIcon(6)} />
//     </div>
//     <div className="OrderName order-details">
//         <strong>{firstname} {lastname}</strong><br />
//         {postcode}, {phone && phone}
//     </div>
//     <div className="Price">
//         <p><span className={'item-price'}> <strong>#{props.order.number}</strong>
//             {auth.appSettings.shop.currencySymbol}{total.toFixed(2)}</span></p>
//     </div>
//     <div className="Options order-details">
//         <Button variant={'secondary'} className="left-nav-button"
//                 onClick={handleShowOrder} >
//             <FontAwesomeIcon icon={basketIcon} />
//         </Button>
//         <Button variant={'secondary'} className="nav-buttons"
//                 onClick={handleShowCustomer} >
//             <FontAwesomeIcon onClick={''} icon={printIcon} />
//         </Button>
//         <Button variant={'secondary'} className="nav-buttons"
//                 onClick={approveOrder} >
//             <FontAwesomeIcon onClick={''} icon={downloadIcon} />
//         </Button>
//         <Button variant={'secondary'} className="nav-buttons"
//                 onClick={handleShowCustomer} >
//             <FontAwesomeIcon onClick={''} icon={fileIcon} />
//         </Button>
//         <Button variant={'secondary'} className="nav-buttons"
//                 onClick={cancelOrder} >
//             <FontAwesomeIcon icon={cancelIcon} />
//         </Button>
//     </div>
//     <div className="Items">
//         {phone &&
//         <strong>{phone}</strong>
//         }
//     </div>
// </div>
