import React from 'react';
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Modal from "react-bootstrap/Modal";
import Button from "../shared/Button";

const WarningModal = props => {
    const {message, warning, handleShowWarningModal, handleDelete,
        controlButtonColor, navButtonColor, priceButtonColor} = props

        return (
            <>
                <Modal show={warning} onHide={handleShowWarningModal}>
                <ModalHeader closeButton>
                    <ModalTitle>Are you sure?</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <p>{message}</p>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleShowWarningModal}
                            className={'user-button pink'}
                            text={'CANCEL'}
                            key={'cancel-type'}
                            usedKey={'cancel-'}
                            keyMode={'type'}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            active={false}
                            solid={false}
                            categoryButton={false}
                            sidebarButton={true}
                            float={'right'}
                            icon={null}/>
                    <Button onClick={handleDelete}
                            className={'user-button purple'}
                            text={'DELETE'}
                            key={'delete-warning'}
                            usedKey={'delete-'}
                            keyMode={'warning'}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            solid={false}
                            categoryButton={true}
                            sidebarButton={true}
                            active={false}
                            icon={null}/>
                </ModalFooter>
                </Modal>
            </>
        );

}

export default WarningModal;
