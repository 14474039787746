import React from 'react';

const DisplayOptionsAmount = (props) =>  {
    const {currentValue, valueColor} = props
        return (
            <div className="options-amount-display product-controls"
                 style={{
                     backgroundColor: valueColor, paddingTop: '6px',
                     marginTop: '5px'
                 }}>
                 <span style={{padding: '0', fontSize: '1.8em', lineHeight: '25px'}}>
                 {currentValue}
                 </span>
            </div>
        );

}

export default DisplayOptionsAmount;
