import React, {useContext, useRef} from 'react';
import AuthContext from "./auth-context";
import axios from "axios";

const LogoUpload = (props) => {
    const auth = useContext(AuthContext)
    const productId = props.productId
    const filePickerRef = useRef()

    const pickLogoImageHandler = () => {
        filePickerRef.current.click();
    }

    const pickedLogoHandler = async event => {
        event.preventDefault();
        // eslint-disable-next-line
        let pickedFile;
        let fileIsValid;
        if (event.target.files && event.target.files.length === 1){
            pickedFile = event.target.files[0]
            fileIsValid = true
        } else {
            fileIsValid = false
        }
        const data = new FormData()
        if (fileIsValid) {
            data.append('logo-image', pickedFile)
            data.append('username', auth.username)
            try {
                await axios.post(`${process.env.REACT_APP_SITE_URL}/api/upload/logo-img-upload`,
                    data,
                    {
                        method: 'POST',
                        headers: {
                            'accept': 'application/json',
                            'Accept-Language': 'en-US,en;q=0.8',
                            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                            'Authorization': 'Bearer ' + auth.userToken
                        }
                    })
                    .then(async res => { // then print response status
                        await auth.setAppSettings(res.data.appSettings)
                        props.setCurrentLogo(res.data.appSettings.invoicing.logo)
                        props.reloadCart();
                        const userData = JSON.parse(localStorage.getItem('userData'))
                        let { username, token, cartId, categoryId, stripeSettings, cartOrder,
                            cartInvoices, userEmail, tickets, messages} = userData
                        await localStorage.setItem('userData', JSON.stringify({
                            username, token, cartId, categoryId, appSettings: res.data.appSettings,
                            stripeSettings, cartOrder, cartInvoices, userEmail, tickets, messages
                        }))
                    })
            } catch (errors) {
                console.log(errors)
            }
        }
    }


    return (
        <div >
            <div className={`from-control`}>
                <form encType="multipart/form-data" method="post">
                    <input id={productId}
                           ref={filePickerRef}
                           style={{display: 'none'}}
                           type={'file'}
                           className="form-control-file"
                           name="logo-image"
                           accept={'.jpg, .png, .jpeg'}
                           onChange={pickedLogoHandler}
                    />
                </form>
            </div>
            <div id={auth.appSettings.invoicing.logo}
                 style={{cursor: 'pointer'}}>
                <span style={{fontSize:'0.7em', marginBottom:'0', width:'100%', display:'block'}}>
                                Company logo:
                            </span>
                <img style={{borderRadius:'10px 10px 10px 10px', width: '120px', height:'auto'}}
                     src={props.currentLogo}
                     alt={'Company logo'}
                     onClick={pickLogoImageHandler}/>
            </div>
        </div>
    )

}

export default LogoUpload;
