import React, {useContext, useState} from 'react';
import Button from "../shared/Button";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import CustomerTaxInvoice from "../shared/CustomerTaxInvoice";
import ModalFooter from "react-bootstrap/ModalFooter";
import { faShoppingBasket, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../shared/auth-context";

const CustomersOrdersRow = (props) => {
    const auth = useContext(AuthContext)
    let { navButtonColor, controlButtonColor } = props
    const [ showCustomer, setShowCustomer ] = useState(false)
    let {type, currency, number, total, status, _id, paymentComplete, priceButtonColor } = props.order
    let currentStatus;
    if(status === 0){
        currentStatus = 'Received'
    } else if(status === 1){
        currentStatus = 'Approved'
    } else if(status === 2){
        currentStatus = 'Ready'
    } else if (status === 3){
        currentStatus = 'Completed'
    } else if (status === 3){
        currentStatus = 'Completed'
    } else if (status === 10){
        currentStatus = 'Received'
    }
    const handleCloseCustomer = async () => {
        try {
            await setShowCustomer(false);
        }catch(err){
            console.log(err)
        }
    }
    const handleBuildBasketFromOrder = async () => {
        if(props.order.paymentComplete){
            if(!showCustomer){
                setShowCustomer(true)
            } else {
                await props.buildBasketFromOrder(props.order)
            }
        } else {
            await props.buildBasketFromOrder(props.order)
        }
    }
    const getPaymentStatus = () => {
        if(props.order.paymentComplete){
            return '#20c620'
        } else {
            return 'red'
        }
    }

    return (
        <>
        <div id={_id} className="card admin-nav-toggle">
            <div className="customer-orders-grid-container">
                <div className="OrdersNumber">
                    <strong>#{number}</strong>
                </div>
                <div className="OrdersType">
                    {type}
                </div>
                <div className="OrdersStatus">
                    <strong>{currentStatus}</strong>
                </div>
                <div className="OrdersPrice">
                    {currency + '' + total.toFixed(2)} <span style={{color: getPaymentStatus(), fontSize: '0.6em'}}>
                        {paymentComplete ? '[PAID]' : '[UNPAID]'}
                    </span>

                </div>
                <div className="OrdersAction">
                    <Button onClick={handleBuildBasketFromOrder}
                            className={'user-button'}
                            text={props.order.paymentComplete ? 'INVOICE' : 'PAY NOW'}
                            key={'action-on-order' + navButtonColor+controlButtonColor}
                            keyUsed={'action-on-order'}
                            float={'right'}
                            keyMode={navButtonColor+controlButtonColor}
                            controlButtonColor={controlButtonColor}
                            priceButtonColor={priceButtonColor}
                            navButtonColor={navButtonColor}
                            active={false}
                            categoryButton={true}
                            sidebarButton={true}
                            icon={null}/>
                </div>
            </div>
        </div>
        <div id={_id} className="card hide-nav-toggle">
                <table>
                    <tbody>
                    <tr>
                        <td style={{paddingLeft:'10px', paddingTop:'0'}}>
                            <strong>#{number}</strong>
                        </td>
                        <td style={{paddingLeft:'10px', paddingTop:'0'}}>
                            <strong>{currentStatus}</strong>
                        </td>
                        <td rowSpan={'2'} style={{padding:'10px'}}>
                            <Button onClick={handleBuildBasketFromOrder}
                                    className={'user-button'}
                                    text={props.order.paymentComplete ? 'INVOICE' : 'PAY NOW'}
                                    key={'action-on-order' + navButtonColor+controlButtonColor}
                                    keyUsed={'action-on-order'}
                                    float={'right'}
                                    keyMode={navButtonColor+controlButtonColor}
                                    controlButtonColor={controlButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    navButtonColor={navButtonColor}
                                    active={false}
                                    categoryButton={true}
                                    sidebarButton={true}
                                    icon={null}/>
                        </td>
                    </tr>
                    <tr>
                        <td style={{paddingLeft:'10px', paddingTop:'0'}}>
                            {currency + '' + total.toFixed(2)}
                        </td>
                        <td style={{paddingLeft:'10px', paddingTop:'0'}}>
                        <span style={{color: getPaymentStatus(), fontSize: '0.6em'}}>
                            {paymentComplete ? '[PAID]' : '[UNPAID]'}
                        </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <Modal show={showCustomer} onHide={handleCloseCustomer}>
                <ModalBody>
                    <CustomerTaxInvoice
                        key={'order-' + props.orderId}
                        loadedOrders={auth.customersOrders}
                        order={props.order}
                        orderIndex={props.orderIndex}
                        viewInvoice={true}
                    />
                </ModalBody>
                <ModalFooter>
                    <div style={{width: '100%'}}>
                        <Button onClick={handleCloseCustomer}
                                className={'user-button'}
                                text={'CLOSE'}
                                key={'close-order' + navButtonColor+controlButtonColor}
                                keyUsed={'close-order'}
                                float={'right'}
                                keyMode={navButtonColor+controlButtonColor}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                active={false}
                                categoryButton={true}
                                sidebarButton={true}
                                solid={true}
                                icon={faTimesCircle}/>
                        <Button onClick={handleBuildBasketFromOrder}
                                className={'user-button'}
                                text={'REORDER'}
                                key={'re-order' + navButtonColor+controlButtonColor}
                                keyUsed={'re-order'}
                                float={'right'}
                                keyMode={navButtonColor+controlButtonColor}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                active={true}
                                categoryButton={true}
                                sidebarButton={true}
                                solid={true}
                                icon={faShoppingBasket}/>
                        <p style={{
                            paddingTop: '10px',
                            float: 'left', marginBottom: '2px'
                        }}>
                            Powered by c4rt.uk 🚀</p>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );

}

export default CustomersOrdersRow;
