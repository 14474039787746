import React from 'react';
import EmailSettingsKey from "./EmailSettingsKey";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp, faCogs} from "@fortawesome/free-solid-svg-icons";

const OrderApprovedEmail = props => {


    return (
        <div>
            <p>
                When you approve an order (either manually or upon first payment) this email
                will be sent to notify your customers that you are dealing with their order.
            </p>
            <span>
                <input type="text"
                   className="form-control"
                   placeholder="Order approved email title"
                   id="order-approved-email-title"
                   name='order-approved-email-title'
                   value={props.data.approvedMessageTitle}
                   onInput={props.handleSetData}
                   onBlur={props.updateMessage}
                   aria-label={'Order approved email title'}
                />
            </span>
            <p style={{cursor: 'pointer', float: 'right', paddingTop: '14px', fontSize: '0.8em',
                width: '100%', display: 'block', marginBottom: '0', paddingBottom: '0'}}
               onClick={props.handleShowInfo}>
                <em>{!props.showInfo ?
                    <p>
                        <FontAwesomeIcon icon={faCogs} /> See available personalisation settings
                    </p> :
                    <p>
                        <FontAwesomeIcon icon={faCogs} /> Hide settings <FontAwesomeIcon icon={faAngleUp} />
                    </p>}
                </em>
            </p>
            {props.showInfo &&
            <>
                <EmailSettingsKey showApproved={props.showApproved}/>
                <div className="container">
                    <div className="row" style={{marginTop: '0px'}}>
                        <div className="col" style={{paddingTop: '10px'}}>
                            <p><strong>Collection text:</strong></p>
                        </div>
                        <div className="col-8">
                            <textarea className="form-control"
                                      placeholder="Collection instructions"
                                      id="order-approved-collection"
                                      name='order-approved-collection'
                                      value={props.data.approvedCollectionMessage}
                                      onInput={props.handleSetData}
                                      onBlur={props.updateMessage}
                                      aria-label={'Order approved collection instructions'}
                            />
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col" style={{paddingTop: '0'}}>
                                        <p><strong>Delivery text:</strong></p>
                                    </div>
                                    <div className="col-8">
                            <textarea className="form-control"
                                      placeholder="Delivery instructions"
                                      id="order-approved-delivery"
                                      name='order-approved-delivery'
                                      value={props.data.approvedDeliveryMessage}
                                      onInput={props.handleSetData}
                                      onBlur={props.updateMessage}
                                      aria-label={'Order approved delivery instructions'}
                            />
                        </div>
                    </div>
                </div>
            </>
            }
            <textarea style={{minHeight: '300px'}}
                      className="form-control"
                      placeholder="Order approved email message body"
                      id="order-approved-email-message"
                      name='order-approved-email-message'
                      value={props.data.approvedMessageBody}
                      onInput={props.handleSetData}
                      onBlur={props.updateMessage}
                      aria-label={'Order approved email message body'}
            />
        </div>
    );

}

export default OrderApprovedEmail;
