import React, {useContext} from 'react';
import { faComments } from "@fortawesome/free-solid-svg-icons";
import Button from "../shared/Button";
import AuthContext from "../shared/auth-context";

const SupportPageUserRow = props => {
    const auth = useContext(AuthContext)
    let { ticketId, ticket, priceButtonColor, navButtonColor, controlButtonColor,
        handleShowMessage, setTicketId} = props
    let rawReplyTime = ticket.lastReply
    let rawDate = rawReplyTime.split('T')[0]
    let day = rawDate.split('-')[2]
    let month = rawDate.split('-')[1]
    let year = rawDate.split('-')[0]
    let rawTime = rawReplyTime.split('T')[1]
    let hours = rawTime.split(':')[0]
    let minutes = rawTime.split(':')[1]



    const prepareMessage = async () => {
        await setTicketId(ticket._id)
        handleShowMessage()
    }

    let ticketStatus = ticket.status
    let finalStatus =''
    for(let i=0 ;i<ticketStatus.length ; i++){
        finalStatus += i===0?ticketStatus[i].toUpperCase():ticketStatus[i]
    }
        return (
            <div className={'container'} id={'ticket-' + ticketId + '-' + auth.userMessages.length}>
                <div className={'row user-support-row'} >
                    <div className={'col-xl-7 col-lg-6 col-md-12 col-sm-12 col-xs-12'}>
                        <p style={{fontWeight:'bold'}}>{ticket.title}</p>
                    </div>
                    <div className={'col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12'}>
                        <p style={{lineHeight:'1em'}}>
                        {finalStatus}<br />
                            <span style={{fontSize:'0.7em'}}>
                                {day}-{month}-{year} {hours}:{minutes}
                            </span>
                        </p>
                    </div>
                    <div className={'col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12'}>
                        <Button onClick={prepareMessage}
                                className={'admin-nav-toggle user-button'}
                                style={{fontSize:'1.1em', marginLeft:'0'}}
                                solid={true}
                                float={'right'}
                                categoryButton={true}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                active={false}
                                text={ticket.status === 'awaiting' ? 'VIEW' : 'VIEW'}
                                icon={faComments}/>
                    </div>
                </div>
            </div>
        );

}

export default SupportPageUserRow;
