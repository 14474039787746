import React from 'react';
import EmailSettingsKey from "./EmailSettingsKey";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp, faCogs} from "@fortawesome/free-solid-svg-icons";

const OrderReadyEmail = props => {

    return (
        <div>
            <p>
                When an order is ready (either for collection or it has been dispatched) then
                the customer will be sent this email.
            </p>
            <input type="text"
                   className="form-control"
                   placeholder="Order ready email title"
                   id="order-ready-email-title"
                   name='order-ready-email-title'
                   value={props.data.readyMessageTitle}
                   onInput={props.handleSetData}
                   onBlur={props.updateMessage}
                   aria-label={'Order ready email title'}
            />
            <p style={{cursor: 'pointer', float: 'right', paddingTop: '14px', fontSize: '0.8em',
                width: '100%', display: 'block', marginBottom: '0', paddingBottom: '0'}}
               onClick={props.handleShowInfo}>
                <em>{!props.showInfo ?
                    <p>
                        <FontAwesomeIcon icon={faCogs} /> See available personalisation settings
                    </p> :
                    <p>
                        <FontAwesomeIcon icon={faCogs} /> Hide settings <FontAwesomeIcon icon={faAngleUp} />
                    </p>}
                </em>
            </p>
            {props.showInfo &&
            <>
                <EmailSettingsKey showReady={props.showReady} showInfo={props.showInfo}
                                  handleShowInfo={props.handleShowInfo}/>
                <div className="container">
                    <div className="row" style={{marginTop: '0px'}}>
                        <div className="col" style={{paddingTop: '10px'}}>
                            <p><strong>Collection text:</strong></p>
                        </div>
                        <div className="col-8">
                            <textarea className="form-control"
                                      placeholder="Collection instructions"
                                      id="order-ready-collection"
                                      name='order-ready-collection'
                                      value={props.data.readyCollectionMessage}
                                      onInput={props.handleSetData}
                                      onBlur={props.updateMessage}
                                      aria-label={'Order ready collection instructions'}
                            />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col" style={{paddingTop: '0'}}>
                            <p><strong>Delivery text:</strong></p>
                        </div>
                        <div className="col-8">
                            <textarea className="form-control"
                                      placeholder="Delivery instructions"
                                      id="order-ready-delivery"
                                      name='order-ready-delivery'
                                      value={props.data.readyDeliveryMessage}
                                      onInput={props.handleSetData}
                                      onBlur={props.updateMessage}
                                      aria-label={'Order ready delivery instructions'}
                            />
                        </div>
                    </div>
                </div>
            </>
            }
            <textarea style={{minHeight: '300px'}}
                      className="form-control"
                      placeholder="Order ready email message body"
                      id="order-ready-email-message"
                      name='order-ready-email-message'
                      value={props.data.readyMessageBody}
                      onInput={props.handleSetData}
                      onBlur={props.updateMessage}
                      aria-label={'Order ready email message body'}
            />

        </div>
    );

}

export default OrderReadyEmail;
