import React from 'react';

const StockModalDynamicRow  = props => {
    let {product, handleChangeAmount, updateProductAmount, newAmount, isPriceOption, row,
        productName} = props
    let qty, amt
    if(isPriceOption){
        qty = row.quantities
        amt = row.amount
    } else {
        qty = product.quantities
        amt = product.amount
    }
    return (
            <div className={'container'}
                 style={{margin:'0 0 20px 0', padding:'0'}}
                 id={'modal-stock-row-' + product._id}>
                <div style={{margin:'0', padding:'0', width:'100%'}} className={'row'}>
                    <div className={'col-1'} style={{padding:'0'}}>
                        <img alt={product.productName} className=" admin-nav-toggle product-image-manage"
                             src={product.image}/>
                    </div>
                    <div className={'col-7'} style={{padding:'0'}}>
                        {productName} ({qty})
                    </div>
                    <div style={{fontSize:'0.8em', padding:'5px 0 0 0'}} className={'col-2'} >
                        Qty: {amt}
                    </div>
                    <div className={'col-2'} style={{padding:'0'}}>
                        <form>
                            <input type="text"
                                   autoComplete={'off'}
                                   style={{
                                       float: 'left', marginTop: '0',
                                       width: '55px', height: '35px', paddingLeft:'10px',
                                       marginRight: '10px', marginBottom: '0'
                                   }}
                                   className="form-control"
                                   placeholder={'0'}
                                   id="restrict-distance"
                                   onInput={handleChangeAmount}
                                   onBlur={updateProductAmount}
                                   name='restrict-distance'
                                   value={newAmount}
                            />
                        </form>
                    </div>
                </div>
            </div>
        );

}

export default StockModalDynamicRow;
