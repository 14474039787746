import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutSubscriptionForm from "./CheckoutSubscriptionForm";

const UserSubscribe = props => {

    return (
        <Elements stripe={props.stripePromise}>
            <CheckoutSubscriptionForm
                setErrorMessage={props.setErrorMessage}
                type={props.type}
                userStripeId={props.userStripeId}
                orderId={props.orderId}
                handleActivateStore={props.handleActivateStore}
            />
        </Elements>
    );
};

export default UserSubscribe;
