import React, {useState} from 'react';
import '../../../../css/CartNav.css';
import Button from "../../../shared/Button";
import {faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import WarningModal from "../../../shared/WarningModal";

const AdminCategoryButton = props => {
    const [ categoryName, setCategoryName ] = useState(props.categoryName)
    const [ warning, setWarning ] = useState(false)
    const [ message, setMessage ] = useState('')
    let { controlButtonColor, navButtonColor, displayCategory, index, keyUsed, keyMode,
        priceButtonColor} = props

    const getIcon = () => {
        if(!props.editMode){
            return <FontAwesomeIcon onClick={handleEditCategory} icon={faPencilAlt}/>
        } else {
            if(props.loadedCategories.length > 1){
                return <FontAwesomeIcon onClick={handleEditCategory} icon={faTrashAlt}/>
            }
        }
    }
    const handleShowWarningModal = () => {
        setMessage(
            'Are you sure you want to do that? You will lose any products allocated to this Category!'
        )
        setWarning(prevState => !prevState)
    }
    const handleDeleteCategory = async () => {
        await props.deleteCategory(props.catId)
        setWarning(false)
    }
    const handleEditCategory = async () => {
        if(props.editMode && props.loadedCategories.length > 1){
            // handleShowWarningModal
            handleShowWarningModal()

        }
        props.setEditMode(prevMode => !prevMode)


    }
    const saveData = event => {
        let categoriesName = event.target.value.toUpperCase()
        setCategoryName(categoriesName)
    }
    const handleUpdateCategory = async () => {
        await props.setEditMode(false)
        await props.updateCategoryName({newName: categoryName, categoryId: props.catId})

    }
    const handleDisplayChange = async () => {
        await props.setDisplayCategory(props.index)
    }


    return (
        <>
            <Button id={keyUsed + keyMode} onClick={handleDisplayChange}
                    className={'admin-nav-toggle user-button'}
                    text={props.editMode && props.index === props.displayCategory ?
                        <input type="text"
                               className="category-menu-inputs"
                               placeholder={categoryName}
                               name={props.catId}
                               onInput={saveData}
                               onBlur={handleUpdateCategory}
                               disabled={!props.editMode}
                               style={{width:'auto', paddingLeft:'5px', paddingRight:'5px'}}
                        />
                        :
                        <input type="text"
                               className="category-menu-inputs"
                               placeholder={categoryName}
                               name={props.catId}
                            // onChange={saveData}
                               style={{cursor: 'pointer'}}
                            // onBlur={handleUpdateCategory}
                               disabled={!props.editMode}
                        />
                    }
                    key={props.categoryIndex + '-' + props.catId + '-button'}
                    usedKey={props.categoryIndex + '-' + props.catId}
                    keyMode={'-button'}
                    controlButtonColor={controlButtonColor}
                    navButtonColor={navButtonColor}
                    priceButtonColor={priceButtonColor}
                    solid={true}
                    categoryButton={true}
                    active={displayCategory === index}
                    fullIcon={getIcon()}/>

            <WarningModal message={message}
                          warning={warning}
                          handleShowWarningModal={handleShowWarningModal}
                          handleDelete={handleDeleteCategory}
                          navButtonColor={navButtonColor}
                          controlButtonColor={controlButtonColor}
                          priceButtonColor={priceButtonColor}
            />
        </>
    );
}

export default AdminCategoryButton;
