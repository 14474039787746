import React, {useContext, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "../shared/Button";
import {faRetweet} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../shared/auth-context";
import jwtToken from 'jsonwebtoken';
import axios from "axios";
import UserSubscribe from "./UserSubscribe";
import {Alert} from "react-bootstrap";

const GoLiveModal = props => {
    const auth = useContext(AuthContext);
    const [ type, setType ] = useState(1)
    let [ errorMessage, setErrorMessage ] = useState(null)
    let [ showSalesText, setShowSalesText ] = useState(false)
    let {controlButtonColor, navButtonColor, priceButtonColor} = props

    const handleShowSalesText = () => {
        setShowSalesText(prevState => !prevState)
    }

    const activateOffer = () => {
        if(auth.cartStats.numberOfUsers <= 100){
            if(type === 0){
                setType(0.1)
            } else if(type === 1){
                setType(1.1)
            }
        } else if(auth.cartStats.numberOfUsers <= 500){
            if(type === 0){
                setType(0.2)
            } else if(type === 1){
                setType(1.2)
            }
        } else if (auth.cartStats.numberOfUsers <= 1000){
            if(type === 0){
                setType(0.3)
            } else if(type === 1){
                setType(1.3)
            }
        }
    }

    const handleSwitchType = () => {
        if(type === 0){
            setType(1)
        } else if(type === 1){
            setType(0)
        } if(type === 0.1){
            setType(1.1)
        } else if(type === 1.1){
            setType(0.1)
        } if(type === 0.2){
            setType(1.2)
        } else if(type === 1.2){
            setType(0.2)
        } if(type === 0.3){
            setType(1.3)
        } else if(type === 1.3){
            setType(0.3)
        }
    }

    const handleActivateStore = async () => {
        let submitData = {
            username: auth.appSettings.username,
            shop: {
                showTax: auth.appSettings.shop.showTax,
                vatNumber: auth.appSettings.shop.vatNumber,
                pricesBefore: auth.appSettings.shop.pricesBefore,
                setGlobal: auth.appSettings.shop.setGlobal,
                globalRate: auth.appSettings.shop.globalRate,
                closeShop: auth.appSettings.shop.closeShop,
                minOrder: auth.appSettings.shop.minOrder,
                minValue: auth.appSettings.shop.minValue,
                restrictDist: auth.appSettings.shop.restrictDist,
                inputDist: auth.appSettings.shop.inputDist,
                distUnits: auth.appSettings.shop.distUnits,
                allowInt: auth.appSettings.shop.allowInt,
                locale: auth.appSettings.shop.locale,
                currencySymbol: auth.appSettings.shop.currencySymbol,
                currency: auth.appSettings.shop.currency,
                showCharges: auth.appSettings.shop.showCharges,
                chargesType: auth.appSettings.shop.chargesType,
                storeIsLive: true,
                chargeDelivery:
                    {
                        minFee: auth.appSettings.shop.chargeDelivery.minFee,
                        maxFee: auth.appSettings.shop.chargeDelivery.maxFee,
                        perUnit: auth.appSettings.shop.chargeDelivery.perUnit,
                        standardFee: auth.appSettings.shop.chargeDelivery.standardFee,
                        showFree: auth.appSettings.shop.chargeDelivery.showFree,
                        freeOver: auth.appSettings.shop.chargeDelivery.freeOver
                    },
                openingHours: auth.appSettings.shop.openingHours,
                openingDays: auth.appSettings.shop.openingDays
            },
            email: {
                welcomeEmail: {
                    title: auth.appSettings.email.welcomeEmail.title,
                    body: auth.appSettings.email.welcomeEmail.body,
                    collection: auth.appSettings.email.welcomeEmail.collection,
                    delivery: auth.appSettings.email.welcomeEmail.delivery
                },
                newOrder: {
                    title: auth.appSettings.email.newOrder.title,
                    body: auth.appSettings.email.newOrder.body,
                    collection: auth.appSettings.email.newOrder.collection,
                    delivery: auth.appSettings.email.newOrder.delivery
                },
                approvedOrder: {
                    title: auth.appSettings.email.approvedOrder.title,
                    body: auth.appSettings.email.approvedOrder.body,
                    collection: auth.appSettings.email.approvedOrder.collection,
                    delivery: auth.appSettings.email.approvedOrder.delivery
                },
                orderCollect: {
                    title: auth.appSettings.email.orderCollect.title,
                    body: auth.appSettings.email.orderCollect.body,
                    collection: auth.appSettings.email.orderCollect.collection,
                    delivery: auth.appSettings.email.orderCollect.delivery
                },
                orderComplete: {
                    title: auth.appSettings.email.orderComplete.title,
                    body: auth.appSettings.email.orderComplete.body,
                    collection: auth.appSettings.email.orderComplete.collection,
                    delivery: auth.appSettings.email.orderComplete.delivery
                }
            },
            invoicing: {
                logo: auth.appSettings.invoicing.logo,
                invoice: {
                    firstname: auth.appSettings.invoicing.invoice.firstname,
                    lastname: auth.appSettings.invoicing.invoice.lastname,
                    companyName: auth.appSettings.invoicing.invoice.companyName,
                    addressOne: auth.appSettings.invoicing.invoice.addressOne,
                    addressTwo: auth.appSettings.invoicing.invoice.addressTwo,
                    accountEmail: auth.appSettings.invoicing.invoice.accountEmail,
                    country: auth.appSettings.invoicing.invoice.userCountry,
                    phone: auth.appSettings.invoicing.invoice.phone,
                    postcode: auth.appSettings.invoicing.invoice.postcode
                },
                isBranded: auth.appSettings.invoicing.isBranded,
                aboutCompany: auth.appSettings.invoicing.aboutCompany,
                bacs: {
                    payeeName: auth.appSettings.invoicing.bacs.payeeName,
                    bankName: auth.appSettings.invoicing.bacs.bankName,
                    sortCode: auth.appSettings.invoicing.bacs.sortCode,
                    accNumber: auth.appSettings.invoicing.bacs.accNumber
                }
            },
            settingsValid: false,
            userToken: auth.userToken
        }
        let token;
        try{
            token = await jwtToken.sign(
                {
                    username: auth.username,
                    submitData
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {expiresIn: '1h'}
            );
        }catch(err){console.log(err)}
        await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/settings`, {}, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then((res) => {
                console.log(res.data)
                props.getSettings().then();
            })
            .catch(error => {
                console.log(error);
            });
        props.handleCloseGoLive();
    }

    const createSubscriptionPayment = () => {
        document.getElementById('stripe-subscribe-button').click()
    }
        return (
            <>
            <Modal show={props.showGoLive} onHide={props.handleCloseGoLive}>
                <ModalHeader closeButton>
                    <ModalTitle>Launch @{auth.username} 🚀</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className="container" style={{marginBottom: '0'}}>
                        {!auth.subscriptionPromise && !auth.orderId &&
                        <p>
                            If you would like to be able to share your @c4rt username with people,
                            share your link on social media and start taking orders, then all you've
                            got to do is activate your cart. Your new store will be open for
                            business and you can begin taking orders as soon as you've setup your
                            subscription.
                        </p>
                        }
                        {auth.subscriptionPromise && auth.orderId &&
                            <div>
                            <div>
                                <p>
                                    Annual membership is £149.99 (save 16%) and monthly membership is
                                    £14.99 per month. Accounts are activated immediately and you can
                                    cancel at any time.
                                </p>
                                <span style={{fontSize: '0.9em'}}>
                                        {auth.cartStats.numberOfUsers <= 1000 &&
                                        <Alert key={'promo'} style={{
                                            textAlign:'center', fontSize: '1em',
                                            marginBottom:'25px'
                                        }}
                                               variant={'success'}>
                                            First <strong><span style={{
                                                textDecoration: 'line-through'
                                            }}>{
                                                   auth.cartStats.numberOfUsers <= 100 ? '100'
                                                       :
                                                       auth.cartStats.numberOfUsers <= 500 ? '500'
                                                           :
                                                           auth.cartStats.numberOfUsers <= 1000 && '1000'

                                               }</span> {
                                            auth.cartStats.numberOfUsers <= 100 ? 100-auth.cartStats.numberOfUsers
                                                :
                                                auth.cartStats.numberOfUsers <= 500 ? 500-auth.cartStats.numberOfUsers
                                                    :
                                                    auth.cartStats.numberOfUsers <= 1000 && 1000-auth.cartStats.numberOfUsers
                                            }</strong> customers can get up to <strong>
                                            {
                                                auth.cartStats.numberOfUsers <= 100 ? '33'
                                                    :
                                                    auth.cartStats.numberOfUsers <= 500 ? '25'
                                                        :
                                                        auth.cartStats.numberOfUsers <= 1000 && '20'
                                            }% off
                                        </strong> <span
                                            style={{fontWeight: 'bold', cursor:'pointer'}}
                                            onClick={handleShowSalesText}
                                        >
                                            ...{showSalesText ? 'hide' : 'see more' }
                                            </span>
                                        </Alert>
                                        }
                                </span>
                                {showSalesText &&
                                <div style={{marginBottom: '30px'}}>
                                    <p>Hi there 👋,</p>
                                    <p>
                                        We've only just launched and are offering
                                        a discount to the first <span style={{
                                        textDecoration: 'line-through'
                                    }}>{
                                        auth.cartStats.numberOfUsers <= 100 ? '100'
                                            :
                                            auth.cartStats.numberOfUsers <= 500 ? '500'
                                                :
                                                auth.cartStats.numberOfUsers <= 1000 && 1000

                                    }</span> {
                                        auth.cartStats.numberOfUsers <= 100 ?
                                            100-auth.cartStats.numberOfUsers
                                            :
                                            auth.cartStats.numberOfUsers <= 500 ?
                                                500-auth.cartStats.numberOfUsers
                                                :
                                                auth.cartStats.numberOfUsers <= 1000 &&
                                                1000-auth.cartStats.numberOfUsers
                                    } customers who register their businesses with us; and to
                                        show you how important you are to us, we'd like you to keep
                                        this rate for the life of the subscription.
                                    </p>
                                    <p>The offer we have for you:</p>
                                    <p style={{textAlign:'center'}}>
                                        <strong>Monthly: </strong> <span style={{
                                            textDecoration:'line-through'}}
                                    >£14.99</span> £{
                                        auth.cartStats.numberOfUsers <= 100 ?
                                            '10.49 (save 30%)'
                                            :
                                            auth.cartStats.numberOfUsers <= 500 ?
                                                '12.59 (save 16%)'
                                                :
                                                auth.cartStats.numberOfUsers <= 1000 &&
                                                '13.49 (save 10%)'

                                    }
                                    </p>
                                    <p style={{textAlign:'center'}}>
                                        <strong>Annual: </strong> <span style={{
                                        textDecoration:'line-through'}}
                                    >£{
                                        auth.cartStats.numberOfUsers <= 100 ?
                                            '149.99'
                                            :
                                            auth.cartStats.numberOfUsers <= 500 ?
                                                '179.88'
                                                :
                                                auth.cartStats.numberOfUsers <= 1000 &&
                                        '179.88'

                                    }</span> £{
                                        auth.cartStats.numberOfUsers <= 100 ?
                                            '99.99 save (33%)'
                                            :
                                            auth.cartStats.numberOfUsers <= 500 ?
                                                '134.91 save (25%)'
                                                :
                                                auth.cartStats.numberOfUsers <= 1000 &&
                                                '143.90 save (20%)'

                                    }
                                    </p>
                                    <p>
                                        To activate this offer simply <span
                                        style={{
                                            cursor: 'pointer', fontWeight: 'bold',
                                            color: '#007bff', textDecoration:'underline'
                                        }}
                                        onClick={activateOffer}>
                                        click here</span> and the page will switch the price in the
                                        payment button down below for you.
                                    </p>
                                    <p>Best wishes.</p>
                                    <p>C4RT LTD Managing Director</p>
                                    <p>
                                        <em>
                                            <strong>P.S.</strong> This offer automatically
                                            disappears when the target is met and won't be seen
                                            again - which is why it is hidden in here
                                        </em> 😉

                                    </p>
                                </div>
                                }
                            </div>
                            </div>
                        }
                        <div className="row" style={{marginTop: '0', paddingTop: '0'}}>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12"
                                 style={{marginTop: '0', paddingTop: '0'}}>
                                <div style={{marginTop: '0', paddingTop: '0', marginBottom: '20px'}}>
                                    <div style={{height: '40px', display: 'block', width: '100%',
                                        margin: '0', padding: '0'}}>
                                        {auth.subscriptionPromise && auth.orderId &&
                                        <UserSubscribe
                                            setErrorMessage={setErrorMessage}
                                            type={type}
                                            stripePromise={auth.subscriptionPromise}
                                            userStripeId={auth.orderNumber}
                                            orderId={auth.orderId}
                                            handleActivateStore={handleActivateStore}
                                        />
                                        }

                                    </div>
                                    {errorMessage &&
                                    <div style={{display: 'block', marginTop:'15px'}}>
                                        <Alert key={'idx7stripe'} variant={'danger'}>
                                            {errorMessage}
                                        </Alert></div>
                                    }
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                </ModalBody>
                <ModalFooter>
                    {!auth.subscriptionPromise && !auth.orderId &&
                    <div style={{width: '100%'}}>
                        <p>Just getting things ready...</p>
                    </div>
                    }
                    {auth.subscriptionPromise && auth.orderId &&
                        <div style={{width:'100%', display: 'block'}} >
                            <Button onClick={createSubscriptionPayment}
                                    className={'user-button'}
                                    text={type === 1 ? 'PAY £149.99 PER YEAR'
                                        : type === 1.1 ? 'PAY £99.99 PER YEAR'
                                            : type === 1.2 ? 'PAY £134.91 PER YEAR'
                                                : type === 1.3 ? 'PAY £143.90 PER YEAR'
                                                    : type === 0 ? 'PAY £14.99 PER MONTH'
                                                        : type === 0.1 ? 'PAY £10.49 PER MONTH'
                                                            : type === 0.2 ? 'PAY £12.59 PER MONTH'
                                                                : type === 0.3 && 'PAY £13.49 PER MONTH'
                                    }
                                    key={'subscription-payment-button'}
                                    usedKey={'subscription-'}
                                    keyMode={'payment-button'}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    solid={true}
                                    float={'right'}
                                    disabled={false}
                                    categoryButton={true}
                                    active={true}
                                    icon={null}/>

                            <Button onClick={handleSwitchType}
                                    className={'user-button'}
                                    text={type === 1 ? 'MONTHLY'
                                        : type === 1.1 ? 'MONTHLY'
                                            : type === 1.2 ? 'MONTHLY'
                                                : type === 1.3 ? 'MONTHLY'
                                                    : type === 0 ? 'ANNUALLY'
                                                        : type === 0.1 ? 'ANNUALLY'
                                                            : type === 0.2 ? 'ANNUALLY'
                                                                : type === 0.3 && 'ANNUALLY'
                                    }
                                    key={'switch-payment-type-button'}
                                    usedKey={'switch-payment'}
                                    keyMode={'-type-button'}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                    solid={true}
                                    float={'left'}
                                    disabled={false}
                                    categoryButton={true}
                                    active={false}
                                    icon={faRetweet}/>
                        </div>
                    }
                </ModalFooter>
            </Modal>
            </>
        );
}

export default GoLiveModal;
