import React from 'react';
import ReactDOM from 'react-dom';

const Backdrop = props => {
    return ReactDOM.createPortal(
        <div className="backdrop hide-nav-toggle" onClick={props.onClick}/>,
        document.getElementById('backdrop-hook')
    );
};

export default Backdrop;
