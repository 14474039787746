import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const ShiftItem = (props) => {

    const handleDeleteShift = () => {
        let weekday = props.weekday;
        let index = props.index;
        props.handleRemoveShift(weekday, index)
    }

        return (
            <div id={'shift-item'}>
                <p style={{marginLeft: '60px'}}>From {props.displayShiftItem({
                    index: props.index, times: props.times, type:'from', weekday: props.weekday })}
                    <span style={{marginLeft: '33px'}}>to</span> {props.displayShiftItem(
                        {times: props.times, type:'to', index: props.index, weekday: props.weekday })}{props.index > 0 && <FontAwesomeIcon
                        onClick={handleDeleteShift}
                        style={{
                            marginLeft: '17px', color: '#ea3c53',
                            marginTop: '4px', cursor: 'pointer'
                        }} icon={faTimes}/>}</p>
            </div>
        );
}

export default ShiftItem;
