import React from 'react';

import '../../../css/Product.css';
import ProductItem from "./ProductItem";

const Product = props => {
    let {controlButtonColor, navButtonColor, priceButtonColor,shopBackground,
        displayCategory, product } = props
    return (
        <>
            {(props.categoryId === props.catId && props.price > 0 &&
            displayCategory === props.categoryIndex) ||
            (displayCategory === 999 && product.status === 'Featured'
                && props.categoryIndex === 0) ?
                <ProductItem
                    shopBackground={shopBackground}
                    productName={props.productName}
                    image={props.image}
                    price={props.price}
                    quantities={props.quantities}
                    productId={props.productId}
                    taxRate={props.taxRate}
                    addToBasketValue={props.addToBasketValue}
                    takeFromBasketValue={props.takeFromBasketValue}
                    basketItems={props.basketItems}
                    index={props.index}
                    buttonColor={props.buttonColor}
                    valueColor={props.valueColor}
                    product={props.product}
                    loadedProducts={props.loadedProducts}
                    controlButtonColor={controlButtonColor}
                    priceButtonColor={priceButtonColor}
                    navButtonColor={navButtonColor}
                />
                : ''}
        </>
    );
}

export default Product;
