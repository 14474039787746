import React from 'react';

const ListCountries = props => {


        return (
            <option id={props.zone + props.index} value={props.zone}>
                {props.zone}
            </option>
        );

}

export default ListCountries;
