import React, {useContext } from 'react';
import AuthContext from "../shared/auth-context";
import SupportPageUserRow from "./SupportPageUserRow";
import SupportTicketBody from "../shared/SupportTicketBody";
import {
    faCommentMedical,
    faComments,
    faCommentSlash,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../shared/Button";
import '../../css/Button.css'
import SupportModal from "../shared/SupportModal";

const SupportPageUser = props => {
    const auth = useContext(AuthContext)
    let { collectInputData, messageSuccess, messageSuccessCode, controlButtonColor,
    navButtonColor, priceButtonColor, openTicket, showMessage, handleShowMessage,
        ticketId, handleReplyMode, replyMode, setReplyMode, setTicketId,
        setShowMessage, setShowOpenNewTicket,
        showOpenNewTicket
    } = props
    const handleShowNewTicket = () => {
        setShowOpenNewTicket(prevState => !prevState)
    }
        return (
            <div id={'user-support-page'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-xl-4 col-lg-4 col-md-12 col-sm-12 col-sx-12'}>
                            <div className={'container'}>
                                <div className={'row'} style={{marginTop: '0'}}>
                                    {!showOpenNewTicket ?
                                        <>
                                            <p>
                                                We care about your experience and want to ensure
                                                a high quality of service by giving you the ability
                                                to speak with us directly should you experience
                                                any difficulties while using our app.
                                            </p>
                                            <p>
                                                Feel free to contact our UK based customer
                                                support and we'll be happy to help. Our customer
                                                support is open Monday to Saturday from 09:00 to
                                                17:00. Once we've received your ticket we'll
                                                get back to you with a response as soon as possible.
                                            </p>
                                        </>
                                        :
                                        <SupportTicketBody key={'support-ticket-body'}
                                                           loggedIn={true}
                                                           messageSuccess={messageSuccess}
                                                           collectInputData={collectInputData}
                                                           messageSuccessCode={messageSuccessCode}
                                        />
                                    }
                                    <Button onClick={handleShowNewTicket}
                                            className={'admin-nav-toggle ticket-button'}
                                            style={{fontSize:'1.1em', marginLeft:'0'}}
                                            solid={true}
                                            categoryButton={true}
                                            controlButtonColor={controlButtonColor}
                                            navButtonColor={navButtonColor}
                                            priceButtonColor={priceButtonColor}
                                            active={true}
                                            text={!showOpenNewTicket ?
                                                'OPEN A NEW SUPPORT TICKET NOW'
                                                :
                                                'CANCEL'}
                                            icon={!showOpenNewTicket ? faCommentMedical : faCommentSlash}/>
                                    {showOpenNewTicket && !ticketId &&
                                    <Button onClick={openTicket}
                                            className={'admin-nav-toggle ticket-button'}
                                            style={{fontSize:'1.1em', marginLeft:'0'}}
                                            solid={true}
                                            float={'right'}
                                            categoryButton={false}
                                            controlButtonColor={controlButtonColor}
                                            navButtonColor={navButtonColor}
                                            priceButtonColor={priceButtonColor}
                                            active={false}
                                            text={showOpenNewTicket &&
                                            'SEND'}
                                            icon={!showOpenNewTicket ? faCommentMedical : faComments}/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'col-xl-8 col-lg-8 col-md-12 col-sm-12 col-sx-12'}>
                            <div className={'container'} style={{marginTop:'0'}}>
                                <div className={'row'} style={{marginTop:'0'}}>
                                    <div className={'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12'}>
                                        <h3>Previous support tickets</h3>
                                        <p>
                                            View any of your previously submitted tickets
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {auth.userTickets.map((ticket) => {
                                return <SupportPageUserRow
                                            priceButtonColor={priceButtonColor}
                                            navButtonColor={navButtonColor}
                                            controlButtonColor={controlButtonColor}
                                            handleShowMessage={handleShowMessage}
                                            setTicketId={setTicketId}
                                            ticketId={ticket._id}
                                            key={'ticket-' + ticket._id + '-' + auth.userMessages.length }
                                            ticket={ticket}
                                />
                            })}
                        </div>
                    </div>
                </div>
                <SupportModal showMessage={showMessage}
                              handleShowMessage={handleShowMessage}
                              ticketId={ticketId}
                              handleReplyMode={handleReplyMode}
                              replyMode={replyMode}
                              setReplyMode={setReplyMode}
                              setShowMessage={setShowMessage}
                              controlButtonColor={controlButtonColor}
                              navButtonColor={navButtonColor}
                              priceButtonColor={priceButtonColor}
                              key={'user-support-modal'}
                              keyMode={auth.userMessages.length} />
            </div>
        );

}

export default SupportPageUser;
