import React, {useContext} from 'react';
import '../../css/OrdersCart.css';
import CustomersOrdersRow from "./CustomersOrdersRow";
import SignupGridContainer from "../structure/SignupGridContainer";
import AuthContext from "../shared/auth-context";

const CustomerOrdersCart = props => {
    const auth = useContext(AuthContext)
    let { navButtonColor, controlButtonColor, priceButtonColor } = props
    let orders
    if(!auth.customersOrders){
        const userData = JSON.parse(localStorage.getItem('customerData'))
        if(userData){
            orders = userData.orders
        }
    } else {
        orders = auth.customersOrders
    }

    if(orders){
        return (
            <>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-sx-12 ">
                    <h3>Previous orders</h3>
                    <div className={'card-body'}>
                        {orders.map((order, index) =>
                            index > orders.length-5 &&
                            <CustomersOrdersRow buildBasketFromOrder={props.buildBasketFromOrder}
                                                order={order}
                                                key={order._id}
                                                controlButtonColor={controlButtonColor}
                                                navButtonColor={navButtonColor}
                                                priceButtonColor={priceButtonColor}
                            />
                        )
                        }
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-sx-12 card-body">
                    <h3>Account Details</h3>
                    <div className={'card'} style={{padding: '20px'}}>
                        <SignupGridContainer errorMessage={props.errorMessage}
                                             countries={props.countries}
                                             getInputData={props.getInputData}
                                             updateCustomer={props.updateCustomer}
                        />
                    </div>
                </div>
            </>
        );
    } else {
        return ''
    }


}

export default CustomerOrdersCart;
