import React from 'react';
import StockModalRow from "./StockModalRow";

const ManageStockModalBody = props => {
    let { products, managementType, displayCategory, setLoadedProducts,
        setLoadedCategories } = props

        return (
            <div id={'manage-stock-modal-body'}>
                {products.map((product, index) => {
                        return (
                            <>
                                <StockModalRow key={'modal-stock-row-' + product._id}
                                               displayCategory={displayCategory}
                                               product={product}
                                               index={index}
                                               managementType={managementType}
                                               setLoadedProducts={setLoadedProducts}
                                               setLoadedCategories={setLoadedCategories}
                                />
                                {}
                            </>
                        )
                    })
                }
            </div>
        );

}

export default ManageStockModalBody;
