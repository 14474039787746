import React, {useState} from 'react';
import '../../css/Button.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Button = (props) => {
    let { onClick, className, text, icon, float, controlButtonColor, priceButtonColor, fullIcon, sidebarButton,
        navButtonColor, solid, active, keyMode, categoryButton, usedKey, disabled, basketButton } = props
    let cursor
    if(basketButton){
        controlButtonColor = priceButtonColor
        navButtonColor = priceButtonColor
    }
    if(disabled){
        onClick = null
        controlButtonColor = '#ccc'
        navButtonColor = '#ccc'
        cursor = 'no-drop'
    } else {
        cursor = 'pointer'
    }
    if(!float){
        float = null
    }
    let [ buttonStyle, setButtonStyle ] = useState(
        solid && !active && !categoryButton ?
            {backgroundColor: controlButtonColor, color:'whitesmoke', float: float, cursor: cursor}
            : solid && active && categoryButton ? // Category menu menu buttons
            {backgroundColor: navButtonColor, color:'whitesmoke', float: float, cursor: cursor}
            : solid && !active && categoryButton ? // Category menu menu buttons
                {backgroundColor: controlButtonColor, color:'whitesmoke', float: float, cursor: cursor}
                : solid && active && !categoryButton ? // Nav menu buttons
                    {backgroundColor: navButtonColor, color: 'whitesmoke', float: float, cursor: cursor}
                    : active && !solid ?
                        {border: '2px solid whitesmoke' , color: 'whitesmoke', float: float, cursor: cursor}
                        : !active && !solid &&
                        {border: '2px solid ' + controlButtonColor, color: controlButtonColor,
                            float: float, cursor: cursor}

    )
    const onMouseEnter=() => {
        setButtonStyle(
            solid && !active && !categoryButton ?
                {backgroundColor: controlButtonColor, color:'whitesmoke', float: float, cursor: cursor}
                : solid && active && categoryButton ? // Category menu menu buttons
                {backgroundColor: controlButtonColor, color:'whitesmoke', float: float, cursor: cursor}
                : solid && !active && categoryButton ? // Category menu menu buttons
                    {backgroundColor: navButtonColor, color:'whitesmoke', float: float, cursor: cursor}
                    : solid && active && !categoryButton ? // Nav menu buttons
                        {backgroundColor: navButtonColor, color:'whitesmoke', float: float, cursor: cursor}
                        : active && !solid ?
                            {border: '2px solid ' + navButtonColor , color: navButtonColor, float: float, cursor: cursor}
                            : !active && !solid &&
                            {border: '2px solid ' + navButtonColor, color: navButtonColor, float: float, cursor: cursor}
        )
    }
    const onMouseLeave=() => {
        setButtonStyle(
            solid && !active && !categoryButton ?
                {backgroundColor: controlButtonColor, color:'whitesmoke', float: float, cursor: cursor}
                : solid && active && categoryButton ? // Category menu menu buttons
                {backgroundColor: navButtonColor, color:'whitesmoke', float: float, cursor: cursor}
                : solid && !active && categoryButton ? // Category menu menu buttons
                    {backgroundColor: controlButtonColor, color:'whitesmoke', float: float, cursor: cursor}
                    : solid && active && !categoryButton ? // Nav menu buttons
                        {backgroundColor: navButtonColor, color:'whitesmoke', float: float, cursor: cursor}
                        : active && !solid ?
                            {border: '2px solid whitesmoke' , color: 'whitesmoke', float: float, cursor: cursor}
                            : !active && !solid &&
                            {border: '2px solid ' + controlButtonColor, color: controlButtonColor, float: float, cursor: cursor}
        )

    }
        return (
            <div className={className}
                 style={buttonStyle}
                 id={usedKey + keyMode || String(usedKey + keyMode)}
                 onMouseEnter={onMouseEnter}
                 onMouseLeave={onMouseLeave}
                 onClick={onClick}>
                <span style={{ marginTop:'0'}}
                      className={sidebarButton ? '' : 'admin-nav-toggle'} >
                    {text}
                </span>
                {icon ?
                <FontAwesomeIcon style={{marginLeft:'5px', color:'inherit'}}
                                 icon={icon}/>
                                 :
                    fullIcon
                }
            </div>
        );

}

export default Button;
