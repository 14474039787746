import React, {useContext, useState} from 'react';
import '../../../../css/CartNav.css';
import Button from "../../../shared/Button";
import AdminCategoryButton from "./AdminCategoryButton";
import '../../../../css/AdminCartNav.css'
import AuthContext from "../../../shared/auth-context";
import {faCartPlus, faPlusSquare, faTruck} from "@fortawesome/free-solid-svg-icons";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Modal from "react-bootstrap/Modal";
import ManageStockModalBody from "./ManageStockModalBody";

const AdminCartNav = props => {
    const auth = useContext(AuthContext)
    let {controlButtonColor, navButtonColor, priceButtonColor, categories,
        loadedProducts, setLoadedProducts, setLoadedCategories } = props
    let [ showStockModal, setShowStockModal] = useState(false)
    const [ chosenManagementType, setChosenManagementType ] = useState(false)
    const [ displayCategory, setDisplayCategory ] = useState(categories[0]._id)
    const [ managementType, setManagementType ] = useState('ADD')

    const handleShowStockQuantities = () => {
        setShowStockModal(prevState => !prevState)
    }
    let setChangeDisplayCategory = (event) => {
        let newCategory = event.target.value
        setDisplayCategory(newCategory)
    }
    // eslint-disable-next-line
    const handleResetType = () => {
        setChosenManagementType(false)
    }
    const handleSetManagementType = (event) => {
        let type = event.target.value
        switch(type){
            case 'ADD' :
                setManagementType(type)
                setChosenManagementType(true)
                break
            case 'REMOVE' :
                setManagementType(type)
                setChosenManagementType(true)
                break
            case 'ADJUST' :
                setManagementType(type)
                setChosenManagementType(true)
                break
            default :
                break
        }

    }
    const addProductHandler = () => {
        props.newProduct(props.categoryId)
    }

    if(props.displayCategory === props.index){
        return <><nav id={props.index + '-' + props.categoryId + '-navbar'}
                      className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12 category-navigation
                            admin-nav-toggle navbar-light"
                      style={{
                          marginTop:'0', backgroundColor: props.cartNavColor}}

        >
            <ul style={{paddingBottom: '0'}} className="navbar-nav ml-auto">
                <li className="nav-item nav-controls ">
                    {/*<label className="switch" style={{marginTop: '0', marginLeft:'20px'}}>*/}
                    {/*    <input type="checkbox"*/}
                    {/*           style={{*/}
                    {/*               float: 'right', marginBottom: '0',*/}
                    {/*               width: '20px', marginTop: '0'*/}
                    {/*           }}*/}
                    {/*        // checked={showDistanceOptions}*/}
                    {/*           className="form-control"*/}
                    {/*           id="distance-options"*/}
                    {/*           name='distance-options'*/}
                    {/*        // onChange={handleShowDistanceOptions}*/}
                    {/*        // onInput={updateSettings}*/}
                    {/*    />*/}
                    {/*    <span className="slider round"> </span>*/}
                    {/*</label>*/}
            <Button onClick={props.createCategory}
                    className={'nav-user-button grey'}
                    text={'CATEGORY'}
                    key={'add-category' + props.loadedCategories.length ? props.loadedCategories.length : 0}
                    keyUsed={'add-category'}
                    keyMode={props.loadedCategories.length ?props.loadedCategories.length : 0}
                    controlButtonColor={'#282a36'}
                    navButtonColor={navButtonColor}
                    priceButtonColor={priceButtonColor}
                    active={false}
                    solid={false}
                    float={'right'}
                    icon={faCartPlus}/>
            <Button onClick={addProductHandler}
                    className={'nav-user-button grey'}
                    text={'PRODUCT'}
                    key={'add-product' + (props.loadedProducts.length || 0)}
                    keyUsed={'add-product'}
                    keyMode={(props.loadedProducts.length || 0)}
                    controlButtonColor={'#282a36'}
                    navButtonColor={navButtonColor}
                    priceButtonColor={priceButtonColor}
                    active={false}
                    solid={false}
                    float={'right'}
                    icon={faPlusSquare}/>
            {auth.appSettings.shop.manageStockLevels &&
            <Button onClick={handleShowStockQuantities}
                    className={'nav-user-button grey'}
                    text={'STOCK'}
                    key={'add-stock' + props.loadedProducts.length}
                    keyUsed={'add-stock'}
                    keyMode={props.loadedProducts.length}
                    controlButtonColor={'#282a36'}
                    navButtonColor={navButtonColor}
                    priceButtonColor={priceButtonColor}
                    active={false}
                    solid={false}
                    float={'right'}
                    icon={faTruck}/>
            }
            {props.categories.map( (category, index) =>
                <AdminCategoryButton
                    key={props.index + '-' + category.id + '-button'}
                    categoryName={category.categoryName}
                    categoryId={props.categoryId}
                    catId={category.id}
                    cartId={auth.cartId}
                    reloadCategories={props.reloadCategories}
                    reloadProducts={props.reloadProducts}
                    categoryIndex={props.categoryIndex}
                    handleCategoryUpdate={props.handleCategoryUpdate}
                    reloadCart={props.reloadCart}
                    loadedCategories={props.categories}
                    index={index}
                    setDisplayCategory={props.setDisplayCategory}
                    displayCategory={props.displayCategory}
                    setLoadedCategories={props.setLoadedCategories}
                    getSettings={props.getSettings}
                    category={category}
                    deleteCategory={props.deleteCategory}
                    updateCategoryName={props.updateCategoryName}
                    editMode={props.editMode}
                    setEditMode={props.setEditMode}
                    controlButtonColor={controlButtonColor}
                    navButtonColor={navButtonColor}
                    priceButtonColor={priceButtonColor}
                />
            )}
                </li>
            </ul>
        </nav>
            <Modal show={showStockModal} onHide={handleShowStockQuantities}>
                <ModalHeader closeButton>
                    <ModalTitle>Manage Stock Levels</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <ManageStockModalBody key={'manage-stock-modal-body'}
                                          categories={categories}
                                          setDisplayCategory={setDisplayCategory}
                                          products={loadedProducts}
                                          chosenManagementType={chosenManagementType}
                                          setChosenManagementType={setChosenManagementType}
                                          handleSetManagementType={handleSetManagementType}
                                          setChangeDisplayCategory={setChangeDisplayCategory}
                                          displayCategory={displayCategory}
                                          managementType={managementType}
                                          setLoadedCategories={setLoadedCategories}
                                          setLoadedProducts={setLoadedProducts}
                    />
                    {
                        // Map out categories
                    }
                </ModalBody>
                <ModalFooter>
                        <div className={'container'} style={{padding:'0', marginTop:'0'}}>
                            <div className={'row'} style={{padding:'0', marginTop:'0'}}>
                                <div className={'col-5'}>
                                    <select id="max-distance-units"
                                            style={{
                                                float: 'right', marginTop: '0',
                                                width: '100%', height: '35px',
                                                marginRight: '0', marginBottom: '0',
                                                marginLeft: '0', paddingTop: '4px'
                                            }}
                                            name="status"
                                            className="form-control shadow-none"
                                            aria-label="Set maximum distance"
                                            onChange={handleSetManagementType}
                                            value={managementType}
                                    >
                                        <option id={'add'}
                                                value={'ADD'}>
                                            ADD STOCK
                                        </option>
                                        <option id={'remove'}
                                                value={'REMOVE'}>
                                            REMOVE STOCK
                                        </option>
                                        <option id={'adjust'}
                                                value={'ADJUST'}>
                                            ADJUST STOCK
                                        </option>
                                    </select>
                                </div>
                                <div className={'col-1'} style={{textAlign:'center', padding:'5px 0 0 0'}}>
                                    {managementType === 'ADD' ? 'to' : managementType === 'REMOVE' ? 'from' : 'in'}
                                </div>
                                <div className={'col-6'}>
                                    <select id="max-distance-units"
                                            style={{
                                                float: 'right', marginTop: '0',
                                                width: '100%', height: '35px',
                                                marginRight: '0', marginBottom: '0',
                                                marginLeft: '0', paddingTop: '4px'
                                            }}
                                            name="status"
                                            className="form-control shadow-none"
                                            aria-label="Set maximum distance"
                                            onChange={setChangeDisplayCategory}
                                            value={displayCategory}
                                    >
                                        {categories.map((category, index) => {
                                                return <option key={category._id+index}
                                                               id={category._id+index}
                                                               value={category._id}>
                                                    {category.categoryName}
                                                </option>
                                            }
                                        )}
                                        <option key={'all-items'}
                                                id={'all-products-in-store'}
                                                value={'ALL'}>
                                            ALL PRODUCTS
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                </ModalFooter>
            </Modal>
        </>
    } else {
        return ''
    }
}

export default AdminCartNav;
