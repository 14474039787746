import React, {useContext} from 'react';
import '../../css/CheckoutForm.css'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import axios from "axios";
import AuthContext from "../shared/auth-context";
import jwtToken from 'jsonwebtoken'

const CheckoutForm = props => {

    // Create a new userOrder here and then use the ID for tracking
    const auth = useContext(AuthContext)
    const stripe = useStripe();
    const elements = useElements();
    const CARD_OPTIONS = {
        iconStyle: 'solid',
        style: {
            base: {
                iconColor: '#c4f0ff',
                color: '#000',
                fontWeight: 500,
                border: '1px solid grey',
                borderRadius: '10px 10px 10px 10px',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#fce883',
                },
                '::placeholder': {
                    color: '#87bbfd',
                },
            },
            invalid: {
                iconColor: '#ffc7ee',
                color: '#ffc7ee',
            },
        },
    };

    const handleSubmit = async event => {
        event.preventDefault();
        let decodedToken = await jwtToken.decode(auth.userToken, {complete: true});
        let email = decodedToken.payload.email;
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                email: email
            }
        });
        if (!error) {
            const {id} = paymentMethod;
            try {
                let token;
                try{
                    token = await jwtToken.sign(
                        {
                            id, orderId: props.orderId,
                            publicKey: process.env.REACT_APP_PUBLIC_KEY,
                            token: auth.userToken,
                            type: props.type
                        },
                        `${process.env.REACT_APP_JWT_KEY}`,
                        {
                            expiresIn: '1h'
                        }
                    );
                }catch(err){console.log(err)}
                await axios.post(`${process.env.REACT_APP_SITE_URL}/api/pay`, {}, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }).then(async (res) => {
                    console.log(res.data)
                    if(res.data.errorMessage){
                        console.log(res.data.errorMessage)
                        await props.setErrorMessage(res.data.errorMessage.raw.message)
                    } else {
                        await props.handleActivateStore();
                    }

                });
            } catch (error) {
            }

        }
    };

    return (
        <>
            <form
                onSubmit={handleSubmit}
                style={{maxWidth: "400px", margin: "0 auto", marginBottom: '30px'}}>
                <div style={{border: '2px solid #f6f6f6', paddingLeft: '20px',
                    borderRadius: '7px 7px 7px 7px', paddingRight:'20px'}}>
                    <CardElement options={CARD_OPTIONS}/>
                </div>

                {/*{message &&*/}
                {/*<p>{message}</p>*/}
                {/*}*/}
                <button type="submit" hidden={true} id={'stripe-subscribe-button'} disabled={!stripe}>
                    Pay
                </button>
            </form>
        </>
    );
};

export default CheckoutForm;
