import React from 'react';

const AmountDisplay = props => {
    return (
        <div className="amount-display product-controls"
             style={{backgroundColor: props.valueColor, paddingTop:'6px'}}>
            <span style={{padding:'0', fontSize: '2.1em', lineHeight: '45px'}}>
                {props.currentValue}
            </span>
        </div>
    );
}

export default AmountDisplay;


