import React, {useContext} from 'react';
import Button from "../shared/Button";
import {
    faGavel,
    faMagic,
    faPaintBrush,
    faSignInAlt, faSignOutAlt,
    faStore, faTimes, faTrashAlt, faUser,
    faUsersCog
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AuthContext from "../shared/auth-context";

const MainNavMenuItems = props => {
    const auth = useContext(AuthContext)
    let { navButtonColor, priceButtonColor, controlButtonColor, username,
        orders, signCustomerOut, handleEmptyBasket,
        showAdminMode, basketValue, handleShowBasket, navbarMode, showEditColors,
        basketItems, handleShowEditColors, editStoreHandler, handleShowSettings,
        ordersModeHandler, switchModeHandler, showGoLive, customerOrdersMode,
        customerOrdersModeHandler, handleShowAuth,
        handleShowAbout, showAbout,
        // contactForm, handleShowContactForm

    } = props
        return (
            <li id={'nav-menu-items'} className="nav-item nav-controls ">
                {auth.userToken && !auth.customerToken && showAdminMode ?
                    <>
                        {basketValue === 0 &&
                        <Button onClick={handleShowBasket}
                                className={'nav-user-button'}
                                text={'LOGIN'}
                                float={'right'}
                                key={'customer-login' + navbarMode}
                                keyUsed={'customer-login'}
                                keyMode={navbarMode}
                                sidebarButton={true}
                                controlButtonColor={controlButtonColor}
                                priceButtonColor={priceButtonColor}
                                navButtonColor={navButtonColor}
                                active={navbarMode === 'account'}
                                icon={faSignInAlt}/>
                        }
                        {!showEditColors && !basketItems.length > 0 &&
                        <Button
                            onClick={handleShowEditColors}
                            className={'nav-user-button'}
                            text={''}
                            float={'left'}
                            key={'edit-store-colors'}
                            keyUsed={'edit'}
                            keyMode={'-store-colors'}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            active={false}
                            icon={faPaintBrush}/>
                        }

                        <Button onClick={editStoreHandler}
                                className={'admin-nav-toggle nav-user-button'}
                                text={'ADMIN AREA'}
                                float={'left'}
                                key={'admin-area' + navbarMode}
                                keyUsed={'admin-area'}
                                keyMode={navbarMode}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                active={false}
                                icon={faUsersCog}/>
                    </>

                    :
                    auth.userToken && !auth.customerToken && !showAdminMode &&
                    <>
                        <Button onClick={handleShowSettings}
                                className={'admin-nav-toggle nav-user-button'}
                                text={'ACCOUNT'}
                                key={'account' + navbarMode}
                                keyUsed={'account'}
                                keyMode={navbarMode}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                active={navbarMode === 'account'}
                                icon={faUsersCog}/>
                        <Button onClick={ordersModeHandler}
                                className={'admin-nav-toggle nav-user-button'}
                                text={'ORDERS'}
                                key={'orders' + navbarMode}
                                keyUsed={'orders'}
                                keyMode={navbarMode}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                active={navbarMode === 'orders'}
                                icon={faGavel}/>
                        <Button onClick={editStoreHandler}
                                className={'admin-nav-toggle nav-user-button'}
                                text={'EDIT'}
                                key={'edit' + navbarMode}
                                keyUsed={'edit'}
                                keyMode={navbarMode}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                active={navbarMode === 'edit'}
                                icon={faMagic}/>
                        {auth.isLoggedIn && !showAdminMode &&
                        <Button onClick={switchModeHandler}
                                className={'admin-nav-toggle nav-user-button'}
                                key={'view-store'}
                                keyUsed={'view'}
                                keyMode={'-store'}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                text={'VIEW'}
                                icon={faStore}/>
                        }
                    </>

                }
                {!auth.isLoggedIn && username === 'about' &&
                !auth.customerToken && basketItems.length === 0 &&
                <><Button onClick={handleShowAbout}
                          className={'nav-user-button'}
                          text={'ABOUT'}
                          key={'about-c4rt' + showAbout}
                          keyUsed={'about-c4rt'}
                          keyMode={showAbout}
                          controlButtonColor={controlButtonColor}
                          navButtonColor={navButtonColor}
                          priceButtonColor={priceButtonColor}
                          sidebarButton={false}
                          active={false}
                          icon={faStore}/>
                    {/*<Button onClick={handleShowContactForm}*/}
                    {/*        className={'nav-user-button'}*/}
                    {/*        text={'SUPPORT'}*/}
                    {/*        key={'contact-c4rt-uk' + contactForm}*/}
                    {/*        keyUsed={'contact-c4rt-uk'}*/}
                    {/*        keyMode={contactForm}*/}
                    {/*        controlButtonColor={controlButtonColor}*/}
                    {/*        navButtonColor={navButtonColor}*/}
                    {/*        priceButtonColor={priceButtonColor}*/}
                    {/*        active={false}*/}
                    {/*        icon={faCommentMedical}/>*/}
                </>
                }
                {!auth.isLoggedIn && username === 'support' &&
                <Button onClick={handleShowAbout}
                        className={'nav-user-button admin-nav-toggle'}
                        text={'HOME'}
                        key={'about-c4rt' + showAbout}
                        keyUsed={'about-c4rt'}
                        keyMode={showAbout}
                        controlButtonColor={controlButtonColor}
                        navButtonColor={navButtonColor}
                        priceButtonColor={priceButtonColor}
                        sidebarButton={false}
                        active={false}
                        icon={faStore}/>
                }
                {!auth.customerIsLoggedIn && basketItems.length === 0 &&
                username !== 'about'  && !auth.isLoggedIn &&
                !auth.isLoggedIn && username !== 'support' &&
                <Button onClick={handleShowBasket}
                        className={'nav-user-button'}
                        text={'SIGN IN'}
                        sidebarButton={true}
                        key={'account' + navbarMode}
                        keyMode={navbarMode}
                        controlButtonColor={controlButtonColor}
                        navButtonColor={navButtonColor}
                        priceButtonColor={priceButtonColor}
                        active={true}
                        icon={faSignInAlt}/>
                }

                {auth.customerIsLoggedIn && basketValue === 0 &&
                <Button onClick={signCustomerOut}
                        className={'nav-user-button'}
                        text={'SIGN OUT'}
                        key={'trash' + basketItems.length}
                        keyUsed={'trash'}
                        float={'right'}
                        keyMode={showGoLive}
                        controlButtonColor={controlButtonColor}
                        navButtonColor={navButtonColor}
                        priceButtonColor={priceButtonColor}
                        sidebarButton={true}
                        active={false}
                        icon={faSignOutAlt}/>
                }
                {orders && auth.customerToken && basketValue === 0 &&
                <>
                    {!customerOrdersMode ?
                        <Button onClick={customerOrdersModeHandler}
                                className={'red nav-user-button'}
                                text={'MY ACCOUNT'}
                                key={'account ' + controlButtonColor+controlButtonColor}
                                keyUsed={'account'}
                                float={'right'}
                                keyMode={controlButtonColor+controlButtonColor}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                active={true}
                                icon={faUser}/>
                        :
                        <Button onClick={customerOrdersModeHandler}
                                className={'nav-user-button'}
                                text={''}
                                key={'auth' + showGoLive}
                                keyUsed={'auth'}
                                float={'right'}
                                keyMode={showGoLive}
                                controlButtonColor={controlButtonColor}
                                navButtonColor={navButtonColor}
                                priceButtonColor={priceButtonColor}
                                active={true}
                                icon={faTimes}/>
                    }
                </>
                }
                {basketItems.length > 0 &&
                <>
                    <Button onClick={handleEmptyBasket}
                            className={' red nav-user-button'}
                            text={<FontAwesomeIcon className={'nav-logout'}
                                                   style={{marginTop:'5px',
                                                       fontSize:'1.2em'}}
                                                   icon={faTrashAlt}/>}
                            key={'trash' + basketItems.length}
                            keyUsed={'trash'}
                            float={'right'}
                            keyMode={showGoLive}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            active={true}
                            sidebarButton={true}
                            icon={null}/>
                    <Button onClick={handleShowBasket}
                            className={'basket price-button'}
                            solid={true}
                            active={true}
                            text={auth.appSettings.shop.currencySymbol+basketValue.toFixed(2)}
                            style={{backgroundColor: navButtonColor}}
                            icon={null}
                            sidebarButton={true}
                            basketButton={true}
                            float={'right'}
                            controlButtonColor={controlButtonColor}
                            navButtonColor={navButtonColor}
                            priceButtonColor={priceButtonColor}
                            width={'180px'}/>
                </>
                }
                {!auth.isLoggedIn && username === 'about' &&
                !auth.customerToken && basketItems.length === 0 &&
                <Button onClick={handleShowAuth}
                        className={'nav-user-button'}
                        text={'SIGNUP'}
                        key={'auth' + showGoLive}
                        keyUsed={'auth'}
                        float={'right'}
                        sidebarButton={true}
                        keyMode={showGoLive}
                        controlButtonColor={controlButtonColor}
                        navButtonColor={navButtonColor}
                        priceButtonColor={priceButtonColor}
                        active={true}
                        icon={faSignInAlt}/>
                }
            </li>
        );

}

export default MainNavMenuItems;
