import React, {useContext} from 'react';
import AuthContext from "../shared/auth-context";

const SignupGridContainer = (props) => {
    const {errorMessage, countries, getInputData} = props
    const auth = useContext(AuthContext)
    const doNothing = ( ) => {

    }
        return (
                <div>
                    <div className="signup-grid-container" style={{paddingLeft: '10px', paddingRight: '10px'}}>
                        <div className="Left">
                            <form autoComplete={'off'}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={auth.customer.addressOne}
                                   id="customer-address"
                                   name='address-one'
                                   onInput={getInputData}
                                   onBlur={getInputData}
                                   onChange={doNothing}
                            />
                            </form>
                            <form autoComplete={'off'}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={auth.customer.postcode}
                                   id="customer-postcode"
                                   name='postcode'
                                   onInput={getInputData}
                                   onBlur={getInputData}
                                   onChange={doNothing}
                            />
                            </form>
                        </div>
                        <div className="Right" style={{paddingLeft: '10px'}}>
                            <form autoComplete={'off'}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={auth.customer.addressTwo}
                                   id="customer-address-two"
                                   name='address-two'
                                   onInput={getInputData}
                                   onBlur={getInputData}
                                   onChange={doNothing}
                            />
                            </form>
                            <select id="select country"
                                    className="form-control"
                                    name={'country'}
                                    onInput={getInputData}
                                    onBlur={getInputData}
                                    onChange={doNothing}
                                    value={auth.customer.country}
                            >
                                <option name={'Please Select'}>
                                    Please Select
                                </option>)
                                {countries.map((country, index) => {
                                    return (
                                        <option key={country+index} id={country+index} name={country}>
                                            {country}
                                        </option>)
                                })}
                            </select>
                        </div>
                    </div>
                    {errorMessage && <p className={'user-warning'}>Sorry but we can't seem to find that location</p>}
                    {/*<p style={{marginBottom: '0'}}>*/}
                    {/*    Some additional information to help with your order (no one will ever see*/}
                    {/*    this but the store owner).*/}
                    {/*</p>*/}
                    <div className="signup-grid-container"
                         style={{paddingLeft: '10px', paddingRight: '10px'}}>
                        <div className="Left">
                            <form autoComplete={'off'}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={auth.customer.firstname}
                                   id="customer-firstname"
                                   name='firstname'
                                   onChange={doNothing}
                                   onInput={getInputData}
                            />
                            </form>
                            <form autoComplete={'off'}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={auth.customer.lastname}
                                   id="customer-lastname"
                                   name='lastname'
                                   onChange={doNothing}
                                   onInput={getInputData}
                            />
                            </form>
                            <form autoComplete={'off'}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={auth.customer.phone}
                                   id="customer-phone"
                                   name='phone'
                                   onChange={doNothing}
                                   onInput={getInputData}
                            />
                            </form>
                        </div>
                        <div className="Right" style={{paddingLeft: '10px'}}>
                                    <textarea
                                        rows={5}
                                        style={{width: '100%'}}
                                        className={'form-control'}
                                        id={'special-instructions'}
                                        name={'instructions'}
                                        onChange={doNothing}
                                        placeholder={'Delivery instructions... (optional)'}
                                        onInput={getInputData}
                                        value={auth.customer.instructions === 'Add Instructions'? '': auth.customer.instructions}
                                    />
                        </div>
                    </div>
                </div>

        );
}

export default SignupGridContainer;
