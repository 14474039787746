import React, {useContext, useState} from 'react';
import BasketMinusButton from "../basket/BasketMinusButton";
import BasketPlusButton from "../basket/BasketPlusButton";
import AuthContext from "../shared/auth-context";


const UserBasketItem = props => {
    const auth = useContext(AuthContext)
    const [ currentOptionValue, setCurrentOptionValue ] = useState(0)
    let { priceButtonColor, navButtonColor, controlButtonColor } = props
    let finalTax;
    if (props.product.taxRate === 'Standard Rate') {
        finalTax = 20;
    } else if (props.product.taxRate === 'Reduced Rate') {
        finalTax = 5
    } else if (props.product.taxRate === 'Zero Rate') {
        finalTax = 0;
    } else {
        finalTax = props.product.taxRate;
    }

    const getLighterColor = (controlButtonColor) => {
        let r = 0, g = 0, b = 0;

        // 3 digits
        if (controlButtonColor.length === 4) {
            r = "0x" + controlButtonColor[1] + controlButtonColor[1];
            g = "0x" + controlButtonColor[2] + controlButtonColor[2];
            b = "0x" + controlButtonColor[3] + controlButtonColor[3];

            // 6 digits
        } else if (controlButtonColor.length === 7) {
            r = "0x" + controlButtonColor[1] + controlButtonColor[2];
            g = "0x" + controlButtonColor[3] + controlButtonColor[4];
            b = "0x" + controlButtonColor[5] + controlButtonColor[6];
        }

        return "rgb("+ +r + "," + +g + "," + +b + ", 0.5)";
    }

    const findImage = () => {
        for (let i = 0; i < props.basketItems.length; i++) {
            if (props.product.productId === props.basketItems[i].productId) {
                return props.product.image;
            }
        }
    }
    return (
        <>
            <tr id={'print-' + props.product._id + '-' + props.index}>
                <td style={{width: '46px'}}>
                    <img src={findImage()} alt={props.product.productName} className={'basket-image'}
                         style={{float: 'left'}}/>
                </td>
                <td><span style={{marginBottom: '0', fontSize: '0.9'}}>{props.product.productName} ({props.product.quantities})</span></td>
                {auth.appSettings.shop.showTax &&
                <td style={{width: '65px'}}>
                    <div style={{marginTop: '8px', marginBottom:'0', padding: ''}}>
                        <p style={{lineHeight:'15px', marginBottom: '0', marginTop: '3px'}}>
                            <strong style={{fontSize: '0.9em'}}>
                                {auth.appSettings.shop.currencySymbol}{(props.product.price*props.product.value).toFixed(2)} </strong>
                        </p>
                        <p style={{lineHeight:'15px'}}>
                       <span style={{lineHeight: '0.9em', fontSize: '0.7em', marginTop: '0'}}>
                     {auth.appSettings.shop.currencySymbol}{props.product.price.toFixed(2)} @{finalTax}%
                        </span>
                        </p>
                    </div>
                </td>
                }
                <td style={{width: '70px'}}>
                    <table>
                        <thead>
                        <tr style={{width: '40px'}}>
                            <td className="tg-0lax" rowSpan="2">
                                <div className="basket-product-value"
                                     style={{float: 'right', marginLeft: '4px', textAlign:'center',
                                         marginTop:'0', width: '35px', height: '35px', display: 'block'}}
                                >
                                    {props.product.value}
                                </div>
                            </td>
                            <td className="tg-0lax" style={{padding: '0'}}>
                                <div style={{width: '30px', height: '16px',
                                    marginTop:'0', borderRadius: '5px 5px 5px 5px', paddingTop: '0',
                                    textAlign: 'center', lineHeight: '15px', cursor: 'pointer'
                                }}>
                                    <BasketPlusButton
                                                price={props.product.price}
                                                product={props.product}
                                                productId={props.product.productId}
                                                productName={props.product.productName}
                                                basketItems={props.basketItems}
                                                setBasketItems={props.setBasketItems}
                                                basketValue={props.basketValue}
                                                setBasketValue={props.setBasketValue}
                                                image={props.product.image}
                                                setShowBasket={props.setShowBasket}
                                                setCurrentOptionValue={setCurrentOptionValue}
                                                addToBasketValue={props.addToBasketValue}
                                                currentOptionValue={currentOptionValue}
                                                optionId={props.optionId}
                                                priceButtonColor={priceButtonColor}
                                                navButtonColor={navButtonColor}
                                                controlButtonColor={controlButtonColor}
                                                getLighterColor={getLighterColor}
                                            />
                                </div>
                                <BasketMinusButton
                                            price={props.product.price}
                                            productId={props.product.productId}
                                            product={props.product}
                                            productName={props.product.productName}
                                            basketItems={props.basketItems}
                                            setBasketItems={props.setBasketItems}
                                            basketValue={props.basketValue}
                                            setBasketValue={props.setBasketValue}
                                            handleCloseBasket={props.handleCloseBasket}
                                            setShowBasket={props.setShowBasket}
                                            takeFromBasketValue={props.takeFromBasketValue}
                                            optionId={props.optionId}
                                            priceButtonColor={priceButtonColor}
                                            navButtonColor={navButtonColor}
                                            controlButtonColor={controlButtonColor}
                                            getLighterColor={getLighterColor}                                        />
                            </td>
                        </tr>
                        </thead>
                    </table>
                </td>
            </tr>
        </>
        // <div className="row"  style={{marginTop: '14px', verticalAlign: 'middle', marginBottom:'0'}}>
        //     <div className="col-6">
        //         <img src={findImage()}
        //              alt={props.product.productName}
        //              className={'basket-image'}
        //              style={{float:'left'}}
        //         />
        //         <span style={{marginBottom:'0', fontSize: '0.9'}} >{props.product.productName}</span>
        //         {auth.appSettings.shop.showTax &&
        //         <span style={{color: 'grey', marginTop:'-7px'}}>
        //             <br />
        //             <strong style={{fontSize: '0.7em', marginTop:'0'}}>
        //                 {auth.appSettings.shop.currencySymbol}{props.product.price.toFixed(2)} </strong>
        //             <span style={{lineHeight: '0.9em', fontSize: '0.7em', marginTop:'0'}}>
        //                 {finalTax}% VAT
        //             </span>
        //         </span>
        //         }
        //     </div>
        //     <div className="col-6" style={{margin: '0', paddingTop: '5px', paddingLeft: '0'}}>
        //         <span style={{fontSize: '0.8em', float: 'left'}}>
        //         <strong >{auth.appSettings.shop.currencySymbol}{combinedPrice}</strong>
        //         </span>
        //         <BasketPlusButton
        //             price={props.product.price}
        //             productId={props.product.productId}
        //             productName={props.product.productName}
        //             basketItems={props.basketItems}
        //             setBasketItems={props.setBasketItems}
        //             basketValue={props.basketValue}
        //             setBasketValue={props.setBasketValue}
        //             image={props.product.image}
        //             setShowBasket={props.setShowBasket}
        //         /><div className="basket-product-value"
        //              style={{float: 'right', marginLeft: '4px'}}
        //         >
        //             {props.product.value}
        //         </div><BasketMinusButton
        //             price={props.product.price}
        //             productId={props.product.productId}
        //             productName={props.product.productName}
        //             basketItems={props.basketItems}
        //             setBasketItems={props.setBasketItems}
        //             basketValue={props.basketValue}
        //             setBasketValue={props.setBasketValue}
        //             handleCloseBasket={props.handleCloseBasket}
        //             setShowBasket={props.setShowBasket}
        //         />
        //     </div>
        // </div>
    );

}

export default UserBasketItem;
