import React from 'react';
import InvoiceSettings from "./InvoiceSettings";
import EmailSettings from "./EmailSettings";
import ShopSettings from "./ShopSettings";
import SettingsNav from "./navigation/SettingsNav";
import SupportPageUser from "./SupportPageUser";

const SettingsPage = props => {
    const { handleShowEmailInfo, showInfo, showWelcomeEmail, handleSetEmailData, emailData,
        updateEmailMessage, handleShowCompletedEmail, handleShowReadyEmail, getEmailMenuItemColor,
        handleShowWelcomeEmail, handleShowNewOrderEmail, handleShowApprovedEmail, updateEmailSettings,
        showNewOrderEmail, newOrderDelivery, newOrderCollection, showApprovedEmail, showEmailSettings,
        showReadyEmail, showCompletedEmail, errorMessage, error, handleSetAddress, addressIsSet,
        countries, timeZones, buildAndCheckUsername, buildAndCheckEmail, usernameSuccess, setUsernameSuccess,
        usernameError, setUsernameError, currentUsername, setCurrentUsername, currentEmail, setCurrentEmail,
        emailValid, userExists, setUserExists, setEmailValid, setUsernameSet, usernameSet, resetCode,
        setResetCode, invalidCode, setInvalidCode, showResetPassword, setShowResetPassword,
        successAlert, setSuccessAlert, handleSetResetState, editUsername, setEditUsername, editEmail,
        setEditEmail, handleEditAccount, basketItems, showAdminMode, navbarColor,cartNavColor,
        showInvoiceSettings, showOrderSettings, showAccountSettings, showOpeningHours, showShopSettings,
        controlButtonColor, navButtonColor, priceButtonColor, handleShowSupportTickets,
        showSupportTickets, collectInputData, messageSuccess, messageSuccessCode,openTicket,
        setReplyMode, replyMode, handleReplyMode, showMessage, handleShowMessage, ticketId,
        setTicketId, getUsersSupportContent, setShowOpenNewTicket, showOpenNewTicket,
        loading
    } = props
    return (
        <>
            <div style={{marginTop: '30px', marginLeft:'-15px', marginRight:'-15px'}}>
            <SettingsNav handleShowShopSettings={props.handleShowShopSettings}
                         menuItemColor={props.menuItemColor}
                         handleShowInvoiceSettings={props.handleShowInvoiceSettings}
                         handleShowEmailSettings={props.handleShowEmailSettings}
                         handleShowOrderSettings={props.handleShowOrderSettings}
                         handleShowAccountSettings={props.handleShowAccountSettings}
                         handleShowOpeningHours={props.handleShowOpeningHours}
                         handleSetAddress={handleSetAddress}
                         addressIsSet={addressIsSet}
                         showAdminMode={showAdminMode}
                         basketItems={basketItems}
                         handleShowGoLive={props.handleShowGoLive}
                         navbarColor={navbarColor}
                         cartNavColor={cartNavColor}
                         showInvoiceSettings={showInvoiceSettings}
                         showEmailSettings={showEmailSettings}
                         showOrderSettings={showOrderSettings}
                         showAccountSettings={showAccountSettings}
                         showOpeningHours={showOpeningHours}
                         showShopSettings={showShopSettings}
                         controlButtonColor={controlButtonColor}
                         navButtonColor={navButtonColor}
                         priceButtonColor={priceButtonColor}
                         handleShowSupportTickets={handleShowSupportTickets}
                         showSupportTickets={showSupportTickets}
                         loading={loading}
            />
            </div>
                {/*<div className={'admin-nav-toggle'} style={{display: 'block', height:'15px'}} />*/}
                {props.showInvoiceSettings &&
                <InvoiceSettings getSettings={props.getSettings}
                                 reloadCart={props.reloadCart}
                                 setSettingsLoaded={props.setSettingsLoaded}
                                 countries={countries}
                                 timeZones={timeZones}
                                 handleShowGoLive={props.handleShowGoLive}
                                 buildAndCheckUsername={buildAndCheckUsername}
                                 buildAndCheckEmail={buildAndCheckEmail}
                                 usernameSuccess={usernameSuccess}
                                 setUsernameSuccess={setUsernameSuccess}
                                 usernameError={usernameError}
                                 setUsernameError={setUsernameError}
                                 usernameSet={usernameSet}
                                 setUsernameSet={setUsernameSet}
                                 currentUsername={currentUsername}
                                 setCurrentUsername={setCurrentUsername}
                                 currentEmail={currentEmail}
                                 setCurrentEmail={setCurrentEmail}
                                 emailValid={emailValid}
                                 setEmailValid={setEmailValid}
                                 userExists={userExists}
                                 setUserExists={setUserExists}
                                 resetCode={resetCode}
                                 setResetCode={setResetCode}
                                 invalidCode={invalidCode}
                                 setInvalidCode={setInvalidCode}
                                 showResetPassword={showResetPassword}
                                 setShowResetPassword={setShowResetPassword}
                                 successAlert={successAlert}
                                 setSuccessAlert={setSuccessAlert}
                                 handleSetResetState={handleSetResetState}
                                 editUsername={editUsername}
                                 setEditUsername={setEditUsername}
                                 editEmail={editEmail}
                                 setEditEmail={setEditEmail}
                                 handleEditAccount={handleEditAccount}
                                 controlButtonColor={controlButtonColor}
                                 navButtonColor={navButtonColor}
                                 priceButtonColor={priceButtonColor}
                />
                }
                {props.showEmailSettings &&
                <EmailSettings getSettings={props.getSettings}
                               setSettingsLoaded={props.setSettingsLoaded}
                               getMenuItemColor={props.getMenuItemColor}
                               handleShowWelcome={props.handleShowWelcome}
                               handleShowNewOrder={props.handleShowNewOrder}
                               handleShowApproved={props.handleShowApproved}
                               handleShowReady={props.handleShowReady}
                               handleShowCompleted={props.handleShowCompleted}
                               handleShowEmailInfo={handleShowEmailInfo}
                               showInfo={showInfo}
                               showWelcomeEmail={showWelcomeEmail}
                               handleSetEmailData={handleSetEmailData}
                               emailData={emailData}
                               updateEmailMessage={updateEmailMessage}
                               handleShowCompletedEmail={handleShowCompletedEmail}
                               handleShowReadyEmail={handleShowReadyEmail}
                               getEmailMenuItemColor={getEmailMenuItemColor}
                               handleShowWelcomeEmail={handleShowWelcomeEmail}
                               handleShowNewOrderEmail={handleShowNewOrderEmail}
                               handleShowApprovedEmail={handleShowApprovedEmail}
                               showNewOrderEmail={showNewOrderEmail}
                               newOrderDelivery={newOrderDelivery}
                               newOrderCollection={newOrderCollection}
                               showApprovedEmail={showApprovedEmail}
                               showReadyEmail={showReadyEmail}
                               showCompletedEmail={showCompletedEmail}
                               errorMessage={errorMessage}
                               updateEmailSettings={updateEmailSettings}
                               showEmailSettings={showEmailSettings}
                               error={error}
                />
                }
                {props.showShopSettings &&
                <ShopSettings handleShowInvoiceSettings={props.handleShowInvoiceSettings}
                              setSettingsLoaded={props.setSettingsLoaded}
                              getSettings={props.getSettings}
                              showOpeningHours={props.showOpeningHours}
                              handleSetAddress={handleSetAddress}
                              timeZones={timeZones}
                              userExists={userExists}
                              controlButtonColor={controlButtonColor}
                              navButtonColor={navButtonColor}
                              priceButtonColor={priceButtonColor}
                />
                }
            {props.showSupportTickets &&
            <SupportPageUser collectInputData={collectInputData}
                             messageSuccess={messageSuccess}
                             messageSuccessCode={messageSuccessCode}
                             controlButtonColor={controlButtonColor}
                             navButtonColor={navButtonColor}
                             priceButtonColor={priceButtonColor}
                             openTicket={openTicket}
                             showMessage={showMessage}
                             handleShowMessage={handleShowMessage}
                             ticketId={ticketId}
                             handleReplyMode={handleReplyMode}
                             replyMode={replyMode}
                             setReplyMode={setReplyMode}
                             setTicketId={setTicketId}
                             getUsersSupportContent={getUsersSupportContent}
                             showOpenNewTicket={showOpenNewTicket}
                             setShowOpenNewTicket={setShowOpenNewTicket}
                             key={'user-support-page'} />
            }
</>
    );

}

export default SettingsPage;
