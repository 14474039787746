import React, {useContext} from 'react';
import '../../css/TicketRow.css'
import AuthContext from "../shared/auth-context";
import Button from "../shared/Button";
import {faComments} from "@fortawesome/free-solid-svg-icons";
const TicketRow = (props) => {
    const auth = useContext(AuthContext)
    let { ticket, handleShowMessage, setTicketId, displayMode, navButtonColor, priceButtonColor,
    controlButtonColor } = props
    let fullDate = ticket.lastReply.split('T')[0]
    let day = fullDate.split('-')[2]
    let month = fullDate.split('-')[1]
    let year = fullDate.split('-')[0]
    let fullTime = ticket.lastReply.split('T')[1]
    let hours = fullTime.split(':')[0]
    let minutes = fullTime.split(':')[1]
    const prepareHandleShowMessage = async () => {
        await setTicketId(ticket._id)
        handleShowMessage()
    }


        return (
            <>
                {displayMode === ticket.status &&
                <div className={'row'} id={'support-tickets-'+ticket._id + '-' + auth.userMessages.length}
                     style={{border:'2px solid #ccc',
                         borderRadius:'7px', padding:'10px 0px 10px 0px',
                         width:'100%'
                     }}>
                    <div className={'col-xl-2 col-lg-3 col-md-6 col-sm-12 col-sx-12'}>
                        <p style={{margin:'0', padding:'0'}}>{ticket.name}</p>
                    </div>
                    <div className={'col-xl-5 col-lg-3 col-md-6 col-sm-12 col-sx-12'}>
                        <p onClick={prepareHandleShowMessage}
                           style={{cursor:'pointer', margin:'0', padding:'0'}}>
                            {ticket.title}
                        </p>
                    </div>
                    <div className={'col-xl-2 col-lg-3 col-md-6 col-sm-12 col-sx-12'}>
                        <p style={{margin:'0', padding:'4px 0px 0px 0px', fontSize:'0.8em'}}>
                            {day}-{month}-{year} {hours}:{minutes}
                        </p>
                    </div>
                    <div className={'col-xl-3 col-lg-3 col-md-6 col-sm-12 col-sx-12'}>
                        <Button onClick={prepareHandleShowMessage}
                                className={'nav-user-button'}
                                text={'VIEW'}
                                key={'support-ticket-view' + ticket._id}
                                keyUsed={'support-ticket-view'}
                                float={'right'}
                                keyMode={ticket._id}
                                controlButtonColor={controlButtonColor}
                                priceButtonColor={priceButtonColor}
                                navButtonColor={navButtonColor}
                                active={false}
                                categoryButton={true}
                                sidebarButton={true}
                                icon={faComments}/>
                    </div>
                </div>

                }

            </>

        );
}

export default TicketRow;
