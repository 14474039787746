import React from 'react';
import Button from "../../shared/Button";
import '../../../css/OrderRow.css'
import {faFileDownload, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const OrderRow = (props) => {
    let buttonAction, actionButtonText;
    const {
        firstname, lastname, orderNumber, currencySymbol, total, archiveOrder, orderIcon,
        orderCompleted, handleShowCustomer, orderReady,  controlButtonColor, navButtonColor,
        orderId, status, approveOrder, reInitiateOrder, order, priceButtonColor, cancelOrder
    } = props
    const {type, paymentComplete} = order

    if(status === 10 || status === 0){
        buttonAction = approveOrder
        actionButtonText='APPROVE'
    }
    if(status === 1){
        buttonAction = orderReady
        actionButtonText= 'FULFIL'
    }
    if(status === 2){
        buttonAction = orderCompleted
        actionButtonText= 'COMPLETE'
    }
    if(status === 3){
        buttonAction = archiveOrder
        actionButtonText= 'ARCHIVE'
    }
    if(status === 4){
        buttonAction = null
        actionButtonText= null
    }
    if(status === 5){
        buttonAction = reInitiateOrder
        actionButtonText= 'RESTORE'
    }

    const date  = props.order.date.split('T')[0]
    const year = date.split('-')[0]
    const month = date.split('-')[1]
    const day = date.split('-')[2]

    const cancelThisOrder = async () => {
        await cancelOrder()
    }

    const getColor = () => {
        if(paymentComplete){
            return '#20c620'
        } else {
            return 'red'
        }
    }


        return (
            <div id={orderId} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12">
                <div className="card-body" >
                    <div className="card " >
                        <div className={'row order-container'} style={{marginTop: '0'}}>
                            <div className={'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-sx-12'}>
                                <div className="orders-admin-grid-container">
                                    <div className="OrderSelect order-row-padding">
                                        <p className={'order-paragraph-padding'}>
                                            Select
                                        </p>
                                    </div>
                                    <div className="OrderNumber order-row-padding">
                                        <p className={'order-paragraph-padding'}>
                                            <strong>#{orderNumber}</strong>
                                        </p>
                                    </div>
                                    <div className="OrderName order-row-padding">
                                        <span style={{cursor:'pointer', fontWeight: 'bold'}}
                                              onClick={handleShowCustomer}>
                                            <p className={'order-paragraph-padding'}>
                                            {firstname} {lastname} <FontAwesomeIcon icon={faFileDownload} />
                                        </p>
                                        </span>
                                    </div>
                                    <div className="OrderDate order-row-padding">
                                        <p className={'order-paragraph-padding'}>
                                            {day + '-' + month + '-' + year}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-sx-12'}>
                                <div className="orders-admin-grid-container-two">
                                    <div className="OrderType order-row-padding">
                                        {type}
                                    </div>
                                    <div className="OrderPrice order-row-padding">
                                        <p className={'order-paragraph-padding'}>
                                            <strong>
                                                {currencySymbol}{total} <span
                                                style={{fontSize: '0.6em', color: getColor()}}>
                                                {paymentComplete ?
                                                '[PAID]' : '[UNPAID]'}
                                            </span>
                                            </strong>
                                        </p>
                                    </div>
                                    <div className="OrderView">
                                        {
                                            status !== 4 && status !== 5 &&
                                            <Button onClick={cancelThisOrder}
                                                    className={'nav-user-button'}
                                                    text={''}
                                                    key={'cancel-this-order-' + orderNumber}
                                                    keyUsed={'cancel-this-'}
                                                    float={'right'}
                                                    keyMode={'order-' + orderNumber}
                                                    controlButtonColor={controlButtonColor}
                                                    navButtonColor={navButtonColor}
                                                    active={false}
                                                    solid={false}
                                                    sidebarButton={true}
                                                    icon={faTrashAlt}/>
                                        }
                                    </div>
                                    <div className="OrderFunction">
                                        {status !== 4 &&
                                        <Button onClick={buttonAction}
                                                className={'nav-user-button'}
                                                text={actionButtonText}
                                                key={'action-this-order-' + orderNumber}
                                                keyUsed={'action-this-order'}
                                                float={'right'}
                                                keyMode={'-' + orderNumber}
                                                controlButtonColor={controlButtonColor}
                                                priceButtonColor={priceButtonColor}
                                                navButtonColor={navButtonColor}
                                                active={false}
                                                solid={false}
                                                sidebarButton={status === 5}
                                                icon={orderIcon}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

}

export default OrderRow;
