import React, {useContext, useState} from 'react';
import AuthContext from "../shared/auth-context";
import '../../css/InvoiceSettings.css';
import axios from "axios";
import jwtToken from "jsonwebtoken";
import ListCountries from "../shared/ListCountries";
import Button from "../shared/Button";
import UserInvoices from "./UserInvoices";
import PaymentSettings from "./PaymentSettings";
import {Alert} from "react-bootstrap";
import LogoUpload from "../shared/LogoUpload";

const InvoiceSettings = props => {
    const auth = useContext(AuthContext)
    let {
        countries, buildAndCheckUsername, buildAndCheckEmail, usernameSuccess,
        usernameError, currentUsername, currentEmail, emailValid, handleSetResetState,
        editUsername, editEmail, handleEditAccount, userExists, controlButtonColor,
        navButtonColor, priceButtonColor
    } = props


    const {invoicing, email, shop} = auth.appSettings;
    let {invoice} = invoicing
    const [payeeNameSettings, setPayeeNameSettings] = useState(invoicing.bacs.payeeName);
    const [bankNameSettings, setBankNameSettings] = useState(invoicing.bacs.bankName);
    const [sortCodeSettings, setSortCodeSettings] = useState(invoicing.bacs.sortCode);
    const [accNumberSettings, setAccNumberSettings] = useState(invoicing.bacs.accNumber);
    const [firstnameSettings, setFirstnameSettings] = useState(invoice.firstname);
    const [lastnameSettings, setLastnameSettings] = useState(invoice.lastname);
    const [companyNameSettings, setCompanyNameSettings] = useState(invoice.companyName);
    const [addressOneSettings, setAddressOneSettings] = useState(invoice.addressOne);
    const [addressTwoSettings, setAddressTwoSettings] = useState(invoice.addressTwo);
    const [currentLogo, setCurrentLogo] = useState(invoicing.logo);
    const [companyAboutSettings, setCompanyAboutSettings] = useState(
        invoicing.aboutCompany || 'My business rocks!!');
    const [country, setCountry] = useState(invoice.country)
    const [emailAddressSettings, setEmailAddressSettings] = useState(invoice.accountEmail);
    const [phoneSettings, setPhoneSettings] = useState(invoice.phone);
    const [postcodeSettings, setPostcodeSettings] = useState(invoice.postcode);
    const [emailPlaceholder, setEmailPlaceholder] = useState(null)

    const allowWeekday = auth.appSettings.shop.openingDays
    const shiftTimes = auth.appSettings.shop.openingHours
    const currencySymbol = useState(auth.appSettings.shop.currencySymbol)
    const currency = useState(auth.appSettings.shop.currency)
    const locale = useState(auth.appSettings.shop.locale)

    const getUsersEmailAddress = async () => {
        let decodedToken = await jwtToken.decode(auth.userToken, {complete: true});
        let email = decodedToken.payload.email;
        setEmailPlaceholder(email)
    }
    if (!emailPlaceholder) {
        getUsersEmailAddress().then()
    }
    const validatePostcode = async postcode => {
        let postcodeRegEx = /[A-Z]{1,2}[A-Z0-9]{1,2} ?[0-9][A-Z]{2}/i;
        let result = postcodeRegEx.test(postcode);
        if (result) {
            await auth.setPostcodeValid(true);
            return true
        } else {
            await auth.setPostcodeValid(false);
            return false
        }
    }
    const updateSettings = async () => {
        let submitData = {
            username: auth.username,
            shop: {
                showTax: shop.showTax,
                vatNumber: shop.vatNumber,
                pricesBefore: shop.pricesBefore,
                setGlobal: shop.setGlobal,
                globalRate: shop.globalRate,
                closeShop: shop.closeShop,
                minOrder: shop.minOrder,
                minValue: shop.minValue,
                restrictDist: shop.restrictDist,
                inputDist: shop.inputDist,
                distUnits: shop.distUnits,
                locale: locale,
                currencySymbol: currencySymbol,
                currency: currency,
                allowInt: shop.allowInt,
                showCharges: shop.showCharges,
                chargesType: shop.chargesType,
                storeIsLive: auth.appSettings.shop.storeIsLive,
                allowCollections: auth.appSettings.shop.allowCollections,
                disableDeliveries: auth.appSettings.shop.disableDeliveries,
                manageStockLevels: auth.appSettings.shop.manageStockLevels,
                colours: auth.appSettings.shop.colours,
                chargeDelivery:
                    {
                        minFee: shop.chargeDelivery.minFee,
                        maxFee: shop.chargeDelivery.maxFee,
                        perUnit: shop.chargeDelivery.perUnit,
                        standardFee: shop.chargeDelivery.standardFee,
                        showFree: shop.chargeDelivery.showFree,
                        freeOver: shop.chargeDelivery.freeOver
                    },
                openingHours: shiftTimes,
                openingDays: allowWeekday
            },
            email: {
                welcomeEmail: {
                    title: email.welcomeEmail.title,
                    body: email.welcomeEmail.body
                },
                newOrder: {
                    title: email.newOrder.title,
                    body: email.newOrder.body,
                    collection: email.newOrder.collection,
                    delivery: email.newOrder.delivery
                },
                approvedOrder: {
                    title: email.approvedOrder.title,
                    body: email.approvedOrder.body,
                    collection: email.approvedOrder.collection,
                    delivery: email.approvedOrder.delivery
                },
                orderCollect: {
                    title: email.orderCollect.title,
                    body: email.orderCollect.body,
                    collection: email.orderCollect.collection,
                    delivery: email.orderCollect.delivery
                },
                orderComplete: {
                    title: email.orderComplete.title,
                    body: email.orderComplete.body,
                    collection: email.orderComplete.collection,
                    delivery: email.orderComplete.delivery
                }
            },
            invoicing: {
                logo: auth.appSettings.invoicing.logo,
                invoice: {
                    firstname: firstnameSettings,
                    lastname: lastnameSettings,
                    companyName: companyNameSettings,
                    addressOne: addressOneSettings,
                    addressTwo: addressTwoSettings,
                    accountEmail: emailAddressSettings,
                    phone: phoneSettings,
                    postcode: postcodeSettings,
                    country: country
                },
                isBranded: invoicing.isBranded,
                aboutCompany: companyAboutSettings,
                bacs: {
                    payeeName: payeeNameSettings,
                    bankName: bankNameSettings,
                    sortCode: sortCodeSettings,
                    accNumber: accNumberSettings
                }
            },
            settingsValid: false,
            userToken: auth.userToken
        }
        let token;
        try {
            token = await jwtToken.sign(
                {
                    username: auth.username,
                    submitData
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {expiresIn: '1h'}
            );
        } catch (err) {
            console.log(err)
        }
        await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/settings`, {}, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(async response => {
                await props.getSettings().then();
                await props.reloadCart().then();
            })
            .catch(error => {
                console.log(error);
            });
    }
    const getInvoicingInputData = async (event) => {
        let {name, value} = event.target
        if (name === 'first-name') {
            setFirstnameSettings(value);
        }
        if (name === 'last-name') {
            setLastnameSettings(value);
        }
        if (name === 'address-one') {
            setAddressOneSettings(value);
        }
        if (name === 'address-two') {
            setAddressTwoSettings(value);
        }
        if (name === 'postcode') {
            setPostcodeSettings(value);
            await validatePostcode(value)
        }
        if (name === 'country') {
            setCountry(value);
        }
        if (name === 'company-name') {
            setCompanyNameSettings(value);
        }
        if (name === 'phone') {
            setPhoneSettings(value);
        }
        if (name === 'email') {
            setEmailAddressSettings(value);
        }
        if (name === 'payee-name') {
            setPayeeNameSettings(value)
        }
        if (name === 'bank-name') {
            setBankNameSettings(value)
        }
        if (name === 'bank-account') {
            setAccNumberSettings(value)
        }
        if (name === 'sort-code') {
            setSortCodeSettings(value)
        }
        if (name === 'company-about') {
            setCompanyAboutSettings(value)
        }


    }

    return (
        <div className={'container'} style={{marginTop: '0'}}>
            {auth.cartInvoices.length > 0 &&
            <div style={{
                border: '2px solid #f1f1f1', height: 'auto', marginBottom: '40px',
                borderRadius: '7px 7px 7px 7px', padding: '7px', fontSize: '0.9em'
            }}>
                {auth.cartInvoices.length > 0 &&
                <UserInvoices
                />
                }
            </div>
            }
            <div className={'hide-nav-toggle'}>
                <hr/>
            </div>
            <div className={'row'} style={{marginBottom: '10px'}}>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                    <h5 style={{marginBottom: '0'}}>Business information</h5>
                    <div className={'row'} style={{marginTop: '0'}}>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-sx-12">
                            <LogoUpload currentLogo={currentLogo}
                                        setCurrentLogo={setCurrentLogo}
                                        key={auth.appSettings.invoicing.logo}/>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-sx-12">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                Company name:
                            </span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete="off"
                                       className="form-control needed-placeholder"
                                       placeholder="Company Name"
                                       id="company-name"
                                       name='company-name'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={companyNameSettings}
                                       style={{marginTop: '0px'}}
                                />
                            </form>
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                Company email:
                            </span>
                            <form autoComplete={'off'}>
                                <input type="email"
                                       className="form-control needed-placeholder"
                                       placeholder="Email Address"
                                       id="email"
                                       name='email'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={emailAddressSettings}
                                       style={{marginTop: '0px'}}
                                />
                            </form>
                        </div>
                    </div>

                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                    <span className={'hide-nav-toggle'}><hr/></span>
                    <h5 style={{marginBottom: '0'}}>Account Settings</h5>
                    <div className={'row'} style={{marginTop: '0'}}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12">
                            <>
                                    <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                        Login (private) email:
                                    </span>
                                <form autoComplete={'off'}>
                                    <input type="text"
                                           autoComplete={'off'}
                                           style={{marginBottom: '0', marginTop: '0'}}
                                           className="customer-email form-control"
                                           placeholder={auth.userEmail}
                                           id="customer-email"
                                           value={currentEmail}
                                           name='customer-email'
                                           onInput={handleSetResetState}
                                           onChange={buildAndCheckEmail}
                                           disabled={!editEmail}
                                    />
                                </form>
                            </>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-7 col-sm-8 col-sx-8">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                        Login username:
                                    </span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete={'off'}
                                       className="customer-email form-control"
                                       placeholder={auth.username}
                                       id="username-signup"
                                       value={currentUsername}
                                       name='username-signup'
                                       onInput={handleSetResetState}
                                       onChange={buildAndCheckUsername}
                                       style={{marginBottom: '14px', marginTop: '0'}}
                                       disabled={!editUsername}
                                />
                            </form>
                        </div>
                        <div style={{paddingTop:'25px'}}
                             className="col-xl-3 col-lg-3 col-md-5 col-sm-4 col-sx-4">
                            <span >
                                <Button onClick={handleEditAccount}
                                        className={'user-button'}
                                        key={'change-username-email'}
                                        keyUsed={'change-'}
                                        keyMode={'username-email'}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        text={!editEmail && !editUsername ? 'CHANGE' : 'APPLY'}
                                        solid={true}
                                        active={false}
                                        categoryButton={true}
                                        float={'right'}
                                        disabled={(editUsername && (currentUsername.length !== 0 &&
                                        currentUsername.toLowerCase() !== auth.username.toLowerCase() ?
                                            usernameSuccess && usernameError
                                            : false)) ||
                                        (editEmail && (userExists || (!emailValid ? currentEmail.length !== 0 : false)))}
                                        icon={null}/>

                            </span>

                        </div>
                        {usernameError && usernameError !==
                        `Sorry!! It looks like @${auth.username.toLowerCase()} is taken!` ?
                            <Alert key={'id2x1'} variant={'danger'}
                                   style={{
                                       textAlign: 'center', width: '100%', marginLeft: '15px', marginRight: '15px',
                                       fontSize: '0.8em', paddingTop: '7px', paddingBottom: '7px'
                                   }}
                            >
                                {usernameError}
                            </Alert>
                            : usernameError ===
                            `Sorry!! It looks like @${auth.username.toLowerCase()} is taken!` &&
                            <Alert key={'success-username'} variant={'success'}
                                   style={{
                                       textAlign: 'center', width: '100%', marginLeft: '15px', marginRight: '15px',
                                       fontSize: '0.8em', paddingTop: '7px', paddingBottom: '7px'
                                   }}
                            >
                                You own @{currentUsername} so you can save it!!
                            </Alert>
                        }
                        {usernameSuccess && !usernameError &&
                        <Alert key={'id2x2'} variant={'success'}
                               style={{
                                   textAlign: 'center', width: '100%', marginLeft: '15px', marginRight: '15px',
                                   fontSize: '0.8em', paddingTop: '7px', paddingBottom: '7px'
                               }}
                        >
                            {usernameSuccess}
                        </Alert>

                        }
                    </div>
                </div>
            </div>
            <hr/>
            <div className={'row'} style={{marginTop: '0'}}>
                <div className={'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-sx-12'}
                     style={{marginTop: '0'}}>
                    <h5 style={{marginBottom: '0'}}>About {companyNameSettings === '' ? 'Your Company' : companyNameSettings}</h5>
                    <div className={'row'} style={{marginTop: '15px', marginBottom: '25px'}}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12">
                            <textarea className="form-control needed-placeholder"
                                      id="company-about"
                                      name='company-about'
                                      onChange={getInvoicingInputData}
                                      onBlur={updateSettings}
                                      placeholder={companyAboutSettings}
                                      value={companyAboutSettings}
                                      style={{marginTop: '0px'}}
                            />
                        </div>
                    </div>
                    <h5 style={{marginBottom: '0'}}>Invoice Configuration</h5>
                    <div className={'row'} style={{marginTop: '0'}}>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                First name:
                            </span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete={'off'}
                                       className="form-control needed-placeholder"
                                       placeholder="First name"
                                       id="first-name"
                                       name='first-name'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={firstnameSettings}
                                       style={{marginTop: '0px'}}
                                />
                            </form>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                Last name:
                            </span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete={'off'}
                                       className="form-control needed-placeholder"
                                       placeholder="Last name"
                                       id="last-name"
                                       name='last-name'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={lastnameSettings}
                                       style={{marginTop: '0px'}}
                                />
                            </form>
                        </div>
                    </div>
                    <div className={'row'} style={{marginTop: '0'}}>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                Address line one:
                            </span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete={'off'}
                                       className="form-control needed-placeholder"
                                       placeholder="Address line 1"
                                       id="address-one"
                                       name='address-one'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={addressOneSettings}
                                       style={{marginTop: '0px'}}
                                />
                            </form>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>Phone:</span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete={'off'}
                                       className="form-control needed-placeholder"
                                       placeholder="Phone Number"
                                       id="phone"
                                       name='phone'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={phoneSettings}
                                       style={{marginTop: '0px'}}
                                />
                            </form>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                            <span style={{
                                fontSize: '0.7em',
                                marginBottom: '0',
                                lineHeight: '0.7em'
                            }}>Address line one:</span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete={'off'}
                                       className="form-control"
                                       placeholder="Address line 2"
                                       id="address-two"
                                       name='address-two'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={addressTwoSettings}
                                       style={{marginTop: '0px'}}
                                />
                            </form>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                Postcode:
                            </span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete={'off'}
                                       className="form-control needed-placeholder"
                                       placeholder="Postcode"
                                       id="postcode"
                                       name='postcode'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={postcodeSettings}
                                       style={{marginTop: '0px'}}
                                />
                            </form>
                        </div>
                    </div>

                    <div className={'row'} style={{
                        marginTop: '0', marginBottom: '30px'
                    }}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                Country:
                            </span>
                            <select id="list-user-countries"
                                    style={{
                                        width: '100%', height: '35px', padding: '3px',
                                        marginRight: '0', marginBottom: '0', marginLeft: '0', marginTop: '0'
                                    }}
                                    name="country"
                                    className="form-control"
                                    aria-label="Invoicing Country"
                                    onInput={getInvoicingInputData}
                                    value={country}
                                    onBlur={updateSettings}
                            >
                                {countries.map((zone, index) =>
                                    <ListCountries key={zone + index} zone={zone}/>)}
                            </select>
                        </div>
                    </div>
                </div>
                <div className={'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-sx-12'}
                     style={{marginTop: '0'}}>
                    <PaymentSettings handleShowGoLive={props.handleShowGoLive}
                                     getSettings={props.getSettings}
                                     setSettingsLoaded={props.setSettingsLoaded}/>
                    <span className={'hide-nav-toggle'}><hr/></span>
                    <h5 style={{marginBottom: '0', marginTop: '21px'}}>
                        BACS Payee Info
                    </h5>
                    <div className={'row'} style={{marginTop: '0'}}>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                        Payee name:
                                    </span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete={'off'}
                                       className="form-control"
                                       placeholder={'Payee Name'}
                                       id="payee-name"
                                       name='payee-name'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={payeeNameSettings}
                                       style={{marginTop: '0'}}
                                />
                            </form>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                        Bank name:
                                    </span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete={'off'}
                                       className="form-control"
                                       placeholder="Bank Name"
                                       id="bank-name"
                                       name='bank-name'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={bankNameSettings}
                                       style={{marginTop: '0'}}
                                />
                            </form>
                        </div>
                    </div>
                    <div className={'row'} style={{marginTop: '0'}}>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                        Account number:
                                    </span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete={'off'}
                                       className="form-control"
                                       placeholder="Account Number"
                                       id="bank-account"
                                       name='bank-account'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={accNumberSettings}
                                       style={{marginTop: '0'}}
                                />
                            </form>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12">
                            <span style={{fontSize: '0.7em', marginBottom: '0', lineHeight: '0.7em'}}>
                                        Sort code:
                                    </span>
                            <form autoComplete={'off'}>
                                <input type="text"
                                       autoComplete={'off'}
                                       className="form-control"
                                       placeholder="Sort Code"
                                       id="sort-code"
                                       name='sort-code'
                                       onChange={getInvoicingInputData}
                                       onBlur={updateSettings}
                                       value={sortCodeSettings}
                                       style={{marginTop: '0'}}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default InvoiceSettings;
