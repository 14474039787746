import React, {useContext} from 'react';
import {Alert} from "react-bootstrap";
import AuthContext from "./auth-context";

const SupportTicketBody = props => {
    const auth = useContext(AuthContext)
    let { messageSuccess, collectInputData, messageSuccessCode, loggedIn} = props
        return (
            !messageSuccess ?
        <>
                <div className={'container'}>
                    {!loggedIn ?
                        <p>Thanks for taking the time to look at our services.
                            If you have any questions then please feel free to use
                            the form below to send us a message. We'll try to get back
                            to you as soon as possible.</p>
                        :
                        <>
                        <h3 style={{marginTop:'0'}}>Submit support ticket</h3>
                            <p>
                                Please use the form below to open a new ticket.
                            </p>
                        </>

                    }
                </div>
            {!loggedIn &&
                <>
                    <div style={{paddingTop:'0'}}
                         id={'support-ticket-body'}
                         className={'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12'}>
                        <form autoComplete={'off'}>
                            <p className={'contact-labels'}>Full Name:</p>
                            <input type={'text'}
                                   name={'name'}
                                   onInput={collectInputData}
                                   onBlur={collectInputData}
                                   className={'form-control ticket-inputs'}
                                   placeholder={auth.isLoggedIn ?
                                       auth.appSettings.invoicing.invoice.firstname
                                       :
                                       'Full Name'
                                   }
                            />
                            <p className={'contact-labels'}>Email:</p>
                            <input type={'text'}
                                   name={'email'}
                                   onInput={collectInputData}
                                   onBlur={collectInputData}
                                   className={'form-control ticket-inputs'}
                                   placeholder={auth.isLoggedIn ?
                                       auth.userEmail
                                       :
                                       'Email Address'
                                   }
                            />
                        </form>
                    </div>
                    <div style={{paddingTop:'0'}}
                         className={'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12'}>
                        <form autoComplete={'off'}>
                            <p className={'contact-labels'}>Phone Number:</p>
                            <input type={'text'}
                                   name={'phone'}
                                   onInput={collectInputData}
                                   onBlur={collectInputData}
                                   className={'form-control ticket-inputs'}
                                   placeholder={auth.isLoggedIn ?
                                       auth.appSettings.invoicing.invoice.phone
                                       :
                                       'Phone Number'
                                   }
                            />
                            <p className={'contact-labels'}>Type:</p>
                            <select className={'form-control ticket-inputs'}
                                    name={'type'}
                                    onInput={collectInputData}
                                    onChange={collectInputData}
                                    id={'select-ticket-type'}>
                                <option value={'sales-ticket'}>
                                    Sales Question
                                </option>
                                <option value={'support-ticket'}>
                                    Support Question
                                </option>
                            </select>
                        </form>
                    </div>
                </>
            }
            <div style={{paddingTop:'5px', paddingBottom:'30px'}}
                 className={'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12'}>
                <form autoComplete={'off'}>
                    <p className={'contact-labels'}>Message Title:</p>
                    <input type={'text'}
                           name={'title'}
                           onInput={collectInputData}
                           onBlur={collectInputData}
                           className={'form-control ticket-inputs'} />
                    <p className={'contact-labels'}>Message Body:</p>
                    <textarea style={{width:'100%', minHeight:'200px'}}
                              name={'body'}
                              onInput={collectInputData}
                              onBlur={collectInputData}
                              className={'form-control ticket-inputs'} />
                </form>
            </div>
        </>
        :
        <div style={{width:'100%'}}>
            <p><strong>Thanks! Your message has been sent</strong></p>
            <Alert style={{textAlign: 'center'}}
                   key={messageSuccessCode}
                   variant={'success'}>
                <strong>Message ID : #{messageSuccessCode}</strong>
            </Alert>
            <p>
                We will get back to you as soon as possible. We would
                have sent you a confirmation email so if you can't see
                that then please check your junk folder and whitelist
                our email address to ensure that you see our response.
            </p>
            {!loggedIn &&
            <p>
                <em><strong>NOTE:</strong> We will email you directly with
                    a reply but you can login to your account or create
                    one with the email you used and your ticket will be
                    in there.</em>
            </p>
            }

        </div>
        );
}

export default SupportTicketBody;
