import React from 'react';
import '../../css/ShopSettings.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
const ShopSettingsRow = props => {
    let { minRowHeight } = props
    const
        {
            infoOnClick, checkboxChecked, checkboxOnChange, checkboxOnBlur, inputType, currencySymbol,
            showInfoOptions, inputValue, inputOnChange, inputOnBlur, checkboxOnInput,
            settingsTitle, settingsText, infoText, input, inputId, inputName, inputLabel,
            checkboxId, checkboxName, checkboxLabel, inputPlaceholder, simpleRow, rowOptionItem  } = props
        return (
            !!rowOptionItem ?
                <>
                <div style={{paddingTop: '0', minHeight: minRowHeight, marginTop:'0'}}>
                    <div style={{paddingTop: '0', textAlign: 'left', width: '100%', fontWeight: 'bold'}}>
                        {settingsTitle}
                        <span style={{marginLeft: '0'}}>
                            <input type={'text'} id="standard-charge"
                                   style={{
                                       float: 'right', marginTop: '0',
                                       width: '60px', marginRight: '10px', marginBottom: '0'
                                   }}
                                   name="status"
                                   className="form-control"
                                   aria-label="Select category"
                                   value={inputValue}
                                   onChange={inputOnChange}
                                   onBlur={inputOnBlur}
                            />
                            <span style={{float: 'right', marginRight: '0', marginTop:'7px'}}>{currencySymbol}</span>

                                    </span>
                    </div>
                </div>
                    <hr />
                </>
                :
            simpleRow ?
                <>
                    <div style={{paddingTop: '0', minHeight: minRowHeight, marginTop:'0'}}>
                        <p><FontAwesomeIcon
                            onClick={infoOnClick}
                            style={{
                                marginLeft: '7px', float: 'right',
                                marginTop: '10px'
                            }} icon={faInfoCircle}/>
                            <label className="switch">
                                <input type="checkbox"
                                       style={{
                                           float: 'right', marginBottom: '0',
                                           width: '20px', marginTop: '0'
                                       }}
                                       checked={checkboxChecked}
                                       className="form-control"
                                       id={checkboxId}
                                       name={checkboxName}
                                       onChange={checkboxOnChange}
                                       onInput={checkboxOnInput}
                                />
                                <span className="slider round"> </span>
                            </label>
                            <span style={{paddingTop: '5px'}}>
                                    <strong>{checkboxLabel}</strong>
                                </span></p>
                    </div>
                    {showInfoOptions &&
                    <div>
                        <p>
                            <hr />
                            {infoText}
                        </p>
                    </div>}
                    <hr style={{verticalAlign:'bottom'}}/>
                </>
                :
            <>
                <div style={{paddingTop: '0', minHeight: minRowHeight, marginTop:'0'}}>
                    <FontAwesomeIcon
                        onClick={infoOnClick}
                        style={checkboxChecked ?
                            {marginLeft: '7px', float: 'right', marginTop: '30px'}
                            :
                            {marginLeft: '7px', float: 'right', marginTop: '10px'}
                        } icon={faInfoCircle}/>
                    <label style={checkboxChecked ? {marginTop: '20px'} : {marginTop: '0'}}
                           className="switch">
                        <input type="checkbox"
                               checked={checkboxChecked}
                               className="form-control"
                               aria-label={checkboxLabel}
                               id={checkboxId}
                               name={checkboxName}
                               onChange={checkboxOnChange}
                               onInput={checkboxOnBlur}
                               onBlur={checkboxOnBlur}
                        />
                        <span className="slider round"> </span>
                    </label>
                    <div style={{paddingTop: '5px', marginBottom: '0'}}>
                        {!checkboxChecked ? <strong>{settingsTitle}</strong>
                            : checkboxChecked && inputOnChange &&
                            <div style={{marginBottom: '10px', display: 'block'}}>
                                            <span style={{
                                                fontSize: '0.9em', marginBottom: '0',
                                                marginTop: '0', float: 'left', fontWeight:'bold'
                                            }}>
                                                {settingsText}
                                            </span><br/>
                                {!!input && inputType === 'tax-rate' ? <select
                                        id={inputId}
                                        name={inputName}
                                        aria-label={inputLabel}
                                        onBlur={inputOnBlur}
                                        onInput={inputOnChange}
                                        value={inputValue}
                                        className={"form-control"}
                                        style={{float: 'left', marginTop: '0', paddingLeft:'0',
                                            maxWidth: '200px', height: '40px', marginRight: '10px',
                                            marginBottom: '0'
                                        }}
                                    >
                                        <option value="Standard Rate">Standard Rate</option>
                                        <option value="Reduced Rate">Reduced Rate</option>
                                        <option value="Zero Rate">Zero Rate</option>
                                    </select>
                                    : !!input && inputType === 'delivery-charges' ?
                                        <select id="max-distance-units"
                                                style={{float: 'left', marginTop: '0', paddingLeft:'0',
                                                    maxWidth: '200px', height: '40px', marginRight: '10px',
                                                    marginBottom: '0'
                                                }}
                                                name="status"
                                                className="form-control"
                                                aria-label="Select category"
                                                onChange={inputOnChange}
                                                onBlur={inputOnBlur}
                                                value={inputValue}
                                        >
                                            <option value={'Standard'}>
                                                Standard
                                            </option>
                                            <option value={'Advanced'}>
                                                Advanced
                                            </option>
                                        </select>
                                        :
                                    <input type="text"
                                           className="form-control"
                                           placeholder={inputPlaceholder}
                                           id={inputId}
                                           name={inputName}
                                           onChange={inputOnChange}
                                           value={inputValue}
                                           onBlur={inputOnBlur}
                                           style={{
                                               float: 'left', marginTop: '0', paddingLeft: '5px',
                                               width: '100%', maxWidth: '200px', height: '40px',
                                               marginRight: '10px', marginBottom: '10px'
                                           }}
                                    />
                                }
                                {checkboxChecked && <div style={{height: '40px'}}/>}
                            </div>
                        }
                    </div>

                    {showInfoOptions &&
                    <div>
                        <hr />
                        <p>
                            {infoText}
                        </p>
                    </div>}
                </div>
                <hr/>
            </>





            // <>
            //     <div style={{height: '45px', paddingTop: '7px'}}>
            //         <p><FontAwesomeIcon
            //             onClick={infoOnClick}
            //             style={{
            //                 marginRight: '7px', float: 'left',
            //                 marginTop: '4px'
            //             }} icon={faInfoCircle}/>
            //             <label className="switch">
            //                 <input type="checkbox"
            //                        checked={checkboxChecked}
            //                        className="form-control"
            //                        id="vat-number-checkbox"
            //                        name='vat-number-checkbox'
            //                        onChange={checkboxOnChange}
            //                        onBlur={checkboxOnBlur}
            //                 />
            //                 <span className="slider round"> </span>
            //             </label>
            //             {showInfoOptions && inputOnChange &&
            //             <input type="text"
            //                    className="form-control"
            //                    placeholder="VAT Number"
            //                    id="vat-number"
            //                    name='vat-number'
            //                    onChange={inputOnChange}
            //                    value={inputValue}
            //                    onBlur={inputOnBlur}
            //                    style={{
            //                        float: 'right', marginTop: '-7px', paddingLeft: '5px',
            //                        width: '170px', height: '40px',
            //                        marginRight: '10px', marginBottom: '0'
            //                    }}
            //             />
            //             }
            //             <span style={{paddingTop: '5px'}}>
            //                 <strong>{settingsText}</strong>
            //             </span>
            //         </p>
            //     </div>
            //     <hr />
            // </>
        );

}

export default ShopSettingsRow;
