import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import {CSSTransition} from 'react-transition-group';

import '../../../css/SideDrawer.css';
import Button from "../../shared/Button";
import {
    faCertificate,
    faGavel,
    faHandshake,
    faHandshakeSlash,
    faMagic,
    faStore, faTrashAlt,
    faTruckLoading, faUniversity,
    faUsersCog
} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../shared/auth-context";
import SideDrawerButton from "./SideDrawerButton";
import SettingsSidedrawerNav from "./user/SettingsSidedrawerNav";

const AdminSideDrawer = props => {
    let {showInvoiceSettings, showEmailSettings, showOrderSettings,
        showShopSettings, showNavDrawer, showOpeningHours, controlButtonColor,
        navButtonColor, showNew, showApproved, showReady, showComplete,
        showArchived, showCancelled,handleNew, handleApproved,handleReady,handleComplete,
        handleCancelled, handleArchived, priceButtonColor, haveClicked, setHaveClicked } = props
    const auth = useContext(AuthContext)

    const addProductHandler = () => {
        // console.log()
        props.newProduct(props.loadedCategories[props.displayCategory]._id)
    }

    const buttonColor = (type) => {
        switch(type){
            case 'new':
                return showNew ? 'dark-blue' : 'grey'
            case 'approved':
                return showApproved ? 'dark-blue' : 'grey'
            case 'ready':
                return showReady ? 'dark-blue' : 'grey'
            case 'complete':
                return showComplete ? 'dark-blue' : 'grey'
            case 'archived':
                return showArchived ? 'dark-blue' : 'grey'
            case 'cancelled':
                return showCancelled ? 'dark-blue' : 'grey'
            default:
                return ''
        }
    }

    const content = (
        <CSSTransition
            in={props.show}
            timeout={200}
            classNames="slide-in-left"
            mountOnEnter
            unmountOnExit
        >
            <aside className="side-drawer hide-nav-toggle" style={{maxWidth: '250px'}} onClick={props.onClick}>
                <>
                    <div style={{marginTop: '60px'}} id="navbarResponsive">
                        {auth.isLoggedIn &&
                        <ul className="navbar-nav ml-auto" style={{marginRight: '7px'}}>
                            <li className="nav-item nav-controls ">
                                {auth.isLoggedIn && !props.showAdminMode &&
                                <Button onClick={props.switchDrawerHandler}
                                        className={'nav-user-button'}
                                        key={'see-store'}
                                        keyUsed={'see'}
                                        keyMode={'-store'}
                                        float={'right'}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        active={false}
                                        icon={faStore}/>
                                }
                                {auth.isLoggedIn && props.showSettings &&
                                <Button onClick={props.editStoreHandler}
                                        className={'nav-user-button'}
                                        key={'edit-store'}
                                        keyUsed={'edit-store'}
                                        keyMode={'-store'}
                                        float={'right'}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        active={false}
                                        icon={faMagic}/>
                                }
                                {auth.isLoggedIn && props.showAdminMode && !props.showOrdersMode &&
                                !props.showSettings &&
                                <Button onClick={props.editStoreHandler}
                                        className={'nav-user-button'}
                                        key={'edit-store'}
                                        keyUsed={'edit-store'}
                                        keyMode={'-store'}
                                        float={'right'}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        active={false}
                                        icon={faMagic}/>
                                }
                                {auth.isLoggedIn && props.showOrdersMode && !props.showAdminMode &&
                                !props.showSettings &&
                                <Button onClick={props.editStoreHandler}
                                        className={'nav-user-button'}
                                        key={'edit-store'}
                                        keyUsed={'edit'}
                                        keyMode={'-store'}
                                        float={'right'}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        active={false}
                                        icon={faMagic}/>
                                }
                                {auth.isLoggedIn && props.currentOrders > 0 && !props.showOrdersMode &&
                                <Button onClick={props.ordersModeHandler}
                                        className={'nav-user-button'}
                                        key={'see-orders'}
                                        keyUsed={'see'}
                                        keyMode={'-orders'}
                                        float={'right'}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        active={false}
                                        icon={faGavel}/>
                                }
                                {auth.isLoggedIn && !props.showSettings &&
                                <Button onClick={props.handleShowSettings}
                                        className={'nav-user-button'}
                                        key={'edit-my-account'}
                                        keyUsed={'edit-'}
                                        keyMode={'my-account'}
                                        float={'right'}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        active={false}
                                        icon={faUsersCog}/>
                                }
                            </li>
                        </ul>
                        }
                    </div>
                    {auth.isLoggedIn &&
                    <hr/>
                    }
                    <div className={'hide-nav-toggle'} style={{height:'20px'}} />
                    <div style={{overflow: 'scroll', overflowY: 'hide', height: '400px', display: 'block'}}>
                        {props.showOrdersMode && auth.isLoggedIn && !props.showSettings &&
                        <nav style={{textAlign: 'center', display: 'block'}}>
                            <Button onClick={handleNew}
                                    className={'user-sidebar nav-button ' +
                                    buttonColor('new')}
                                    style={{fontSize:'1.1em', marginLeft:'0'}}
                                    text={'NEW ORDERS'}
                                    sidebarButton={true}
                                    icon={faHandshakeSlash}/>
                            <Button onClick={handleApproved}
                                    className={'user-sidebar nav-button ' +
                                    buttonColor('approved')}
                                    style={{fontSize:'1.1em'}}
                                    text={'APPROVED'}
                                    sidebarButton={true}
                                    icon={faHandshake}/>
                            <Button onClick={handleReady}
                                    className={'user-sidebar nav-button ' +
                                    buttonColor('ready')}
                                    style={{fontSize:'1.1em'}}
                                    text={'READY'}
                                    sidebarButton={true}
                                    icon={faTruckLoading}/>
                            <Button onClick={handleComplete}
                                    className={'user-sidebar nav-button ' +
                                    buttonColor('complete')}
                                    style={{fontSize:'1.1em'}}
                                    text={'COMPLETE'}
                                    sidebarButton={true}
                                    icon={faCertificate}/>
                                    <hr/>
                            <Button onClick={handleArchived}
                                    className={'user-sidebar nav-button ' +
                                    buttonColor('archived')}
                                    text={'ARCHIVED'}
                                    sidebarButton={true}
                                    icon={faUniversity}/>
                            <Button onClick={handleCancelled}
                                    className={'user-sidebar nav-button ' +
                                    buttonColor('cancelled')}
                                    sidebarButton={true}
                                    text={'CANCELLED'}
                                    icon={faTrashAlt}/>
                        </nav>
                        }
                        <nav style={{textAlign: 'center', display: 'block'}}>
                            {props.showAdminMode && !props.showSettings &&
                            // <p>EDITOR MODE</p>
                                    props.loadedCategories.map((category, index) =>
                                        <SideDrawerButton
                                            key={category._id + '-' + index + '-side-drawer-button'}
                                            category={category}
                                            categoryId={category._id}
                                            index={index}
                                            deleteMode={props.deleteMode}
                                            setDeleteMode={props.setDeleteMode}
                                            displayCategory={props.displayCategory}
                                            setDisplayCategory={props.setDisplayCategory}
                                            setShowNavDrawer={props.setShowNavDrawer}
                                            showAdminMode={props.showAdminMode}
                                            showOrdersMode={props.showOrdersMode}
                                            updateCategoryName={props.updateCategoryName}
                                            deleteCategory={props.deleteCategory}
                                            setEditMode={props.setEditMode}
                                            editMode={props.editMode}
                                            loadedCategories={props.loadedCategories}
                                            controlButtonColor={controlButtonColor}
                                            navButtonColor={navButtonColor}
                                            priceButtonColor={priceButtonColor}
                                            setHaveClicked={setHaveClicked}
                                        />
                                    )}
                        </nav>
                            {!props.showAdminMode &&  !props.showOrdersMode &&
                            !props.showSettings &&
                            // <p>EDITOR MODE</p>
                            props.loadedCategories.map( (category, index) =>
                                <SideDrawerButton
                                    key={category._id + '-' + index + '-button'}
                                    category={category}
                                    index={index}
                                    setHaveClicked={setHaveClicked}
                                    deleteMode={props.deleteMode}
                                    setDeleteMode={props.setDeleteMode}
                                    displayCategory={auth.hasFeatured && !haveClicked ? 999 : props.displayCategory}
                                    setDisplayCategory={props.setDisplayCategory}
                                    setShowNavDrawer={props.setShowNavDrawer}
                                    showAdminMode={props.showAdminMode}
                                    showOrdersMode={props.showOrdersMode}
                                    updateCategoryName={props.updateCategoryName}
                                    deleteCategory={props.deleteCategory}
                                    setEditMode={props.setEditMode}
                                    editMode={props.editMode}
                                    loadedCategories={props.loadedCategories}
                                    controlButtonColor={controlButtonColor}
                                    navButtonColor={navButtonColor}
                                    priceButtonColor={priceButtonColor}
                                />
                            )}
                            {!props.showAdminMode &&  !props.showOrdersMode && auth.isLoggedIn &&
                            !props.showSettings &&
                            <>
                            <div style={{display: 'block', paddingRight:'45px'}}>
                                <Button onClick={addProductHandler}
                                        className={'sidebar-category'}
                                        key={'edit-my-account'}
                                        keyUsed={'edit-'}
                                        keyMode={'my-account'}
                                        text={'ADD PRODUCT'}
                                        float={'right'}
                                        sidebarButton={true}
                                        categoryButton={true}
                                        solid={false}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        active={false}
                                        icon={null}/>
                                <Button onClick={props.createCategory}
                                        className={'sidebar-category'}
                                        key={'edit-my-account'}
                                        keyUsed={'edit-'}
                                        keyMode={'my-account'}
                                        text={'ADD CATEGORY'}
                                        float={'right'}
                                        sidebarButton={true}
                                        categoryButton={true}
                                        solid={false}
                                        controlButtonColor={controlButtonColor}
                                        navButtonColor={navButtonColor}
                                        priceButtonColor={priceButtonColor}
                                        active={false}
                                        icon={null}/>
                            </div>
                            </>
                            }
                        {props.showSettings &&
                        <SettingsSidedrawerNav handleShowShopSettings={props.handleShowShopSettings}
                                               menuItemColor={props.menuItemColor}
                                               handleShowInvoiceSettings={props.handleShowInvoiceSettings}
                                               handleShowEmailSettings={props.handleShowEmailSettings}
                                               handleShowOrderSettings={props.handleShowOrderSettings}
                                               handleShowOpeningHours={props.handleShowOpeningHours}
                                               showInvoiceSettings={showInvoiceSettings}
                                               showEmailSettings={showEmailSettings}
                                               showOrderSettings={showOrderSettings}
                                               showShopSettings={showShopSettings}
                                               showNavDrawer={showNavDrawer}
                                               showOpeningHours={showOpeningHours}
                                               controlButtonColor={controlButtonColor}
                                               navButtonColor={navButtonColor}
                                               priceButtonColor={priceButtonColor}
                        />
                        }
                    </div>
                </>

            </aside>
        </CSSTransition>
    );
    return ReactDOM.createPortal(content, document.getElementById('drawer-hook'));
};

export default AdminSideDrawer;
