import React, {useContext, useState} from 'react';
import AuthContext from "./auth-context";
import CustomerInvoiceTaxRow from "./CustomerInvoiceTaxRow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

const CustomerTaxInvoice = props => {
    const auth = useContext(AuthContext);
    let width = 100
    const [ showDeliveryInfo, setShowDeliveryInfo ] = useState(false);
    const [ knowTaxBreakdown, setKnowTaxBreakdown ] = useState(false)
    const [ taxTotals, setTaxTotals ] = useState({
        standard: 0,
        reduced: 0,
        zero: 0
    })
    const handleShowDeliveryChargeInfo = () => {
        setShowDeliveryInfo(prevState => !prevState);
    }
    if(!knowTaxBreakdown){
        let standardTotals = 0, reducedTotals = 0, zeroTotals = 0;
        for(let i=0 ; i<props.order.items.length ; i++){
            if(props.order.items[i].taxRate === '20'){
                standardTotals += props.order.items[i].price*props.order.items[i].value
            } else if(props.order.items[i].taxRate === '5'){
                reducedTotals += props.order.items[i].price*props.order.items[i].value
            } else if(props.order.items[i].taxRate === '0'){
                zeroTotals += props.order.items[i].price*props.order.items[i].value
            }
        }
        let taxTotals = {
            standard: standardTotals,
            reduced: reducedTotals,
            zero: zeroTotals
        }
        setTaxTotals(taxTotals)
        setKnowTaxBreakdown(true)
    }
    const { payeeName, bankName, sortCode,  accNumber } = auth.appSettings.invoicing.bacs;
    let currentOrder;
    for(let i=0 ; i<props.loadedOrders.length ; i++){
        if(props.loadedOrders[i]._id === props.order._id){
            currentOrder = props.loadedOrders[i]
        }
    }
    let orderNumber
    if(props.orderIndex){
        orderNumber = props.orderIndex
    } else {
        orderNumber = props.order.number
    }
    const showBasketCharges = () => {
        return (
            <>
                {// eslint-disable-next-line
                    props.order.deliveryCharges.totalDelivery > 0 || props.order.taxCharges.totalTax > 0 &&
                    <>
                        <div style={{width: '100%', height: '25px'}}>
                            <h5 style={{float: 'right', marginBottom: '0', marginTop: '15px;'}}>
                                Subtotal:
                                <span
                                    style={{width: '100px'}}> {props.order.currency}{props.order.subTotal.toFixed(2)}</span>
                            </h5>
                        </div>
                        <hr/>
                    </>
                }
                <div className="signup-grid-container"
                     style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <div className="Left">
                        {props.order.deliveryCharges.totalDelivery > 0 &&
                        <>
                            <h6>Delivery Charges <FontAwesomeIcon icon={faInfoCircle}
                                                                  onClick={handleShowDeliveryChargeInfo}
                                                                  onBlur={handleShowDeliveryChargeInfo}
                                                                  style={{
                                                                      marginLeft: '7px',
                                                                      marginTop: '0',
                                                                      cursor: 'pointer',
                                                                      fontSize: '0.8em'
                                                                  }}/>
                            </h6>
                            {showDeliveryInfo &&
                            <p>
                            <span style={{fontSize: '0.7em'}}>
                                {auth.appSettings.shop.chargesType === 'Advanced' ?
                                    <>
                                        <p>
                                            This shop has a minimum charge of
                                            <strong> {props.order.currency}{auth.appSettings.shop.chargeDelivery.minFee} </strong>
                                            and charges
                                            <strong> {props.order.currency}{auth.appSettings.shop.chargeDelivery.perUnit} </strong>
                                            per
                                            <strong> {auth.appSettings.shop.distUnits === 'Miles' ?
                                                <span>Mile</span>
                                                :
                                                <span>km</span>
                                            } </strong>
                                            with a maximum of
                                            <strong> {props.order.currency}{auth.appSettings.shop.chargeDelivery.maxFee} </strong>
                                            per order.
                                        </p>
                                        <p>
                                            Your total delivery charges will be calculated and added to the order
                                            once you have provided us with an address.
                                        </p>
                                    </>
                                    :
                                    <p>
                                        Our store has a standard charge of
                                        <strong> {props.order.currency}{auth.appSettings.shop.chargeDelivery.standardFee} </strong>
                                        on all deliveries.
                                    </p>
                                }
                            </span>
                            </p>
                            }
                            <div style={{width: '100%', height: '25px'}}>
                                <h6 style={{float: 'left', marginBottom: '0'}}>
                                    <span style={{width: '100px'}}>
                                        {props.order.currency}{props.order.deliveryCharges.subtotal.toFixed(2)} </span>
                                    <span style={{width: '130px', fontSize: '0.7em'}}>
                                        Delivery Subtotal
                                    </span>
                                </h6>
                            </div>
                                <>
                                    <div style={{width: '100%', height: '25px'}}>
                                        <p style={{float: 'left', marginTop: '0', marginBottom: '0'}}>
                                            <span style={{width: '100px'}}>
                                                {props.order.currency}{props.order.deliveryCharges.standardCharges.toFixed(2)} </span>
                                            <span style={{width: '130px', fontSize: '0.7em'}}>
                                                20% Charges
                                            </span>
                                        </p>
                                    </div>
                                    <div style={{width: '100%', height: '25px'}}>
                                        <p style={{float: 'left', marginTop: '0', marginBottom: '0'}}>
                                    <span style={{width: '100px'}}>
                                        {props.order.currency}{props.order.deliveryCharges.reducedCharges.toFixed(2)}
                                    </span> <span style={{width: '130px', fontSize: '0.7em'}}>
                                        5% Charges
                                    </span>
                                        </p>
                                    </div>
                                </>
                            <div style={{width: '100%', height: '25px'}}>
                                <h6 style={{float: 'left', marginBottom: '0'}}>
                                    <span style={{width: '100px', marginTop: '4px'}}>
                                        {props.order.currency}{props.order.deliveryCharges.totalDelivery.toFixed(2)}
                                    </span> <span style={{width: '130px', fontSize: '0.7em'}}>
                                        Total Delivery
                                    </span>
                                </h6>
                            </div>
                        </>
                        }
                    </div>
                    <div className="Right" style={{paddingLeft: '10px'}}>
                        {props.order.taxCharges.totalTax > 0 &&
                        <>
                            <h6 style={{float: 'right'}}>
                                VAT Charges
                            </h6>
                            <div style={{width: `100%`, height: '25px', float: 'right'}}>
                                <p style={{float: 'right', marginBottom: '0'}}>
                                <span style={{width: '130px', fontSize: '0.7em'}}>
                                    {props.order.currency}{taxTotals.standard.toFixed(2)} @ 20%
                                </span>
                                    <span
                                        style={{width: '100px'}}> {props.order.currency}{props.order.taxCharges.standardCharges.toFixed(2)}
                                </span>
                                </p>
                            </div>
                            <div style={{width: `${width}%`, height: '25px', float: 'right'}}>
                                <p style={{float: 'right', marginBottom: '0'}}>
                                <span style={{width: '130px', fontSize: '0.7em'}}>
                                    {props.order.currency}{taxTotals.reduced.toFixed(2)} @ 5%
                                </span> <span style={{width: '100px'}}>
                                    {props.order.currency}{props.order.taxCharges.reducedCharges.toFixed(2)}
                                </span>
                                </p>
                            </div>
                            <div style={{width: `${width}%`, height: '25px', float: 'right'}}>
                                <p style={{float: 'right', marginBottom: '0'}}>
                                <span style={{width: '130px', fontSize: '0.7em'}}>
                                    {props.order.currency}{taxTotals.zero.toFixed(2)} @ 0%
                                </span> <span style={{width: '100px'}}> {props.order.currency}0.00</span>
                                </p>
                            </div>
                            <div style={{width: `${width}%`, height: '25px', float: 'right'}}>
                                <h6 style={{float: 'right', marginBottom: '0', marginTop: '3px', textAlign: 'right'}}>
                                    Total Tax:
                                    <span
                                        style={{width: '100px'}}> {props.order.currency}{props.order.taxCharges.totalTax.toFixed(2)}</span>
                                </h6>
                            </div>
                        </>
                        }
                    </div>
                </div>
                {(props.order.taxCharges.totalTax > 0 || props.order.taxCharges.totalTax > 0) &&
                    <hr/>
                }
                <h4 style={{float: 'right', marginTop: '10px', width: '100%', textAlign: 'right'}}>
                    <span>Total Price: </span>
                    {props.order.currency}{props.order.total.toFixed(2)}</h4>
            </>
        )
    }

    return (
        <>
            <div className="container-fluid invoice-container">
            {props.viewInvoice &&
                <>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-sx-4">
                            <img src={auth.appSettings.invoicing.logo}
                                 style={{width:'90px', height:'auto', borderRadius: '10px 10px 10px 10px'}}
                                 alt={auth.appSettings.invoicing.invoice.companyName} />
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-sx-8 text-right">
                            <span style={{fontSize: '1em', lineHeight: '0.4em'}}>
                                <p><strong>Date:</strong> {currentOrder.date.split("T")[0]}</p>
                                    <p><strong>Invoice No:</strong> #{orderNumber}</p>
                                </span>
                            <h1 style={{float: 'right'}} className="text-7 mb-0">
                                {props.order.paymentComplete ?
                                    <span style={{color: '#2cc415'}}>PAID</span>
                                    :
                                    <span style={{color: '#ea3c53'}}>UNPAID</span>
                                }
                            </h1>
                        </div>
                    </div>
                    <hr />
                    <div className="row" style={{marginTop:'0'}}>
                        <div className="col-sm-6 text-sm-right order-sm-1"> <strong>Pay To:</strong>
                            <p className={'address'}>
                                {auth.appSettings.invoicing.invoice.firstname + ' ' +
                                auth.appSettings.invoicing.invoice.lastname}<br />
                                {auth.appSettings.invoicing.invoice.addressOne}<br />
                                {auth.appSettings.invoicing.invoice.addressTwo}<br />
                                {auth.appSettings.invoicing.invoice.postcode}<br />
                                {auth.appSettings.invoicing.invoice.country}<br />
                                {auth.appSettings.invoicing.invoice.phone}
                            </p>
                        </div>
                        <div className="col-sm-6 order-sm-0"> <strong>Invoiced To:</strong>
                            <p className={'address'}>
                                {props.order.customer.firstname} {props.order.customer.lastname}<br />
                                {props.order.customer.addressOne}<br />
                                {props.order.customer.addressTwo && props.order.customer.addressTwo}
                                {props.order.customer.addressTwo && <br />}
                                {props.order.customer.postcode && props.order.customer.postcode}
                                {props.order.customer.postcode && <br />}
                                {props.order.customer.country && props.order.customer.country}
                                {props.order.customer.country && <br />}
                                {props.order.customer.phone && props.order.customer.phone}
                                {props.order.customer.phone && <br />}
                            </p>
                        </div>
                    </div>
                </>
            }
                <div className="card">
                    <div className="card-header px-2 py-0">
                        <table className="table mb-0">
                            <thead>
                            <tr>
                                <td className="col-4 border-0"><strong>Item</strong></td>
                                <td className="col-3 border-0" />
                                <td className="col-2 text-center border-0"><strong>
                                    {props.order.totalTax > 0 && 'Tax'}</strong></td>
                                <td className="col-1 text-center border-0">
                                    {props.viewInvoice &&
                                    <strong>QTY</strong>
                                    }
                                </td>
                                <td className="col-2 text-right border-0">
                                    {props.viewInvoice ?
                                    <strong>Amount</strong>
                                        :
                                        <strong>QTY</strong>
                                    }
                                </td>
                            </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <table className="table table-responsive card-body px-2">
                    <tbody>
                    {props.order.items.map(item =>
                        <CustomerInvoiceTaxRow viewInvoice={props.viewInvoice}
                            order={props.order} item={item} />
                        )}
                    </tbody>
                </table>
                {props.viewInvoice &&
                <>
                    {showBasketCharges()}
                    {payeeName.length > 0 && bankName.length > 0 &&
                    sortCode.length > 0 && accNumber.length > 0 &&
                    <div style={{float: 'left', width: '50%', fontSize: '0.8em'}}>
                        <table style={{width: '200px'}}>
                            <tr>
                                <td><strong>Payee name: </strong><br/> {payeeName}</td>
                                <td><strong>Bank Name: </strong><br/> {bankName}</td>
                            </tr>
                            <tr>
                                <td><strong>Sort Code: </strong><br/> {sortCode}</td>
                                <td><strong>Account No: </strong><br/> {accNumber}</td>
                            </tr>
                        </table>
                    </div>
                    }
                </>
                }
            </div>
        </>
    );
}

export default CustomerTaxInvoice;
