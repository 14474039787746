import React from 'react';

import '../../css/OrdersCart.css';
import {
    faHandshake, faTruckLoading, faCertificate, faUniversity, faHandshakeSlash, faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import OrderItem from "../product/user/OrderItem";
import Button from "../shared/Button";


const OrdersCart = props => {
    const { showNew, showApproved, showReady, showComplete,
        showArchived, showCancelled, showIncomplete, handleNew,
        handleApproved, handleReady, handleComplete, handleArchived,
        handleCancelled, controlButtonColor, navButtonColor, priceButtonColor } = props

    const isSet = {
        showNew, showApproved, showReady, showComplete,
        showArchived, showCancelled, showIncomplete
    }

    const getIcon = (status) => {
        switch(status) {
            case 0: // 0=new
                return faHandshakeSlash
            case 1: // approved
                return faHandshake
            case 2: // ready/dispatched
                return faTruckLoading
            case 3: // complete
                return faCertificate
            case 4: // archive
                return faUniversity
            case 5: // cancelled
                return faTrashAlt
            default: // incomplete
                return faHandshakeSlash
        }
    }

    const buttonColor = (type) => {
        switch(type){
            case 'new':
                return showNew ? 'dark-blue' : 'grey'
            case 'approved':
                return showApproved ? 'dark-blue' : 'grey'
            case 'ready':
                return showReady ? 'dark-blue' : 'grey'
            case 'complete':
                return showComplete ? 'dark-blue' : 'grey'
            case 'archived':
                return showArchived ? 'dark-blue' : 'grey'
            case 'cancelled':
                return showCancelled ? 'dark-blue' : 'grey'
            default:
                return ''
        }
    }

    return (
        <>
                <nav className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12
                category-navigation navbar-light admin-nav-toggle" style={{
                    marginTop:'0', backgroundColor: props.cartNavColor}}>
                    <ul style={{paddingBottom: '0'}} className="navbar-nav ml-auto">
                        <li className="nav-item nav-controls ">
                            <Button onClick={handleNew}
                                    className={'admin-nav-toggle nav-button ' +
                                    buttonColor('new')}
                                    style={{fontSize:'1.1em', marginLeft:'0'}}
                                    text={'NEW ORDERS'}
                                    icon={faHandshakeSlash}/>
                            <Button onClick={handleApproved}
                                    className={'admin-nav-toggle nav-button ' +
                                    buttonColor('approved')}
                                    style={{fontSize:'1.1em'}}
                                    text={'APPROVED'}
                                    icon={faHandshake}/>
                            <Button onClick={handleReady}
                                    className={'admin-nav-toggle nav-button ' +
                                    buttonColor('ready')}
                                    style={{fontSize:'1.1em'}}
                                    text={'READY'}
                                    icon={faTruckLoading}/>
                            <Button onClick={handleComplete}
                                    className={'admin-nav-toggle nav-button ' +
                                    buttonColor('complete')}
                                    style={{fontSize:'1.1em'}}
                                    text={'COMPLETE'}
                                    icon={faCertificate}/>
                            <Button onClick={handleArchived}
                                    className={'admin-nav-toggle nav-button ' +
                                    buttonColor('archived')}
                                    float={'right'}
                                    text={'ARCHIVED'}
                                    icon={faUniversity}/>
                            <Button onClick={handleCancelled}
                                    className={'admin-nav-toggle nav-button ' +
                                    buttonColor('cancelled')}
                                    float={'right'}
                                    text={'CANCELLED'}
                                    icon={faTrashAlt}/>
                        </li>
                    </ul>
                </nav>
            {props.loadedOrders.map((order, index) => <OrderItem
            key={order._id}
            orderId={order.id}
            order={order}
            orderIndex={index}
            customer={order.customer}
            total={order.total}
            type={order.type}
            status={order.status}
            items={order.items}
            date={order.date}
            isSet={isSet}
            loadedOrders={props.loadedOrders}
            showOrdersMode={props.showOrdersMode}
            getIcon={getIcon}
            getOrders={props.getOrders}
            priceButtonColor={priceButtonColor}
            controlButtonColor={controlButtonColor}
            navButtonColor={navButtonColor}
        />)}

        </>
    );
}

export default OrdersCart;
