import React, { useContext } from 'react';
import '../../css/SupportPage.css'
import {
    faComment,
    faCommentDots,
    faCommentMedical,
    faCommentSlash,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../shared/Button";
import TicketRow from "./TicketRow";
import AuthContext from "../shared/auth-context";
import SupportModal from "../shared/SupportModal";

const SupportPage = props => {
    const auth = useContext(AuthContext)
    let { shopBackground, displayMode, ticketType, showMessage, ticketId,
        setTicketId, replyMode, setReplyMode, handleSwitchTicketType,
        handleReplyMode, handleShowMessage, handleShowNew, handleShowClosed,
        handleShowCustomerReplies, handleShowAwaitingReply, setShowMessage,
        supportButtonColor, controlButtonColor, navButtonColor, priceButtonColor} = props

        return (
            <div id={'support-page'}
                 style={{
                backgroundColor: shopBackground,paddingTop:'18px', minHeight: '700px'
            }}
                 className={'container'} >

                    <nav className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12
                category-navigation navbar-light admin-nav-toggle" style={{
                        marginTop:'30px', backgroundColor: props.cartNavColor}}>
                        <ul style={{paddingBottom: '0'}} className="navbar-nav ml-auto">
                            <li className="nav-item ">
                                <select value={ticketType}
                                        style={{float:'right'}}
                                        onInput={handleSwitchTicketType}
                                        className={'form-control support-select'} >
                                    <option value={'support'}>
                                        Support Tickets
                                    </option>
                                    <option value={'sales'}>
                                        Sales Tickets
                                    </option>
                                </select>
                                <Button onClick={handleShowNew}
                                        className={'admin-nav-toggle nav-button ' +
                                        supportButtonColor('new')}
                                        style={{fontSize:'1.1em', marginLeft:'0'}}
                                        text={'NEW'}
                                        icon={faCommentMedical}/>
                                <Button onClick={handleShowAwaitingReply}
                                        className={'admin-nav-toggle nav-button ' +
                                        supportButtonColor('awaiting')}
                                        style={{fontSize:'1.1em', marginLeft:'0'}}
                                        text={'REPLIES'}
                                        icon={faComment}/>
                                <Button onClick={handleShowCustomerReplies}
                                        className={'admin-nav-toggle nav-button ' +
                                        supportButtonColor('answered')}
                                        style={{fontSize:'1.1em', marginLeft:'0'}}
                                        text={'AWAITING'}
                                        icon={faCommentDots}/>
                                <Button onClick={handleShowClosed}
                                        className={'admin-nav-toggle nav-button ' +
                                        supportButtonColor('closed')}
                                        style={{fontSize:'1.1em', marginLeft:'0'}}
                                        text={'CLOSED'}
                                        icon={faCommentSlash}/>
                            </li>
                        </ul>
                    </nav>
                    <div className={'container'}
                         style={{marginTop:'0', padding: '0px 0px 40px 60px'}}
                    >
                            {ticketType === 'support' ?
                                auth.userTickets.map((ticket, index) => {
                                    return <TicketRow key={'support-tickets-'+ticket._id + '-' + auth.userMessages.length}
                                                      type={'support'}
                                                      ticket={ticket}
                                                      index={index}
                                                      displayMode={displayMode}
                                                      showMessage={showMessage}
                                                      setTicketId={setTicketId}
                                                      handleShowMessage={handleShowMessage}
                                                      navButtonColor={navButtonColor}
                                                      priceButtonColor={priceButtonColor}
                                                      controlButtonColor={controlButtonColor}
                                    />
                                })
                                :
                                ticketType === 'sales' &&
                                auth.userTickets.map((ticket, index) => {
                                    return <TicketRow key={'support-tickets-'+ticket._id}
                                                      type={'support'}
                                                      ticket={ticket}
                                                      index={index}
                                                      displayMode={displayMode}
                                                      showMessage={showMessage}
                                                      setTicketId={setTicketId}
                                                      handleShowMessage={handleShowMessage}
                                                      navButtonColor={navButtonColor}
                                                      priceButtonColor={priceButtonColor}
                                                      controlButtonColor={controlButtonColor}
                                    />
                                })
                            }
                </div>
                <SupportModal showMessage={showMessage}
                              handleShowMessage={handleShowMessage}
                              ticketId={ticketId}
                              handleReplyMode={handleReplyMode}
                              replyMode={replyMode}
                              setShowMessage={setShowMessage}
                              controlButtonColor={controlButtonColor}
                              navButtonColor={navButtonColor}
                              priceButtonColor={priceButtonColor}
                              setReplyMode={setReplyMode}
                              key={'support-modal'}
                              keyMode={auth.userMessages.length}
                />
            </div>
        );
}

export default SupportPage;



