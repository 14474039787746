import React from 'react';

const TermsConditions = () => {

        return (
            <div style={{fontSize: '0.7em'}}>
                <h4>Terms & Conditions</h4>
                <h5>(1) User Accounts</h5>
                <p>(a) To access our Services an account (‘User’) must be registered on our platform. C4RT LTD reserves the right to terminate any account, for any reason, solely at our discretion.</p>
                <p>(b) Users agree that the email used to open the account is the primary means of communication for C4RT LTD and any means of further authentication will happen via this email. It is the Users responsibility to keep their details up to date and to monitor the email address provided for important information from C4RT LTD.</p>
                <p>(c) You are responsible for keeping your password secure and C4RT LTD cannot and will not be held liable for any loss or damage from a Users’ failure to maintain account security.</p>
                <p>(d) Our Users are responsible for the creation and operation of their own C4RT LTD stores.</p>
                <p>(e) Our users are responsible for all content (words/writing, images, emails, videos etc) that were generated by them in their account.</p>
                <p>(f) Failure to abide by any of the terms set out in this agreement may result in immediate and permanent suspension of your account.</p>
                <h5>(2) Customer Accounts</h5>
                <p>(a) Customer accounts can only be created under a certain username (User account) and cannot be created without first creating an order on that Users c4rt.</p>
                <p>(b) Customers who create accounts on C4RT LTD agree that they are entering into an agreement with the User with whom they create their account and not with C4RT LTD.</p>
                <p>(c) Customers who use the site agree that any content (words, images etc) or communications from Users are done so without our prior knowledge and that C4RT LTD does not pre-screen accounts or content before they go live.</p>
                <p>(d) Customers who purchase goods from a User agree that C4RT LTD cannot and will not be held liable for any loss or damages incurred as a result of that Users actions.</p>
                <h5>(3) General Conditions</h5>
                <p>(a) Users of the site who create c4rt accounts (‘Users’) with the intention of selling items via our online portal are the contracting party with C4RT LTD. The owner of the email address who registers the account is considered as the contracting party and the one single owner of the relevant C4RT LTD account.</p>
                <p>(b) Customers of our Users (‘Customers’) enter into a separate agreement between themselves and the Users of our platform (according to the Users own terms and conditions) and are not entered into any kind of contractual agreement with C4RT LTD.</p>
                <p>(c) C4RT LTD does not accept any responsibility for views held or expressions made by our Users either in the configuration of their products and settings or in any of the email communications that are sent to Customers. The views held in these instances are expressly the views of our User and are not a reflection of how C4RT LTD operates.</p>
                <p>(d) Users agree that they are not allowed to sell illegal items or use the C4RT LTD platform for unauthorised purposes (including but not limited to copyright laws) and that they operate in accordance with local law where they live and where appropriate are the correct age to do so. Users are solely responsible for the activity that occurs using our Services.</p>
                <h5>(4) C4RT LTD Rights</h5>
                <p>(a) C4RT LTD reserves the right to make any desired changes to their services at any time without any need for giving notice.</p>
                <p>(b) C4RT LTD reserves the right to refuse anyone for any reason at any time.</p>
                <p>(c) C4RT LTD may, but have no obligation to remove any accounts that violate our Acceptable Uses policy (outlined in section 4)</p>
                <p>(d) C4RT LTD does not pre-screen content created by our Users. We reserve the rights, at our sole discretion, to decide whether a User account has violated our terms or not and take appropriate action at any time.</p>
                <p>(e) Verbal or written abuse of any kind will not be tolerated and will result in immediate account suspension.</p>
                <h5>(5) Acceptable Uses</h5>
                <p>(a) Child exploitation: No form of child exploitation or abuse will be accepted.</p>
                <p>(b) Harassment, bullying, defamation or threats: You may not offer goods or services or use language that could be classed as harassment, bullying, defamation or threatening towards a specific individual.</p>
                <p>(c) Hateful content: You may not use our platform to promote hate in any form</p>
                <p>(d) Illegal activities: You may not use our platform for any kind of illegal activity</p>
                <p>(e) Intellectual property: You may not infringe on the copyright or trademarks of others.</p>
                <p>(f) Deceptive practices: You may not use our platform for deceptive commercial practices or any other illegal or deceptive activities.</p>
                <p>(g) Self-harm: You may not offer goods or services that promote self-harm.</p>
                <p>(h) Spam: You may not use our platform to transmit unsolicited commercial electronic messages.</p>
                <p>(i) Terrorist organizations: You may not imply or promote support for any terrorist organisations.</p>
                <h5>(6) Confidentiality</h5>
                <p>(a) All of the information provided to us by our Users is used in some form of another by parts our systems to configure each order generated on the platform (and subsequently the fulfilment of that order). Our Users data is not used in any other way other than to configure settings and orders on our app, or in communications to our Users (and subsequently their Customers) and is dealt with in a secure and safe manor. All data is encrypted and stored in a highly secure location and a User accounts individual information is only possibly accessible by an individual (logged in) User and their relevant Customers of the platform at any given time.</p>
                <p>(b) Our data and payments are processed in accordance with strict SCA (Strong Customer Authentication) guidelines in accordance with European mandate restrictions.</p>
                <p>(c) Each party agrees to use other party’s data in accordance with the conditions found in this agreement.</p>
                <h5>(7) Limiting Liability</h5>
                <p>(a) As a User or Customer of C4RT LTD you agree that, C4RT LTD shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages as a result of using our service.</p>
                <p>(b) As a User or Customer of C4RT LTD you agree that C4RT LTD shall not be liable for any damages. loss of profits, goodwill or other losses resulting from the use of our service.</p>
                <p>(c) As a User or Customer of C4RT LTD you understand that using our service is done so at your own risk and is each individual’s sole responsibility to conduct their own due diligence when generating a transaction with any of the Users of our service.</p>
                <p>(d) C4RT LTD does not state that any kind of results will be obtained from creating an account on our platform and cannot guarantee the quality of any services or products will meet your expectations.</p>
            </div>
        );

}

export default TermsConditions;
