import React from 'react';
import Button from "react-bootstrap/Button";

const BasketMinusButton = props => {
    let { navButtonColor, getLighterColor} = props


    const removeProduct = async () => {
        if(props.basketItems.length > 0){
            await props.takeFromBasketValue({
                index: props.product.index,
                price: props.price,
                optionId: props.optionId,
                productId: props.productId,
                productName: props.productName
            });
        }
    }

    return (
        <Button className={'shadow-none'} onClick={removeProduct} style={{
            backgroundColor: getLighterColor(navButtonColor), width: '30px', height: '16px', border: '0',
            marginTop:'2px', borderRadius: '5px 5px 5px 5px', paddingTop: '0',
            textAlign: 'center', lineHeight: '15px', cursor: 'pointer', color: '#fff'
        }}>
            -
        </Button>
    );
}

export default BasketMinusButton;
