import React, {useContext, useState} from 'react';
import Button from "react-bootstrap/Button";
import AuthContext from "../shared/auth-context";
import '../../css/UserInvoice.css'
import jwtToken from "jsonwebtoken";
import axios from "axios";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserInvoicesModal from "./UserInvoicesModal";

const UserInvoices = (props) =>{
    const auth = useContext(AuthContext)
    let [ showInvoice, setShowInvoice ] = useState(false)
    let billingDate = new Date(auth.cartOrder.currentPeriodEnd*1000).toString()
    let billingMonth = billingDate.split(' ')[1],
        billingDay = billingDate.split(' ')[2],
        billingYear = billingDate.split(' ')[3]
    const handleShowInvoice = () => {
        setShowInvoice(prevState => !prevState)
    }
    const handleToggleOrder = async () =>{
        let token;
        try{
            token = await jwtToken.sign(
                {
                    userToken: auth.userToken
                },
                `${process.env.REACT_APP_JWT_KEY}`,
                {expiresIn: '1h'}
            );
        }catch(err){console.log(err)}
        await axios.patch(`${process.env.REACT_APP_SITE_URL}/api/order-toggle`, {}, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(async (res) => {
                await auth.setCartOrder(res.data.cartOrder)
            })
            .catch(error => {
                console.log(error);
            });
    }
        return (
            <>
            <div className={'row'} style={{marginTop: '0', padding: '7px'}}>
                <div className={'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12'}
                     style={{float: 'left'}}>
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr style={{lineHeight: '40px'}}>
                        <td style={{width:'33%', verticalAlign:'middle'}}>
                            {auth.stripeSettings.devMode && auth.appSettings.shop.storeIsLive ?
                                <><div className="dev-bubble">
                                <span className="dev-bubble-outer-dot">
                                    <span className="dev-bubble-inner-dot" />
                                </span>
                                </div> <span style={{marginLeft: '40px'}}>
                            DEV <span className={'admin-nav-toggle'}> MODE</span>
                        </span></>
                            : !auth.stripeSettings.devMode && auth.appSettings.shop.storeIsLive ?
                                    <><div className="help-bubble">
                                <span className="help-bubble-outer-dot">
                                    <span className="help-bubble-inner-dot" />
                                </span>
                                    </div> <span style={{marginLeft: '40px'}}>
                            LIVE <span className={'admin-nav-toggle'}> MODE</span>
                        </span></>
                                    :
                                <>
                                    <div className="cancel-bubble">
                                <span className="cancel-bubble-outer-dot">
                                    <span className="cancel-bubble-inner-dot" />
                                </span>
                                    </div> <span style={{marginLeft: '40px'}}>
                                    OFF
                        </span>
                                </>
                            }
                        </td>
                        <td style={{width:'33%', textAlign:'center'}}>{billingDay}/{billingMonth}/{billingYear}</td>
                        <td style={{width:'34%', textAlign:'center'}}>
                            £{auth.cartOrder.currentOrderTotal || 0.00} <span className={'admin-nav-toggle'}>
                            / {auth.cartOrder.orderPeriod}</span></td>
                    </tr>
                    </tbody>
                </table>
                </div>
                <div className={'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12'}
                     style={{float: 'left'}}>
                    <span className={'hide-nav-toggle'}><hr /></span>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td style={{width:'34%', textAlign:'center', cursor: 'pointer'}}>
                                <span style={{fontWeight: 'bold'}}
                                      onClick={handleShowInvoice}>
                                    <span
                                        className={'admin-nav-toggle'}
                                    >View </span>Invoice{auth.cartInvoices.length > 1 && 's'}
                                </span>
                            </td>
                            <td style={{width:'33%', textAlign:'center'}}>
                                {!auth.cartOrder.cancelPeriodEnds ?
                                    <span style={{fontWeight: 'bold', fontSize: '0.9em',
                                        color: '#44db35'
                                    }}>
                                        ACTIVE
                                    </span>
                                    :
                                    <span style={{fontWeight: 'bold', fontSize: '0.9em',
                                        color: '#dc3545'
                                    }}>
                                        CANCELLED
                                    </span> }
                            </td>
                            <td style={{width:'33%'}}>
                                {!auth.cartOrder.cancelPeriodEnds ?
                                <Button variant={'danger'} style={{float: 'right'}}
                                        onClick={handleToggleOrder} >
                                    CANCEL
                                </Button> : <Button variant={'success'} style={{float: 'right'}}
                                                    onClick={handleToggleOrder} >
                                        ACTIVATE <FontAwesomeIcon style={{marginLeft: '10px'}}
                                                                  icon={faRocket} />
                                    </Button> }

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
                <UserInvoicesModal
                    showInvoice={showInvoice}
                    handleShowInvoice={handleShowInvoice}
                />
            </>

        );

}

export default UserInvoices;
