import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCommentMedical, faUserMd
} from "@fortawesome/free-solid-svg-icons";
import Button from "../shared/Button";
import '../../css/AboutPage.css'


const AboutPage = (props) => {
    let {
        controlButtonColor, navButtonColor, priceButtonColor, handleShowContactForm
    } = props
    return (
        <>
            <div className={'container'} style={{
                padding: '0', marginLeft: 'auto', marginRight: 'auto',
                maxWidth: '960px', width: '100%'
            }}>
                    <div style={{
                        backgroundColor: 'white', marginTop: '0', padding: '0',
                        minHeight: '700px', maxWidth: '960px',
                        marginLeft: 'auto', marginRight: 'auto',
                    }} id={'about-page'}>
                        <div style={{paddingBottom: '30px', paddingTop: '70px'}} className="container">
                            <div className="row" style={{marginTop: '0', padding: '0 20px 0 20px'}}>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12">
                                    <h2>UK Based Customer Support</h2>
                                    <p>We're passionate about your results and have keen and highly
                                        knowledgeable support staff on standby to answer any of your
                                        questions and ensure your success with our systems. Support is
                                        open Mon - Sat 09:00 to 17:00.</p>
                                </div>
                            </div>

                            <div style={{
                                backgroundColor: 'whitesmoke', padding: '30px',
                                marginBottom: '20px',
                                color: 'black', textAlign: 'center', marginTop: '20px'
                            }}
                                 className="row">
                                <div className="col-xl-9 col-lg-8 col-md-6 col-sm-12 col-sx-12">
                                    <h3 style={{marginTop: '0', color: '#282a36'}}>
                                        Contact our support for guaranteed success
                                        <FontAwesomeIcon style={{marginLeft: '20px'}}
                                                         icon={faUserMd}/></h3>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-sx-12">
                                    <div className={'hide-nav-toggle'} style={{height: '30px'}}/>
                                    <Button onClick={handleShowContactForm}
                                            className={'launch-button'}
                                            key={'click-new-support-ticket'}
                                            keyUsed={'click-new-'}
                                            keyMode={'support-ticket'}
                                            controlButtonColor={navButtonColor}
                                            navButtonColor={controlButtonColor}
                                            priceButtonColor={priceButtonColor}
                                            text={'OPEN TICKET'}
                                            solid={true}
                                            active={true}
                                            categoryButton={true}
                                            sidebarButton={true}
                                            float={'right'}
                                            icon={faCommentMedical}/>
                                </div>
                            </div>
                            <div className="row" style={{marginTop: '0', padding: '0 20px 0 20px'}}>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12">
                                    <p><em>It's free to create an account on our platform (so you can see how easy it is
                                        to use).
                                        Once you're happy you know how to use it, you trust that it works and you want
                                        to use it to
                                        start generating orders, then simply activate your account (thus displaying your
                                        products)
                                        and you'll be able to share your c4rt with other people. </em></p>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>

        </>

    );
}

export default AboutPage;
