import React, {useContext} from 'react';
import Button from "react-bootstrap/Button";
import AuthContext from "../shared/auth-context";
import DisplayOptionsAmount from "./DisplayOptionsAmount";

const PriceOptions  = (props) => {
    const auth = useContext(AuthContext)
    let { basketItems, loadedProducts, row, priceButtonColor } = props

    const getGlobalValue = () => {
        let gotValue = false
        for (let i = 0; i < basketItems.length; i++) {
            if (basketItems[i].productId !== basketItems.optionId) {
                for (let j = 0; j < loadedProducts.length; j++) {
                    if (loadedProducts[j]._id === basketItems[i].productId) {
                        for (let k = 0; k < loadedProducts[j].priceOptions.length; k++) {
                            if (row.id === basketItems[i].optionId) {
                                return basketItems[i].value
                            }
                        }
                    }

                }
            }
        }
        if(!gotValue){
            return 0
        }
    }
    const addPriceOptionToBasket = async () => {
        let newValue = getGlobalValue() + 1
        await props.addToBasketValue({
            index: props.index,
            productId: props.productId,
            optionId: props.row.id,
            productName: props.productName,
            quantities: row.quantities,
            image: props.row.image,
            taxRate: props.taxRate,
            value: newValue,
            price: props.price
        });
    }
    const takePriceOptionFromBasket = async () => {
        if(getGlobalValue() > 0){
            await props.takeFromBasketValue({
                index: props.index,
                price: props.price,
                optionId: props.row.id,
                productId: props.productId,
                productName: props.productName
            });
        }
    }
        return (
            <tr id={'options-' + props.index  + '-' +  props.row.productId}>
                <td style={{width: '60px'}}>
                    <img style={{width: '50px', borderRadius: '5px 5px 5px 5px'}}
                         src={row.image} alt={row.productName} />
                </td>
                <td style={{width: '60px'}}>
                    /{row.quantities}
                </td>
                <td style={{textAlign: 'center', fontWeight: 'bold', }}>
                    {auth.appSettings.shop.currencySymbol}{row.price.toFixed(2)}
                </td>
                <td style={{width: '40px'}}>

                    <Button className="option-product-buttons product-controls" name={props.index}
                            style={{backgroundColor: priceButtonColor, color:'white',
                                marginTop:'5px'}}>
                                        <span style={{padding: '0', fontSize: '2em', margin: '0', lineHeight: '2rem'}}
                                              onClick={takePriceOptionFromBasket}>
                                            -
                                        </span>
                    </Button></td>
                <td style={{width: '40px'}}>
                <DisplayOptionsAmount currentValue={getGlobalValue()}
                                      valueColor={props.valueColor}

                />
                </td>
                <td style={{width: '40px'}}>
                    <Button className="option-product-buttons product-controls" name={props.index}
                            style={{backgroundColor: priceButtonColor, color:'white',
                                marginTop:'5px'}}>
                                     <span style={{padding: '0', fontSize: '2em', margin: '0', lineHeight: '2rem'}}
                                           onClick={addPriceOptionToBasket}>
                                         +
                                     </span>
                    </Button>
                </td>
            </tr>
        )
}

export default PriceOptions;
