import React, {useContext} from 'react';
import AuthContext from "../shared/auth-context";

const UserInvoiceItem = (props) => {
    const auth = useContext(AuthContext)
    let {invoice, index} = props
    let paymentString = String(invoice.createdOn)
    let createdOnDate = paymentString.split('T')[0]
    let createdOnTimeFull = paymentString.split('T')[1]
    let createdOnTime = createdOnTimeFull.split('.')[0]
    let {type} = invoice
    return (
        <>
            <div id={'print-' + index} className="container-fluid invoice-container">
                <div className="row" style={{marginTop:'0'}}>
                    <div className="col-sm-6"><strong>Date:</strong> {createdOnDate}</div>
                    <div className="col-sm-6 text-sm-right"><strong>Invoice No:</strong> #
                        {invoice.number} <span style={{
                            padding: '0', float: 'right', fontSize: '1em', marginLeft: '10px'
                        }} className="text-7 mb-0">
                            {invoice.paymentComplete ?
                                <span style={{color: '#2cc415'}}>[PAID]</span>
                                :
                                <span style={{color: '#ea3c53'}}>[UNPAID]</span>
                            }
                        </span></div>

                </div>
                <div className="row">
                    <div className="col-sm-6 text-sm-right order-sm-1"><strong>Pay To:</strong>
                        <p className={'address'}>
                            C4RT LTD<br/>
                            86-90 Paul Street<br />
                            EC2A 4NE<br />
                            London<br />
                            England<br />
                            United Kingdom<br />

                        </p>
                    </div>
                    <div className="col-sm-6 order-sm-0"><strong>Invoiced To:</strong>
                        <p className={'address'}>
                            {auth.appSettings.invoicing.invoice.companyName}<br/>
                            {auth.appSettings.invoicing.invoice.addressOne}<br/>
                            {auth.appSettings.invoicing.invoice.addressTwo}<br/>
                            {auth.appSettings.invoicing.invoice.postcode}<br/>
                            {auth.appSettings.invoicing.invoice.country}<br/>
                            {auth.appSettings.invoicing.invoice.phone}
                        </p>
                    </div>
                </div>
                <hr/>
                <table style={{marginBottom: '0'}}
                       className="table table-responsive card-body px-2">
                    <tbody>
                    <tr>
                        <td className="col-6 border-0">
                            <p style={{
                                marginBottom: '0'
                            }}>{type === 1 || '1' ? 'Annual'
                                : type === 1.1 || '1.1' ? 'Annual'
                                    : type === '1.2' ? 'Annual'
                                        : type === '1.3' ? 'Annual'
                                            : type === '0' ? 'Monthly'
                                                : type === '0.1' ? 'Monthly'
                                                    : type === '0.2' ? 'Monthly'
                                                        : type === '0.3' && 'Monthly'
                            } Membership {type === '1' ? 'Activation'
                                : type === '1.1' ? '(33% Off)'
                                    : type === '1.2' ? '(25% Off)'
                                        : type === '1.3' ? '(20% Off)'
                                            : type === '0' ? 'Activation'
                                                : type === '0.1' ? '(30% Off)'
                                                    : type === '0.2' ? '(16% Off)'
                                                        : type === '0.3' && '(10% Off)'
                            }</p>
                            <p style={{
                                fontSize: '0.8em', marginBottom:'0'
                            }}>Created on <strong>
                                {createdOnDate}
                            </strong> at {createdOnTime}
                            </p>
                        </td>
                        <td className="col-1 border-0"/>
                        <td className="col-5 text-center border-0">
                            <strong>
                                £{invoice.total}
                            </strong>
                        </td>
                    </tr>
                    </tbody>
                </table>


            </div>
        </>
    );

}

export default UserInvoiceItem;
