

const currencies = [
    'USD', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM',
    'BBD', 'BDT', 'BGN', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BWP', 'BZD', 'CAD',
    'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD',
    'EGP', 'ETB', 'EUR', 'FJD', 'FKP', 'GBP', 'GEL', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD',
    'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'ISK', 'JMD', 'JPY', 'KES',
    'KGS', 'KHR', 'KMF', 'KRW', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'MAD',
    'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR',
    'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR',
    'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SEK', 'SGD',
    'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SZL', 'THB', 'TJS', 'TOP', 'TRY', 'TTD', 'TWD',
    'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF',
    'YER', 'ZAR', 'ZMW'
]
const timeZones = [
    'GMT - (Greenwich Mean Time)',
    'GMT - (Universal Coordinated Time)',
    'GMT+1:00 - (British Summer Time)',
    'GMT+2:00 - (Eastern European Time)',
    'GMT+2:00 - (Arabic or Egypt Standard Time)',
    'GMT+3:00 - (Eastern African Time)',
    'GMT+3:30 - (Middle East Time)',
    'GMT+4:00 - (Near East Time)',
    'GMT+5:00 - (Pakistan Lahore Time)',
    'GMT+5:30 - (India Standard Time)',
    'GMT+6:00 - (Bangladesh Standard Time)',
    'GMT+7:00 - (Vietnam Standard Time)',
    'GMT+8:00 - (China Taiwan Time)',
    'GMT+9:00 - (Japan Standard Time)',
    'GMT+9:30 - (Australia Central Time)',
    'GMT+10:00 - (Australia Eastern Time)',
    'GMT+11:00 - (Solomon Standard Time)',
    'GMT+12:00 - (New Zealand Standard Time)',
    'GMT-11:00 - (Midway Islands Time)',
    'GMT-10:00 - (Hawaii Standard Time)',
    'GMT-9:00 - (Alaska Standard Time)',
    'GMT-8:00 - (Pacific Standard Time)',
    'GMT-7:00 - (Phoenix Standard Time)',
    'GMT-7:00 - (Mountain Standard Time)',
    'GMT-6:00 - (Central Standard Time)',
    'GMT-5:00 - (Eastern Standard Time)',
    'GMT-5:00 - (Indiana Eastern Standard Time)',
    'GMT-4:00 - (Puerto Rico and US Virgin Islands Time)',
    'GMT-3:30 - (Canada Newfoundland Time)',
    'GMT-3:00 - (Argentina Standard Time)',
    'GMT-3:00 - (Brazil Eastern Time)',
    'GMT-1:00 - (Central African Time)'
]
const currencySymbols = [
    '£','€','$','ƒ','¥','₡','₱','¢','₪','¥','₩','₭','₮','₦','₽','₴','฿','؋','﷼','₼','៛',
    'B/.','$b','Br', 'Bs','BZ$','CHF', 'C$','₫', 'Ft','Gs','J$','Kč','KM','kn','kr','L',
    'lei','MT','NT$','P','Q','R','RD$','RM','Rp','₨','R$','S','S/.','TT$','$U','Z$','zł',
    'лв','ден', 'Дин.'
]
const countries = [
    'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua & Deps',
    'Argentina', 'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh',
    'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia', 'Bosnia Herzegovina',
    'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina', 'Burundi', 'Cambodia', 'Cameroon',
    'Canada', 'Cape Verde', 'Central African Rep', 'Chad', 'Chile', 'China', 'Colombia',
    'Comoros', 'Congo', 'Congo {Democratic Rep}', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus',
    'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'East Timor',
    'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Ethiopia',
    'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece',
    'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Honduras', 'Hungary',
    'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland {Republic}', 'Israel', 'Italy',
    'Ivory Coast', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Korea North',
    'Korea South', 'Kosovo', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho',
    'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macedonia', 'Madagascar',
    'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania',
    'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco',
    'Mozambique', 'Myanmar {Burma}', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand',
    'Nicaragua', 'Niger', 'Nigeria', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Panama',
    'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar',
    'Romania', 'Russian Federation', 'Rwanda', 'St Kitts & Nevis', 'St Lucia',
    'Saint Vincent & the Grenadines', 'Samoa', 'San Marino', 'Sao Tome & Principe',
    'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia',
    'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Sudan', 'Spain', 'Sri Lanka',
    'Sudan', 'Suriname', 'Swaziland', 'Sweden', 'Switzerland', 'Syria', 'Taiwan', 'Tajikistan',
    'Tanzania', 'Thailand', 'Togo', 'Tonga', 'Trinidad & Tobago', 'Tunisia', 'Turkey',
    'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom',
    'United States', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City', 'Venezuela', 'Vietnam',
    'Yemen', 'Zambia', 'Zimbabwe'];

module.exports.currencies = currencies;
module.exports.timeZones = timeZones;
module.exports.currencySymbols = currencySymbols;
module.exports.countries = countries;
